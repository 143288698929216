import axios, { AxiosInstance } from "axios";
import { Answerable } from "@/interfaces/answers";
import { toCamelCase } from "@/utils/toCamelCase";

class Api {
  private globalHttp: AxiosInstance;
  private http: AxiosInstance;

  constructor() {
    this.globalHttp = axios.create({
      baseURL: process.env.VUE_APP_API_URL
    });

    this.http = axios.create({});
  }

  async loadAnswers(path: string): Promise<Answerable> {
    const data = await this.http.get(`${path}`, {
      headers: { "Access-Control-Allow-Origin": "*" }
    });
    return toCamelCase(data.data.data);
  }
}

export const api = new Api();
