import { monthLUT, KPILUT, EvaluationType } from "@/data";
import { BenchmarkSurveyAnswers } from "./answers";

export enum MetricType {
  CURRENCY,
  PERCENT,
  NUMBER,
  PPTS,
  NONE
}

export enum StageState {
  BEHIND = "behind",
  AHEAD = "ahead",
  ON_TRACK = "on track",
  LESS_BURN = "less burn",
  MORE_BURN = "more burn",
  POSITIVE_CF = "positive CF",
  N_A = "n.a."
}

export enum Period {
  CURRENT = "current",
  YEAR_LATER = "yearLater",
  NEXT_STAGE = "nextStage"
}

enum Average {
  MEDIAN = "median",
  AVERAGE = "average"
}

enum TestType {
  BENCHMARK,
  DELTA,
  PERFORMANCE
}

export interface Statistics {
  name: string;
  inputType: MetricType;
  input: number;
  metrics: Metric;
}

export interface StatisticsHeader {
  name: string;
  stage: string;
  firstColumn: Column;
  secondColumn: Column;
  thirdColumn: Column;
}

export interface StatisticsObject {
  headers: StatisticsHeader[];
  stats: Statistics[];
}

type Column = {
  name: string;
  value?: number | string;
  type: string;
};

interface Metric {
  benchmark: Periods<TestType.BENCHMARK>;
  delta: Periods<TestType.DELTA>;
  performance: Periods<TestType.PERFORMANCE>;
}

type Periods<T extends TestType> = {
  [L in Period]: Averages<T>;
} & { metricType: MetricType };

type Averages<T extends TestType> = {
  [A in Average]: IPeriod<T>;
};

// type IBenchmarking<T extends TestType> = {
//   metricType?: MetricType;
// } & IPeriod<T>;

type IPeriod<T> = T extends TestType.BENCHMARK
  ? { amount: number | string }
  : T extends TestType.DELTA
  ? { percent: number | string }
  : { state: string | string };

/* ----- Helper functions ----- */

const lookUpAndAverageKPI = (
  evaluationType: EvaluationType,
  evaluationGroups: string[],
  avg: Average,
  month: number,
  metricType: MetricType
) => {
  try {
    const average =
      evaluationGroups
        .map(group => {
          try {
            //@ts-ignore
            return KPILUT[evaluationType][group][avg][month];
          } catch (error) {
            //console.log("no val for", evaluationType, group);
            return KPILUT[evaluationType]["all"][avg][month] as number;
          }
        })
        .reduce((avg, cur: any) => (avg += cur / 3), 0) *
      (metricType === MetricType.PERCENT ? 100 : 1);
    return average;
  } catch (error) {
    console.warn(
      "evaluation failed for",
      evaluationType,
      evaluationGroups,
      ", falling back to 'all ventures'",
      error
    );
    return KPILUT[evaluationType]["all"][avg][month] as number;
  }
};

const getBenchMark = (
  metricType: MetricType,
  evaluationType: EvaluationType,
  evaluationGroups: string[],
  monthsToCheck: { current: number; yearLater: number; nextStage: number },
  options: { tooEarly?: boolean } = {},
  override: (v: number, p: Period, a: Average) => number = (v: number) => v
) => ({
  metricType,
  current: {
    median: {
      amount: options.tooEarly
        ? "too early"
        : override(
            lookUpAndAverageKPI(
              evaluationType,
              evaluationGroups,
              Average.MEDIAN,
              monthsToCheck.current,
              metricType
            ),
            Period.CURRENT,
            Average.MEDIAN
          )
    },
    average: {
      amount: options.tooEarly
        ? "too early"
        : override(
            lookUpAndAverageKPI(
              evaluationType,
              evaluationGroups,
              Average.AVERAGE,
              monthsToCheck.current,
              metricType
            ),
            Period.CURRENT,
            Average.AVERAGE
          )
    }
  },
  yearLater: {
    median: {
      amount: override(
        lookUpAndAverageKPI(
          evaluationType,
          evaluationGroups,
          Average.MEDIAN,
          monthsToCheck.yearLater,
          metricType
        ),
        Period.YEAR_LATER,
        Average.MEDIAN
      )
    },
    average: {
      amount: override(
        lookUpAndAverageKPI(
          evaluationType,
          evaluationGroups,
          Average.AVERAGE,
          monthsToCheck.yearLater,
          metricType
        ),
        Period.YEAR_LATER,
        Average.AVERAGE
      )
    }
  },
  nextStage: {
    median: {
      amount: override(
        lookUpAndAverageKPI(
          evaluationType,
          evaluationGroups,
          Average.MEDIAN,
          monthsToCheck.nextStage,
          metricType
        ),
        Period.NEXT_STAGE,
        Average.MEDIAN
      )
    },
    average: {
      amount: override(
        lookUpAndAverageKPI(
          evaluationType,
          evaluationGroups,
          Average.AVERAGE,
          monthsToCheck.nextStage,
          metricType
        ),
        Period.NEXT_STAGE,
        Average.AVERAGE
      )
    }
  }
});

const getStageState = (
  userInput: number,
  benchmark: number,
  metricType: MetricType,
  options: { cashBurn?: boolean; tooEarly?: boolean } = {}
) => {
  // too early
  if (options.tooEarly) return StageState.N_A;
  // cashburn
  if (options.cashBurn) {
    if (userInput > 0) return StageState.POSITIVE_CF;
    if (Math.abs(benchmark - userInput) / (-1 * userInput) < 0.05)
      return StageState.ON_TRACK;
    return userInput > benchmark ? StageState.LESS_BURN : StageState.MORE_BURN;
  }
  // growth values (values < 0 ==> always behind)
  if (metricType === MetricType.PERCENT) {
    if (userInput < 0) return StageState.BEHIND;
  }
  // other
  if (Math.abs(benchmark - userInput) / userInput < 0.05)
    return StageState.ON_TRACK;
  return userInput > benchmark ? StageState.AHEAD : StageState.BEHIND;
};

const getFromBenchmark = (
  benchmark: Periods<TestType.BENCHMARK>,
  userInput: number,
  options: { cashBurn?: boolean; tooEarly?: boolean } = {}
) => ({
  delta: {
    metricType:
      benchmark.metricType === MetricType.PERCENT
        ? MetricType.PPTS
        : MetricType.PERCENT,
    current: options.tooEarly
      ? { median: { percent: "n.a." }, average: { percent: "n.a." } }
      : {
          median: {
            percent:
              ((benchmark.current.median.amount as number) / userInput - 1) *
              100,
            pptsPercent:
              benchmark.metricType === MetricType.PERCENT
                ? (benchmark.current.median.amount as number) - userInput
                : null
          },
          average: {
            percent:
              ((benchmark.current.average.amount as number) / userInput - 1) *
              100,
            pptsPercent:
              benchmark.metricType === MetricType.PERCENT
                ? (benchmark.current.average.amount as number) - userInput
                : null
          }
        },
    yearLater: {
      median: {
        percent:
          ((benchmark.yearLater.median.amount as number) / userInput - 1) * 100,
        pptsPercent:
          benchmark.metricType === MetricType.PERCENT
            ? (benchmark.yearLater.median.amount as number) - userInput
            : null
      },
      average: {
        percent:
          ((benchmark.yearLater.average.amount as number) / userInput - 1) *
          100,
        pptsPercent:
          benchmark.metricType === MetricType.PERCENT
            ? (benchmark.yearLater.average.amount as number) - userInput
            : null
      }
    },
    nextStage: {
      median: {
        percent:
          ((benchmark.nextStage.median.amount as number) / userInput - 1) * 100,
        pptsPercent:
          benchmark.metricType === MetricType.PERCENT
            ? (benchmark.nextStage.median.amount as number) - userInput
            : null
      },
      average: {
        percent:
          ((benchmark.nextStage.average.amount as number) / userInput - 1) *
          100,
        pptsPercent:
          benchmark.metricType === MetricType.PERCENT
            ? (benchmark.nextStage.average.amount as number) - userInput
            : null
      }
    }
  },
  performance: {
    metricType: MetricType.NONE,
    current: {
      median: {
        state: getStageState(
          userInput,
          benchmark.current.median.amount as number,
          benchmark.metricType,
          options
        )
      },
      average: {
        state: getStageState(
          userInput,
          benchmark.current.average.amount as number,
          benchmark.metricType,
          options
        )
      }
    }
  }
});

/* ----- Main computing ----- */

export const computeStats: (
  surveyData: BenchmarkSurveyAnswers
) => StatisticsObject = (surveyData: BenchmarkSurveyAnswers) => {
  const statObject: StatisticsObject = { headers: [], stats: [] };

  /* ----- Preparations ----- */
  const nextFundingStage = surveyData.nextFundingStage;
  const ageOfCompany = ((start: Date, end: Date) => {
    let m: number;
    m = (end.getFullYear() - start.getFullYear()) * 12;
    m -= start.getMonth();
    m += end.getMonth();
    return m;
  })(surveyData.businessStartDate, surveyData.currentKPIMonth);
  const nextFundingMonth = Math.round(
    surveyData.evaluationGroups
      .map(group => {
        return (
          //@ts-ignore
          monthLUT[nextFundingStage][group] || monthLUT[nextFundingStage].all
        );
      })
      .reduce((avg, cur: any) => (avg += cur / 3), 0)
  );
  const monthsToCheck = {
    current: ageOfCompany,
    yearLater: ageOfCompany + 12,
    nextStage: nextFundingMonth
  };
  console.log(monthsToCheck);
  const cashRunRate =
    surveyData.cashBurn > 0
      ? StageState.POSITIVE_CF
      : Math.round(surveyData.cashBalance / Math.abs(surveyData.cashBurn));

  /* ----- Headers ----- */

  statObject.headers.push({
    name: "Current stage",
    stage: surveyData.fundingStage,
    firstColumn: {
      name: `Average time to ${surveyData.nextFundingStage}`,
      type: "In Months",
      value: nextFundingMonth - ageOfCompany
    },
    secondColumn: {
      name: "Cash run rate",
      type: "In Months",
      value: cashRunRate
    },
    thirdColumn: {
      name: "Cash situation **",
      type:
        cashRunRate === StageState.POSITIVE_CF
          ? cashRunRate
          : cashRunRate > 6
          ? "funding sufficient"
          : "funding needed"
    }
  });

  /* ----- Pre-Money evaluation ----- */
  if (
    surveyData.preMoneyValuation !== null &&
    surveyData.preMoneyValuation !== undefined
  ) {
    const benchmark: Periods<TestType.BENCHMARK> = getBenchMark(
      MetricType.CURRENCY,
      EvaluationType.PRE_MONEY_VALUATION,
      surveyData.evaluationGroups,
      monthsToCheck
    );
    const { delta, performance } = getFromBenchmark(
      benchmark,
      surveyData.preMoneyValuation
    );

    statObject.stats.push({
      name: EvaluationType.PRE_MONEY_VALUATION,
      inputType: MetricType.CURRENCY,
      input: surveyData.preMoneyValuation,
      metrics: {
        benchmark,
        delta,
        performance: performance as Periods<TestType.PERFORMANCE>
      }
    });
  }
  /* ----- Total revenue ----- */
  if (
    surveyData.totalRevenue !== null &&
    surveyData.totalRevenue !== undefined
  ) {
    const MRRbenchmark: Periods<TestType.BENCHMARK> = getBenchMark(
      MetricType.CURRENCY,
      EvaluationType.MRR,
      surveyData.evaluationGroups,
      monthsToCheck
    );
    const GMVbenchmark = getBenchMark(
      MetricType.CURRENCY,
      EvaluationType.GMV,
      surveyData.evaluationGroups,
      monthsToCheck
    );
    const takeRateBenchmark: Periods<TestType.BENCHMARK> = getBenchMark(
      MetricType.PERCENT,
      EvaluationType.TAKE_RATE,
      surveyData.evaluationGroups,
      monthsToCheck
    );
    const benchmark: Periods<TestType.BENCHMARK> = getBenchMark(
      MetricType.CURRENCY,
      EvaluationType.TOTAL_REVENUE,
      surveyData.evaluationGroups,
      monthsToCheck,
      {},
      (v: number, p: Period, a: Average) => {
        if (surveyData.model === "SaaS")
          return MRRbenchmark[p][a].amount as number;
        if (surveyData.eCommerce)
          return (
            ((GMVbenchmark[p][a].amount as number) *
              (takeRateBenchmark[p][a].amount as number)) /
            100
          );
        return v;
      }
    );
    const { delta, performance } = getFromBenchmark(
      benchmark,
      surveyData.totalRevenue
    );

    statObject.stats.push({
      name: EvaluationType.TOTAL_REVENUE,
      inputType: MetricType.CURRENCY,
      input: surveyData.totalRevenue,
      metrics: {
        benchmark,
        delta,
        performance: performance as Periods<TestType.PERFORMANCE>
      }
    });
  }
  /* ----- Revenue Growth ----- */
  if (
    surveyData.totalRevenueGrowth !== null &&
    surveyData.totalRevenueGrowth !== undefined
  ) {
    const MRRGrowthBenchmark: Periods<TestType.BENCHMARK> = getBenchMark(
      MetricType.PERCENT,
      EvaluationType.MRR_GROWTH,
      surveyData.evaluationGroups,
      monthsToCheck
    );
    const GMVGrowthBenchmark: Periods<TestType.BENCHMARK> = getBenchMark(
      MetricType.PERCENT,
      EvaluationType.GMV_GROWTH,
      surveyData.evaluationGroups,
      monthsToCheck
    );
    const takeRateGrowthBenchmark: Periods<TestType.BENCHMARK> = getBenchMark(
      MetricType.PERCENT,
      EvaluationType.TAKE_RATE_GROWTH,
      surveyData.evaluationGroups,
      monthsToCheck
    );
    const benchmark: Periods<TestType.BENCHMARK> = getBenchMark(
      MetricType.PERCENT,
      EvaluationType.REVENUE_GROWTH,
      surveyData.evaluationGroups,
      monthsToCheck,
      { tooEarly: monthsToCheck.current < 3 },
      (v: number, p: Period, a: Average) => {
        if (surveyData.model === "SaaS")
          return MRRGrowthBenchmark[p][a].amount as number;
        if (surveyData.eCommerce)
          return (
            (GMVGrowthBenchmark[p][a].amount as number) *
            (1 + (takeRateGrowthBenchmark[p][a].amount as number) / 100)
          );
        return v;
      }
    );
    const { delta, performance } = getFromBenchmark(
      benchmark,
      surveyData.totalRevenueGrowth,
      { tooEarly: monthsToCheck.current < 3 }
    );

    statObject.stats.push({
      name: EvaluationType.REVENUE_GROWTH,
      inputType: MetricType.PERCENT,
      input: surveyData.totalRevenueGrowth,
      metrics: {
        benchmark,
        delta,
        performance: performance as Periods<TestType.PERFORMANCE>
      }
    });
  }
  /* ----- Cash Burn ----- */
  if (surveyData.cashBurn !== null && surveyData.cashBurn !== undefined) {
    const benchmark: Periods<TestType.BENCHMARK> = getBenchMark(
      MetricType.CURRENCY,
      EvaluationType.CASH_BURN,
      surveyData.evaluationGroups,
      monthsToCheck
    );
    const { delta, performance } = getFromBenchmark(
      benchmark,
      surveyData.cashBurn,
      { cashBurn: true }
    );

    statObject.stats.push({
      name: EvaluationType.CASH_BURN,
      inputType: MetricType.CURRENCY,
      input: surveyData.cashBurn,
      metrics: {
        benchmark,
        delta,
        performance: performance as Periods<TestType.PERFORMANCE>
      }
    });
  }
  /* ----- FTE ----- */
  if (surveyData.FTE !== null && surveyData.FTE !== undefined) {
    const benchmark: Periods<TestType.BENCHMARK> = getBenchMark(
      MetricType.NUMBER,
      EvaluationType.FTE,
      surveyData.evaluationGroups,
      monthsToCheck
    );
    const { delta, performance } = getFromBenchmark(benchmark, surveyData.FTE);

    statObject.stats.push({
      name: EvaluationType.FTE,
      inputType: MetricType.NUMBER,
      input: surveyData.FTE,
      metrics: {
        benchmark,
        delta,
        performance: performance as Periods<TestType.PERFORMANCE>
      }
    });
  }
  /* ----- FTE Growth ----- */
  if (surveyData.FTEGrowth !== null && surveyData.FTEGrowth !== undefined) {
    const benchmark: Periods<TestType.BENCHMARK> = getBenchMark(
      MetricType.PERCENT,
      EvaluationType.FTE_GROWTH,
      surveyData.evaluationGroups,
      monthsToCheck,
      { tooEarly: monthsToCheck.current < 3 }
    );
    const { delta, performance } = getFromBenchmark(
      benchmark,
      surveyData.FTEGrowth,
      { tooEarly: monthsToCheck.current < 3 }
    );

    statObject.stats.push({
      name: EvaluationType.FTE_GROWTH,
      inputType: MetricType.PERCENT,
      input: surveyData.FTEGrowth,
      metrics: {
        benchmark,
        delta,
        performance: performance as Periods<TestType.PERFORMANCE>
      }
    });
  }
  /* ----- Customer ----- */
  if (
    surveyData.customerCount !== null &&
    surveyData.customerCount !== undefined
  ) {
    const benchmark: Periods<TestType.BENCHMARK> = getBenchMark(
      MetricType.NUMBER,
      EvaluationType.CUSTOMERS,
      surveyData.evaluationGroups,
      monthsToCheck
    );
    const { delta, performance } = getFromBenchmark(
      benchmark,
      surveyData.customerCount
    );

    statObject.stats.push({
      name: EvaluationType.CUSTOMERS,
      inputType: MetricType.NUMBER,
      input: surveyData.customerCount,
      metrics: {
        benchmark,
        delta,
        performance: performance as Periods<TestType.PERFORMANCE>
      }
    });
  }
  /* ----- Customer Growth ----- */
  if (
    surveyData.customerCountGrowth !== null &&
    surveyData.customerCountGrowth !== undefined
  ) {
    const benchmark: Periods<TestType.BENCHMARK> = getBenchMark(
      MetricType.PERCENT,
      EvaluationType.CUSTOMER_GROWTH,
      surveyData.evaluationGroups,
      monthsToCheck,
      { tooEarly: monthsToCheck.current < 3 }
    );
    const { delta, performance } = getFromBenchmark(
      benchmark,
      surveyData.customerCountGrowth,
      { tooEarly: monthsToCheck.current < 3 }
    );

    statObject.stats.push({
      name: EvaluationType.CUSTOMER_GROWTH,
      inputType: MetricType.PERCENT,
      input: surveyData.customerCountGrowth,
      metrics: {
        benchmark,
        delta,
        performance: performance as Periods<TestType.PERFORMANCE>
      }
    });
  }
  /* ----- MRR ----- */
  if (surveyData.MRR !== null && surveyData.MRR !== undefined) {
    const benchmark: Periods<TestType.BENCHMARK> = getBenchMark(
      MetricType.CURRENCY,
      EvaluationType.MRR,
      surveyData.evaluationGroups,
      monthsToCheck
    );
    const { delta, performance } = getFromBenchmark(benchmark, surveyData.MRR);

    statObject.stats.push({
      name: EvaluationType.MRR,
      inputType: MetricType.CURRENCY,
      input: surveyData.MRR,
      metrics: {
        benchmark,
        delta,
        performance: performance as Periods<TestType.PERFORMANCE>
      }
    });
  }
  /* ----- MRR Growth ----- */
  if (surveyData.MRRGrowth !== null && surveyData.MRRGrowth !== undefined) {
    const benchmark: Periods<TestType.BENCHMARK> = getBenchMark(
      MetricType.PERCENT,
      EvaluationType.MRR_GROWTH,
      surveyData.evaluationGroups,
      monthsToCheck,
      { tooEarly: monthsToCheck.current < 3 }
    );
    const { delta, performance } = getFromBenchmark(
      benchmark,
      surveyData.MRRGrowth,
      { tooEarly: monthsToCheck.current < 3 }
    );

    statObject.stats.push({
      name: EvaluationType.MRR_GROWTH,
      inputType: MetricType.PERCENT,
      input: surveyData.MRRGrowth,
      metrics: {
        benchmark,
        delta,
        performance: performance as Periods<TestType.PERFORMANCE>
      }
    });
  }
  /* ----- GMV ----- */
  if (surveyData.GMV !== null && surveyData.GMV !== undefined) {
    const benchmark: Periods<TestType.BENCHMARK> = getBenchMark(
      MetricType.CURRENCY,
      EvaluationType.GMV,
      surveyData.evaluationGroups,
      monthsToCheck
    );
    const { delta, performance } = getFromBenchmark(benchmark, surveyData.GMV);

    statObject.stats.push({
      name: EvaluationType.GMV,
      inputType: MetricType.CURRENCY,
      input: surveyData.GMV,
      metrics: {
        benchmark,
        delta,
        performance: performance as Periods<TestType.PERFORMANCE>
      }
    });
  }
  /* ----- GMV Growth ----- */
  if (surveyData.GMVGrowth !== null && surveyData.GMVGrowth !== undefined) {
    const benchmark: Periods<TestType.BENCHMARK> = getBenchMark(
      MetricType.PERCENT,
      EvaluationType.GMV_GROWTH,
      surveyData.evaluationGroups,
      monthsToCheck,
      { tooEarly: monthsToCheck.current < 3 }
    );
    const { delta, performance } = getFromBenchmark(
      benchmark,
      surveyData.GMVGrowth,
      { tooEarly: monthsToCheck.current < 3 }
    );

    statObject.stats.push({
      name: EvaluationType.GMV_GROWTH,
      inputType: MetricType.PERCENT,
      input: surveyData.GMVGrowth,
      metrics: {
        benchmark,
        delta,
        performance: performance as Periods<TestType.PERFORMANCE>
      }
    });
  }
  /* ----- Take Rate ----- */
  if (surveyData.takeRate !== null && surveyData.takeRate !== undefined) {
    const benchmark: Periods<TestType.BENCHMARK> = getBenchMark(
      MetricType.PERCENT,
      EvaluationType.TAKE_RATE,
      surveyData.evaluationGroups,
      monthsToCheck
    );
    const { delta, performance } = getFromBenchmark(
      benchmark,
      surveyData.takeRate
    );

    statObject.stats.push({
      name: EvaluationType.TAKE_RATE,
      inputType: MetricType.PERCENT,
      input: surveyData.takeRate,
      metrics: {
        benchmark,
        delta,
        performance: performance as Periods<TestType.PERFORMANCE>
      }
    });
  }
  return statObject;
};
