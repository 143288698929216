import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
const Analyze = () => import("@/views/Analyze.vue");
const NotFound = () => import("@/views/NotFound.vue");

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    redirect: { name: "Analyze" }
  },
  {
    path: "/analyze/:encodedUrl",
    name: "Analyze",
    component: Analyze
  },
  {
    path: "/404",
    name: "NotFound",
    component: NotFound
  },
  {
    path: "*",
    redirect: { name: "NotFound" }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// router.beforeEach((to, from, next) => {
//   if (!to.params.encodedUrl && to.name !== "NotFound") {
//     next({ name: "NotFound" });
//   }
//   next();
// });

export default router;
