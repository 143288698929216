import Vue from "vue";
import Vuex from "vuex";
import { Answerable } from "@/interfaces/answers";
import { api } from "@/api/api";
import { MetricType, Statistics } from "@/interfaces/benchmark";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    answers: null as Answerable | null,
    statistics: [
      {
        name: "Pre-money valuation",
        inputType: MetricType.CURRENCY,
        input: 5000000,
        metrics: {
          benchmark: {
            metricType: MetricType.CURRENCY,
            current: {
              median: {
                amount: 4758000
              },
              average: {
                amount: 9436900
              }
            },
            yearLater: {
              median: {
                amount: 6558000
              },
              average: {
                amount: 13215612
              }
            },
            nextStage: {
              median: {
                amount: 6558000
              },
              average: {
                amount: 13215612
              }
            }
          },
          delta: {
            metricType: MetricType.PERCENT,
            current: {
              median: {
                percent: 10
              },
              average: {
                percent: 23
              }
            },
            yearLater: {
              median: {
                percent: 33
              },
              average: {
                percent: 76
              }
            },
            nextStage: {
              median: {
                percent: 12
              },
              average: {
                percent: 32
              }
            }
          },
          performance: {
            metricType: MetricType.NONE,
            current: {
              median: {
                state: "behind"
              },
              average: {
                state: "above"
              }
            }
          }
        }
      },
      {
        name: "Pre-money valuation",
        inputType: MetricType.CURRENCY,
        input: 5000000,
        metrics: {
          benchmark: {
            metricType: MetricType.CURRENCY,
            current: {
              median: {
                amount: -4758
              },
              average: {
                amount: -9436
              }
            },
            yearLater: {
              median: {
                amount: 6558000
              },
              average: {
                amount: 13215612
              }
            },
            nextStage: {
              median: {
                amount: 6558000
              },
              average: {
                amount: 13215612
              }
            }
          },
          delta: {
            metricType: MetricType.PERCENT,
            current: {
              median: {
                percent: -23
              },
              average: {
                percent: -12
              }
            },
            yearLater: {
              median: {
                percent: 6558000
              },
              average: {
                percent: 13215612
              }
            },
            nextStage: {
              median: {
                percent: 6558000
              },
              average: {
                percent: 13215612
              }
            }
          },
          performance: {
            metricType: MetricType.NONE,
            current: {
              median: {
                state: "ahead"
              },
              average: {
                state: "ahead"
              }
            },
            yearLater: {
              median: {
                state: "less burn"
              },
              average: {
                state: "less burn"
              }
            },
            nextStage: {
              median: {
                state: "less burn"
              },
              average: {
                state: "less burn"
              }
            }
          }
        }
      }
    ] as Statistics[]
  },
  mutations: {
    setAnswers(state, answers: Answerable): void {
      state.answers = answers;
    }
  },
  actions: {
    async fetchAnswers({ commit }, { path }: { path: string }): Promise<void> {
      const answers = await api.loadAnswers(path);
      commit("setAnswers", answers);
    }
  },
  modules: {}
});
