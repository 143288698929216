export enum Industry {
  IT = "Information Technology (IT)",
  FIPT = "Fin, Insur, Prop Tech",
  DH = "Digital Health",
  OTHER = "Universal (Others)"
}

export enum RevenueModel {
  RRM = "Recurring Revenue Model",
  TRM = "Transactional Revenue Model",
  MRM = "Mixed Revenue Model"
}

export enum FundingStage {
  PRESEED = "Pre-Seed",
  SEED = "Seed",
  SA = "Series A",
  SB = "Series B",
  SC = "Series C",
  SD = "Series D",
  SE = "Series E",
  // not selectable
  SF = "Series F"
}

export enum CustomerFocus {
  B2B = "B2B",
  B2C = "B2C",
  MIXED = "Mixed"
}

const questionIdMap: any = {
  // section1:
  [process.env.VUE_APP_Q_BUSINESS_START_DATE]: "businessStartDate",
  [process.env.VUE_APP_Q_INDUSTRY]: "industry",
  [process.env.VUE_APP_Q_REVENUE_MODEL]: "revenueModel",
  [process.env.VUE_APP_Q_E_COMMERCE]: "eCommerce",
  [process.env.VUE_APP_Q_FUNDING_STAGE]: "fundingStage",
  [process.env.VUE_APP_Q_CUSTOMER_FOCUS]: "customerFocus",
  [process.env.VUE_APP_Q_CURRENT_KPI_MONTH]: "currentKPIMonth",

  // section2:
  [process.env.VUE_APP_Q_PRE_MONEY_VALUATION]: "preMoneyValuation",
  [process.env.VUE_APP_Q_TOTAL_REVENUE]: "totalRevenue",
  [process.env.VUE_APP_Q_TOTAL_REVENUE_GROWTH]: "totalRevenueGrowth",
  [process.env.VUE_APP_Q_FTE]: "FTE",
  [process.env.VUE_APP_Q_FTE_GROWTH]: "FTEGrowth",
  [process.env.VUE_APP_Q_CUSTOMER_COUNT]: "customerCount",
  [process.env.VUE_APP_Q_CUSTOMER_COUNT_GROWTH]: "customerCountGrowth",
  [process.env.VUE_APP_Q_CASH_BALANCE]: "cashBalance",
  [process.env.VUE_APP_Q_CASH_BURN]: "cashBurn",
  [process.env.VUE_APP_Q_MRR]: "MRR",
  [process.env.VUE_APP_Q_MRR_GROWTH]: "MRRGrowth",
  [process.env.VUE_APP_Q_GMV]: "GMV",
  [process.env.VUE_APP_Q_GMV_GROWTH]: "GMVGrowth",
  [process.env.VUE_APP_Q_TAKE_RATE]: "takeRate"
};

const defaultValues = {
  // section1:
  businessStartDate: new Date("2018.05.01"),
  industry: Industry.IT,
  revenueModel: RevenueModel.TRM,
  eCommerce: false,
  fundingStage: FundingStage.SEED,
  customerFocus: CustomerFocus.B2B,
  currentKPIMonth: new Date("2020.10.01"),

  // section2:
  preMoneyValuation: 5000000,
  totalRevenue: 5000,
  totalRevenueGrowth: 15, //%
  FTE: 20,
  FTEGrowth: 8, //%
  customerCount: 23,
  customerCountGrowth: 23, //%
  cashBalance: 100000,
  cashBurn: -10000,
  MRR: 4500,
  MRRGrowth: -2, //%
  GMV: 180000, // random value, not from sheet
  GMVGrowth: 15, //% // random value, not from sheet
  takeRate: 15 //%
};

export class BenchmarkSurveyAnswers {
  // section1:
  businessStartDate: Date;
  industry: Industry;
  revenueModel: RevenueModel;
  eCommerce: boolean;
  fundingStage: FundingStage;
  customerFocus: CustomerFocus;
  currentKPIMonth: Date;

  // section2:
  preMoneyValuation: number;
  totalRevenue: number;
  totalRevenueGrowth: number;
  FTE: number;
  FTEGrowth: number;
  customerCount: number;
  customerCountGrowth: number;
  cashBalance: number;
  cashBurn: number;
  MRR: number;
  MRRGrowth: number;
  GMV: number;
  GMVGrowth: number;
  takeRate: number;

  constructor(surveyData?: Answerable) {
    let answers: any;
    if (!surveyData) {
      answers = defaultValues;
    } else {
      // load from surveyData
      answers = {};
      for (const a of surveyData.answers) {
        const fieldName = questionIdMap[a.questionRef];
        if (!fieldName) continue;
        switch (a.type) {
          case "date":
            answers[fieldName] = new Date(a.value as string);
            break;
          case "dropdown":
            answers[fieldName] =
              JSON.parse(a.valueString as string).en ||
              JSON.parse(a.valueString as string)[0].en;
            break;

          default:
            answers[fieldName] = a.value;
        }
      }
      Object.values(questionIdMap).map(q => {
        if (answers[q as string] === undefined)
          console.warn("Unanswered question:", q);
      });
    }
    this.businessStartDate = answers.businessStartDate;
    this.industry = answers.industry;
    this.revenueModel = answers.revenueModel;
    this.eCommerce = answers.eCommerce;
    this.fundingStage = answers.fundingStage;
    this.customerFocus = answers.customerFocus;
    this.currentKPIMonth = answers.currentKPIMonth;

    // section2:
    this.preMoneyValuation = answers.preMoneyValuation;
    this.totalRevenue = answers.totalRevenue;
    this.totalRevenueGrowth = answers.totalRevenueGrowth;
    this.FTE = answers.FTE;
    this.FTEGrowth = answers.FTEGrowth;
    this.customerCount = answers.customerCount;
    this.customerCountGrowth = answers.customerCountGrowth;
    this.cashBalance = answers.cashBalance;
    this.cashBurn = answers.cashBurn;
    this.MRR = answers.MRR;
    this.MRRGrowth = answers.MRRGrowth;
    this.GMV = answers.GMV;
    this.GMVGrowth = answers.GMVGrowth;
    this.takeRate = answers.takeRate;
  }

  get nextFundingStage(): FundingStage {
    const orderedStages = [
      FundingStage.PRESEED,
      FundingStage.SEED,
      FundingStage.SA,
      FundingStage.SB,
      FundingStage.SC,
      FundingStage.SD,
      FundingStage.SE,
      FundingStage.SF
    ];
    return orderedStages[orderedStages.indexOf(this.fundingStage) + 1];
  }

  get model(): string | undefined {
    return !this.revenueModel
      ? undefined
      : this.revenueModel === RevenueModel.TRM
      ? "Transactional"
      : "SaaS";
  }

  get evaluationGroups(): string[] {
    return [this.model as string, this.customerFocus, this.industry];
  }
}

export interface Answerable {
  id: number;
  user: User;
  formId: number;
  status: string;
  fillOutLanguage: string;
  createdAt: string;
  answers: Array<Answer>;
}

export type Answer = {
  id: number;
  type: string;
  questionId: number;
  value: string | number | boolean | Array<number>;
  valueString: string | null;
  otherAnswer: string;
  noAnswer: boolean;
  questionRef: string;
};

type User = {
  id: null | number;
  fullName: string;
};
