/* eslint-disable */
// ^ for speedup
import { CustomerFocus, FundingStage, Industry } from "../interfaces/answers";
//import { EvaluationType } from "../interfaces/benchmark";

export enum EvaluationType {
  PRE_MONEY_VALUATION = "Pre-Money Valuation",
  TOTAL_REVENUE = "Total Revenue",
  REVENUE_GROWTH = "Revenue Growth (3 Months trailing)",
  CASH_BURN = "Cash Burn",
  FTE = "FTE",
  FTE_GROWTH = "FTE Growth (3 Months trailing)",
  MRR = "Total MRR",
  MRR_GROWTH = "MRR Growth (3 Months trailing)",
  GMV = "GMV",
  GMV_GROWTH = "GMV Growth (3 Months trailing)",
  CUSTOMERS = "Existing Customers",
  CUSTOMER_GROWTH = "Customer Growth (3 Months trailing)",
  TAKE_RATE = "Take Rate",
  TAKE_RATE_GROWTH = "Take Rate Growth (3 Months trailing)"
}

export const monthLUT = {
  [FundingStage.SEED]: {
    all: 19.775,
    SaaS: 19.375,
    Transactional: 20.0416666666667,
    [CustomerFocus.B2B]: 22.5416666666667,
    [CustomerFocus.B2C]: 15.93333333,
    [Industry.IT]: 20.47619048,
    [Industry.FIPT]: 19.66666667,
    [Industry.DH]: 42,
    [Industry.OTHER]: 17.3333333333333
  },
  [FundingStage.SA]: {
    all: 34.953125,
    SaaS: 41.54545455,
    Transactional: 31.5,
    [CustomerFocus.B2B]: 38.29411765,
    [CustomerFocus.B2C]: 29.07142857,
    [Industry.IT]: 38.35483871,
    [Industry.FIPT]: 34.57142857,
    [Industry.DH]: 48.66666667,
    [Industry.OTHER]: 28.69565217
  },
  [FundingStage.SB]: {
    all: 53,
    SaaS: 65,
    Transactional: 49.34782609,
    [CustomerFocus.B2B]: 61.06666667,
    [CustomerFocus.B2C]: 43.30769231,
    [Industry.IT]: 51,
    [Industry.FIPT]: 47.16666667,
    [Industry.DH]: 67.5,
    [Industry.OTHER]: 42.44444444
  },
  [FundingStage.SC]: {
    all: 59.28571429,
    SaaS: 67,
    Transactional: 59.28571429,
    [CustomerFocus.B2B]: 55,
    [CustomerFocus.B2C]: 60,
    [Industry.IT]: 55,
    [Industry.FIPT]: 70.5,
    [Industry.DH]: 60,
    [Industry.OTHER]: 55.33333333
  },
  [FundingStage.SD]: {
    all: 67.4,
    SaaS: 70,
    Transactional: 67.4,
    [CustomerFocus.B2B]: 65,
    [CustomerFocus.B2C]: 67.4,
    [Industry.IT]: 60,
    [Industry.FIPT]: 90,
    [Industry.DH]: 72,
    [Industry.OTHER]: 50.66666667
  },
  [FundingStage.SE]: {
    all: 71,
    SaaS: 71,
    Transactional: 71,
    [CustomerFocus.B2B]: 71,
    [CustomerFocus.B2C]: 71,
    [Industry.IT]: 71,
    [Industry.FIPT]: 100,
    [Industry.DH]: 75,
    [Industry.OTHER]: 71
  },
  [FundingStage.SF]: {
    all: 83,
    SaaS: 80,
    Transactional: 83,
    [CustomerFocus.B2B]: 80,
    [CustomerFocus.B2C]: 83,
    [Industry.IT]: 83,
    [Industry.FIPT]: 110,
    [Industry.DH]: 83,
    [Industry.OTHER]: 83
  }
};

export const KPILUT = {
  sample: {
    all: { median: [], average: [] },
    SaaS: { median: [], average: [] },
    Transactional: { median: [], average: [] },
    [CustomerFocus.B2B]: { median: [], average: [] },
    [CustomerFocus.B2C]: { median: [], average: [] },
    [Industry.OTHER]: { median: [], average: [] },
    [Industry.IT]: { median: [], average: [] },
    [Industry.FIPT]: { median: [], average: [] },
    [Industry.DH]: { median: [], average: [] }
  },
  [EvaluationType.PRE_MONEY_VALUATION]: {
    all: {
      median: [
        null,
        31322.6334694685,
        721322.633469469,
        1411322.63346947,
        2101322.63346947,
        2791322.63346947,
        3481322.63346947,
        3568738.91635736,
        3658350.2289275,
        3750211.68854643,
        3844379.79658206,
        3940912.47315608,
        4039869.09276893,
        4141310.5208194,
        4245299.15104117,
        4351898.94387948,
        4461175.4658313,
        4573195.92977347,
        4688029.23630342,
        4805746.01611793,
        4926418.67345612,
        5050121.43063317,
        5176930.3736924,
        5306923.49920361,
        5440180.76223656,
        5576784.12553903,
        5716817.6099498,
        5860367.34607748,
        6007521.62727696,
        6158370.96395617,
        6313008.13924643,
        6471528.26607075,
        6634028.84564503,
        6800609.82744831,
        6971373.67069887,
        7146425.40737393,
        7325872.70681186,
        7509825.94193651,
        7698398.25714445,
        7891705.6378969,
        8089866.98205904,
        8293004.17303071,
        8501242.15471346,
        8714709.00835991,
        8933536.03135296,
        9157857.81796303,
        9387812.34213316,
        9623541.04234286,
        9865188.9086029,
        10112904.5716345,
        10366840.394288,
        10627152.5652567,
        10894001.1951441,
        11167550.4149435,
        11447968.4769904,
        11735427.8584494,
        12030105.3674002,
        12332182.2515871,
        12641844.3098995,
        12959282.0066514,
        13284690.5887306,
        13618270.2056893,
        13960226.0328509,
        14310768.3975068,
        14670112.9082834,
        15038480.5877564,
        15416098.0083955,
        15803197.4319231,
        16200016.9521712,
        16606800.6415267,
        17023798.7010532,
        17451267.6143825,
        17889470.3054703,
        18338676.3003134,
        18799161.8927275,
        19271210.3142874,
        19755111.9085349,
        20251164.3095609,
        20759672.6250713,
        21280949.6240504,
        21815315.9291363,
        22363100.2138263,
        22924639.4046349,
        23500278.8883278,
        24090372.724359,
        24695283.8626435,
        25315384.3667965,
        25951055.6429798,
        26602688.6744939,
        27270684.2622601,
        27955453.2713422,
        28657416.883658,
        29377006.8570359,
        30114665.7907771,
        30870847.3978858,
        31646016.7841352,
        32440650.7341406,
        33255238.0046162,
        34090279.6249956,
        34946289.2056003,
        35823793.2535473,
        36723331.4965882,
        37645457.2150805,
        38590737.5822948,
        39559754.0132667,
        40553102.5224087,
        41571394.0901017,
        42615255.0384915,
        43685327.4167221,
        44782269.3958413,
        45906755.6736234,
        47059477.8895564,
        48241145.05025,
        49452483.9655248,
        50694239.6954529,
        51967176.0086231,
        53272075.8519126,
        54609741.8320563,
        55980996.7093063,
        57386683.9034891,
        58827668.0127689
      ],
      average: [
        null,
        109927.329412658,
        829927.329412658,
        1549927.32941266,
        2269927.32941266,
        2989927.32941266,
        3709927.32941266,
        3843610.99750522,
        3982111.82818018,
        4125603.40326453,
        4274265.55943967,
        4428284.61362908,
        4587853.59650765,
        4753172.49442522,
        4924448.50004768,
        5101896.27202965,
        5285738.20404417,
        5476204.7035067,
        5673534.48034262,
        5877974.84616022,
        6089782.02420402,
        6309221.47047712,
        6536568.20643468,
        6772107.16366588,
        7016133.54099605,
        7268953.17445665,
        7530882.92058682,
        7802251.05354672,
        8083397.67654063,
        8374675.14806509,
        8676448.52351668,
        8989096.01271254,
        9313009.45389735,
        9648594.80483057,
        9996272.65156962,
        10356478.7355866,
        10729664.4998789,
        11116297.6547589,
        11516862.7640307,
        11931861.85229,
        12361815.0341062,
        12807261.1658779,
        13268758.5211765,
        13746885.4904253,
        14242241.3057909,
        14755446.7921956,
        15287145.1453916,
        15838002.7380719,
        16408709.9550299,
        16999982.0584117,
        17612560.0841478,
        18247211.770687,
        18904732.5211954,
        19585946.4004285,
        20291707.1675237,
        21022899.3460088,
        21780439.3323675,
        22565276.5445506,
        23378394.611873,
        24220812.6077871,
        25093586.3270782,
        25997809.6090831,
        26934615.7085882,
        27905178.7161276,
        28910715.0294605,
        29952484.8780706,
        31031793.9026011,
        32149994.7912015,
        33308488.9748396,
        34508728.3837021,
        35752217.2668855,
        37040514.0776579,
        38375233.4266537,
        39758048.105451,
        41190691.1830658,
        42674958.1779916,
        44212709.3085059,
        45805871.8240657,
        47456442.4207109,
        49166489.7435053,
        50938156.979151,
        52773664.5420244,
        54675312.8570012,
        56645485.2425582,
        58686650.8977645,
        60801367.9969058,
        62992286.8956215,
        65262153.4525706,
        67613812.470792,
        70050211.2630694,
        72574403.3457721,
        75189552.265799,
        77898935.565423,
        80705948.8900048,
        83614110.2437245,
        86627064.3986638,
        89748587.4627658,
        92982591.6123964,
        96333129.9954395,
        99804401.81107,
        103400757.572572,
        107126704.559798,
        110986912.468099,
        114986219.260815,
        119129637.232648,
        123422359.291524,
        127869765.466819,
        132477429.652103,
        137251126.590844,
        142196839.113845,
        147320765.637472,
        152629327.932071,
        158129179.170313,
        163827212.26556,
        169730568.510683,
        175846646.528182,
        182183111.542818,
        188747904.988358,
        195549254.460512,
        202595684.028497,
        209896024.918176
      ]
    },
    SaaS: {
      median: [
        null,
        29492.49184115,
        709492.49184115,
        1389492.49184115,
        2069492.49184115,
        2749492.49184115,
        3429492.49184115,
        3506829.27466351,
        3585910.0408862,
        3666774.11821318,
        3749461.72120795,
        3834013.97129267,
        3920472.91719827,
        4008881.55587575,
        4099283.853879,
        4191724.76922985,
        4286250.27377616,
        4382907.37605409,
        4481744.14466602,
        4582809.73218545,
        4686154.39960123,
        4791829.54131278,
        4899887.71068913,
        5010382.64620424,
        5123369.29816171,
        5238903.85602219,
        5357043.77634693,
        5477847.8113716,
        5601376.03822437,
        5727689.88880288,
        5856852.18032502,
        5988927.14656858,
        6123980.46981539,
        6262079.3135158,
        6403292.3556898,
        6547689.82308131,
        6695343.52608271,
        6846326.89444686,
        7000715.01380457,
        7158584.6630054,
        7320014.35230066,
        7485084.36238735,
        7653876.78433263,
        7826475.56039855,
        8002966.52578746,
        8183437.45132875,
        8367978.08712819,
        8556680.20720168,
        8749637.65511534,
        8946946.39065503,
        9148704.53754805,
        9355012.4322612,
        9565972.67389913,
        9781690.17522797,
        10002272.2148496,
        10227828.4905522,
        10458471.1738647,
        10694314.9658401,
        10935477.1540982,
        11182077.6711536,
        11434239.1540593,
        11692087.005396,
        11955749.4556354,
        12225357.6269111,
        12501045.5982268,
        12782950.4721352,
        13071212.4429208,
        13365974.8663196,
        13667384.3308118,
        13975590.7305215,
        14290747.3397608,
        14613010.889255,
        14942541.6440859,
        15279503.4833936,
        15624063.9818755,
        15976394.4931227,
        16336670.2348362,
        16705070.3759644,
        17081778.1258054,
        17466980.8251196,
        17860870.0392959,
        18263641.6536189,
        18675495.9706852,
        19096637.8100159,
        19527276.6099153,
        19967626.5316271,
        20417906.5658386,
        20878340.6415878,
        21349157.7376249,
        21830591.9962865,
        22322882.8399366,
        22826275.0900344,
        23341019.0888859,
        23867370.8241424,
        24405592.0561056,
        24955950.447904,
        25518719.6986041,
        26094179.6793245,
        26682616.5724183,
        27284323.0137949,
        27899598.2384508,
        28528748.2292822,
        29172085.8692539,
        29829931.0969991,
        30502611.0659283,
        31190460.3069261,
        31893820.8947171,
        32613042.6179832,
        33348483.1533172,
        34100508.2430991,
        34869491.877384,
        35655816.4798909,
        36459873.0981863,
        37282061.5981563,
        38122790.8628641,
        38982478.9958919,
        39861553.5292681,
        40760451.6360839,
        41679620.3479037,
        42619516.7770788,
        43580608.3440743
      ],
      average: [
        null,
        350162.0,
        700324.0,
        1050486.0,
        1400648.0,
        1750810.0,
        2100972.0,
        2451134.0,
        2801296.0,
        3151458.0,
        3501620.0,
        3851782.0,
        4201944.0,
        4552106.0,
        4902268.0,
        5252430.0,
        5602592.0,
        5952754.0,
        6302916.0,
        6653078.0,
        7003240.0,
        7353402.0,
        7703564.0,
        8053726.0,
        8403888.0,
        8754050.0,
        9104212.0,
        9454374.0,
        9804536.0,
        10154698.0,
        10504860.0,
        10855022.0,
        11205184.0,
        11555346.0,
        11905508.0,
        12255670.0,
        12605832.0,
        12955994.0,
        13306156.0,
        13656318.0,
        14006480.0,
        14356642.0,
        14706804.0,
        15056966.0,
        15407128.0,
        15757290.0,
        16107452.0,
        16457614.0,
        16807776.0,
        17157938.0,
        17508100.0,
        17858262.0,
        18208424.0,
        18558586.0,
        18908748.0,
        19258910.0,
        19609072.0,
        19959234.0,
        20309396.0,
        20659558.0,
        21009720.0,
        21359882.0,
        21710044.0,
        22060206.0,
        22410368.0,
        22760530.0,
        23110692.0,
        23460854.0,
        23811016.0,
        24161178.0,
        24511340.0,
        24861502.0,
        25211664.0,
        25561826.0,
        25911988.0,
        26262150.0,
        26612312.0,
        26962474.0,
        27312636.0,
        27662798.0,
        28012960.0,
        28363122.0,
        28713284.0,
        29063446.0,
        29413608.0,
        29763770.0,
        30113932.0,
        30464094.0,
        30814256.0,
        31164418.0,
        31514580.0,
        31864742.0,
        32214904.0,
        32565066.0,
        32915228.0,
        33265390.0,
        33615552.0,
        33965714.0,
        34315876.0,
        34666038.0,
        35016200.0,
        35366362.0,
        35716524.0,
        36066686.0,
        36416848.0,
        36767010.0,
        37117172.0,
        37467334.0,
        37817496.0,
        38167658.0,
        38517820.0,
        38867982.0,
        39218144.0,
        39568306.0,
        39918468.0,
        40268630.0,
        40618792.0,
        40968954.0,
        41319116.0,
        41669278.0,
        42019440.0
      ]
    },
    Transactional: {
      median: [
        null,
        388694.973359178,
        788694.973359178,
        1188694.97335918,
        1588694.97335918,
        1988694.97335918,
        2388694.97335918,
        2460457.1857928,
        2534375.30979772,
        2610514.11420625,
        2688940.31366411,
        2769722.62708738,
        2852931.8378757,
        2938640.85593437,
        3026924.78155971,
        3117860.97124383,
        3211529.10545615,
        3308011.2584614,
        3407391.97023503,
        3509758.32053917,
        3615200.00522401,
        3723809.41482145,
        3835681.7154999,
        3950914.93245116,
        4069610.03578242,
        4191871.02898873,
        4317805.04008333,
        4447522.41546579,
        4581136.81661016,
        4718765.3196579,
        4860528.51800276,
        5006550.62795767,
        5156959.59759599,
        5311887.2188627,
        5471469.24305368,
        5635845.49976421,
        5805160.01941107,
        5979561.15943536,
        6159201.73429696,
        6344239.1493741,
        6534835.53888584,
        6731157.90795786,
        6933378.27895644,
        7141673.84221852,
        7356227.11131014,
        7577226.08294921,
        7804864.40173276,
        8039341.52981291,
        8280862.92167048,
        8529640.20413905,
        8785891.36183751,
        9049840.92817342,
        9321720.18208451,
        9601767.3506909,
        9890227.81803534,
        10187354.3400947,
        10493407.2662505,
        10808654.767414,
        11133373.071003,
        11467846.7029798,
        11812368.7371591,
        12167241.0520064,
        12532774.5951517,
        12909289.6558483,
        13297116.1456187,
        13696593.8873307,
        14108072.9129585,
        14531913.7702886,
        14968487.8388412,
        15418177.6552812,
        15881377.2486063,
        16358492.4854045,
        16849941.4254843,
        17356154.6881887,
        17877575.829714,
        18414661.7317645,
        18967883.0018833,
        19537724.3858098,
        20124685.1922255,
        20729279.7302604,
        21352037.7601427,
        21993504.9573877,
        22654243.3909318,
        23334832.0156305,
        24035867.1795525,
        24757963.1465146,
        25501752.6343141,
        26267887.3691311,
        27057038.6565871,
        27869897.9699587,
        28707177.5560629,
        29569611.0593456,
        30457954.1647176,
        31372985.2597043,
        32315506.1164873,
        33286342.5944367,
        34286345.3637485,
        35316390.6508224,
        36377381.0060326,
        37470246.0945636,
        38595943.5110055,
        39755459.6184212,
        40949810.4126214,
        42180042.4124043,
        43447233.5765409,
        44752494.2483077,
        46096968.1283968,
        47481833.2770533,
        48908303.14632,
        50377627.6432936,
        51891094.2253227,
        53450029.0281091,
        55055798.0276999,
        56709808.2373879,
        58413508.9405707,
        60168392.9606479,
        61975997.9690678,
        63837907.8326732,
        65755754.0015225,
        67731216.9384057,
        69766027.5913064
      ],
      average: [
        null,
        68206.24,
        268206.24,
        468206.24,
        668206.24,
        868206.24,
        1068206.24,
        1098647.52,
        1179831.68,
        1310886.56,
        1490940.0,
        1719119.84,
        1994553.92,
        2316370.08,
        2683696.16,
        3095660.0,
        3551389.44,
        4050012.32,
        4590656.48,
        5172449.76,
        5794520.0,
        6455995.04,
        7156002.72,
        7893670.88,
        8668127.36,
        9478500.0,
        10323916.64,
        11203505.12,
        12116393.28,
        13061708.96,
        14038580.0,
        15046134.24,
        16083499.52,
        17149803.68,
        18244174.56,
        19365740.0,
        20513627.84,
        21686965.92,
        22884882.08,
        24106504.16,
        25350960.0,
        26617377.44,
        27904884.32,
        29212608.48,
        30539677.76,
        31885220.0,
        33248363.04,
        34628234.72,
        36023962.88,
        37434675.36,
        38859500.0,
        40297564.64,
        41747997.12,
        43209925.28,
        44682476.96,
        46164780.0,
        47655962.24,
        49155151.52,
        50661475.68,
        52174062.56,
        53692040.0,
        55214535.84,
        56740677.92,
        58269594.08,
        59800412.16,
        61332260.0,
        62864265.44,
        64395556.32,
        65925260.48,
        67452505.76,
        68976420.0,
        70496131.04,
        72010766.72,
        73519454.88,
        75021323.36,
        76515500.0,
        78001112.64,
        79477289.12,
        80943157.28,
        82397844.96,
        83840480.0,
        85270190.24,
        86686103.52,
        88087347.68,
        89473050.56,
        90842340.0,
        92194343.84,
        93528189.92,
        94843006.08,
        96137920.16,
        97412060.0,
        98664553.44,
        99894528.32,
        101101112.48,
        102283433.76,
        103440620.0,
        104571799.04,
        105676098.72,
        106752646.88,
        107800571.36,
        108819000.0,
        109807060.64,
        110763881.12,
        111688589.28,
        112580312.96,
        113438180.0,
        114261318.24,
        115048855.52,
        115799919.68,
        116513638.56,
        117189140.0,
        117825551.84,
        118422001.92,
        118977618.08,
        119491528.16,
        119962860.0,
        120390741.44,
        120774300.32,
        121112664.48,
        121404961.76,
        121650320.0
      ]
    },
    [CustomerFocus.B2B]: {
      median: [
        null,
        140417.0,
        280834.0,
        421251.0,
        561668.0,
        702085.0,
        842502.0,
        982919.0,
        1123336.0,
        1263753.0,
        1404170.0,
        1544587.0,
        1685004.0,
        1825421.0,
        1965838.0,
        2106255.0,
        2246672.0,
        2387089.0,
        2527506.0,
        2667923.0,
        2808340.0,
        2948757.0,
        3089174.0,
        3229591.0,
        3370008.0,
        3510425.0,
        3650842.0,
        3791259.0,
        3931676.0,
        4072093.0,
        4212510.0,
        4352927.0,
        4493344.0,
        4633761.0,
        4774178.0,
        4914595.0,
        5055012.0,
        5195429.0,
        5335846.0,
        5476263.0,
        5616680.0,
        5757097.0,
        5897514.0,
        6037931.0,
        6178348.0,
        6318765.0,
        6459182.0,
        6599599.0,
        6740016.0,
        6880433.0,
        7020850.0,
        7161267.0,
        7301684.0,
        7442101.0,
        7582518.0,
        7722935.0,
        7863352.0,
        8003769.0,
        8144186.0,
        8284603.0,
        8425020.0,
        8565437.0,
        8705854.0,
        8846271.0,
        8986688.0,
        9127105.0,
        9267522.0,
        9407939.0,
        9548356.0,
        9688773.0,
        9829190.0,
        9969607.0,
        10110024.0,
        10250441.0,
        10390858.0,
        10531275.0,
        10671692.0,
        10812109.0,
        10952526.0,
        11092943.0,
        11233360.0,
        11373777.0,
        11514194.0,
        11654611.0,
        11795028.0,
        11935445.0,
        12075862.0,
        12216279.0,
        12356696.0,
        12497113.0,
        12637530.0,
        12777947.0,
        12918364.0,
        13058781.0,
        13199198.0,
        13339615.0,
        13480032.0,
        13620449.0,
        13760866.0,
        13901283.0,
        14041700.0,
        14182117.0,
        14322534.0,
        14462951.0,
        14603368.0,
        14743785.0,
        14884202.0,
        15024619.0,
        15165036.0,
        15305453.0,
        15445870.0,
        15586287.0,
        15726704.0,
        15867121.0,
        16007538.0,
        16147955.0,
        16288372.0,
        16428789.0,
        16569206.0,
        16709623.0,
        16850040.0
      ],
      average: [
        null,
        320741.0,
        641482.0,
        962223.0,
        1282964.0,
        1603705.0,
        1924446.0,
        2245187.0,
        2565928.0,
        2886669.0,
        3207410.0,
        3528151.0,
        3848892.0,
        4169633.0,
        4490374.0,
        4811115.0,
        5131856.0,
        5452597.0,
        5773338.0,
        6094079.0,
        6414820.0,
        6735561.0,
        7056302.0,
        7377043.0,
        7697784.0,
        8018525.0,
        8339266.0,
        8660007.0,
        8980748.0,
        9301489.0,
        9622230.0,
        9942971.0,
        10263712.0,
        10584453.0,
        10905194.0,
        11225935.0,
        11546676.0,
        11867417.0,
        12188158.0,
        12508899.0,
        12829640.0,
        13150381.0,
        13471122.0,
        13791863.0,
        14112604.0,
        14433345.0,
        14754086.0,
        15074827.0,
        15395568.0,
        15716309.0,
        16037050.0,
        16357791.0,
        16678532.0,
        16999273.0,
        17320014.0,
        17640755.0,
        17961496.0,
        18282237.0,
        18602978.0,
        18923719.0,
        19244460.0,
        19565201.0,
        19885942.0,
        20206683.0,
        20527424.0,
        20848165.0,
        21168906.0,
        21489647.0,
        21810388.0,
        22131129.0,
        22451870.0,
        22772611.0,
        23093352.0,
        23414093.0,
        23734834.0,
        24055575.0,
        24376316.0,
        24697057.0,
        25017798.0,
        25338539.0,
        25659280.0,
        25980021.0,
        26300762.0,
        26621503.0,
        26942244.0,
        27262985.0,
        27583726.0,
        27904467.0,
        28225208.0,
        28545949.0,
        28866690.0,
        29187431.0,
        29508172.0,
        29828913.0,
        30149654.0,
        30470395.0,
        30791136.0,
        31111877.0,
        31432618.0,
        31753359.0,
        32074100.0,
        32394841.0,
        32715582.0,
        33036323.0,
        33357064.0,
        33677805.0,
        33998546.0,
        34319287.0,
        34640028.0,
        34960769.0,
        35281510.0,
        35602251.0,
        35922992.0,
        36243733.0,
        36564474.0,
        36885215.0,
        37205956.0,
        37526697.0,
        37847438.0,
        38168179.0,
        38488920.0
      ]
    },
    [CustomerFocus.B2C]: {
      median: [
        17187.0,
        34374.0,
        68748.0,
        518748.0,
        1718748.0,
        1913097.5,
        2113312.0,
        2319391.5,
        2531336.0,
        2749145.5,
        2972820.0,
        3202359.5,
        3437764.0,
        3679033.5,
        3926168.0,
        4179167.5,
        4438032.0,
        4702761.5,
        4973356.0,
        5249815.5,
        5532140.0,
        5820329.5,
        6114384.0,
        6414303.5,
        6720088.0,
        7031737.5,
        7349252.0,
        7672631.5,
        8001876.0,
        8336985.5,
        8677960.0,
        9024799.5,
        9377504.0,
        9736073.5,
        10100508.0,
        10470807.5,
        10846972.0,
        11229001.5,
        11616896.0,
        12010655.5,
        12410280.0,
        12815769.5,
        13227124.0,
        13644343.5,
        14067428.0,
        14496377.5,
        14931192.0,
        15371871.5,
        15818416.0,
        16270825.5,
        16729100.0,
        17193239.5,
        17663244.0,
        18139113.5,
        18620848.0,
        19108447.5,
        19601912.0,
        20101241.5,
        20606436.0,
        21117495.5,
        21634420.0,
        22157209.5,
        22685864.0,
        23220383.5,
        23760768.0,
        24307017.5,
        24859132.0,
        25417111.5,
        25980956.0,
        26550665.5,
        27126240.0,
        27707679.5,
        28294984.0,
        28888153.5,
        29487188.0,
        30092087.5,
        30702852.0,
        31319481.5,
        31941976.0,
        32570335.5,
        33204560.0,
        33844649.5,
        34490604.0,
        35142423.5,
        35800108.0,
        36463657.5,
        37133072.0,
        37808351.5,
        38489496.0,
        39176505.5,
        39869380.0,
        40568119.5,
        41272724.0,
        41983193.5,
        42699528.0,
        43421727.5,
        44149792.0,
        44883721.5,
        45623516.0,
        46369175.5,
        47120700.0,
        47878089.5,
        48641344.0,
        49410463.5,
        50185448.0,
        50966297.5,
        51753012.0,
        52545591.5,
        53344036.0,
        54148345.5,
        54958520.0,
        55774559.5,
        56596464.0,
        57424233.5,
        58257868.0,
        59097367.5,
        59942732.0,
        60793961.5,
        61651056.0,
        62514015.5,
        63382840.0
      ],
      average: [
        null,
        258876.3,
        537445.2,
        835706.7,
        1153660.8,
        1491307.5,
        1848646.8,
        2225678.7,
        2622403.2,
        3038820.3,
        3474930.0,
        3930732.3,
        4406227.2,
        4901414.7,
        5416294.8,
        5950867.5,
        6505132.8,
        7079090.7,
        7672741.2,
        8286084.3,
        8919120.0,
        9571848.3,
        10244269.2,
        10936382.7,
        11648188.8,
        12379687.5,
        13130878.8,
        13901762.7,
        14692339.2,
        15502608.3,
        16332570.0,
        17182224.3,
        18051571.2,
        18940610.7,
        19849342.8,
        20777767.5,
        21725884.8,
        22693694.7,
        23681197.2,
        24688392.3,
        25715280.0,
        26761860.3,
        27828133.2,
        28914098.7,
        30019756.8,
        31145107.5,
        32290150.8,
        33454886.7,
        34639315.2,
        35843436.3,
        37067250.0,
        38310756.3,
        39573955.2,
        40856846.7,
        42159430.8,
        43481707.5,
        44823676.8,
        46185338.7,
        47566693.2,
        48967740.3,
        50388480.0,
        51828912.3,
        53289037.2,
        54768854.7,
        56268364.8,
        57787567.5,
        59326462.8,
        60885050.7,
        62463331.2,
        64061304.3,
        65678970.0,
        67316328.3,
        68973379.2,
        70650122.7,
        72346558.8,
        74062687.5,
        75798508.8,
        77554022.7,
        79329229.2,
        81124128.3,
        82938720.0,
        84773004.3,
        86626981.2,
        88500650.7,
        90394012.8,
        92307067.5,
        94239814.8,
        96192254.7,
        98164387.2,
        100156212.3,
        102167730.0,
        104198940.3,
        106249843.2,
        108320438.7,
        110410726.8,
        112520707.5,
        114650380.8,
        116799746.7,
        118968805.2,
        121157556.3,
        123366000.0,
        125594136.3,
        127841965.2,
        130109486.7,
        132396700.8,
        134703607.5,
        137030206.8,
        139376498.7,
        141742483.2,
        144128160.3,
        146533530.0,
        148958592.3,
        151403347.2,
        153867794.7,
        156351934.8,
        158855767.5,
        161379292.8,
        163922510.7,
        166485421.2,
        169068024.3,
        171670320.0
      ]
    },
    [Industry.OTHER]: {
      median: [
        null,
        15000.0,
        33890.0999999996,
        83890.0999999996,
        133890.1,
        183890.1,
        233890.1,
        283890.1,
        333890.1,
        383890.1,
        855570.0,
        1324166.1,
        1789678.4,
        2252106.9,
        2711451.6,
        3167712.5,
        3620889.6,
        4070982.9,
        4517992.4,
        4961918.1,
        5402760.0,
        5840518.1,
        6275192.4,
        6706782.9,
        7135289.6,
        7560712.5,
        7983051.6,
        8402306.9,
        8818478.4,
        9231566.1,
        9641570.0,
        10048490.1,
        10452326.4,
        10853078.9,
        11250747.6,
        11645332.5,
        12036833.6,
        12425250.9,
        12810584.4,
        13192834.1,
        13572000.0,
        13948082.1,
        14321080.4,
        14690994.9,
        15057825.6,
        15421572.5,
        15782235.6,
        16139814.9,
        16494310.4,
        16845722.1,
        17194050.0,
        17539294.1,
        17881454.4,
        18220530.9,
        18556523.6,
        18889432.5,
        19219257.6,
        19545998.9,
        19869656.4,
        20190230.1,
        20507720.0,
        20822126.1,
        21133448.4,
        21441686.9,
        21746841.6,
        22048912.5,
        22347899.6,
        22643802.9,
        22936622.4,
        23226358.1,
        23513010.0,
        23796578.1,
        24077062.4,
        24354462.9,
        24628779.6,
        24900012.5,
        25168161.6,
        25433226.9,
        25695208.4,
        25954106.1,
        26209920.0,
        26462650.1,
        26712296.4,
        26958858.9,
        27202337.6,
        27442732.5,
        27680043.6,
        27914270.9,
        28145414.4,
        28373474.1,
        28598450.0,
        28820342.1,
        29039150.4,
        29254874.9,
        29467515.6,
        29677072.5,
        29883545.6,
        30086934.9,
        30287240.4,
        30484462.1,
        30678600.0,
        30869654.1,
        31057624.4,
        31242510.9,
        31424313.6,
        31603032.5,
        31778667.6,
        31951218.9,
        32120686.4,
        32287070.1,
        32450370.0,
        32610586.1,
        32767718.4,
        32921766.9,
        33072731.6,
        33220612.5,
        33365409.6,
        33507122.9,
        33645752.4,
        33781298.1,
        33913760.0
      ],
      average: [
        null,
        29090.52,
        229490.52,
        445328.52,
        645848.52,
        845848.52,
        1084398.74,
        1707518.992,
        2246499.83903099,
        3946499.83903099,
        5446499.83903099,
        6446499.83903099,
        6946499.83903099,
        7139465.49163776,
        7337791.50470647,
        7541626.78279029,
        7751124.366837,
        7966441.54909315,
        8187739.99120021,
        8415185.84557122,
        8648949.88013924,
        8889207.60657102,
        9136139.41204247,
        9389930.69467456,
        9650772.00273151,
        9918859.1776858,
        10194393.5012573,
        10477581.8465369,
        10768636.8333085,
        11067776.9876851,
        11375226.90618,
        11691217.4243353,
        12015985.7900349,
        12349775.8416317,
        12692838.1910235,
        13045430.4118138,
        13407817.2326998,
        13780270.7362331,
        14163070.5631005,
        14556504.1220805,
        14960866.8058321,
        15376462.212677,
        15803602.374544,
        16242607.9912443,
        16693808.6712552,
        17157543.1791927,
        17634159.6901583,
        18124016.0511509,
        18627480.0497413,
        19144929.6902093,
        19676753.4773524,
        20223350.7081773,
        20785131.7716954,
        21362518.4570454,
        21955944.270176,
        22565854.7593243,
        23192707.8495376,
        23836974.1864859,
        24499137.4898262,
        25179694.9163829,
        25879157.4334161,
        26598050.2022597,
        27336912.9726161,
        28096300.4878041,
        28876782.9012639,
        29678946.2046325,
        30503392.6677101,
        31350741.2906486,
        32221628.2687011,
        33116707.469882,
        34036650.9258952,
        34982149.3367007,
        35953912.5890965,
        36952670.2897071,
        37979172.3127764,
        39034189.3631795,
        40118513.5550725,
        41232959.0066178,
        42378362.4512295,
        43555583.8657985,
        44765507.1163698,
        46009040.6217543,
        47287118.035577,
        48600698.9472699,
        49950769.6025387,
        51338343.6438431,
        52764462.8714463,
        54230198.0256063,
        55736649.5904948,
        57284948.6204484,
        58876257.5891725,
        60511771.2625341,
        62192717.5956006,
        63920358.6545973,
        65695991.564475,
        67520949.4828007,
        69396602.6007018,
        71324359.1716147,
        73305666.5686117,
        75342012.3710986,
        77434925.4816997,
        79585977.2741686,
        81796782.7731867,
        84069001.8669345,
        86404340.5533479,
        88804552.2209927,
        91271438.9655213,
        93806852.9426989,
        96412697.7590144,
        99090929.9009219,
        101843560.203784,
        104672655.361621,
        107580339.478801,
        110568795.664827,
        113640267.673439,
        116797061.587231,
        120041547.549082,
        123376161.541669,
        126803407.216424,
        130325857.773292,
        133946157.892707
      ]
    },
    [Industry.IT]: {
      median: [
        null,
        153265.0,
        306530.0,
        459795.0,
        613060.0,
        766325.0,
        919590.0,
        1072855.0,
        1226120.0,
        1379385.0,
        1532650.0,
        1685915.0,
        1839180.0,
        1992445.0,
        2145710.0,
        2298975.0,
        2452240.0,
        2605505.0,
        2758770.0,
        2912035.0,
        3065300.0,
        3218565.0,
        3371830.0,
        3525095.0,
        3678360.0,
        3831625.0,
        3984890.0,
        4138155.0,
        4291420.0,
        4444685.0,
        4597950.0,
        4751215.0,
        4904480.0,
        5057745.0,
        5211010.0,
        5364275.0,
        5517540.0,
        5670805.0,
        5824070.0,
        5977335.0,
        6130600.0,
        6283865.0,
        6437130.0,
        6590395.0,
        6743660.0,
        6896925.0,
        7050190.0,
        7203455.0,
        7356720.0,
        7509985.0,
        7663250.0,
        7816515.0,
        7969780.0,
        8123045.0,
        8276310.0,
        8429575.0,
        8582840.0,
        8736105.0,
        8889370.0,
        9042635.0,
        9195900.0,
        9349165.0,
        9502430.0,
        9655695.0,
        9808960.0,
        9962225.0,
        10115490.0,
        10268755.0,
        10422020.0,
        10575285.0,
        10728550.0,
        10881815.0,
        11035080.0,
        11188345.0,
        11341610.0,
        11494875.0,
        11648140.0,
        11801405.0,
        11954670.0,
        12107935.0,
        12261200.0,
        12414465.0,
        12567730.0,
        12720995.0,
        12874260.0,
        13027525.0,
        13180790.0,
        13334055.0,
        13487320.0,
        13640585.0,
        13793850.0,
        13947115.0,
        14100380.0,
        14253645.0,
        14406910.0,
        14560175.0,
        14713440.0,
        14866705.0,
        15019970.0,
        15173235.0,
        15326500.0,
        15479765.0,
        15633030.0,
        15786295.0,
        15939560.0,
        16092825.0,
        16246090.0,
        16399355.0,
        16552620.0,
        16705885.0,
        16859150.0,
        17012415.0,
        17165680.0,
        17318945.0,
        17472210.0,
        17625475.0,
        17778740.0,
        17932005.0,
        18085270.0,
        18238535.0,
        18391800.0
      ],
      average: [
        null,
        296093.0,
        592186.0,
        888279.0,
        1184372.0,
        1480465.0,
        1776558.0,
        2072651.0,
        2368744.0,
        2664837.0,
        2960930.0,
        3257023.0,
        3553116.0,
        3849209.0,
        4145302.0,
        4441395.0,
        4737488.0,
        5033581.0,
        5329674.0,
        5625767.0,
        5921860.0,
        6217953.0,
        6514046.0,
        6810139.0,
        7106232.0,
        7402325.0,
        7698418.0,
        7994511.0,
        8290604.0,
        8586697.0,
        8882790.0,
        9178883.0,
        9474976.0,
        9771069.0,
        10067162.0,
        10363255.0,
        10659348.0,
        10955441.0,
        11251534.0,
        11547627.0,
        11843720.0,
        12139813.0,
        12435906.0,
        12731999.0,
        13028092.0,
        13324185.0,
        13620278.0,
        13916371.0,
        14212464.0,
        14508557.0,
        14804650.0,
        15100743.0,
        15396836.0,
        15692929.0,
        15989022.0,
        16285115.0,
        16581208.0,
        16877301.0,
        17173394.0,
        17469487.0,
        17765580.0,
        18061673.0,
        18357766.0,
        18653859.0,
        18949952.0,
        19246045.0,
        19542138.0,
        19838231.0,
        20134324.0,
        20430417.0,
        20726510.0,
        21022603.0,
        21318696.0,
        21614789.0,
        21910882.0,
        22206975.0,
        22503068.0,
        22799161.0,
        23095254.0,
        23391347.0,
        23687440.0,
        23983533.0,
        24279626.0,
        24575719.0,
        24871812.0,
        25167905.0,
        25463998.0,
        25760091.0,
        26056184.0,
        26352277.0,
        26648370.0,
        26944463.0,
        27240556.0,
        27536649.0,
        27832742.0,
        28128835.0,
        28424928.0,
        28721021.0,
        29017114.0,
        29313207.0,
        29609300.0,
        29905393.0,
        30201486.0,
        30497579.0,
        30793672.0,
        31089765.0,
        31385858.0,
        31681951.0,
        31978044.0,
        32274137.0,
        32570230.0,
        32866323.0,
        33162416.0,
        33458509.0,
        33754602.0,
        34050695.0,
        34346788.0,
        34642881.0,
        34938974.0,
        35235067.0,
        35531160.0
      ]
    },
    [Industry.FIPT]: {
      median: [
        48142.7421875,
        96285.484375,
        192570.96875,
        385141.9375,
        770283.875,
        1540567.75,
        3081135.5,
        6162271.0,
        6362271.0,
        6562271.0,
        6762271.0,
        6962271.0,
        7162271.0,
        7362271.0,
        7562271.0,
        7762271.0,
        8162822.0,
        8563373.0,
        8963924.0,
        9364475.0,
        9765026.0,
        10165577.0,
        10566128.0,
        10966679.0,
        11367230.0,
        11767781.0,
        12168332.0,
        12568855.0,
        12969378.0,
        13369901.0,
        13770424.0,
        14170947.0,
        14571470.0,
        14971993.0,
        15372516.0,
        15773039.0,
        16173562.0,
        16574085.0,
        16974608.0,
        17375131.0,
        17775654.0,
        18176177.0,
        18576700.0,
        18977223.0,
        19056401.0,
        23000626.0,
        27100901.0,
        31357226.0,
        35769601.0,
        40338026.0,
        45062501.0,
        49943026.0,
        54979601.0,
        60172226.0,
        65520901.0,
        71025626.0,
        76686401.0,
        82503226.0,
        88476101.0,
        94605026.0,
        100890001.0,
        107331026.0,
        113928101.0,
        120681226.0,
        127590401.0,
        134655626.0,
        141876901.0,
        149254226.0,
        156787601.0,
        164477026.0,
        172322501.0,
        180324026.0,
        188481601.0,
        196795226.0,
        205264901.0,
        213890626.0,
        222672401.0,
        231610226.0,
        240704101.0,
        249954026.0,
        259360001.0,
        268922026.0,
        278640101.0,
        288514226.0,
        298544401.0,
        308730626.0,
        319072901.0,
        329571226.0,
        340225601.0,
        351036026.0,
        362002501.0,
        373125026.0,
        384403601.0,
        395838226.0,
        407428901.0,
        419175626.0,
        431078401.0,
        443137226.0,
        455352101.0,
        467723026.0,
        480250001.0,
        492933026.0,
        505772101.0,
        518767226.0,
        531918401.0,
        545225626.0,
        558688901.0,
        572308226.0,
        586083601.0,
        600015026.0,
        614102501.0,
        628346026.0,
        642745601.0,
        657301226.0,
        672012901.0,
        686880626.0,
        701904401.0,
        717084226.0,
        732420101.0,
        747912026.0,
        763560001.0
      ],
      average: [
        40722.65625,
        81445.3125,
        162890.625,
        325781.25,
        651562.5,
        1303125.0,
        2606250.0,
        2606250.0,
        2606250.0,
        2606250.0,
        2053125.0,
        2053125.0,
        2053125.0,
        5240800.0,
        5240800.0,
        8827200.0,
        8291455.0,
        10692177.0,
        13092899.0,
        15493621.0,
        17894343.0,
        20295065.0,
        22695787.0,
        25096509.0,
        27497231.0,
        29897953.0,
        30298675.0,
        30699397.0,
        31100119.0,
        31500841.0,
        31901563.0,
        32302285.0,
        32703007.0,
        33103729.0,
        33504451.0,
        33905173.0,
        34305895.0,
        34706617.0,
        35107339.0,
        35508061.0,
        35908783.0,
        36309505.0,
        36710227.0,
        37110949.0,
        37511671.0,
        37912393.0,
        38313115.0,
        38713837.0,
        39114559.0,
        39515281.0,
        39916003.0,
        40316725.0,
        44972097.0,
        49776117.0,
        54728785.0,
        59830101.0,
        65080065.0,
        70478677.0,
        76025937.0,
        81721845.0,
        87566401.0,
        93559605.0,
        99701457.0,
        105991957.0,
        112431105.0,
        119018901.0,
        125755345.0,
        132640437.0,
        139674177.0,
        146856565.0,
        154187601.0,
        161667285.0,
        169295617.0,
        177072597.0,
        184998225.0,
        193072501.0,
        201295425.0,
        209666997.0,
        218187217.0,
        226856085.0,
        235673601.0,
        244639765.0,
        253754577.0,
        263018037.0,
        272430145.0,
        281990901.0,
        291700305.0,
        301558357.0,
        311565057.0,
        321720405.0,
        332024401.0,
        342477045.0,
        353078337.0,
        363828277.0,
        374726865.0,
        385774101.0,
        396969985.0,
        408314517.0,
        419807697.0,
        431449525.0,
        443240001.0,
        455179125.0,
        467266897.0,
        479503317.0,
        491888385.0,
        504422101.0,
        517104465.0,
        529935477.0,
        542915137.0,
        556043445.0,
        569320401.0,
        582746005.0,
        596320257.0,
        610043157.0,
        623914705.0,
        637934901.0,
        652103745.0,
        666421237.0,
        680887377.0,
        695502165.0,
        710265601.0
      ]
    },
    [Industry.DH]: {
      median: [
        null,
        1848.9,
        10301.3905996748,
        28136.3400264849,
        57395.5585954182,
        99777.1259806888,
        156765.335420026,
        229694.64766947,
        319786.936977622,
        428175.471948713,
        555921.438498587,
        704025.898536872,
        873438.775840081,
        1065065.8107647,
        1279774.07339385,
        1518396.42136301,
        1781735.16495218,
        2070565.12357915,
        2385636.20625442,
        2727675.61361145,
        3097389.73481911,
        3495465.79538745,
        3922573.29932141,
        4379365.29979758,
        4866479.52557222,
        5384539.38502149,
        5934154.86562092,
        6515923.34347431,
        7130430.31498026,
        7778250.06071467,
        8459946.24999126,
        9176072.49325201,
        9927172.84836845,
        10713782.2860539,
        11536427.1188559,
        12395625.3975913,
        13291887.2785728,
        14225715.3645493,
        15197605.0219153,
        16208044.6764343,
        17257516.0894558,
        18346494.6163779,
        19475449.4489097,
        20644843.8425166,
        21855135.3302856,
        23106775.9243159,
        24400212.3056287,
        25735886.0034899,
        27114233.564952,
        28535686.7153453,
        30000672.5103787,
        31509613.4804498,
        33062927.7677116,
        34661029.2563933,
        36304327.6968294,
        37993228.8236146,
        39728134.4682644,
        41509442.666733,
        43337547.7621105,
        45212840.5027949,
        47135708.1364131,
        49106534.4997456,
        51125700.1048845,
        53193582.2218447,
        55310554.9578289,
        57476989.33333,
        59693253.3552479,
        61959712.0871787,
        64276727.7170298,
        66644659.6220963,
        69063864.4317367,
        71534696.0877619,
        74057505.9026595,
        76632642.6157541,
        79260452.4474108,
        81941279.1513681,
        84675464.065298,
        87463346.1596673,
        90305262.0849857,
        93201546.21751,
        96152530.703476,
        99158545.5019219,
        102219918.426167,
        105336975.184002,
        108510039.416648,
        111739432.736534,
        115025474.763943,
        118368483.16258,
        121768773.67409,
        125226660.151584,
        128742454.592211,
        132316467.168801,
        135949006.260627,
        139640378.483322,
        143390888.717969,
        147200840.139414,
        151070534.243812,
        155000270.87545,
        158990348.252866,
        163041062.99429,
        167152710.14243,
        171325583.188632,
        175559974.096431,
        179856173.324514,
        184214469.849117,
        188635151.185874,
        193118503.411144,
        197664811.182816,
        202274357.760632,
        206947425.02602,
        211684293.501475,
        216485242.369489,
        221350549.491046,
        226280491.423701,
        231275343.439247,
        236335379.541003,
        241460872.480699,
        246652093.775009,
        251909313.72172,
        257232801.415547,
        262622824.763623
      ],
      average: [
        null,
        10552.0,
        47031.1454513905,
        112734.26036769,
        209621.743979326,
        339143.899093016,
        502466.015609152,
        700568.597730846,
        934301.623470985,
        1204417.50006162,
        1511592.68832405,
        1856442.70832576,
        2239533.00459578,
        2661387.08140464,
        3122492.76142495,
        3623307.10978636,
        4164260.38181712,
        4745759.23933868,
        5368189.40765712,
        6031917.89724532,
        6737294.88133308,
        7484655.29776505,
        8274320.22720265,
        9106598.08792609,
        9981785.67876553,
        10900169.0951491,
        11862024.5382859,
        12867619.033676,
        13917211.0721656,
        15011051.1844251,
        16149382.4578677,
        17332441.0035426,
        18560456.3793313,
        19833651.9748007,
        21152245.3622648,
        22516448.6179459,
        23926468.6165785,
        25382507.3023394,
        26884761.9386079,
        28433425.3387299,
        30028686.0796897,
        31670728.7003567,
        33359733.8857761,
        35095878.6387982,
        36879336.4401962,
        38710277.3982903,
        40588868.3889885,
        42515273.1870549,
        44489652.5893317,
        46512164.530569,
        48582964.192448,
        50702204.1063285,
        52870034.250198,
        55086602.1402586,
        57352052.9175412,
        59666529.4299089,
        62030172.3097727,
        64443120.0478189,
        66905509.0630176,
        69417473.7691651,
        71979146.6381833,
        74590658.2603916,
        77252137.4019388,
        79963711.0595771,
        82725504.5129377,
        85537641.3744646,
        88400243.6371406,
        91313431.7201402,
        94277324.5125261,
        97292039.4151034,
        100357692.380532,
        103474397.951799,
        106642269.299131,
        109861418.255443,
        113131955.350389,
        116453989.8431,
        119827629.753665,
        123252981.893426,
        126730151.894149,
        130259244.236117,
        133840362.275211,
        137473608.269017,
        141159083.402009,
        144896887.809855,
        148687120.602882,
        152529879.888744,
        156425262.79432,
        160373365.486893,
        164374283.194621,
        168428110.226347,
        172534939.990776,
        176694865.015034,
        180907976.962648,
        185174366.650967,
        189494124.068042,
        193867338.389,
        198294097.991915,
        202774490.473219,
        207308602.662642,
        211896520.63773,
        216538329.737938,
        221234114.578316,
        225983959.062815,
        230787946.397215,
        235646159.101693,
        240558679.023051,
        245525587.346606,
        250546964.607757,
        255622890.703253,
        260753444.902151,
        265938705.856493,
        271178751.611706,
        276473659.61673,
        281823506.733891,
        287228369.248521,
        292688322.878342,
        298203442.782611,
        303773803.571044,
        309399479.312518,
        315080543.543566,
        320817069.276665
      ]
    }
  },
  [EvaluationType.TOTAL_REVENUE]: {
    all: {
      median: [
        100.0,
        100.0,
        102.759937276832,
        287.127175788345,
        595.242655532047,
        1047.81207868661,
        1663.20014512313,
        2458.10861279552,
        3447.976208961,
        4647.23873033774,
        6069.50977924142,
        7727.71253390679,
        9634.17940201079,
        11800.7295942136,
        14238.730939713,
        16959.1501076776,
        19972.5940791907,
        23289.3448733993,
        26919.3889763231,
        30872.4425430291,
        35157.973180156,
        39785.2189274513,
        44763.2049198226,
        50100.7581097238,
        55806.5203531216,
        61888.9601038075,
        68356.3829155719,
        75216.9409163523,
        82478.6413904509,
        90149.354582553,
        98236.8208192409,
        106748.657029058,
        115692.362730173,
        125075.325544806,
        134904.826291377,
        145188.043698462,
        155932.058778896,
        167143.858897486,
        178830.341561684,
        190998.31796102,
        203654.516278094,
        216805.584791338,
        230458.094787482,
        244618.543299748,
        259293.355686074,
        274488.888060208,
        290211.429587202,
        306467.204653714,
        323262.374922494,
        340603.041279567,
        358495.245681828,
        376944.972912059,
        395958.152247762,
        415540.65904964,
        435698.31627506,
        456436.895921387,
        477762.12040367,
        499679.663870796,
        522195.153463926,
        545314.170520682,
        569042.251728331,
        593384.89022896,
        618347.536679375,
        643935.600268327,
        670154.449693409,
        697009.414099857,
        724505.783983292,
        752648.812058345,
        781443.714094919,
        810895.669723791,
        841009.823213077,
        871791.284217056,
        903245.128498683,
        935376.398627086,
        968190.104651261,
        1001691.22475106,
        1035884.70586658,
        1070775.46430689,
        1106368.38633906,
        1142668.32875843,
        1179680.11944084,
        1217408.55787774,
        1255858.41569475,
        1295034.43715459,
        1334941.33964487,
        1375583.8141514,
        1416966.52571771,
        1459094.11389124,
        1501971.19315673,
        1545602.35335744,
        1589992.16010453,
        1635145.15517516,
        1681065.8568997,
        1727758.76053846,
        1775228.33864835,
        1823479.04143992,
        1872515.29712488,
        1922341.51225482,
        1972962.07205111,
        2024381.34072649,
        2076603.66179861,
        2129633.35839567,
        2183474.7335547,
        2238132.07051246,
        2293609.63298931,
        2349911.66546642,
        2407042.39345628,
        2465006.02376697,
        2523806.74476024,
        2583448.72660367,
        2643936.12151712,
        2705273.06401357,
        2767463.67113457,
        2830512.04268053,
        2894422.26143583,
        2959198.3933892,
        3024844.4879492,
        3091364.57815513,
        3158762.68088349,
        3227042.79705006,
        3296208.91180782
      ],
      average: [
        27.0,
        27.449,
        159.28666526011,
        445.541052222365,
        924.341204768351,
        1628.0721010276,
        2585.4766455966,
        3822.70896550181,
        5363.95599366328,
        7231.8419328723,
        9447.70941658011,
        12031.8245658724,
        15003.5321135553,
        18381.3761900227,
        22183.1965964046,
        26426.2070379872,
        31127.0597410691,
        36301.8995694705,
        41966.4098938198,
        48135.8518794356,
        54825.098448526,
        62048.6638795418,
        69820.7297931616,
        78155.1681161935,
        87065.5614955444,
        96565.2215433864,
        106667.205224246,
        117384.329639613,
        128729.185422082,
        140714.148916178,
        153351.393294992,
        166652.898738892,
        180630.461783931,
        195295.703932154,
        210660.079603206,
        226734.883495995,
        243531.257420141,
        261060.196649405,
        279332.555842832,
        298359.054573859,
        318150.282502931,
        338716.704225132,
        360068.66382082,
        382216.389134231,
        405169.995802394,
        428939.491054351,
        453534.777298691,
        478965.655515623,
        505241.828468226,
        532372.903746145,
        560368.396653787,
        589237.732953932,
        618990.251476772,
        649635.206603433,
        681181.770632355,
        713639.036036116,
        747016.017615718,
        781321.654558768,
        816564.812407468,
        852754.28494188,
        889898.795983495,
        928007.001123797,
        967087.48938209,
        1007148.78479664,
        1048199.34795279,
        1090247.5774516,
        1133301.81132203,
        1177370.32837994,
        1222461.34953638,
        1268583.03905806,
        1315743.50578224,
        1363950.80428841,
        1413212.93602885,
        1463537.8504201,
        1514933.44589721,
        1567407.5709325,
        1620968.02502052,
        1675622.55963079,
        1731378.87912963,
        1788244.64167278,
        1846227.46006972,
        1905334.90262123,
        1965574.49393116,
        2026953.71569368,
        2089480.00745681,
        2153160.7673635,
        2218003.35287086,
        2284015.08144881,
        2351203.23125857,
        2419575.0418121,
        2489137.71461316,
        2559898.41378052,
        2631864.26665435,
        2705042.36438607,
        2779439.76251253,
        2855063.48151502,
        2931920.50736363,
        3010017.79204755,
        3089362.25409178,
        3169960.77906077,
        3251820.22004942,
        3334947.39816188,
        3419349.10297859,
        3505032.09301198,
        3592003.09615112,
        3680268.81009584,
        3769835.90278056,
        3860711.01278815,
        3952900.74975428,
        4046411.6947624,
        4141250.40072981,
        4237423.39278493,
        4334937.16863621,
        4433798.19893289,
        4534012.9276177,
        4635587.77227207,
        4738529.12445382,
        4842843.35002758,
        4948536.78948822,
        5055615.75827759,
        5164086.54709455
      ]
    },
    SaaS: {
      median: [
        15.0,
        20.0,
        33.0476084642343,
        87.3116715006485,
        173.955437810448,
        296.923171992856,
        459.589687353468,
        664.938497454864,
        915.663666754397,
        1214.23442336893,
        1562.9391287416,
        1963.91676240607,
        2419.18035803598,
        2930.63498983444,
        3500.09192244471,
        4129.27997291795,
        4819.85479251143,
        5573.40656139326,
        6391.46644908521,
        7275.51209900292,
        8226.97233010274,
        9247.23120238183,
        10337.6315595531,
        11499.478137628,
        12734.0403097557,
        14042.5545237244,
        15426.2264778099,
        16886.2330723233,
        18423.724167654,
        20039.824174399,
        21735.6334969971,
        23512.2298489158,
        25370.6694546901,
        27311.9881518588,
        29337.2024039818,
        31447.3102343726,
        33643.2920888869,
        35926.1116350156,
        38296.7165036154,
        40756.0389788208,
        43304.9966410215,
        45944.4929672094,
        48675.4178925132,
        51498.6483363087,
        54415.0486959246,
        57425.4713106443,
        60530.7568984203,
        63731.7349674732,
        67029.2242047344,
        70424.0328428925,
        73916.9590076465,
        77508.7910466091,
        81200.3078411778,
        84992.2791025698,
        88885.4656531139,
        92880.6196937943,
        96978.4850589619,
        101179.797459047,
        105485.284712045,
        109895.666964478,
        114411.656902482,
        119033.959953634,
        123763.274480043,
        128600.291963256,
        133545.69718142,
        138600.168379162,
        143764.377430584,
        149038.989995761,
        154424.665671087,
        159922.058133804,
        165531.815281025,
        171254.579363522,
        177090.98711457,
        183041.669874073,
        189107.253708228,
        195288.35952493,
        201585.60318514,
        207999.59561039,
        214530.942886632,
        221180.24636458,
        227948.102756711,
        234835.104231094,
        241841.838502158,
        248968.888918573,
        256216.834548327,
        263586.250261167,
        271077.706808471,
        278691.770900701,
        286429.005282507,
        294289.968805588,
        302275.216499418,
        310385.299639896,
        318620.765816024,
        326982.158994677,
        335470.019583551,
        344084.884492354,
        352827.287192303,
        361697.757773998,
        370696.823003726,
        379825.006378271,
        389082.828178259,
        398470.805520106,
        407989.452406621,
        417639.279776314,
        427420.795551429,
        437334.504684791,
        447380.909205462,
        457560.508263282,
        467873.798172316,
        478321.272453237,
        488903.421874706,
        499620.734493749,
        510473.695695199,
        521462.788230198,
        532588.492253816,
        543851.285361801,
        555251.6426265,
        566790.036631959,
        578466.937508235,
        590282.812964961,
        602238.128324156
      ],
      average: [
        15.0,
        20.0,
        30.9181154721864,
        86.554791082678,
        179.679309866443,
        316.623323218275,
        503.009478095787,
        743.956208879882,
        1044.19864862476,
        1408.16733569528,
        1840.04294358295,
        2343.79620753582,
        2923.21813604184,
        3581.94353978005,
        4323.46978918052,
        5151.17205989644,
        6068.31592686015,
        7078.06791338756,
        8183.50443389117,
        9387.61945449299,
        10693.3311160256,
        12103.4875069094,
        13620.8717318644,
        15248.206391622,
        16988.1575656049,
        18843.3383718251,
        20816.3121645374,
        22909.5954194497,
        25125.6603478051,
        27466.9372738593,
        29935.8168048153,
        32534.6518178284,
        35265.7592850589,
        38131.4219547453,
        41133.8899037807,
        44275.3819751921,
        47558.0871121756,
        50984.1655988589,
        54555.7502167133,
        58274.9473244634,
        62143.8378684268,
        66164.4783294269,
        70338.901611742,
        74669.1178789583,
        79157.1153410819,
        83804.8609968166,
        88614.3013345175,
        93587.3629949824,
        98725.9533989453,
        104031.961341853,
        109507.257558282,
        115153.695258123,
        120973.110636488,
        126967.323359108,
        133138.137024852,
        139487.339606853,
        146016.703873609,
        152727.987791309,
        159622.934908553,
        166703.27472451,
        173970.72304152,
        181426.982303044,
        189073.741917785,
        196912.678570795,
        204945.45652227,
        213173.727894701,
        221599.132949039,
        230223.30035042,
        239047.847424025,
        248074.380401563,
        257304.494658876,
        266739.774945072,
        276381.795603651,
        286232.120785972,
        296292.304657455,
        306563.891596845,
        317048.416388876,
        327747.404410623,
        338662.371811838,
        349794.825689548,
        361146.264257142,
        372718.177008223,
        384512.044875417,
        396529.340384383,
        408771.527803202,
        421240.063287359,
        433936.395020475,
        446861.963350986,
        460018.200924918,
        473406.532814907,
        487028.376645636,
        500885.142715791,
        514978.234116712,
        529309.046847826,
        543878.969929005,
        558689.385509962,
        573741.668976783,
        589037.189055709,
        604577.30791426,
        620363.381259807,
        636396.758435664,
        652678.782514803,
        669210.790391265,
        685994.112869355,
        703030.074750672,
        720319.994919086,
        737865.186423694,
        755666.956559843,
        773726.606948266,
        792045.433612411,
        810624.727054016,
        829465.772326958,
        848569.849109485,
        867938.231774823,
        887572.189460243,
        907472.986134638,
        927641.880664625,
        948080.126879253,
        968788.973633322,
        989769.664869393,
        1011023.43967849
      ]
    },
    Transactional: {
      median: [
        300.0,
        541.775,
        1083.55,
        2167.1,
        4334.2,
        8668.39999999999,
        17336.8,
        18834.8,
        20332.8,
        21830.8,
        23328.8,
        24826.8,
        26324.8,
        27822.8,
        29320.8,
        33418.8,
        37516.8,
        39614.8,
        41712.8,
        43810.8,
        45908.8,
        48006.8,
        50104.8,
        52202.8,
        54300.8,
        56398.8,
        58496.8,
        60594.8,
        62692.8,
        64790.8,
        66888.8,
        68986.8,
        71084.8,
        76182.8,
        81280.8,
        86378.8,
        91476.8,
        96574.8,
        101672.8,
        116247.3,
        131432.0,
        147226.9,
        175647.3,
        180647.3,
        198272.8,
        216508.5,
        235354.4,
        254810.5,
        274876.8,
        295553.3,
        316840.0,
        338736.9,
        361244.0,
        384361.3,
        408088.8,
        432426.5,
        457374.4,
        482932.5,
        509100.8,
        535879.3,
        563268.0,
        591266.9,
        619876.0,
        649095.3,
        678924.8,
        709364.5,
        740414.4,
        772074.5,
        804344.8,
        837225.3,
        870716.0,
        904816.9,
        939528.0,
        974849.3,
        1010780.8,
        1047322.5,
        1084474.4,
        1122236.5,
        1160608.8,
        1199591.3,
        1239184.0,
        1279386.9,
        1320200.0,
        1361623.3,
        1403656.8,
        1446300.5,
        1489554.4,
        1533418.5,
        1577892.8,
        1622977.3,
        1668672.0,
        1714976.9,
        1761892.0,
        1809417.3,
        1857552.8,
        1906298.5,
        1955654.4,
        2005620.5,
        2056196.8,
        2107383.3,
        2159180.0,
        2211586.9,
        2264604.0,
        2318231.3,
        2372468.8,
        2427316.5,
        2482774.4,
        2538842.5,
        2595520.8,
        2652809.3,
        2710708.0,
        2769216.9,
        2828336.0,
        2888065.3,
        2948404.8,
        3009354.5,
        3070914.4,
        3133084.5,
        3195864.8,
        3259255.3,
        3323256.0
      ],
      average: [
        300.0,
        629.105000000003,
        1258.21000000001,
        2516.42000000001,
        5032.84000000003,
        10065.6800000001,
        24421.6800000001,
        38777.6800000001,
        53133.6800000001,
        67489.6800000001,
        81845.6800000001,
        96201.6800000001,
        110557.68,
        134913.68,
        159269.68,
        169613.68,
        179957.68,
        190301.68,
        200645.68,
        210989.68,
        221333.68,
        231677.68,
        242021.68,
        252365.68,
        262709.68,
        273053.68,
        283397.68,
        293741.68,
        304085.68,
        314429.68,
        324773.68,
        335117.68,
        345461.68,
        355805.68,
        366149.68,
        376493.68,
        386837.68,
        397181.68,
        420730.68,
        444954.12,
        469852.0,
        495424.32,
        521671.08,
        548592.28,
        576187.92,
        604458.0,
        633402.52,
        663021.48,
        693314.88,
        724282.72,
        755925.0,
        788241.72,
        821232.88,
        854898.48,
        889238.52,
        924253.0,
        959941.92,
        996305.28,
        1033343.08,
        1071055.32,
        1109442.0,
        1148503.12,
        1188238.68,
        1228648.68,
        1269733.12,
        1311492.0,
        1353925.32,
        1397033.08,
        1440815.28,
        1485271.92,
        1530403.0,
        1576208.52,
        1622688.48,
        1669842.88,
        1717671.72,
        1766175.0,
        1815352.72,
        1865204.88,
        1915731.48,
        1966932.52,
        2018808.0,
        2071357.92,
        2124582.28,
        2178481.08,
        2233054.32,
        2288302.0,
        2344224.12,
        2400820.68,
        2458091.68,
        2516037.12,
        2574657.0,
        2633951.32,
        2693920.08,
        2754563.28,
        2815880.92,
        2877873.0,
        2940539.52,
        3003880.48,
        3067895.88,
        3132585.72,
        3197950.0,
        3263988.72,
        3330701.88,
        3398089.48,
        3466151.52,
        3534888.0,
        3604298.92,
        3674384.28,
        3745144.08,
        3816578.32,
        3888687.0,
        3961470.12,
        4034927.68,
        4109059.68,
        4183866.12,
        4259347.0,
        4335502.32,
        4412332.08,
        4489836.28,
        4568014.92,
        4646868.0
      ]
    },
    [CustomerFocus.B2B]: {
      median: [
        795.0,
        765.719239488931,
        815.719239488931,
        865.719239488931,
        915.719239488931,
        1098.86308738672,
        1208.74939612539,
        1329.62433573793,
        1462.58676931172,
        1608.84544624289,
        1769.72999086718,
        2271.31411473559,
        2852.40658929907,
        3517.40277135607,
        4270.55993138098,
        5116.01178337868,
        6057.78056225209,
        7099.78719826241,
        8245.85998833097,
        9499.74206138242,
        10865.0978629311,
        12345.5188324317,
        13944.528409086,
        15665.5864736256,
        17512.0933122786,
        19487.3931727866,
        21594.7774696423,
        23837.4876857461,
        26218.7180097629,
        28741.6177421143,
        31409.2934974112,
        34224.8112269493,
        37191.198081454,
        40311.4441314211,
        43588.503960035,
        47025.298141662,
        50624.7146172527,
        54389.6099765704,
        58322.8106559616,
        62427.1140593571,
        66705.2896093066,
        71160.0797340929,
        75794.2007963048,
        80610.3439676834,
        85611.1760545474,
        90799.3402776747,
        96177.4570101223,
        101748.124476141,
        107513.919414032,
        113477.397705523,
        119641.094974037,
        126007.527153968,
        132579.191032929,
        139358.564768771,
        146348.108382984,
        153550.264232001,
        160967.45745778,
        168602.096418932,
        176456.573103567,
        184533.263524943,
        192834.528100909,
        201362.712018084,
        210120.145581608,
        219109.144551287,
        228332.010464855,
        237791.030949046,
        247488.480019121,
        257426.618367452,
        267607.693641717,
        278033.940713225,
        288707.58193588,
        299630.827396213,
        310805.875154936,
        322234.911480411,
        333920.111074416,
        345863.637290554,
        358067.642345665,
        370534.26752452,
        383265.643378128,
        396263.889915922,
        409531.116792083,
        423069.423486263,
        436880.899478935,
        450967.624421613,
        465331.668302111,
        479975.0916051,
        494899.945468095,
        510108.271833103,
        525602.10359406,
        541383.464740253,
        557454.370495867,
        573816.827455792,
        590472.833717863,
        607424.379011625,
        624673.444823791,
        642222.004520469,
        660072.023466327,
        678225.459140748,
        696684.261251126,
        715450.371843377,
        734525.725409779,
        753912.248994199,
        773611.862294851,
        793626.477764614,
        813958.000709006,
        834608.329381914,
        855579.355079125,
        876872.962229743,
        898491.028485549,
        920435.424808382,
        942708.015555596,
        965310.658563642,
        988245.205229861,
        1011513.50059251,
        1035117.3834091,
        1059058.68623306,
        1083339.23548884,
        1107960.85154544,
        1132925.34878844,
        1158234.53569053,
        1183890.21488068
      ],
      average: [
        2500.0,
        5035.804,
        10190.816,
        15465.036,
        20858.464,
        26371.1,
        32002.944,
        37753.996,
        43624.256,
        49613.724,
        55722.4,
        61950.284,
        68297.376,
        74763.676,
        81349.184,
        88053.9,
        94877.824,
        101820.956,
        108883.296,
        116064.844,
        123365.6,
        130785.564,
        138324.736,
        145983.116,
        153760.704,
        161657.5,
        169673.504,
        177808.716,
        186063.136,
        194436.764,
        202929.6,
        211541.644,
        220272.896,
        229123.356,
        238093.024,
        247181.9,
        256389.984,
        265717.276,
        275163.776,
        284729.484,
        294414.4,
        304218.524,
        314141.856,
        324184.396,
        334346.144,
        344627.1,
        355027.264,
        365546.636,
        376185.216,
        386943.004,
        397820.0,
        408816.204,
        419931.616,
        431166.236,
        442520.064,
        453993.1,
        465585.344,
        477296.796,
        489127.456,
        501077.324,
        513146.4,
        525334.684,
        537642.176,
        550068.876,
        562614.784,
        575279.9,
        588064.224,
        600967.756,
        613990.496,
        627132.444,
        640393.6,
        653773.964,
        667273.536,
        680892.316,
        694630.304,
        708487.5,
        722463.904,
        736559.516,
        750774.336,
        765108.364,
        779561.6,
        794134.044,
        808825.696,
        823636.556,
        838566.624,
        853615.9,
        868784.384,
        884072.076,
        899478.976,
        915005.084,
        930650.4,
        946414.924,
        962298.656,
        978301.596,
        994423.744,
        1010665.1,
        1027025.664,
        1043505.436,
        1060104.416,
        1076822.604,
        1093660.0,
        1110616.604,
        1127692.416,
        1144887.436,
        1162201.664,
        1179635.1,
        1197187.744,
        1214859.596,
        1232650.656,
        1250560.924,
        1268590.4,
        1286739.084,
        1305006.976,
        1323394.076,
        1341900.384,
        1360525.9,
        1379270.624,
        1398134.556,
        1417117.696,
        1436220.044,
        1455441.6
      ]
    },
    [CustomerFocus.B2C]: {
      median: [
        400.0,
        525.0,
        1050.0,
        2100.0,
        4105.16634314939,
        8210.33268629877,
        16420.6653725975,
        32841.3307451951,
        36125.4638197146,
        39738.0102016861,
        43711.8112218547,
        48082.9923440402,
        52891.2915784442,
        58180.4207362886,
        63998.4628099175,
        70398.3090909092,
        77438.1400000001,
        86871.1400000001,
        96304.1400000001,
        105737.14,
        115170.14,
        124603.14,
        134036.14,
        143469.14,
        148902.14,
        154335.14,
        159768.14,
        165201.14,
        170634.14,
        182856.14,
        188289.14,
        193722.14,
        199155.14,
        204588.14,
        210021.14,
        215454.14,
        220887.14,
        226320.14,
        231753.14,
        237186.14,
        242619.14,
        248052.14,
        253485.14,
        256061.14,
        258637.14,
        261213.14,
        263789.14,
        266365.14,
        268941.14,
        271517.14,
        274093.14,
        276669.14,
        279245.14,
        281821.14,
        334467.36,
        388866.5,
        445018.56,
        502923.54,
        562581.44,
        623992.26,
        687156.0,
        752072.66,
        818742.24,
        887164.74,
        957340.16,
        1029268.5,
        1102949.76,
        1178383.94,
        1255571.04,
        1334511.06,
        1415204.0,
        1497649.86,
        1581848.64,
        1667800.34,
        1755504.96,
        1844962.5,
        1936172.96,
        2029136.34,
        2123852.64,
        2220321.86,
        2318544.0,
        2418519.06,
        2520247.04,
        2623727.94,
        2728961.76,
        2835948.5,
        2944688.16,
        3055180.74,
        3167426.24,
        3281424.66,
        3397176.0,
        3514680.26,
        3633937.44,
        3754947.54,
        3877710.56,
        4002226.5,
        4128495.36,
        4256517.14,
        4386291.84,
        4517819.46,
        4651100.0,
        4786133.46,
        4922919.84,
        5061459.14,
        5201751.36,
        5343796.5,
        5487594.56,
        5633145.54,
        5780449.44,
        5929506.26,
        6080316.0,
        6232878.66,
        6387194.24,
        6543262.74,
        6701084.16,
        6860658.5,
        7021985.76,
        7185065.94,
        7349899.04,
        7516485.06,
        7684824.0
      ],
      average: [
        400.0,
        678.875,
        1357.75,
        2715.5,
        5431.0,
        9432.4375,
        18864.875,
        37729.75,
        75459.5,
        77782.5,
        80105.5,
        82428.5,
        84751.5,
        87074.5,
        89397.5,
        91720.5,
        94043.5,
        96366.5,
        98689.5,
        106456.5,
        114223.5,
        121990.5,
        129757.5,
        137524.5,
        145291.5,
        153058.5,
        160825.5,
        168592.5,
        176359.5,
        184126.5,
        191893.5,
        199660.5,
        207427.5,
        215194.5,
        222961.5,
        230728.5,
        238495.5,
        246262.5,
        254029.5,
        261796.5,
        269563.5,
        277330.5,
        285097.5,
        292864.5,
        300631.5,
        308398.5,
        350135.44,
        393389.06,
        438159.36,
        484446.34,
        532250.0,
        581570.34,
        632407.36,
        684761.06,
        738631.44,
        794018.5,
        850922.24,
        909342.66,
        969279.76,
        1030733.54,
        1093704.0,
        1158191.14,
        1224194.96,
        1291715.46,
        1360752.64,
        1431306.5,
        1503377.04,
        1576964.26,
        1652068.16,
        1728688.74,
        1806826.0,
        1886479.94,
        1967650.56,
        2050337.86,
        2134541.84,
        2220262.5,
        2307499.84,
        2396253.86,
        2486524.56,
        2578311.94,
        2671616.0,
        2766436.74,
        2862774.16,
        2960628.26,
        3059999.04,
        3160886.5,
        3263290.64,
        3367211.46,
        3472648.96,
        3579603.14,
        3688074.0,
        3798061.54,
        3909565.76,
        4022586.66,
        4137124.24,
        4253178.5,
        4370749.44,
        4489837.06,
        4610441.36,
        4732562.34,
        4856200.0,
        4981354.34,
        5108025.36,
        5236213.06,
        5365917.44,
        5497138.5,
        5629876.24,
        5764130.66,
        5899901.76,
        6037189.54,
        6175994.0,
        6316315.14,
        6458152.96,
        6601507.46,
        6746378.64,
        6892766.5,
        7040671.04,
        7190092.26,
        7341030.16,
        7493484.74,
        7647456.0
      ]
    },
    [Industry.OTHER]: {
      median: [
        306.126835024386,
        612.253670048772,
        1224.50734009754,
        2449.01468019509,
        4898.02936039018,
        9796.05872078036,
        19592.1174415607,
        20561.6422628537,
        21579.1444598391,
        22646.9982147222,
        23767.695197192,
        24943.8503783427,
        26178.2081322995,
        27473.6486397864,
        28833.1946085775,
        30260.0183265126,
        31757.4490635344,
        33328.9808400197,
        34978.2805795288,
        36709.1966649983,
        38525.7679183404,
        40432.2330244025,
        42433.0404212745,
        44532.8586800241,
        46736.5873980762,
        49049.3686316576,
        51476.5988939806,
        54023.9417471624,
        56697.3410172614,
        59503.0346632653,
        62447.5693323928,
        65537.8156356708,
        68780.98417943,
        72184.6423901276,
        75756.7321717522,
        79505.588437015,
        83439.9585555647,
        87569.0227646058,
        91902.4155895467,
        96450.2483246567,
        101223.13262619,
        106232.205273027,
        111489.154152601,
        117006.24553276,
        122796.352683183,
        128872.98591314,
        135250.324095683,
        141943.247751833,
        148967.373771944,
        156339.091855277,
        164075.602752795,
        172194.95840243,
        180716.104050451,
        189658.922457236,
        199044.280290582,
        208894.076814808,
        219231.294989267,
        230080.055095484,
        241465.671018055,
        253414.709310648,
        265955.051184887,
        279115.957566809,
        292928.13737265,
        307423.8191633,
        322636.826344609,
        338602.656089012,
        355358.562162627,
        372943.641851095,
        391398.927186973,
        410767.480691571,
        431094.495854601,
        452427.402586122,
        474815.977886814,
        498312.461994817,
        522971.680280165,
        548851.171171198,
        576011.320411495,
        604515.501960552,
        634430.225867009,
        665825.293459437,
        698773.960216826,
        733353.106698783,
        769643.417934288,
        807729.571687593,
        847700.436040532,
        889649.276752296,
        933673.974880484,
        979877.255171245,
        1028366.9257514,
        1079256.12968184,
        1132663.60895917,
        1188713.98158157,
        1247538.03232539,
        1309273.01791101,
        1374062.98726987,
        1442059.11766016,
        1513420.06741527,
        1588312.34614822,
        1666910.70327577,
        1749398.53576889,
        1835968.31608082,
        1926822.04125154,
        2022171.70423615,
        2122239.78855737,
        2227259.78743596,
        2337476.74861068,
        2453147.8461188,
        2574542.98037153,
        2701945.40792441,
        2835652.40241212,
        2975975.94819007,
        3123243.46830102,
        3277798.58846561,
        3440001.93887914,
        3610231.99568579,
        3788885.96409344,
        3976380.70518992,
        4173153.70862314,
        4379664.11341473,
        4596393.77928914,
        4823848.41101794
      ],
      average: [
        1082.38672879427,
        2164.77345758855,
        4329.5469151771,
        8659.09383035419,
        17318.1876607084,
        34636.3753214168,
        69272.7506428335,
        71804.8211152537,
        74429.4442987731,
        77150.0031973111,
        79970.0044709756,
        82893.0829559673,
        85923.0063496963,
        89063.6800671495,
        92319.1522747691,
        95693.6191083303,
        99191.4300815444,
        102817.093692357,
        106575.283234169,
        110470.842819473,
        114508.793623661,
        118694.340357064,
        123032.877973551,
        127529.998624353,
        132191.498866049,
        137023.387132035,
        142031.891477082,
        147223.467604979,
        152604.8071896,
        158182.846500129,
        163964.77534155,
        169958.046321937,
        176170.384458485,
        182609.797134649,
        189284.584421259,
        196203.349774876,
        203375.011127207,
        210808.812379859,
        218514.335319249,
        226501.511967035,
        234780.63738198,
        243362.38292975,
        252257.810037754,
        261478.384452753,
        271035.991019621,
        280942.94900029,
        291212.027952648,
        301856.464189839,
        312889.977841189,
        324326.790536742,
        336181.643738213,
        348469.817739966,
        361207.15136453,
        374410.062378022,
        388095.5686518,
        402281.310097625,
        416985.571404593,
        432227.305607161,
        448026.158514626,
        464402.494033563,
        481377.42041585,
        498972.817466117,
        517211.364743681,
        536116.570795335,
        555712.803456647,
        576025.321260846,
        597080.305995768,
        618904.89645083,
        641527.22339753,
        664976.44584856,
        689282.788642271,
        714477.581400932,
        740593.298912989,
        767663.602991406,
        795723.385861992,
        824808.815137692,
        854957.380436767,
        886207.941704981,
        918600.779304069,
        952177.645931042,
        986981.820435262,
        1023058.16360265,
        1060453.17597891,
        1099215.05780637,
        1139393.77115156,
        1181041.10430377,
        1224210.73852749,
        1268958.31725477,
        1315341.51780673,
        1363420.12573669,
        1413256.11189063,
        1464913.71228441,
        1518459.51090071,
        1573962.52551235,
        1631494.29664259,
        1691128.97977723,
        1752943.44094719,
        1817017.35580485,
        1883433.31232187,
        1952276.9172408,
        2023636.90641778,
        2097605.25919851,
        2174277.3169748,
        2253751.90607478,
        2336131.46514492,
        2421522.17718816,
        2510034.1064283,
        2601781.34017716,
        2696882.1358872,
        2795459.07357927,
        2897639.21384199,
        3003554.26160623,
        3113340.73590602,
        3227140.1458445,
        3345099.17299192,
        3467369.86045056,
        3594109.80883046,
        3725482.3793885,
        3861656.90459262,
        4002808.90638263,
        4149120.32240896
      ]
    },
    [Industry.IT]: {
      median: [
        9.0,
        8.7921,
        47.5775077288685,
        127.74973135796,
        257.460588675122,
        443.387606487082,
        691.303992339058,
        1006.35554644761,
        1393.2204078172,
        1856.21112840289,
        2399.34455642266,
        3026.39213812208,
        3740.91753261555,
        4546.30559865017,
        5445.78528327713,
        6442.44806316736,
        7539.26305671458,
        8739.08958837344,
        10044.6877660627,
        11458.727483423,
        12983.796155346,
        14622.4054218305,
        16376.9970020917,
        18249.9478416795,
        20243.5746660156,
        22360.1380314536,
        24601.8459477914,
        26970.8571327812,
        29469.2839486443,
        32099.1950622082,
        34862.6178635569,
        37761.5406726347,
        40797.9147587961,
        43973.6561946451,
        47290.6475624835,
        50750.7395291689,
        54355.7523030812,
        58107.4769851187,
        62007.6768241439,
        66058.0883860227,
        70260.4226443086,
        74616.3659996881,
        79127.5812344932,
        83795.7084078955,
        88622.3656967813,
        93609.1501867863,
        98757.6386175034,
        104069.388085474,
        109545.936708213,
        115188.804252212,
        120999.492727568,
        126979.486951679,
        133130.255084164,
        139453.249135041,
        145949.905447971,
        152621.645160232,
        159469.874640967,
        166495.985909097,
        173701.357032184,
        181087.352507443,
        188655.323625979,
        196406.608821271,
        204342.534002828,
        212464.41287588,
        220773.547247908,
        229271.227322746,
        237958.731982954,
        246837.329061093,
        255908.275600511,
        265172.81810618,
        274632.192786118,
        284287.625783872,
        294140.333402521,
        304191.522320613,
        314442.38980045,
        324894.123889072,
        335547.903612321,
        346404.899162271,
        357466.272078374,
        368733.175422594,
        380206.75394879,
        391888.144266641,
        403778.475000312,
        415878.866942133,
        428190.433201483,
        440714.279349084,
        453451.503556904,
        466403.19673387,
        479570.442657525,
        492954.31810183,
        506555.892961253,
        520376.23037129,
        534416.386825567,
        548677.412289645,
        563160.350311672,
        577866.238129989,
        592796.106777807,
        607950.98118507,
        623331.880277603,
        638939.817073648,
        654775.798777885,
        670840.826873012,
        687135.897208999,
        703662.000090077,
        720420.12035953,
        737411.237482415,
        754636.325626216,
        772096.353739549,
        789792.28562897,
        807725.080033936,
        825895.690700009,
        844305.066450312,
        862954.151255364,
        881843.88430127,
        900975.200056371,
        920349.028336388,
        939966.294368089,
        959827.918851562,
        979934.818021088,
        1000287.90370471,
        1020888.08338249
      ],
      average: [
        62.0,
        61.9959151587472,
        123.991830317494,
        247.983660634989,
        495.967321269978,
        991.934642539956,
        1091.12810679395,
        1200.24091747335,
        1320.26500922068,
        2252.22809013693,
        2702.67370816432,
        3243.20844979718,
        3891.85013975661,
        4670.22016770794,
        5604.26420124952,
        6725.11704149943,
        8070.14044979931,
        9684.16853975917,
        11621.002247711,
        13945.2026972532,
        16734.2432367039,
        20081.0918840446,
        24097.3102608535,
        28916.7723130243,
        34700.1267756291,
        41640.1521307549,
        49968.1825569059,
        59961.8190682871,
        71954.1828819445,
        86345.0194583334,
        103614.02335,
        207228.0467,
        226372.4032,
        245636.4207,
        264975.2192,
        284345.6875,
        303706.4832,
        323018.0327,
        342242.5312,
        361343.9427,
        380288.0,
        399042.2047,
        417575.8272,
        435859.9067,
        453867.2512,
        471572.4375,
        488951.8112,
        505983.4867,
        522647.3472,
        538925.0447,
        554800.0,
        570257.4027,
        585284.2112,
        599869.1527,
        614002.7232,
        627677.1875,
        640886.5792,
        653626.7007,
        665895.1232,
        677691.1867,
        689016.0,
        699872.4407,
        710265.1552,
        720200.558700001,
        729686.8352,
        738733.9375,
        747353.5872,
        755559.274700001,
        763366.2592,
        770791.5687,
        777854.0,
        784574.118700001,
        790974.2592,
        797078.524700001,
        802912.7872,
        808504.6875,
        813883.635199999,
        819080.8087,
        824129.1552,
        829063.390699999,
        833920.0,
        838737.236699999,
        843555.123199998,
        848415.4507,
        853361.779199999,
        858439.4375,
        863695.5232,
        869178.9027,
        874940.2112,
        881031.8527,
        887508.0,
        894424.594699998,
        901839.347199999,
        909811.736699998,
        918403.0112,
        927676.1875,
        937696.051200001,
        948529.1567,
        960243.827199999,
        972910.154699998,
        986600.0,
        1001386.9927,
        1017346.5312,
        1034555.7827,
        1053093.6832,
        1073040.9375,
        1094480.0192,
        1117495.1707,
        1142172.4032,
        1168599.4967,
        1196866.0,
        1227063.2307,
        1259284.2752,
        1293623.9887,
        1330178.9952,
        1369047.6875,
        1410330.2272,
        1454128.5447,
        1500546.3392,
        1549689.0787,
        1601664.0
      ]
    },
    [Industry.FIPT]: {
      median: [
        null,
        1305.77481474089,
        2544.58571023491,
        4098.08073219042,
        8005.72,
        36441.25,
        40662.888,
        44031.834,
        46620.736,
        48502.242,
        49749.0,
        50433.658,
        50628.864,
        50407.266,
        49841.512,
        49004.25,
        47968.128,
        46805.794,
        45589.896,
        44393.082,
        43288.0,
        42347.2980000001,
        41643.624,
        41249.626,
        41237.952,
        41681.25,
        42652.168,
        44223.3540000001,
        46467.456,
        49457.122,
        53265.0,
        57963.7380000001,
        63625.9840000001,
        70324.3860000001,
        78131.592,
        87120.25,
        97363.008,
        108932.514,
        121901.416,
        136342.362,
        152328.0,
        169930.978,
        189223.944,
        210279.546,
        233170.432,
        257969.25,
        284748.648,
        313581.274,
        344539.776,
        377696.802,
        413125.0,
        450897.018,
        491085.504,
        533763.106,
        579002.472,
        626876.25,
        677457.088,
        730817.634,
        787030.536,
        846168.442,
        908304.0,
        973509.858000001,
        1041858.664,
        1113423.066,
        1188275.712,
        1266489.25,
        1348136.328,
        1433289.594,
        1522021.696,
        1614405.282,
        1710513.0,
        1810417.498,
        1914191.424,
        2021907.426,
        2133638.152,
        2249456.25,
        2369434.368,
        2493645.154,
        2622161.256,
        2755055.322,
        2892400.0,
        3034267.938,
        3180731.784,
        3331864.186,
        3487737.792,
        3648425.25,
        3813999.208,
        3984532.314,
        4160097.216,
        4340766.562,
        4526613.0,
        4717709.178,
        4914127.744,
        5115941.346,
        5323222.632,
        5536044.25,
        5754478.848,
        5978599.074,
        6208477.576,
        6444187.002,
        6685800.0,
        6933389.218,
        7187027.304,
        7446786.906,
        7712740.672,
        7984961.25,
        8263521.288,
        8548493.434,
        8839950.336,
        9137964.642,
        9442609.0,
        9753956.058,
        10072078.464,
        10397048.866,
        10728939.912,
        11067824.25,
        11413774.528,
        11766863.394,
        12127163.496,
        12494747.482,
        12869688.0
      ],
      average: [
        null,
        13315.0,
        25299.28,
        36026.76,
        45571.36,
        54007.0,
        61407.6,
        67847.08,
        73399.36,
        78138.36,
        82138.0,
        85472.2,
        88214.88,
        90439.96,
        92221.36,
        93633.0,
        94748.8,
        95642.68,
        96388.56,
        97060.36,
        97732.0,
        98477.4,
        99370.48,
        100485.16,
        101895.36,
        103675.0,
        105898.0,
        108638.28,
        111969.76,
        115966.36,
        120702.0,
        126250.6,
        132686.08,
        140082.36,
        148513.36,
        158053.0,
        168775.2,
        180753.88,
        194062.96,
        208776.36,
        224968.0,
        242711.8,
        262081.68,
        283151.56,
        305995.36,
        330687.0,
        357300.4,
        385909.48,
        416588.16,
        449410.36,
        484450.0,
        521781.0,
        561477.28,
        603612.76,
        648261.36,
        695497.0,
        745393.6,
        798025.08,
        853465.36,
        911788.36,
        973068.0,
        1037378.2,
        1104792.88,
        1175385.96,
        1249231.36,
        1326403.0,
        1406974.8,
        1491020.68,
        1578614.56,
        1669830.36,
        1764742.0,
        1863423.4,
        1965948.48,
        2072391.16,
        2182825.36,
        2297325.0,
        2415964.0,
        2538816.28,
        2665955.76,
        2797456.36,
        2933392.0,
        3073836.6,
        3218864.08,
        3368548.36,
        3522963.36,
        3682183.0,
        3846281.2,
        4015331.88,
        4189408.96,
        4368586.36,
        4552938.0,
        4742537.8,
        4937459.68,
        5137777.56,
        5343565.36,
        5554897.0,
        5771846.4,
        5994487.48,
        6222894.16,
        6457140.36,
        6697300.0,
        6943447.0,
        7195655.28,
        7453998.76,
        7718551.36,
        7989387.0,
        8266579.6,
        8550203.08,
        8840331.36,
        9137038.36,
        9440398.0,
        9750484.2,
        10067370.88,
        10391131.96,
        10721841.36,
        11059573.0,
        11404400.8,
        11756398.68,
        12115640.56,
        12482200.36,
        12856152.0
      ]
    },
    [Industry.DH]: {
      median: [
        null,
        84.26,
        337.835239009488,
        761.177910484205,
        1354.52941747683,
        2118.05855114016,
        3051.89557698996,
        4156.1466967097,
        5430.90160810192,
        6876.23797067529,
        8492.22426846105,
        10278.9217571892,
        12236.3858495657,
        14364.6671362942,
        16663.8121604725,
        19133.8640189897,
        21774.8628389524,
        24586.8461615622,
        27569.8492559839,
        30723.9053792691,
        34049.0459940388,
        37545.3009526147,
        41212.6986541638,
        45051.2661798867,
        49061.029410163,
        53242.0131267265,
        57594.2411023226,
        62117.7361798097,
        66812.5203423029,
        71678.6147756632,
        76716.0399244058,
        81924.8155419245,
        87304.9607357738,
        92856.4940086387,
        98579.4332955242,
        104473.795997614,
        110539.599013184,
        116776.858765909,
        123185.591230837,
        129765.811958291,
        136517.536095907,
        143440.778409002,
        150535.553299427,
        157801.874823066,
        165239.756706093,
        172849.212360117,
        180630.254896298,
        188582.897138538,
        196707.151635817,
        205003.030673759,
        213470.546285473,
        222109.710261743,
        230920.534160613,
        239903.02931641,
        249057.206848259,
        258383.077668114,
        267880.652488348,
        277549.94182894,
        287390.956024272,
        297403.705229575,
        307588.199427048,
        317944.448431661,
        328472.461896681,
        339172.249318916,
        350043.820043723,
        361087.183269761,
        372302.348053543,
        383689.32331376,
        395248.11783543,
        406978.740273842,
        418881.199158347,
        430955.502895972,
        443201.659774886,
        455619.677967721,
        468209.56553475,
        480971.330426943,
        493904.980488901,
        507010.523461658,
        520287.966985396,
        533737.318602035,
        547358.585757741,
        561151.775805325,
        575116.896006555,
        589253.953534401,
        603562.955475167,
        618043.908830579,
        632696.820519768,
        647521.697381219,
        662518.54617461,
        677687.373582628,
        693028.186212699,
        708540.990598666,
        724225.793202418,
        740082.600415453,
        756111.418560403,
        772312.253892504,
        788685.112601018,
        805230.000810614,
        821946.924582706,
        838835.889916741,
        855896.902751469,
        873129.968966146,
        890535.094381724,
        908112.284762004,
        925861.545814731,
        943782.883192696,
        961876.302494773,
        980141.809266944,
        998579.40900329,
        1017189.10714695,
        1035970.90909108,
        1054924.82017972,
        1074050.84570874,
        1093348.99092665,
        1112819.26103548,
        1132461.66119156,
        1152276.19650635,
        1172262.87204721,
        1192421.69283813,
        1212752.6638605,
        1233255.79005382
      ],
      average: [
        null,
        18.812,
        113.730968062736,
        325.832225842357,
        687.578837789017,
        1227.13178228683,
        1969.8710648037,
        2939.17180014144,
        4156.86832028469,
        5643.55939811742,
        7418.822322987,
        9501.37023774787,
        11909.1719731499,
        14659.5459234508,
        17769.2352824144,
        21254.4694888591,
        25131.0152123802,
        29414.2192368328,
        34119.044953617,
        39260.1037356773,
        44851.6821538775,
        50907.7657757375,
        57442.06012447,
        64468.0092557147,
        71998.8123183045,
        80047.4383956225,
        88626.6398699867,
        97748.9645100017,
        107426.766447135,
        117672.216180785,
        128497.309729312,
        139913.877026758,
        151933.5896504,
        164567.967952249,
        177828.387657588,
        191726.085985262,
        206272.167337384,
        221477.608600116,
        237353.264092176,
        253909.870193293,
        271158.049681194,
        289108.315802427,
        307771.076099598,
        327156.636015144,
        347275.2022897,
        368136.886171243,
        389751.706449599,
        412129.592329479,
        435280.386153943,
        459213.845989086,
        483939.648079754,
        509467.389185217,
        535806.588802946,
        562966.69128793,
        590957.067874362,
        619787.018605921,
        649465.774180423,
        680002.497714097,
        711406.286430373,
        743686.173277668,
        776851.128480324,
        810910.061026554,
        845871.820096947,
        881745.196436844,
        918538.923675658,
        956261.679595992,
        994922.087355228,
        1034528.71666207,
        1075090.08491032,
        1116614.65827213,
        1159110.85275267,
        1202587.03520818,
        1247051.52432911,
        1292512.59159011,
        1338978.46216835,
        1386457.31583163,
        1434957.28779786,
        1484486.46956686,
        1535052.90972606,
        1586664.61473108,
        1639329.54966221,
        1693055.63895809,
        1747850.76712721,
        1803722.77943856,
        1860679.48259181,
        1918728.64536839,
        1977877.99926378,
        2038135.23910208,
        2099508.02363332,
        2162003.97611444,
        2225630.68487436,
        2290395.70386376,
        2356306.5531903,
        2423370.71963973,
        2491595.6571833,
        2560988.78747223,
        2631557.50031944,
        2703309.15416919,
        2776251.0765549,
        2850390.56454577,
        2925734.88518234,
        3002291.27590154,
        3080066.94495159,
        3159069.07179697,
        3239304.80751388,
        3320781.27517655,
        3403505.57023451,
        3487484.76088138,
        3572725.88841521,
        3659235.96759078,
        3747021.98696407,
        3836090.90922912,
        3926449.6715476,
        4018105.18587117,
        4111064.33925699,
        4205333.99417654,
        4300920.98881789,
        4397832.1373817,
        4496074.23037107,
        4595654.03487545,
        4696578.29484881
      ]
    }
  },
  [EvaluationType.REVENUE_GROWTH]: {
    all: {
      median: [
        null,
        null,
        null,
        0.623757252627818,
        1.65080885177349,
        3.06556610307455,
        1.59751855086118,
        1.04319694382923,
        0.763547897915344,
        0.598051612321877,
        0.489726280827868,
        0.413743798833174,
        0.357699195948891,
        0.314754679451144,
        0.280851475657162,
        0.253436935311722,
        0.230829357335785,
        0.211877822820183,
        0.195769222030695,
        0.181913416298034,
        0.169872079145699,
        0.159313050808153,
        0.149980168208081,
        0.141672794513288,
        0.134231605418463,
        0.127528515251605,
        0.121459404971259,
        0.115938785410792,
        0.110895821872528,
        0.106271332378221,
        0.102015492886054,
        0.0980860629671542,
        0.0944469995224396,
        0.0910673632070831,
        0.0879204480441523,
        0.0849830829285359,
        0.0822350667479333,
        0.0796587082720478,
        0.0772384488562362,
        0.0749605511031068,
        0.0728128404306724,
        0.0707844893620486,
        0.0688658365297152,
        0.0670482340555561,
        0.0653239182555001,
        0.0636858996185092,
        0.0621278687930941,
        0.0606441159317037,
        0.0592294612324322,
        0.0578791949074767,
        0.0565890251203241,
        0.0553550326855862,
        0.0541736315293786,
        0.0530415340741981,
        0.0519557208479769,
        0.0509134137284467,
        0.0499120523258647,
        0.0489492730832155,
        0.0480228907362947,
        0.0471308818287623,
        0.0462713700214884,
        0.0454426129725668,
        0.0446429905956524,
        0.0438709945307368,
        0.0431252186838502,
        0.0424043507113109,
        0.0417071643403431,
        0.0410325124318615,
        0.0403793207031039,
        0.0397465820380949,
        0.0391333513227248,
        0.0385387407489222,
        0.0379619155389614,
        0.0374020900467336,
        0.0368585241977878,
        0.0363305202343051,
        0.0358174197349922,
        0.0353186008831668,
        0.0348334759592912,
        0.0343614890367273,
        0.033902113861766,
        0.0334548519009752,
        0.0330192305406509,
        0.0325948014247645,
        0.0321811389191088,
        0.0317778386906287,
        0.0313845163919665,
        0.0310008064422769,
        0.0306263608961551,
        0.0302608483933831,
        0.0299039531827959,
        0.0295553742142768,
        0.0292148242933689,
        0.0288820292935428,
        0.028556727421586,
        0.0282386685319715,
        0.0279276134864466,
        0.0276233335554029,
        0.0273256098578739,
        0.027034232837296,
        0.0267490017703893,
        0.0264697243067435,
        0.0261962160368981,
        0.0259283000868749,
        0.0256658067372953,
        0.0254085730653665,
        0.0251564426081359,
        0.0249092650455774,
        0.0246668959021348,
        0.024429196265502,
        0.0241960325214829,
        0.0239672761038567,
        0.023742803258292,
        0.023522494819371,
        0.0233062359999048,
        0.0230939161917433,
        0.02288542877736,
        0.0226806709515384,
        0.0224795435525281,
        0.0222819509020942,
        0.0220878006539254
      ],
      average: [
        null,
        null,
        null,
        5.16717348422672,
        10.891619667606,
        3.07367314430454,
        1.60100143611923,
        1.04520125460952,
        0.764889116454096,
        0.599034004708449,
        0.490489903176111,
        0.414362619683819,
        0.358216263243477,
        0.315196921621539,
        0.281236703404237,
        0.253777462488579,
        0.231134009758649,
        0.212153117964887,
        0.196020095171607,
        0.182143685481961,
        0.170084753128027,
        0.159510538996408,
        0.150164427462781,
        0.141845434103942,
        0.134393963553989,
        0.127681715871744,
        0.121604400558912,
        0.116076389727759,
        0.111026735314654,
        0.106396161845483,
        0.102134767521932,
        0.0982002467491855,
        0.0945565014255271,
        0.0911725454763788,
        0.0880216329815402,
        0.0850805584999894,
        0.0823290912477775,
        0.0797495142271096,
        0.0773262463129345,
        0.075045530410262,
        0.0728951746075578,
        0.0708643361233229,
        0.0689433400248793,
        0.0671235263696598,
        0.0653971207091627,
        0.0637571238984601,
        0.0621972179389491,
        0.0607116852002535,
        0.0592953388571746,
        0.0579434627681867,
        0.0566517593351102,
        0.0554163041358906,
        0.0542335063268168,
        0.0531000739767501,
        0.0520129836319737,
        0.0509694535218358,
        0.0499669199074727,
        0.0490030161520868,
        0.0480755541545902,
        0.0471825078412869,
        0.0463219984544621,
        0.0454922814139582,
        0.0446917345590758,
        0.0439188476046725,
        0.0431722126677371,
        0.0424505157398603,
        0.0417525289972824,
        0.041077103854156,
        0.0404231646766027,
        0.0397897030854219,
        0.039175772784168,
        0.0385804848569625,
        0.038003003487045,
        0.037442542052801,
        0.036898359563041,
        0.0363697573976216,
        0.0358560763233737,
        0.0353566937585634,
        0.0348710212621097,
        0.0343985022262944,
        0.0339386097540027,
        0.0334908447035091,
        0.0330547338855714,
        0.0326298283992107,
        0.0322157020938505,
        0.0318119501468098,
        0.0314181877461372,
        0.0310340488698548,
        0.0306591851534258,
        0.0302932648381458,
        0.0299359717937633,
        0.0295870046093006,
        0.0292460757465986,
        0.0289129107515671,
        0.0285872475186472,
        0.028268835604303,
        0.0279574355858018,
        0.0276528184618264,
        0.0273547650917649,
        0.0270630656708109,
        0.0267775192382263,
        0.0264979332163488,
        0.0262241229781301,
        0.025955911441163,
        0.0256931286863193,
        0.0254356115992924,
        0.0251832035334308,
        0.0249357539924318,
        0.0246931183315146,
        0.0244551574758505,
        0.024221737655094,
        0.0239927301529419,
        0.0237680110707525,
        0.0235474611043015,
        0.0233309653328303,
        0.0231184130196174,
        0.0229096974233274,
        0.0227047156194847,
        0.0225033683314162,
        0.0223055597701118,
        0.0221111974824421
      ]
    },
    SaaS: {
      median: [
        null,
        null,
        null,
        1.60692603334774,
        2.56592396350746,
        2.66156993694112,
        1.42126098170069,
        0.940821522692537,
        0.694613459107675,
        0.547332223198957,
        0.450167263450656,
        0.381600484148377,
        0.330783444414819,
        0.291693138894887,
        0.260733243119839,
        0.235630718093066,
        0.21488173600945,
        0.197453351215645,
        0.182613473454929,
        0.169829273024769,
        0.158704001432482,
        0.14893633441015,
        0.140293421680922,
        0.132592553542897,
        0.125688400886807,
        0.119463952093473,
        0.113823957724744,
        0.108690110956288,
        0.103997451378421,
        0.0996916453336908,
        0.09572690376245,
        0.0920643701015954,
        0.0886708591502408,
        0.0855178610373023,
        0.0825807475867659,
        0.0798381347476185,
        0.0772713664762035,
        0.0748640939503211,
        0.072601930214858,
        0.0704721649627486,
        0.0684635275958445,
        0.0665659893050451,
        0.0647705968826509,
        0.0630693324930366,
        0.0614549947967337,
        0.0599210977325578,
        0.0584617839749109,
        0.0570717506451257,
        0.055746185301283,
        0.0544807105863809,
        0.0532713361999405,
        0.0521144170880146,
        0.0510066169330096,
        0.0499448761764203,
        0.0489263839317854,
        0.047948553247123,
        0.0470089992603014,
        0.0461055198604818,
        0.0452360785267713,
        0.0443987890635821,
        0.0435919019927225,
        0.042813792396332,
        0.0420629490334307,
        0.0413379645772369,
        0.0406375268409081,
        0.0399604108770075,
        0.0393054718508762,
        0.0386716386009653,
        0.0380579078101231,
        0.0374633387213084,
        0.0368870483393471,
        0.0363282070673898,
        0.0357860347328296,
        0.0352597969627412,
        0.0347488018735168,
        0.0342523970433867,
        0.0337699667400398,
        0.0333009293786091,
        0.0328447351880271,
        0.032400864066078,
        0.0319688236056117,
        0.0315481472761895,
        0.031138392747065,
        0.0307391403388825,
        0.0303499915926706,
        0.0299705679459513,
        0.0296005095066701,
        0.0292394739166854,
        0.0288871352972158,
        0.0285431832694842,
        0.0282073220443474,
        0.0278792695753247,
        0.0275587567699363,
        0.0272455267547062,
        0.0269393341896427,
        0.0266399446283358,
        0.0263471339201797,
        0.0260606876515179,
        0.025780400622784,
        0.0255060763589723,
        0.0252375266509791,
        0.0249745711255625,
        0.0247170368418739,
        0.0244647579126531,
        0.0242175751483529,
        0.0239753357225869,
        0.0237378928574256,
        0.0235051055271881,
        0.0232768381794592,
        0.0230529604721883,
        0.0228333470257949,
        0.022617877189282,
        0.0224064348194656,
        0.0221989080724389,
        0.0219951892065165,
        0.0217951743959081,
        0.021598763554461,
        0.0214058601688324,
        0.0212163711405078,
        0.0210302066361316,
        0.0208472799456395
      ],
      average: [
        null,
        null,
        null,
        1.59010646850396,
        2.66132183110739,
        3.0802352534833,
        1.60381912159859,
        1.0468222513263,
        0.765973605493479,
        0.599828231882824,
        0.491107192251439,
        0.414862810705819,
        0.358634176952769,
        0.315554337517299,
        0.281548025276204,
        0.254052647934483,
        0.231380195273614,
        0.212375573210543,
        0.196222810312915,
        0.182329747255501,
        0.170256593883956,
        0.159670106480055,
        0.150313303526784,
        0.141984919578216,
        0.134525140127505,
        0.127805492011777,
        0.121721546126123,
        0.11618756244318,
        0.11113250125879,
        0.106497011522402,
        0.102231128583505,
        0.0982924941734641,
        0.0946449657326953,
        0.0912575194163591,
        0.0881033770199043,
        0.0851593053506187,
        0.0824050496431167,
        0.0798228720739328,
        0.0773971733492997,
        0.0751141804548051,
        0.0729616874735299,
        0.0709288392552566,
        0.0690059499045038,
        0.0671843497288261,
        0.0654562555805541,
        0.0638146605293151,
        0.0622532395885758,
        0.0607662688385486,
        0.0593485557784668,
        0.0579953791323669,
        0.0567024366461129,
        0.0554657996659847,
        0.0542818734938777,
        0.0531473626806091,
        0.0520592405550286,
        0.0510147223983749,
        0.0500112417655221,
        0.0490464295310577,
        0.0481180953015807,
        0.047224210888472,
        0.0463628955797271,
        0.045532402986622,
        0.0447311092723358,
        0.043957502596188,
        0.0432101736295989,
        0.0424878070190486,
        0.0417891736875783,
        0.0411131238803585,
        0.0404585808718169,
        0.0398245352620773,
        0.0392100397993841,
        0.0386142046727684,
        0.0380361932259536,
        0.0374752180491402,
        0.0369305374104384,
        0.0364014519929731,
        0.0358873019076092,
        0.0353874639544813,
        0.0349013491095297,
        0.0344284002147552,
        0.0339680898532,
        0.0335199183916689,
        0.0330834121759151,
        0.0326581218646705,
        0.0322436208901643,
        0.0318395040341256,
        0.0314453861092436,
        0.0310609007371327,
        0.0306856992146269,
        0.0303194494610741,
        0.0299618350399485,
        0.0296125542487267,
        0.0292713192715626,
        0.0289378553897241,
        0.0286119002453024,
        0.0282932031539955,
        0.0279815244632384,
        0.0276766349521953,
        0.0273783152704861,
        0.0270863554127468,
        0.0268005542263969,
        0.0265207189501754,
        0.0262466647812458,
        0.0259782144688097,
        0.0257151979323646,
        0.0254574519028793,
        0.0252048195852947,
        0.0249571503408957,
        0.0247142993881858,
        0.0244761275210355,
        0.0242425008429496,
        0.0240132905163743,
        0.0237883725260867,
        0.0235676274557182,
        0.0233509402766054,
        0.0231382001481542,
        0.0229293002290096,
        0.0227241374983467,
        0.0225226125866507,
        0.02232462961542,
        0.0221300960452296
      ]
    },
    Transactional: {
      median: [
        null,
        null,
        null,
        2.07455555555555,
        2.33333333333333,
        2.33333333333333,
        2.33333333333333,
        1.11520772768523,
        0.448541061018566,
        0.0864057957639242,
        0.0795336292394929,
        0.0736740635819956,
        0.068618648881397,
        0.0642124755666815,
        0.0603380218151353,
        0.0898265767134667,
        0.116139760675897,
        0.117027275290351,
        0.0827278857010625,
        0.0559216137836916,
        0.0529600048466735,
        0.0502963119234384,
        0.0478877354442283,
        0.0456992994807096,
        0.0437021421965222,
        0.0418722357937763,
        0.0401894151271579,
        0.0386366315045082,
        0.0371993730363058,
        0.0358652097208736,
        0.0346234330338577,
        0.0334647678840313,
        0.0323811405322978,
        0.0463156761670115,
        0.0594026683365513,
        0.0717171603493293,
        0.0669179919876928,
        0.0627208393618173,
        0.0590191111707966,
        0.0902615016412176,
        0.120311578865985,
        0.149348695029546,
        0.170326536616334,
        0.124818156917645,
        0.115571950506327,
        0.0775440328430894,
        0.100946429866375,
        0.0950503548646107,
        0.0898629845941384,
        0.0852599314055738,
        0.0811446153121633,
        0.07744087533033,
        0.074087821046153,
        0.0710361696755461,
        0.0682455911948181,
        0.0656827518242518,
        0.0633198503595445,
        0.0611335081972354,
        0.0591039170818624,
        0.0572141772692132,
        0.0554497781781098,
        0.0537981869209399,
        0.0522485193960656,
        0.0507912752011476,
        0.0494181223403508,
        0.0481217211184171,
        0.0468955791237435,
        0.0457339310627628,
        0.0446316386004656,
        0.0435841064139217,
        0.0425872114672871,
        0.0416372431325471,
        0.0407308522568537,
        0.0398650076488775,
        0.0390369587482285,
        0.0382442034723819,
        0.0374844604186514,
        0.0367556447451317,
        0.0360558471721938,
        0.0353833156411991,
        0.0347364392443127,
        0.034113734102309,
        0.0335138309189138,
        0.0329354639827499,
        0.0323774614231239,
        0.0318387365550674,
        0.0313182801733782,
        0.0308151536757419,
        0.030328482912092,
        0.0298574526717521,
        0.029401301732045,
        0.0289593184023654,
        0.0285308365064626,
        0.0281152317531546,
        0.0277119184520795,
        0.027320346536564,
        0.0269399988604065,
        0.0265703887394211,
        0.0262110577121055,
        0.0258615734968305,
        0.025521528125585,
        0.0251905362366092,
        0.0248682335102495,
        0.0245542752341167,
        0.0242483349851638,
        0.0239501034176395,
        0.023659287147059,
        0.0233756077213746,
        0.0230988006714411,
        0.0228286146336936,
        0.0225648105386608,
        0.0223071608595854,
        0.0220554489159851,
        0.0218094682274889,
        0.0215690219137403,
        0.0213339221365496,
        0.021103989580845,
        0.0208790529712881,
        0.0206589486217061,
        0.0204435200147553,
        0.0202326174094571
      ],
      average: [
        null,
        null,
        null,
        2.4626888888889,
        2.33333333333333,
        2.33333333333333,
        2.90164333987701,
        2.23497667321034,
        1.42623250490776,
        0.587838346911432,
        0.370212967872239,
        0.27018644294918,
        0.212714003089065,
        0.216130323962527,
        0.218527022258516,
        0.17805486994059,
        0.111290913320774,
        0.0649464480621798,
        0.0609856469124424,
        0.057480180895864,
        0.054355799696566,
        0.0515535644724571,
        0.0490260945464252,
        0.0467348665598476,
        0.0446482371543085,
        0.0427399727164938,
        0.0409881407012237,
        0.0393742628745161,
        0.0378826610210857,
        0.0364999459416887,
        0.0352146144190365,
        0.0340167284431151,
        0.0328976577529195,
        0.0318498715782634,
        0.0308667689511338,
        0.0299425395024999,
        0.0290720485406529,
        0.0282507416092785,
        0.0391657747526245,
        0.0500782309175949,
        0.0609883114447776,
        0.0591777143516132,
        0.0574718130489499,
        0.0558617607246537,
        0.0543396819921961,
        0.0528985428902825,
        0.0515320412456406,
        0.0502345137676611,
        0.0490008569660754,
        0.0478264595473981,
        0.0467071443899525,
        0.0456391185488475,
        0.0446189300222433,
        0.043643430234481,
        0.0427097413722278,
        0.0418152278559523,
        0.040957471347943,
        0.0401342487952501,
        0.0393435130857027,
        0.0385833759609123,
        0.0378520928846227,
        0.0371480496100095,
        0.0364697502272805,
        0.0358158065045312,
        0.0351849283613614,
        0.0345759153371442,
        0.033987648934763,
        0.033419085736694,
        0.0328692512039723,
        0.0323372340802372,
        0.0318221813330291,
        0.0313232935730665,
        0.0308398208995967,
        0.0303710591262563,
        0.0299163463473728,
        0.029475059809385,
        0.0290466130561936,
        0.0286304533208476,
        0.0282260591391,
        0.0278329381631135,
        0.0274506251559882,
        0.0270786801498924,
        0.0267166867524261,
        0.0263642505874754,
        0.0260209978582552,
        0.025686574021506,
        0.0253606425629365,
        0.0250428838650015,
        0.0247329941589878,
        0.0244306845541714,
        0.0241356801375103,
        0.0238477191379616,
        0.0235665521500732,
        0.0232919414120017,
        0.0230236601335518,
        0.0227614918702415,
        0.0225052299397529,
        0.0222546768774583,
        0.0220096439279982,
        0.0217699505701593,
        0.0215354240725317,
        0.021305899077644,
        0.0210812172124695,
        0.0208612267233697,
        0.0206457821337079,
        0.0204347439225032,
        0.0202279782226335,
        0.0200253565372122,
        0.019826755472875,
        0.0196320564888108,
        0.0194411456604643,
        0.0192539134569156,
        0.0190702545310272,
        0.0188900675215054,
        0.0187132548661001,
        0.0185397226252144,
        0.018369380315255,
        0.0182021407511003,
        0.018037919897111,
        0.0178766367261452,
        0.0177182130860831
      ]
    },
    [CustomerFocus.B2B]: {
      median: [
        null,
        null,
        null,
        0.02965167274169,
        0.0652980850179132,
        0.115703146454812,
        0.13207906250644,
        0.150666666666667,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.184314385624324,
        0.257650840226292,
        0.329178799309852,
        0.293405156024716,
        0.264525775365922,
        0.240743711201113,
        0.220831874691003,
        0.203925006266326,
        0.189396180320466,
        0.17678044705294,
        0.165725946107191,
        0.155961650291263,
        0.147275513798253,
        0.139499320899993,
        0.132497961472567,
        0.126161698574112,
        0.12040050001775,
        0.115139820178315,
        0.110317417911483,
        0.105880926036692,
        0.101785973595979,
        0.0979947198884664,
        0.0944746988630782,
        0.0911978999776291,
        0.0881400310423806,
        0.0852799224310335,
        0.0825990420657594,
        0.0800810979107905,
        0.0777117101224582,
        0.0754781390418837,
        0.0733690582566775,
        0.0713743642665593,
        0.0694850160550427,
        0.0676928992325221,
        0.0659907104753121,
        0.064371858813718,
        0.0628303809746842,
        0.0613608685013273,
        0.0599584047835647,
        0.0586185104640156,
        0.0573370959491545,
        0.0561104199709631,
        0.0549350533192987,
        0.0538078470083837,
        0.052725904258139,
        0.0516865557679989,
        0.0506873378408835,
        0.0497259729816269,
        0.0488003526496335,
        0.0479085218920146,
        0.0470486656224825,
        0.0462190963441233,
        0.045418243141996,
        0.0446446417950337,
        0.0438969258767743,
        0.0431738187315308,
        0.0424741262272183,
        0.0417967301985968,
        0.0411405825054217,
        0.0405046996393332,
        0.0398881578212647,
        0.039290088538163,
        0.0387096744737578,
        0.0381461457934486,
        0.0375987767478565,
        0.0370668825636455,
        0.0365498165936506,
        0.0360469677014676,
        0.0355577578582991,
        0.0350816399322477,
        0.0346180956523186,
        0.0341666337312333,
        0.033726788132819,
        0.0332981164711226,
        0.0328801985297467,
        0.0324726348909767,
        0.0320750456653648,
        0.0316870693132406,
        0.0313083615505298,
        0.0309385943318941,
        0.0305774549049179,
        0.0302246449296109,
        0.0298798796580182,
        0.0295428871692315,
        0.0292134076554501,
        0.0288911927552022,
        0.028576004930098,
        0.0282676168818616,
        0.0279658110066202,
        0.027670378883721,
        0.0273811207965286,
        0.0270978452829273,
        0.0268203687133692,
        0.0265485148945451,
        0.0262821146968639,
        0.026021005704099,
        0.025765031883682,
        0.0255140432762214,
        0.0252678957029681,
        0.0250264504900251,
        0.0247895742081848,
        0.0245571384273901,
        0.024329019484846,
        0.0241050982659227,
        0.0238852599970281,
        0.0236693940496924,
        0.0234573937551699,
        0.0232491562288909,
        0.0230445822041764,
        0.0228435758746419
      ],
      average: [
        null,
        null,
        null,
        1.72867146666667,
        1.04734417781153,
        0.529243978107347,
        0.356458012771519,
        0.270002815164146,
        0.218081612067756,
        0.183428749555041,
        0.158644610758554,
        0.140029345142299,
        0.125527444785237,
        0.113905574777827,
        0.104378859667536,
        0.0964240265980351,
        0.0896787900049216,
        0.083884357094473,
        0.0788509310774423,
        0.0744361506435898,
        0.0705311389926451,
        0.0670512031524101,
        0.0639294703226414,
        0.0611124332877236,
        0.0585567685436597,
        0.0562270221864005,
        0.0540938994616336,
        0.052132981909344,
        0.0503237523777121,
        0.0486488450194322,
        0.0470934619425518,
        0.0456449148529884,
        0.0442922615190201,
        0.0430260149332576,
        0.0418379087571051,
        0.0407207067364293,
        0.0396680467616754,
        0.0386743124401663,
        0.0377345266785311,
        0.0368442629958587,
        0.0359995712134276,
        0.0351969148729809,
        0.0344331182786817,
        0.0337053214788407,
        0.0330109418320628,
        0.0323476410606041,
        0.0317132968978556,
        0.0311059785992328,
        0.0305239257156504,
        0.0299655296332397,
        0.0294293174674434,
        0.0289139379682587,
        0.0284181491494288,
        0.0279408074003318,
        0.0274808578771502,
        0.0270373260011935,
        0.0266093099182284,
        0.0261959737943091,
        0.0257965418417152,
        0.0254102929837929,
        0.0250365560802969,
        0.0246747056456385,
        0.0243241580016101,
        0.0239843678139415,
        0.023654824968686,
        0.0233350517501068,
        0.0230246002866013,
        0.0227230502353809,
        0.0224300066802265,
        0.0221450982197481,
        0.0218679752262783,
        0.0215983082578529,
        0.0213357866077808,
        0.0210801169780585,
        0.0208310222644474,
        0.0205882404423724,
        0.020351523543996,
        0.020120636717859,
        0.0198953573633973,
        0.0196754743334554,
        0.0194607871986274,
        0.019251105567892,
        0.0190462484605645,
        0.0188460437250886,
        0.0186503275006304,
        0.0184589437178317,
        0.0182717436354295,
        0.0180885854097622,
        0.0179093336944637,
        0.017733859267894,
        0.0175620386860856,
        0.0173937539591809,
        0.0172288922495211,
        0.017067345589708,
        0.0169090106191098,
        0.016753788337412,
        0.0166015838739366,
        0.0164523062715606,
        0.0163058682841626,
        0.0161621861866151,
        0.0160211795964233,
        0.0158827713061804,
        0.0157468871260804,
        0.0156134557357862,
        0.0154824085450104,
        0.0153536795622114,
        0.0152272052708594,
        0.0151029245127634,
        0.0149807783779916,
        0.0148607101009547,
        0.014742664962248,
        0.0146265901958843,
        0.0145124349015722,
        0.0144001499617213,
        0.0142896879628785,
        0.0141810031213196,
        0.0140740512125429,
        0.0139687895044226,
        0.0138651766938064,
        0.0137631728463463,
        0.0136627393393745
      ]
    },
    [CustomerFocus.B2C]: {
      median: [
        null,
        null,
        null,
        1.41666666666667,
        2.2731214877139,
        2.2731214877139,
        2.2731214877139,
        2.33333333333333,
        1.13333333333333,
        0.473333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.119131388400255,
        0.122663130424706,
        0.121813359670054,
        0.108586119624998,
        0.0979500985108219,
        0.0892117944555715,
        0.0819049104220937,
        0.0650037123195022,
        0.0504814099640092,
        0.0378687709426571,
        0.0364870511599094,
        0.0352026116670513,
        0.048169804067319,
        0.0465856349417443,
        0.0451023458728716,
        0.0297118817011012,
        0.0288545584732078,
        0.028045323059099,
        0.0272802399174834,
        0.0265557915527264,
        0.0258688244431013,
        0.0252165031500439,
        0.0245962711998535,
        0.0240058175997947,
        0.0234430480639874,
        0.0229060601939051,
        0.0184679026834678,
        0.0142241600227006,
        0.0101623314092495,
        0.0100600973658088,
        0.0099598998040266,
        0.00986167847452083,
        0.00976537548134086,
        0.00967093516816801,
        0.00957830401105612,
        0.00948743051727785,
        0.00939826512987518,
        0.0696357871113974,
        0.130854393144795,
        0.193027180288888,
        0.167885021725288,
        0.148907092794056,
        0.134057105393537,
        0.122107666704167,
        0.112274837932798,
        0.104034399401044,
        0.0970224810668903,
        0.0909785764582906,
        0.0857112490006622,
        0.0810766442318255,
        0.0769645556287955,
        0.0732891174654621,
        0.0699824260354343,
        0.0669900677702719,
        0.0642679206745642,
        0.0617798251893095,
        0.0594958606674373,
        0.0573910513369259,
        0.0554443818341556,
        0.05363803919119,
        0.0519568227252403,
        0.0503876799663951,
        0.0489193382806049,
        0.0475420099173819,
        0.0462471539456711,
        0.0450272826661266,
        0.0438758030902153,
        0.0427868862856925,
        0.0417553590302004,
        0.0407766134472006,
        0.0398465312317164,
        0.0389614197860081,
        0.038117958133808,
        0.037313150907072,
        0.0365442890313367,
        0.0358089159968707,
        0.035104798809364,
        0.0344299028782777,
        0.0337823702325486,
        0.0331605005592169,
        0.0325627346461877,
        0.0319876398799518,
        0.0314338975059427,
        0.0309002914058511,
        0.0303856981846425,
        0.0298890783918133,
        0.0294094687278279,
        0.0289459751086841,
        0.028497766479984,
        0.02806406928734,
        0.0276441625229853,
        0.0272373732794654,
        0.0268430727506224,
        0.0264606726280322,
        0.0260896218478125,
        0.0257294036485258,
        0.0253795329058556,
        0.0250395537140137,
        0.0247090371875085,
        0.0243875794600906,
        0.0240747998604454,
        0.0237703392465899,
        0.023473858483017,
        0.0231850370464379
      ],
      average: [
        null,
        null,
        null,
        1.92958333333333,
        2.33333333333333,
        1.98236972933162,
        1.98236972933162,
        1.98236972933162,
        2.33333333333333,
        1.04104630077503,
        0.374379634108363,
        0.0307847255812721,
        0.0298653296049883,
        0.0289992572295286,
        0.0281820001577124,
        0.0274095443738459,
        0.026678304210762,
        0.025985066696496,
        0.0253269443581315,
        0.0439973700113954,
        0.0617676613069203,
        0.0787013816059459,
        0.0729593777740204,
        0.0679982665563566,
        0.0636688922498063,
        0.0598578116871857,
        0.0564772095153954,
        0.0534580481308267,
        0.0507453032663981,
        0.0482945801505358,
        0.046069665020686,
        0.0440407236355285,
        0.0421829557396681,
        0.0404755762962268,
        0.0389010345060741,
        0.0374444082872329,
        0.0360929298843604,
        0.0348356106323289,
        0.0336629415091764,
        0.0325666522009849,
        0.0315395157606213,
        0.0305751891020531,
        0.0296680818880313,
        0.0288132480844032,
        0.0280062957373963,
        0.0272433114986978,
        0.0651847982030372,
        0.102847461648785,
        0.140252368283244,
        0.12786566249906,
        0.117662092585899,
        0.109101081396504,
        0.101807642926975,
        0.0955134241427901,
        0.0900212689663644,
        0.0851830377179671,
        0.0808852360851244,
        0.0770394230714036,
        0.0735756408698287,
        0.0704378110977566,
        0.0675804432181813,
        0.0649662384366718,
        0.0625643170590917,
        0.0603488878160818,
        0.0582982356435571,
        0.0563939423504897,
        0.0546202799183034,
        0.0529637333645004,
        0.0514126219646177,
        0.0499567959345715,
        0.0485873915747462,
        0.0472966321195851,
        0.0460776646234185,
        0.0449244254842471,
        0.0438315288950277,
        0.0427941737784986,
        0.0418080657204466,
        0.0408693511484413,
        0.0399745615664815,
        0.0391205660928671,
        0.038304530889728,
        0.0375238843408005,
        0.0367762870461671,
        0.0360596058715024,
        0.03537189142449,
        0.0347113584398149,
        0.0340763686421071,
        0.0334654157277121,
        0.0328771121645779,
        0.0323101775574608,
        0.0317634283651434,
        0.0312357687890226,
        0.0307261826795694,
        0.0302337263297863,
        0.0297575220437318,
        0.0292967523840806,
        0.0288506550160936,
        0.0284185180767015,
        0.0279996760070145,
        0.0275935057947407,
        0.0271994235799728,
        0.0268168815837623,
        0.0264453653240203,
        0.0260843910876818,
        0.0257335036318657,
        0.0253922740900409,
        0.0250602980620502,
        0.0247371938693116,
        0.0244226009586624,
        0.0241161784401855,
        0.0238176037459914,
        0.0235265713983686,
        0.0232427918769633,
        0.0229659905757681,
        0.0226959068416589,
        0.0224322930870934,
        0.0221749139703313,
        0.021923545637219,
        0.0216779750191741,
        0.0214379991825324,
        0.0212034247249005
      ]
    },
    [Industry.OTHER]: {
      median: [
        null,
        null,
        null,
        2.33333333333333,
        2.33333333333333,
        2.33333333333333,
        2.33333333333333,
        1.06598060498463,
        0.40094647840576,
        0.0519746573636667,
        0.0519746573636667,
        0.0519746573636667,
        0.0519746573636667,
        0.0519746573636667,
        0.0519746573636667,
        0.0519746573636667,
        0.0519746573636667,
        0.0519746573636667,
        0.0519746573636667,
        0.0519746573636667,
        0.0519746573636667,
        0.0519746573636667,
        0.0519746573636667,
        0.0519746573636667,
        0.0519746573636667,
        0.0519746573636667,
        0.0519746573636667,
        0.0519746573636667,
        0.0519746573636667,
        0.0519746573636667,
        0.0519746573636667,
        0.0519746573636667,
        0.0519746573636667,
        0.0519746573636667,
        0.0519746573636667,
        0.0519746573636667,
        0.0519746573636667,
        0.0519746573636667,
        0.0519746573636667,
        0.0519746573636667,
        0.0519746573636667,
        0.0519746573636667,
        0.0519746573636666,
        0.0519746573636667,
        0.0519746573636665,
        0.0519746573636667,
        0.0519746573636667,
        0.0519746573636668,
        0.0519746573636666,
        0.0519746573636667,
        0.0519746573636666,
        0.0519746573636668,
        0.0519746573636666,
        0.0519746573636668,
        0.0519746573636666,
        0.0519746573636667,
        0.0519746573636666,
        0.0519746573636667,
        0.0519746573636667,
        0.0519746573636668,
        0.0519746573636667,
        0.0519746573636667,
        0.0519746573636666,
        0.0519746573636667,
        0.0519746573636667,
        0.0519746573636666,
        0.0519746573636666,
        0.0519746573636666,
        0.0519746573636668,
        0.0519746573636666,
        0.0519746573636667,
        0.0519746573636665,
        0.0519746573636668,
        0.0519746573636667,
        0.0519746573636668,
        0.0519746573636667,
        0.0519746573636667,
        0.0519746573636666,
        0.0519746573636667,
        0.0519746573636667,
        0.0519746573636668,
        0.0519746573636666,
        0.0519746573636668,
        0.0519746573636667,
        0.0519746573636666,
        0.0519746573636666,
        0.0519746573636666,
        0.0519746573636669,
        0.0519746573636666,
        0.0519746573636666,
        0.0519746573636667,
        0.051974657363667,
        0.0519746573636667,
        0.0519746573636666,
        0.0519746573636665,
        0.0519746573636669,
        0.0519746573636667,
        0.0519746573636666,
        0.0519746573636667,
        0.0519746573636666,
        0.0519746573636667,
        0.0519746573636667,
        0.0519746573636669,
        0.0519746573636666,
        0.0519746573636666,
        0.0519746573636666,
        0.0519746573636669,
        0.0519746573636665,
        0.0519746573636666,
        0.0519746573636666,
        0.051974657363667,
        0.0519746573636666,
        0.0519746573636666,
        0.0519746573636666,
        0.0519746573636669,
        0.0519746573636667,
        0.0519746573636667,
        0.0519746573636666,
        0.0519746573636669,
        0.0519746573636666,
        0.0519746573636665
      ],
      average: [
        null,
        null,
        null,
        2.33333333333333,
        2.33333333333333,
        2.33333333333333,
        2.33333333333333,
        1.04873624811917,
        0.382960289657783,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633292,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633292,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633292,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633292,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633292,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633292,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633292,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633293,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633292,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633292,
        0.0379045270633291,
        0.0379045270633292,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633293,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633292,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633292,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633292,
        0.037904527063329,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633293,
        0.0379045270633291,
        0.0379045270633293,
        0.0379045270633291,
        0.0379045270633291,
        0.0379045270633289,
        0.0379045270633292,
        0.0379045270633293,
        0.0379045270633292,
        0.0379045270633289,
        0.0379045270633289
      ]
    },
    [Industry.IT]: {
      median: [
        null,
        null,
        null,
        4.39813819844298,
        9.42772446003123,
        2.7730897620351,
        1.47046430806703,
        0.969591710620334,
        0.714072583771986,
        0.561695553607475,
        0.461397238412155,
        0.390742608788802,
        0.338450437268646,
        0.298270491758599,
        0.266477159902606,
        0.240719066645929,
        0.219442460339155,
        0.201581231098073,
        0.186380480813912,
        0.173291226530764,
        0.161904987319646,
        0.151911728945063,
        0.14307200998202,
        0.135198047970102,
        0.128140550568902,
        0.121779367906457,
        0.11601673533929,
        0.11077230804925,
        0.105979457836834,
        0.101582473801996,
        0.09753442010789,
        0.0937954780129849,
        0.0903316493319097,
        0.0871137327929717,
        0.0841165086673703,
        0.081318083936524,
        0.0786993623521684,
        0.0762436124982449,
        0.0739361133743032,
        0.0717638617632142,
        0.069715329189634,
        0.0677802589470917,
        0.0659494957029156,
        0.0642148417471994,
        0.0625689351544842,
        0.0610051460622697,
        0.0595174880029939,
        0.058100541803575,
        0.0567493900244691,
        0.0554595602754585,
        0.0542269760382983,
        0.053047913862539,
        0.0519189659921827,
        0.0508370076366687,
        0.0497991682270996,
        0.0488028061033052,
        0.0478454861637117,
        0.0469249600814801,
        0.0460391487498847,
        0.0451861266694751,
        0.0443641080311705,
        0.043571434284343,
        0.0428065630083664,
        0.0420680579310591,
        0.0413545799585103,
        0.0406648790988271,
        0.0399977871776067,
        0.0393522112561154,
        0.0387271276743735,
        0.0381215766510521,
        0.0375346573804285,
        0.0369655235738481,
        0.0364133793994204,
        0.0358774757790518,
        0.0353571070067177,
        0.0348516076558889,
        0.0343603497477471,
        0.0338827401548332,
        0.0334182182176808,
        0.03296625355428,
        0.0325263440444643,
        0.0320980139731151,
        0.0316808123177902,
        0.0312743111678543,
        0.0308781042634638,
        0.0304918056439604,
        0.0301150483962108,
        0.0297474834944185,
        0.0293887787236589,
        0.0290386176802169,
        0.0286966988423782,
        0.0283627347059735,
        0.0280364509794682,
        0.0277175858338522,
        0.027405889203058,
        0.0271011221309499,
        0.0268030561613329,
        0.0265114727676933,
        0.0262261628196999,
        0.0259469260837189,
        0.0256735707548553,
        0.0254059130181987,
        0.0251437766372062,
        0.024886992567245,
        0.0246353985925503,
        0.0243888389849437,
        0.0241471641828101,
        0.0239102304889492,
        0.0236778997860086,
        0.0234500392683263,
        0.0232265211890936,
        0.0230072226218017,
        0.0227920252350825,
        0.0225808150800256,
        0.0223734823892173,
        0.0221699213867195,
        0.0219700301083232,
        0.0217737102314277,
        0.0215808669139384,
        0.0213914086416516,
        0.021205247083591
      ],
      average: [
        null,
        null,
        null,
        0.999912153951554,
        2.33333333333333,
        2.33333333333333,
        1.13333333333333,
        0.473333333333333,
        0.110333333333333,
        0.354709337401461,
        0.417258671104624,
        0.48549430787171,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.626666666666667,
        0.540573097016666,
        0.456895674794454,
        0.0928882832537937,
        0.0853656533518659,
        0.0788021883922529,
        0.0730166562685118,
        0.0678714281538029,
        0.0632600473245345,
        0.059098834019987,
        0.0553210743083703,
        0.0518729091179529,
        0.0487103692464656,
        0.0457971996063404,
        0.0431032376100149,
        0.0406031874645011,
        0.0382756818300266,
        0.0361025550820067,
        0.0340682744565729,
        0.0321594904334258,
        0.030364678181227,
        0.0286738492708241,
        0.0270783181326603,
        0.0255705115461187,
        0.024143812236157,
        0.0227924297131475,
        0.0215112930300435,
        0.020295961289815,
        0.0191425486165025,
        0.0180476609773848,
        0.0170083427636071,
        0.0160220314401208,
        0.0150865188907083,
        0.0141999183309176,
        0.0133606358562356,
        0.0125673458464643,
        0.0118189695688241,
        0.0111146564185026,
        0.0104537673114953,
        0.00983585980458046,
        0.00926067456453813,
        0.00872812284564365,
        0.00823827466336905,
        0.00779134737471154,
        0.00738769439338024,
        0.00702779378243545,
        0.00671223647937428,
        0.00644171392018052,
        0.00621700484093972,
        0.00603896104933854,
        0.00590849197516633,
        0.0058265478299809,
        0.00579410123273213,
        0.00581212719156225,
        0.00588158137334223,
        0.0060033766426549,
        0.0061783579116268,
        0.00640727541131862,
        0.00669075657424919,
        0.00702927680483397,
        0.00742312950857723,
        0.00787239584896304,
        0.00837691479964065,
        0.00893625415419315,
        0.00954968324096694,
        0.0102161481601832,
        0.0109342504080851,
        0.0117022297718511,
        0.0125179523631112,
        0.0133789046023112,
        0.014282193867353,
        0.0152245563770149,
        0.016202372694101,
        0.017211691010215,
        0.018248258121156,
        0.0193075577305935,
        0.0203848554430302,
        0.0214752495405139,
        0.0225737263966305,
        0.0236752191809716,
        0.0247746683606795,
        0.0258670824224741,
        0.0269475972247516,
        0.0280115324459619,
        0.029054443718984,
        0.0300721692236816,
        0.0310608697394055,
        0.0320170614217555,
        0.0329376408475806,
        0.0338199021532487
      ]
    },
    [Industry.FIPT]: {
      median: [
        null,
        null,
        null,
        null,
        1.71033706312999,
        4.44036451636966,
        2.9741407956,
        1.50001557219921,
        0.0931132164785786,
        0.0642629711888638,
        0.0432805501583241,
        0.0272619891715137,
        0.0146152831450554,
        0.00441058111720838,
        -0.00391369588936023,
        -0.0106962305138824,
        -0.0161295397373862,
        -0.0203024739698909,
        -0.023224883556018,
        -0.0248432042209359,
        -0.0250524112463512,
        -0.023708455048899,
        -0.0206447932585533,
        -0.0156962206616151,
        -0.00873212737209387,
        0.00030117455675797,
        0.0113337754868372,
        0.0241315087616381,
        0.0382762513120409,
        0.0531817749569021,
        0.0681513663572414,
        0.0824683408534356,
        0.0954959328203535,
        0.106757946118465,
        0.11597971821624,
        0.123085279121184,
        0.12816143179693,
        0.131406179461952,
        0.133077235200772,
        0.133450252481928,
        0.13279012361727,
        0.131334438313661,
        0.129286259541257,
        0.126813074418361,
        0.124049294884892,
        0.121100435365621,
        0.118047781974952,
        0.11495288562145,
        0.111861557142954,
        0.108807252352609,
        0.105813850351281,
        0.102897884277953,
        0.100070304540201,
        0.0973378565809381,
        0.09470414816538,
        0.0921704705826542,
        0.0897364270058784,
        0.0874004109605941,
        0.0851599689731425,
        0.0830120741167891,
        0.08095333123831,
        0.0789801299399649,
        0.0770887577015074,
        0.0752754826577888,
        0.0735366133292919,
        0.0718685408945257,
        0.0702677682806329,
        0.0687309293417586,
        0.067254800623061,
        0.0658363076171032,
        0.0644725269665218,
        0.0631606857199493,
        0.061898158482363,
        0.0606824630973282,
        0.0595112553424955,
        0.0583823230001056,
        0.0572935795726188,
        0.0562430578434839,
        0.05522890342944,
        0.0542493684298582,
        0.0533028052474862,
        0.0523876606313491,
        0.0515024699747208,
        0.0506458518877058,
        0.0498165030539897,
        0.0490131933739937,
        0.0482347613913216,
        0.0474801099956081,
        0.0467482023922513,
        0.0460380583277772,
        0.0453487505585329,
        0.0446794015498314,
        0.044029180392493,
        0.0433972999238061,
        0.0427830140402098,
        0.0421856151894125,
        0.0416044320301713,
        0.0410388272485087,
        0.0404881955197523,
        0.0399519616063763,
        0.0394295785822415,
        0.0389205261744187,
        0.0384243092143588,
        0.0379404561907327,
        0.0374685178967837,
        0.0370080661655435,
        0.0365586926867274,
        0.0361200078995732,
        0.0356916399562992,
        0.0352732337512434,
        0.0348644500111105,
        0.0344649644420807,
        0.0340744669298535,
        0.0336926607889833,
        0.0333192620581314,
        0.0329539988381091,
        0.0325966106698107,
        0.0322468479493522,
        0.0319044713779224,
        0.0315692514440392,
        0.0312409679360641
      ],
      average: [
        null,
        null,
        null,
        null,
        0.80751933909125,
        0.378241594227188,
        0.234833218418753,
        0.162936546111417,
        0.119690410502342,
        0.0908180746357128,
        0.0702114225107404,
        0.0548271810544397,
        0.0429858010841283,
        0.0336911052131778,
        0.0263210728166585,
        0.0204731900105741,
        0.0158810331185462,
        0.012366332485229,
        0.00980978928369273,
        0.00813224019723731,
        0.00728168637683517,
        0.00722367882661601,
        0.00793361986293895,
        0.00939016903368387,
        0.011569354999218,
        0.014439298270472,
        0.0179556861928668,
        0.0220583155111282,
        0.0266691102001449,
        0.0316920055147406,
        0.0370149453765285,
        0.0425139787742689,
        0.0480591095555642,
        0.0535212341137676,
        0.0587792850093386,
        0.0637266546724418,
        0.0682761198483521,
        0.0723627827153057,
        0.0759449045573322,
        0.0790028392797049,
        0.0815365069895044,
        0.0835619532959816,
        0.0851075284577237,
        0.0862101276626006,
        0.0869118023927972,
        0.0872569192932523,
        0.0872899305234272,
        0.0870537383311954,
        0.0865885867905299,
        0.0859313899452673,
        0.0851154006374758,
        0.0841701309994025,
        0.0831214482906003,
        0.0819917844978844,
        0.0808004124335688,
        0.0795637536749484,
        0.0782956940804234,
        0.0770078907680076,
        0.075710060575387,
        0.0744102444668158,
        0.0731150454569673,
        0.0718298397019885,
        0.0705589617309877,
        0.0693058655715736,
        0.0680732639263097,
        0.0668632477066652,
        0.0656773882172286,
        0.0645168241693836,
        0.0633823355345246,
        0.0622744060519066,
        0.0611932760047299,
        0.0601389866820943,
        0.0591114177610234,
        0.0581103186754779,
        0.057135334889537,
        0.0561860298597447,
        0.0552619033561212,
        0.0543624067112728,
        0.0534869554808311,
        0.0526349399246016,
        0.0518057336547408,
        0.0509987007436311,
        0.0502132015385575,
        0.0494485973916885,
        0.0487042544811915,
        0.0479795468717027,
        0.0472738589390476,
        0.0465865872644216,
        0.0459171420866373,
        0.045264948387029,
        0.0446294466697981,
        0.0440100934906101,
        0.0434063617778636,
        0.0428177409839536,
        0.0422437370978888,
        0.0416838725455679,
        0.0411376859997808,
        0.0406047321184071,
        0.0400845812262585,
        0.0395768189534635,
        0.0390810458411367,
        0.0385968769232611,
        0.0381239412921791,
        0.0376618816538008,
        0.0372103538775482,
        0.0367690265451404,
        0.0363375805015562,
        0.035915708410859,
        0.0355031143190335,
        0.0350995132255183,
        0.0347046306647492,
        0.0343182022986976,
        0.0339399735211356,
        0.033569699074128,
        0.0332071426770785,
        0.0328520766685015,
        0.0325042816605709,
        0.0321635462063952,
        0.0318296664798904,
        0.0315024459680512,
        0.0311816951753801
      ]
    },
    [Industry.DH]: {
      median: [
        null,
        null,
        null,
        null,
        5.02519747399649,
        1.75650051323853,
        1.00314597282019,
        0.689444182135622,
        0.521364727331457,
        0.417701315702423,
        0.34776423838168,
        0.29755772300342,
        0.259839168924902,
        0.230502739219242,
        0.207054480165285,
        0.187895299974512,
        0.171954227047728,
        0.158487824290361,
        0.14696430769066,
        0.136993477394924,
        0.128282683761062,
        0.120608224392881,
        0.1137962242041,
        0.107709529637278,
        0.102238523999599,
        0.0972945626420202,
        0.0928051971750921,
        0.0887106455410142,
        0.0849611451472603,
        0.0815149420252981,
        0.0783367448245858,
        0.0753965230977479,
        0.0726685637411628,
        0.0701307232061567,
        0.0677638297318952,
        0.0655512016619571,
        0.0634782563974473,
        0.0615321907151153,
        0.0597017177195653,
        0.0579768490711149,
        0.056348713659587,
        0.0548094058073451,
        0.0533518575442471,
        0.0519697306193336,
        0.0506573247832723,
        0.0494094995536951,
        0.0482216072080444,
        0.0470894351693746,
        0.0460091562851005,
        0.0449772857661695,
        0.043990643769165,
        0.0430463227775887,
        0.0421416590796634,
        0.0412742077550895,
        0.0404417206775278,
        0.0396421271172008,
        0.0388735165921814,
        0.0381341236701499,
        0.0374223144667628,
        0.0367365746237947,
        0.0360754985813458,
        0.0354377799845225,
        0.0348222030871273,
        0.0342276350335726,
        0.0336530189161444,
        0.0330973675182747,
        0.0325597576660578,
        0.0320393251201475,
        0.0315352599487007,
        0.0310468023293366,
        0.0305732387344441,
        0.0301138984596083,
        0.0296681504597117,
        0.0292354004613497,
        0.0288150883238413,
        0.0284066856242079,
        0.0280096934442856,
        0.0276236403404761,
        0.0272480804788315,
        0.0268825919199449,
        0.0265267750398309,
        0.0261802510743544,
        0.0258426607760921,
        0.0255136631736344,
        0.025192934424308,
        0.0248801667522412,
        0.0245750674644287,
        0.0242773580382317,
        0.0239867732742935,
        0.0237030605095076,
        0.0234259788850952,
        0.023155298665368,
        0.0228908006031173,
        0.022632275347943,
        0.0223795228941956,
        0.02213235206544,
        0.0218905800326799,
        0.0216540318637743,
        0.0214225401017273,
        0.0211959443697073,
        0.0209740910008522,
        0.0207568326910423,
        0.0205440281730255,
        0.0203355419103545,
        0.0201312438097553,
        0.0199310089506507,
        0.0197347173306362,
        0.0195422536258549,
        0.0193535069652262,
        0.0191683707176336,
        0.0189867422911963,
        0.0188085229438329,
        0.0186336176043926,
        0.0184619347036548,
        0.0182933860145901,
        0.0181278865012745,
        0.0179653541759356,
        0.0178057099636034,
        0.0176488775739168,
        0.0174947833796313,
        0.0173433563014449
      ],
      average: [
        null,
        null,
        null,
        null,
        11.8500042134279,
        3.26325899676368,
        1.68188688182606,
        1.09155626409943,
        0.795822307021808,
        0.621646157279472,
        0.508039999865492,
        0.428567975189645,
        0.370074518168008,
        0.325331222181928,
        0.290058691107494,
        0.261571432415809,
        0.238103538895618,
        0.218448416965981,
        0.201754827323906,
        0.187405726932681,
        0.174943313331416,
        0.16402101177357,
        0.154371780838928,
        0.145786632442885,
        0.138099732730226,
        0.131177852501398,
        0.124912758504485,
        0.119215634085076,
        0.114012926169248,
        0.10924321157238,
        0.104854802890399,
        0.100803898488403,
        0.0970531378933102,
        0.0935704628081897,
        0.0903282110312716,
        0.087302389652445,
        0.0844720875394075,
        0.0818189969882197,
        0.0793270216246952,
        0.0769819529715348,
        0.0747712020718339,
        0.0726835755530946,
        0.0707090877894351,
        0.0688388025603381,
        0.0670646989472078,
        0.065379557252601,
        0.0637768615434285,
        0.0622507160624319,
        0.0607957732616052,
        0.0594071716172379,
        0.0580804817115767,
        0.056811659328199,
        0.0555970045204026,
        0.0544331257845554,
        0.0533169086114746,
        0.052245487804717,
        0.0512162230501916,
        0.0502266773005053,
        0.0492745976031846,
        0.0483578980566212,
        0.0474746446235093,
        0.0466230415699875,
        0.0458014193311827,
        0.0450082236312616,
        0.0442420057093437,
        0.0435014135224384,
        0.0427851838133944,
        0.0420921349463196,
        0.0414211604242503,
        0.0407712230145212,
        0.040141349416435,
        0.0395306254137444,
        0.0389381914613302,
        0.0383632386613815,
        0.0378050050895919,
        0.0372627724363723,
        0.0367358629320329,
        0.0362236365283219,
        0.035725488311746,
        0.0352408461267486,
        0.034769168389149,
        0.0343099420723369,
        0.0338626808504732,
        0.0334269233846688,
        0.0330022317393894,
        0.0325881899177693,
        0.0321844025054738,
        0.0317904934139224,
        0.0314061047144084,
        0.0310308955556068,
        0.0306645411575486,
        0.0303067318758635,
        0.0299571723306232,
        0.0296155805946344,
        0.0292816874365315,
        0.0289552356143697,
        0.0286359792158509,
        0.0283236830416308,
        0.028018122028451,
        0.0277190807091446,
        0.0274263527067827,
        0.0271397402604684,
        0.0268590537805145,
        0.0265841114308717,
        0.0263147387369082,
        0.0260507682167435,
        0.0257920390345136,
        0.0255383966740657,
        0.0252896926316702,
        0.025045784126497,
        0.0248065338276543,
        0.02457180959669,
        0.0243414842445699,
        0.0241154353021495,
        0.0238935448033242,
        0.0236756990799968,
        0.0234617885681699,
        0.023251707624417,
        0.0230453543521272,
        0.022842630436895,
        0.0226434409905244
      ]
    }
  },
  [EvaluationType.CASH_BURN]: {
    all: {
      median: [
        null,
        -8556.936,
        -16969.744,
        -25238.424,
        -33362.976,
        -41343.4,
        -49179.696,
        -56871.864,
        -64419.904,
        -71823.816,
        -79083.6,
        -86199.256,
        -93170.784,
        -99998.184,
        -106681.456,
        -113220.6,
        -119615.616,
        -125866.504,
        -131973.264,
        -137935.896,
        -143754.4,
        -149428.776,
        -154959.024,
        -160345.144,
        -165587.136,
        -170685.0,
        -175638.736,
        -180448.344,
        -185113.824,
        -189635.176,
        -194012.4,
        -198245.496,
        -202334.464,
        -206279.304,
        -210080.016,
        -213736.6,
        -217249.056,
        -220617.384,
        -223841.584,
        -226921.656,
        -229857.6,
        -232649.416,
        -235297.104,
        -237800.664,
        -240160.096,
        -242375.4,
        -244446.576,
        -246373.624,
        -248156.544,
        -249795.336,
        -251290.0,
        -252640.536,
        -253846.944,
        -254909.224,
        -255827.376,
        -256601.4,
        -257231.296,
        -257717.064,
        -258058.704,
        -258256.216,
        -258309.6,
        -258218.856,
        -257983.984,
        -257604.984,
        -257081.856,
        -256414.6,
        -255603.216,
        -254647.704,
        -253548.064,
        -252304.296,
        -250916.4,
        -249384.376,
        -247708.224,
        -245887.944,
        -243923.536,
        -241815.0,
        -239562.336,
        -237165.544,
        -234624.624,
        -231939.576,
        -229110.4,
        -226137.096,
        -223019.664,
        -219758.104,
        -216352.416,
        -212802.6,
        -209108.656,
        -205270.584,
        -201288.384,
        -197162.056,
        -192891.6,
        -188477.016,
        -183918.304,
        -179215.464,
        -174368.496,
        -169377.4,
        -164242.176,
        -158962.824,
        -153539.344,
        -147971.736,
        -142260.0,
        -136404.136,
        -130404.144,
        -124260.024,
        -117971.776,
        -111539.4,
        -104962.896,
        -98242.264,
        -91377.504,
        -84368.616,
        -77215.6,
        -69918.456,
        -62477.184,
        -54891.784,
        -47162.256,
        -39288.6,
        -31270.816,
        -23108.904,
        -14802.864,
        -6352.696,
        2241.6
      ],
      average: [
        null,
        -8008.98,
        -15926.32,
        -23752.02,
        -31486.08,
        -39128.5,
        -46679.28,
        -54138.42,
        -61505.92,
        -68781.78,
        -75966.0,
        -83058.58,
        -90059.52,
        -96968.82,
        -103786.48,
        -110512.5,
        -117146.88,
        -123689.62,
        -130140.72,
        -136500.18,
        -142768.0,
        -148944.18,
        -155028.72,
        -161021.62,
        -166922.88,
        -172732.5,
        -178450.48,
        -184076.82,
        -189611.52,
        -195054.58,
        -200406.0,
        -205665.78,
        -210833.92,
        -215910.42,
        -220895.28,
        -225788.5,
        -230590.08,
        -235300.02,
        -239918.32,
        -244444.98,
        -248880.0,
        -253223.38,
        -257475.12,
        -261635.22,
        -265703.68,
        -269680.5,
        -273565.68,
        -277359.22,
        -281061.12,
        -284671.38,
        -288190.0,
        -291616.98,
        -294952.32,
        -298196.02,
        -301348.08,
        -304408.5,
        -307377.28,
        -310254.42,
        -313039.92,
        -315733.78,
        -318336.0,
        -320846.58,
        -323265.52,
        -325592.82,
        -327828.48,
        -329972.5,
        -332024.88,
        -333985.62,
        -335854.72,
        -337632.18,
        -339318.0,
        -340912.18,
        -342414.72,
        -343825.62,
        -345144.88,
        -346372.5,
        -347508.48,
        -348552.82,
        -349505.52,
        -350366.58,
        -351136.0,
        -351813.78,
        -352399.92,
        -352894.42,
        -353297.28,
        -353608.5,
        -353828.08,
        -353956.02,
        -353992.32,
        -353936.98,
        -353790.0,
        -353551.38,
        -353221.12,
        -352799.22,
        -352285.68,
        -351680.5,
        -350983.68,
        -350195.22,
        -349315.12,
        -348343.38,
        -347280.0,
        -346124.98,
        -344878.32,
        -343540.02,
        -342110.08,
        -340588.5,
        -338975.28,
        -337270.42,
        -335473.92,
        -333585.78,
        -331606.0,
        -329534.58,
        -327371.52,
        -325116.82,
        -322770.48,
        -320332.5,
        -317802.88,
        -315181.62,
        -312468.72,
        -309664.18,
        -306768.0
      ]
    },
    SaaS: {
      median: [
        null,
        -8508.9,
        -16881.4,
        -25117.5,
        -33217.2,
        -41180.5,
        -49007.4,
        -56697.9,
        -64252.0,
        -71669.7,
        -78951.0,
        -86095.9,
        -93104.4,
        -99976.5,
        -106712.2,
        -113311.5,
        -119774.4,
        -126100.9,
        -132291.0,
        -138344.7,
        -144262.0,
        -150042.9,
        -155687.4,
        -161195.5,
        -166567.2,
        -171802.5,
        -176901.4,
        -181863.9,
        -186690.0,
        -191379.7,
        -195933.0,
        -200349.9,
        -204630.4,
        -208774.5,
        -212782.2,
        -216653.5,
        -220388.4,
        -223986.9,
        -227449.0,
        -230774.7,
        -233964.0,
        -237016.9,
        -239933.4,
        -242713.5,
        -245357.2,
        -247864.5,
        -250235.4,
        -252469.9,
        -254568.0,
        -256529.7,
        -258355.0,
        -260043.9,
        -261596.4,
        -263012.5,
        -264292.2,
        -265435.5,
        -266442.4,
        -267312.9,
        -268047.0,
        -268644.7,
        -269106.0,
        -269430.9,
        -269619.4,
        -269671.5,
        -269587.2,
        -269366.5,
        -269009.4,
        -268515.9,
        -267886.0,
        -267119.7,
        -266217.0,
        -265177.9,
        -264002.4,
        -262690.5,
        -261242.2,
        -259657.5,
        -257936.4,
        -256078.9,
        -254085.0,
        -251954.7,
        -249688.0,
        -247284.9,
        -244745.4,
        -242069.5,
        -239257.2,
        -236308.5,
        -233223.4,
        -230001.9,
        -226644.0,
        -223149.7,
        -219519.0,
        -215751.9,
        -211848.4,
        -207808.5,
        -203632.2,
        -199319.5,
        -194870.4,
        -190284.9,
        -185563.0,
        -180704.7,
        -175710.0,
        -170578.9,
        -165311.4,
        -159907.5,
        -154367.2,
        -148690.5,
        -142877.4,
        -136927.9,
        -130842.0,
        -124619.7,
        -118261.0,
        -111765.9,
        -105134.4,
        -98366.5,
        -91462.2,
        -84421.5,
        -77244.4,
        -69930.9,
        -62481.0,
        -54894.7,
        -47172.0
      ],
      average: [
        null,
        -8687.492,
        -17246.568,
        -25677.228,
        -33979.472,
        -42153.3,
        -50198.712,
        -58115.708,
        -65904.288,
        -73564.452,
        -81096.2,
        -88499.532,
        -95774.448,
        -102920.948,
        -109939.032,
        -116828.7,
        -123589.952,
        -130222.788,
        -136727.208,
        -143103.212,
        -149350.8,
        -155469.972,
        -161460.728,
        -167323.068,
        -173056.992,
        -178662.5,
        -184139.592,
        -189488.268,
        -194708.528,
        -199800.372,
        -204763.8,
        -209598.812,
        -214305.408,
        -218883.588,
        -223333.352,
        -227654.7,
        -231847.632,
        -235912.148,
        -239848.248,
        -243655.932,
        -247335.2,
        -250886.052,
        -254308.488,
        -257602.508,
        -260768.112,
        -263805.3,
        -266714.072,
        -269494.428,
        -272146.368,
        -274669.892,
        -277065.0,
        -279331.692,
        -281469.968,
        -283479.828,
        -285361.272,
        -287114.3,
        -288738.912,
        -290235.108,
        -291602.888,
        -292842.252,
        -293953.2,
        -294935.732,
        -295789.848,
        -296515.548,
        -297112.832,
        -297581.7,
        -297922.152,
        -298134.188,
        -298217.808,
        -298173.012,
        -297999.8,
        -297698.172,
        -297268.128,
        -296709.668,
        -296022.792,
        -295207.5,
        -294263.792,
        -293191.668,
        -291991.128,
        -290662.172,
        -289204.8,
        -287619.012,
        -285904.808,
        -284062.188,
        -282091.152,
        -279991.7,
        -277763.832,
        -275407.548,
        -272922.848,
        -270309.732,
        -267568.2,
        -264698.252,
        -261699.888,
        -258573.108,
        -255317.912,
        -251934.3,
        -248422.272,
        -244781.828,
        -241012.968,
        -237115.692,
        -233090.0,
        -228935.892,
        -224653.368,
        -220242.428,
        -215703.072,
        -211035.3,
        -206239.112,
        -201314.508,
        -196261.488,
        -191080.052,
        -185770.2,
        -180331.932,
        -174765.248,
        -169070.148,
        -163246.632,
        -157294.7,
        -151214.352,
        -145005.588,
        -138668.408,
        -132202.812,
        -125608.8
      ]
    },
    Transactional: {
      median: [
        null,
        -9056.622,
        -17959.688,
        -26709.198,
        -35305.152,
        -43747.55,
        -52036.392,
        -60171.678,
        -68153.408,
        -75981.582,
        -83656.2,
        -91177.262,
        -98544.768,
        -105758.718,
        -112819.112,
        -119725.95,
        -126479.232,
        -133078.958,
        -139525.128,
        -145817.742,
        -151956.8,
        -157942.302,
        -163774.248,
        -169452.638,
        -174977.472,
        -180348.75,
        -185566.472,
        -190630.638,
        -195541.248,
        -200298.302,
        -204901.8,
        -209351.742,
        -213648.128,
        -217790.958,
        -221780.232,
        -225615.95,
        -229298.112,
        -232826.718,
        -236201.768,
        -239423.262,
        -242491.2,
        -245405.582,
        -248166.408,
        -250773.678,
        -253227.392,
        -255527.55,
        -257674.152,
        -259667.198,
        -261506.688,
        -263192.622,
        -264725.0,
        -266103.822,
        -267329.088,
        -268400.798,
        -269318.952,
        -270083.55,
        -270694.592,
        -271152.078,
        -271456.008,
        -271606.382,
        -271603.2,
        -271446.462,
        -271136.168,
        -270672.318,
        -270054.912,
        -269283.95,
        -268359.432,
        -267281.358,
        -266049.728,
        -264664.542,
        -263125.8,
        -261433.502,
        -259587.648,
        -257588.238,
        -255435.272,
        -253128.75,
        -250668.672,
        -248055.038,
        -245287.848,
        -242367.102,
        -239292.8,
        -236064.942,
        -232683.528,
        -229148.558,
        -225460.032,
        -221617.95,
        -217622.312,
        -213473.118,
        -209170.368,
        -204714.062,
        -200104.2,
        -195340.782,
        -190423.808,
        -185353.278,
        -180129.192,
        -174751.55,
        -169220.352,
        -163535.598,
        -157697.288,
        -151705.422,
        -145560.0,
        -139261.022,
        -132808.488,
        -126202.398,
        -119442.752,
        -112529.55,
        -105462.792,
        -98242.478,
        -90868.608,
        -83341.182,
        -75660.2,
        -67825.662,
        -59837.568,
        -51695.918,
        -43400.712,
        -34951.95,
        -26349.632,
        -17593.758,
        -8684.328,
        378.658,
        9595.2
      ],
      average: [
        null,
        -10266.326,
        -20395.304,
        -30386.934,
        -40241.216,
        -49958.15,
        -59537.736,
        -68979.974,
        -78284.864,
        -87452.406,
        -96482.6,
        -105375.446,
        -114130.944,
        -122749.094,
        -131229.896,
        -139573.35,
        -147779.456,
        -155848.214,
        -163779.624,
        -171573.686,
        -179230.4,
        -186749.766,
        -194131.784,
        -201376.454,
        -208483.776,
        -215453.75,
        -222286.376,
        -228981.654,
        -235539.584,
        -241960.166,
        -248243.4,
        -254389.286,
        -260397.824,
        -266269.014,
        -272002.856,
        -277599.35,
        -283058.496,
        -288380.294,
        -293564.744,
        -298611.846,
        -303521.6,
        -308294.006,
        -312929.064,
        -317426.774,
        -321787.136,
        -326010.15,
        -330095.816,
        -334044.134,
        -337855.104,
        -341528.726,
        -345065.0,
        -348463.926,
        -351725.504,
        -354849.734,
        -357836.616,
        -360686.15,
        -363398.336,
        -365973.174,
        -368410.664,
        -370710.806,
        -372873.6,
        -374899.046,
        -376787.144,
        -378537.894,
        -380151.296,
        -381627.35,
        -382966.056,
        -384167.414,
        -385231.424,
        -386158.086,
        -386947.4,
        -387599.366,
        -388113.984,
        -388491.254,
        -388731.176,
        -388833.75,
        -388798.976,
        -388626.854,
        -388317.384,
        -387870.566,
        -387286.4,
        -386564.886,
        -385706.024,
        -384709.814,
        -383576.256,
        -382305.35,
        -380897.096,
        -379351.494,
        -377668.544,
        -375848.246,
        -373890.6,
        -371795.606,
        -369563.264,
        -367193.574,
        -364686.536,
        -362042.15,
        -359260.416,
        -356341.334,
        -353284.904,
        -350091.126,
        -346760.0,
        -343291.526,
        -339685.704,
        -335942.534,
        -332062.016,
        -328044.15,
        -323888.936,
        -319596.374,
        -315166.464,
        -310599.206,
        -305894.6,
        -301052.646,
        -296073.344,
        -290956.694,
        -285702.696,
        -280311.35,
        -274782.656,
        -269116.614,
        -263313.224,
        -257372.486,
        -251294.4
      ]
    },
    [CustomerFocus.B2B]: {
      median: [
        null,
        -9441.323,
        -18712.492,
        -27813.507,
        -36744.368,
        -45505.075,
        -54095.628,
        -62516.027,
        -70766.272,
        -78846.363,
        -86756.3,
        -94496.083,
        -102065.712,
        -109465.187,
        -116694.508,
        -123753.675,
        -130642.688,
        -137361.547,
        -143910.252,
        -150288.803,
        -156497.2,
        -162535.443,
        -168403.532,
        -174101.467,
        -179629.248,
        -184986.875,
        -190174.348,
        -195191.667,
        -200038.832,
        -204715.843,
        -209222.7,
        -213559.403,
        -217725.952,
        -221722.347,
        -225548.588,
        -229204.675,
        -232690.608,
        -236006.387,
        -239152.012,
        -242127.483,
        -244932.8,
        -247567.963,
        -250032.972,
        -252327.827,
        -254452.528,
        -256407.075,
        -258191.468,
        -259805.707,
        -261249.792,
        -262523.723,
        -263627.5,
        -264561.123,
        -265324.592,
        -265917.907,
        -266341.068,
        -266594.075,
        -266676.928,
        -266589.627,
        -266332.172,
        -265904.563,
        -265306.8,
        -264538.883,
        -263600.812,
        -262492.587,
        -261214.208,
        -259765.675,
        -258146.988,
        -256358.147,
        -254399.152,
        -252270.003,
        -249970.7,
        -247501.243,
        -244861.632,
        -242051.867,
        -239071.948,
        -235921.875,
        -232601.648,
        -229111.267,
        -225450.732,
        -221620.043,
        -217619.2,
        -213448.203,
        -209107.052,
        -204595.747,
        -199914.288,
        -195062.675,
        -190040.908,
        -184848.987,
        -179486.912,
        -173954.683,
        -168252.3,
        -162379.763,
        -156337.072,
        -150124.227,
        -143741.228,
        -137188.075,
        -130464.768,
        -123571.307,
        -116507.692,
        -109273.923,
        -101870.0,
        -94295.923,
        -86551.692,
        -78637.307,
        -70552.768,
        -62298.075,
        -53873.228,
        -45278.227,
        -36513.072,
        -27577.763,
        -18472.3,
        -9196.683,
        249.088,
        9865.013,
        19651.092,
        29607.325,
        39733.712,
        50030.253,
        60496.948,
        71133.797,
        81940.8
      ],
      average: [
        null,
        -11799.479,
        -23405.916,
        -34819.311,
        -46039.664,
        -57066.975,
        -67901.244,
        -78542.471,
        -88990.656,
        -99245.799,
        -109307.9,
        -119176.959,
        -128852.976,
        -138335.951,
        -147625.884,
        -156722.775,
        -165626.624,
        -174337.431,
        -182855.196,
        -191179.919,
        -199311.6,
        -207250.239,
        -214995.836,
        -222548.391,
        -229907.904,
        -237074.375,
        -244047.804,
        -250828.191,
        -257415.536,
        -263809.839,
        -270011.1,
        -276019.319,
        -281834.496,
        -287456.631,
        -292885.724,
        -298121.775,
        -303164.784,
        -308014.751,
        -312671.676,
        -317135.559,
        -321406.4,
        -325484.199,
        -329368.956,
        -333060.671,
        -336559.344,
        -339864.975,
        -342977.564,
        -345897.111,
        -348623.616,
        -351157.079,
        -353497.5,
        -355644.879,
        -357599.216,
        -359360.511,
        -360928.764,
        -362303.975,
        -363486.144,
        -364475.271,
        -365271.356,
        -365874.399,
        -366284.4,
        -366501.359,
        -366525.276,
        -366356.151,
        -365993.984,
        -365438.775,
        -364690.524,
        -363749.231,
        -362614.896,
        -361287.519,
        -359767.1,
        -358053.639,
        -356147.136,
        -354047.591,
        -351755.004,
        -349269.375,
        -346590.704,
        -343718.991,
        -340654.236,
        -337396.439,
        -333945.6,
        -330301.719,
        -326464.796,
        -322434.831,
        -318211.824,
        -313795.775,
        -309186.684,
        -304384.551,
        -299389.376,
        -294201.159,
        -288819.9,
        -283245.599,
        -277478.256,
        -271517.871,
        -265364.444,
        -259017.975,
        -252478.464,
        -245745.911,
        -238820.316,
        -231701.679,
        -224390.0,
        -216885.279,
        -209187.516,
        -201296.711,
        -193212.864,
        -184935.975,
        -176466.044,
        -167803.071,
        -158947.056,
        -149897.999,
        -140655.9,
        -131220.759,
        -121592.576,
        -111771.351,
        -101757.084,
        -91549.775,
        -81149.424,
        -70556.031,
        -59769.596,
        -48790.119,
        -37617.6
      ]
    },
    [CustomerFocus.B2C]: {
      median: [
        null,
        -5181.107,
        -10321.828,
        -15422.163,
        -20482.112,
        -25501.675,
        -30480.852,
        -35419.643,
        -40318.048,
        -45176.067,
        -49993.7,
        -54770.947,
        -59507.808,
        -64204.283,
        -68860.372,
        -73476.075,
        -78051.392,
        -82586.323,
        -87080.868,
        -91535.027,
        -95948.8,
        -100322.187,
        -104655.188,
        -108947.803,
        -113200.032,
        -117411.875,
        -121583.332,
        -125714.403,
        -129805.088,
        -133855.387,
        -137865.3,
        -141834.827,
        -145763.968,
        -149652.723,
        -153501.092,
        -157309.075,
        -161076.672,
        -164803.883,
        -168490.708,
        -172137.147,
        -175743.2,
        -179308.867,
        -182834.148,
        -186319.043,
        -189763.552,
        -193167.675,
        -196531.412,
        -199854.763,
        -203137.728,
        -206380.307,
        -209582.5,
        -212744.307,
        -215865.728,
        -218946.763,
        -221987.412,
        -224987.675,
        -227947.552,
        -230867.043,
        -233746.148,
        -236584.867,
        -239383.2,
        -242141.147,
        -244858.708,
        -247535.883,
        -250172.672,
        -252769.075,
        -255325.092,
        -257840.723,
        -260315.968,
        -262750.827,
        -265145.3,
        -267499.387,
        -269813.088,
        -272086.403,
        -274319.332,
        -276511.875,
        -278664.032,
        -280775.803,
        -282847.188,
        -284878.187,
        -286868.8,
        -288819.027,
        -290728.868,
        -292598.323,
        -294427.392,
        -296216.075,
        -297964.372,
        -299672.283,
        -301339.808,
        -302966.947,
        -304553.7,
        -306100.067,
        -307606.048,
        -309071.643,
        -310496.852,
        -311881.675,
        -313226.112,
        -314530.163,
        -315793.828,
        -317017.107,
        -318200.0,
        -319342.507,
        -320444.628,
        -321506.363,
        -322527.712,
        -323508.675,
        -324449.252,
        -325349.443,
        -326209.248,
        -327028.667,
        -327807.7,
        -328546.347,
        -329244.608,
        -329902.483,
        -330519.972,
        -331097.075,
        -331633.792,
        -332130.123,
        -332586.068,
        -333001.627,
        -333376.8
      ],
      average: [
        null,
        -7201.365,
        -14349.26,
        -21443.685,
        -28484.64,
        -35472.125,
        -42406.14,
        -49286.685,
        -56113.76,
        -62887.365,
        -69607.5,
        -76274.165,
        -82887.36,
        -89447.085,
        -95953.34,
        -102406.125,
        -108805.44,
        -115151.285,
        -121443.66,
        -127682.565,
        -133868.0,
        -139999.965,
        -146078.46,
        -152103.485,
        -158075.04,
        -163993.125,
        -169857.74,
        -175668.885,
        -181426.56,
        -187130.765,
        -192781.5,
        -198378.765,
        -203922.56,
        -209412.885,
        -214849.74,
        -220233.125,
        -225563.04,
        -230839.485,
        -236062.46,
        -241231.965,
        -246348.0,
        -251410.565,
        -256419.66,
        -261375.285,
        -266277.44,
        -271126.125,
        -275921.34,
        -280663.085,
        -285351.36,
        -289986.165,
        -294567.5,
        -299095.365,
        -303569.76,
        -307990.685,
        -312358.14,
        -316672.125,
        -320932.64,
        -325139.685,
        -329293.26,
        -333393.365,
        -337440.0,
        -341433.165,
        -345372.86,
        -349259.085,
        -353091.84,
        -356871.125,
        -360596.94,
        -364269.285,
        -367888.16,
        -371453.565,
        -374965.5,
        -378423.965,
        -381828.96,
        -385180.485,
        -388478.54,
        -391723.125,
        -394914.24,
        -398051.885,
        -401136.06,
        -404166.765,
        -407144.0,
        -410067.765,
        -412938.06,
        -415754.885,
        -418518.24,
        -421228.125,
        -423884.54,
        -426487.485,
        -429036.96,
        -431532.965,
        -433975.5,
        -436364.565,
        -438700.16,
        -440982.285,
        -443210.94,
        -445386.125,
        -447507.84,
        -449576.085,
        -451590.86,
        -453552.165,
        -455460.0,
        -457314.365,
        -459115.26,
        -460862.685,
        -462556.64,
        -464197.125,
        -465784.14,
        -467317.685,
        -468797.76,
        -470224.365,
        -471597.5,
        -472917.165,
        -474183.36,
        -475396.085,
        -476555.34,
        -477661.125,
        -478713.44,
        -479712.285,
        -480657.66,
        -481549.565,
        -482388.0
      ]
    },
    [Industry.OTHER]: {
      median: [
        null,
        -6273.4,
        -12546.8,
        -18820.2,
        -25093.6,
        -31367.0,
        -37640.4,
        -43913.8,
        -50187.2,
        -56460.6,
        -62734.0,
        -69007.4,
        -75280.8,
        -81554.2,
        -87827.6,
        -94101.0,
        -100374.4,
        -106647.8,
        -112921.2,
        -119194.6,
        -125468.0,
        -131741.4,
        -138014.8,
        -144288.2,
        -150561.6,
        -156835.0,
        -163108.4,
        -169381.8,
        -175655.2,
        -181928.6,
        -188202.0,
        -194475.4,
        -200748.8,
        -207022.2,
        -213295.6,
        -219569.0,
        -225842.4,
        -232115.8,
        -238389.2,
        -244662.6,
        -250936.0,
        -257209.4,
        -263482.8,
        -269756.2,
        -276029.6,
        -282303.0,
        -288576.4,
        -294849.8,
        -301123.2,
        -307396.6,
        -313670.0,
        -319943.4,
        -326216.8,
        -332490.2,
        -338763.6,
        -345037.0,
        -351310.4,
        -357583.8,
        -363857.2,
        -370130.6,
        -376404.0,
        -382677.4,
        -388950.8,
        -395224.2,
        -401497.6,
        -407771.0,
        -414044.4,
        -420317.8,
        -426591.2,
        -432864.6,
        -439138.0,
        -445411.4,
        -451684.8,
        -457958.2,
        -464231.6,
        -470505.0,
        -476778.4,
        -483051.8,
        -489325.2,
        -495598.6,
        -501872.0,
        -508145.4,
        -514418.8,
        -520692.2,
        -526965.6,
        -533239.0,
        -539512.4,
        -545785.8,
        -552059.2,
        -558332.6,
        -564606.0,
        -570879.4,
        -577152.8,
        -583426.2,
        -589699.6,
        -595973.0,
        -602246.4,
        -608519.8,
        -614793.2,
        -621066.6,
        -627340.0,
        -633613.4,
        -639886.8,
        -646160.2,
        -652433.6,
        -658707.0,
        -664980.4,
        -671253.8,
        -677527.2,
        -683800.6,
        -690074.0,
        -696347.4,
        -702620.8,
        -708894.2,
        -715167.6,
        -721441.0,
        -727714.4,
        -733987.8,
        -740261.2,
        -746534.6,
        -752808.0
      ],
      average: [
        null,
        -5895.0,
        -11790.0,
        -17685.0,
        -23580.0,
        -29475.0,
        -35370.0,
        -41265.0,
        -47160.0,
        -53055.0,
        -58950.0,
        -64845.0,
        -70740.0,
        -76635.0,
        -82530.0,
        -88425.0,
        -94320.0,
        -100215.0,
        -106110.0,
        -112005.0,
        -117900.0,
        -123795.0,
        -129690.0,
        -135585.0,
        -141480.0,
        -147375.0,
        -153270.0,
        -159165.0,
        -165060.0,
        -170955.0,
        -176850.0,
        -182745.0,
        -188640.0,
        -194535.0,
        -200430.0,
        -206325.0,
        -212220.0,
        -218115.0,
        -224010.0,
        -229905.0,
        -235800.0,
        -241695.0,
        -247590.0,
        -253485.0,
        -259380.0,
        -265275.0,
        -271170.0,
        -277065.0,
        -282960.0,
        -288855.0,
        -294750.0,
        -300645.0,
        -306540.0,
        -312435.0,
        -318330.0,
        -324225.0,
        -330120.0,
        -336015.0,
        -341910.0,
        -347805.0,
        -353700.0,
        -359595.0,
        -365490.0,
        -371385.0,
        -377280.0,
        -383175.0,
        -389070.0,
        -394965.0,
        -400860.0,
        -406755.0,
        -412650.0,
        -418545.0,
        -424440.0,
        -430335.0,
        -436230.0,
        -442125.0,
        -448020.0,
        -453915.0,
        -459810.0,
        -465705.0,
        -471600.0,
        -477495.0,
        -483390.0,
        -489285.0,
        -495180.0,
        -501075.0,
        -506970.0,
        -512865.0,
        -518760.0,
        -524655.0,
        -530550.0,
        -536445.0,
        -542340.0,
        -548235.0,
        -554130.0,
        -560025.0,
        -565920.0,
        -571815.0,
        -577710.0,
        -583605.0,
        -589500.0,
        -595395.0,
        -601290.0,
        -607185.0,
        -613080.0,
        -618975.0,
        -624870.0,
        -630765.0,
        -636660.0,
        -642555.0,
        -648450.0,
        -654345.0,
        -660240.0,
        -666135.0,
        -672030.0,
        -677925.0,
        -683820.0,
        -689715.0,
        -695610.0,
        -701505.0,
        -707400.0
      ]
    },
    [Industry.IT]: {
      median: [
        null,
        -7380.336,
        -14622.744,
        -21727.224,
        -28693.776,
        -35522.4,
        -42213.096,
        -48765.864,
        -55180.704,
        -61457.616,
        -67596.6,
        -73597.656,
        -79460.784,
        -85185.984,
        -90773.256,
        -96222.6,
        -101534.016,
        -106707.504,
        -111743.064,
        -116640.696,
        -121400.4,
        -126022.176,
        -130506.024,
        -134851.944,
        -139059.936,
        -143130.0,
        -147062.136,
        -150856.344,
        -154512.624,
        -158030.976,
        -161411.4,
        -164653.896,
        -167758.464,
        -170725.104,
        -173553.816,
        -176244.6,
        -178797.456,
        -181212.384,
        -183489.384,
        -185628.456,
        -187629.6,
        -189492.816,
        -191218.104,
        -192805.464,
        -194254.896,
        -195566.4,
        -196739.976,
        -197775.624,
        -198673.344,
        -199433.136,
        -200055.0,
        -200538.936,
        -200884.944,
        -201093.024,
        -201163.176,
        -201095.4,
        -200889.696,
        -200546.064,
        -200064.504,
        -199445.016,
        -198687.6,
        -197792.256,
        -196758.984,
        -195587.784,
        -194278.656,
        -192831.6,
        -191246.616,
        -189523.704,
        -187662.864,
        -185664.096,
        -183527.4,
        -181252.776,
        -178840.224,
        -176289.744,
        -173601.336,
        -170775.0,
        -167810.736,
        -164708.544,
        -161468.424,
        -158090.376,
        -154574.4,
        -150920.496,
        -147128.664,
        -143198.904,
        -139131.216,
        -134925.6,
        -130582.056,
        -126100.584,
        -121481.184,
        -116723.856,
        -111828.6,
        -106795.416,
        -101624.304,
        -96315.264,
        -90868.296,
        -85283.4,
        -79560.576,
        -73699.824,
        -67701.144,
        -61564.536,
        -55290.0,
        -48877.536,
        -42327.144,
        -35638.824,
        -28812.576,
        -21848.4,
        -14746.296,
        -7506.264,
        -128.304,
        7387.584,
        15041.4,
        22833.144,
        30762.816,
        38830.416,
        47035.944,
        55379.4,
        63860.784,
        72480.096,
        81237.336,
        90132.504,
        99165.6
      ],
      average: [
        null,
        -9771.43,
        -19386.52,
        -28845.27,
        -38147.68,
        -47293.75,
        -56283.48,
        -65116.87,
        -73793.92,
        -82314.63,
        -90679.0,
        -98887.03,
        -106938.72,
        -114834.07,
        -122573.08,
        -130155.75,
        -137582.08,
        -144852.07,
        -151965.72,
        -158923.03,
        -165724.0,
        -172368.63,
        -178856.92,
        -185188.87,
        -191364.48,
        -197383.75,
        -203246.68,
        -208953.27,
        -214503.52,
        -219897.43,
        -225135.0,
        -230216.23,
        -235141.12,
        -239909.67,
        -244521.88,
        -248977.75,
        -253277.28,
        -257420.47,
        -261407.32,
        -265237.83,
        -268912.0,
        -272429.83,
        -275791.32,
        -278996.47,
        -282045.28,
        -284937.75,
        -287673.88,
        -290253.67,
        -292677.12,
        -294944.23,
        -297055.0,
        -299009.43,
        -300807.52,
        -302449.27,
        -303934.68,
        -305263.75,
        -306436.48,
        -307452.87,
        -308312.92,
        -309016.63,
        -309564.0,
        -309955.03,
        -310189.72,
        -310268.07,
        -310190.08,
        -309955.75,
        -309565.08,
        -309018.07,
        -308314.72,
        -307455.03,
        -306439.0,
        -305266.63,
        -303937.92,
        -302452.87,
        -300811.48,
        -299013.75,
        -297059.68,
        -294949.27,
        -292682.52,
        -290259.43,
        -287680.0,
        -284944.23,
        -282052.12,
        -279003.67,
        -275798.88,
        -272437.75,
        -268920.28,
        -265246.47,
        -261416.32,
        -257429.83,
        -253287.0,
        -248987.83,
        -244532.32,
        -239920.47,
        -235152.28,
        -230227.75,
        -225146.88,
        -219909.67,
        -214516.12,
        -208966.23,
        -203260.0,
        -197397.43,
        -191378.52,
        -185203.27,
        -178871.68,
        -172383.75,
        -165739.48,
        -158938.87,
        -151981.92,
        -144868.63,
        -137599.0,
        -130173.03,
        -122590.72,
        -114852.07,
        -106957.08,
        -98905.75,
        -90698.08,
        -82334.07,
        -73813.72,
        -65137.03,
        -56304.0
      ]
    },
    [Industry.FIPT]: {
      median: [
        null,
        -13936.0,
        -27652.0,
        -41148.0,
        -54424.0,
        -67480.0,
        -80316.0,
        -92932.0,
        -105328.0,
        -117504.0,
        -129460.0,
        -141196.0,
        -152712.0,
        -164008.0,
        -175084.0,
        -185940.0,
        -196576.0,
        -206992.0,
        -217188.0,
        -227164.0,
        -236920.0,
        -246456.0,
        -255772.0,
        -264868.0,
        -273744.0,
        -282400.0,
        -290836.0,
        -299052.0,
        -307048.0,
        -314824.0,
        -322380.0,
        -329716.0,
        -336832.0,
        -343728.0,
        -350404.0,
        -356860.0,
        -363096.0,
        -369112.0,
        -374908.0,
        -380484.0,
        -385840.0,
        -390976.0,
        -395892.0,
        -400588.0,
        -405064.0,
        -409320.0,
        -413356.0,
        -417172.0,
        -420768.0,
        -424144.0,
        -427300.0,
        -430236.0,
        -432952.0,
        -435448.0,
        -437724.0,
        -439780.0,
        -441616.0,
        -443232.0,
        -444628.0,
        -445804.0,
        -446760.0,
        -447496.0,
        -448012.0,
        -448308.0,
        -448384.0,
        -448240.0,
        -447876.0,
        -447292.0,
        -446488.0,
        -445464.0,
        -444220.0,
        -442756.0,
        -441072.0,
        -439168.0,
        -437044.0,
        -434700.0,
        -432136.0,
        -429352.0,
        -426348.0,
        -423124.0,
        -419680.0,
        -416016.0,
        -412132.0,
        -408028.0,
        -403704.0,
        -399160.0,
        -394396.0,
        -389412.0,
        -384208.0,
        -378784.0,
        -373140.0,
        -367276.0,
        -361192.0,
        -354888.0,
        -348364.0,
        -341620.0,
        -334656.0,
        -327472.0,
        -320068.0,
        -312444.0,
        -304600.0,
        -296536.0,
        -288252.0,
        -279748.0,
        -271024.0,
        -262080.0,
        -252916.0,
        -243532.0,
        -233928.0,
        -224104.0,
        -214060.0,
        -203796.0,
        -193312.0,
        -182608.0,
        -171684.0,
        -160540.0,
        -149176.0,
        -137592.0,
        -125788.0,
        -113764.0,
        -101520.0
      ],
      average: [
        null,
        -15315.076,
        -30440.304,
        -45375.684,
        -60121.216,
        -74676.9,
        -89042.736,
        -103218.724,
        -117204.864,
        -131001.156,
        -144607.6,
        -158024.196,
        -171250.944,
        -184287.844,
        -197134.896,
        -209792.1,
        -222259.456,
        -234536.964,
        -246624.624,
        -258522.436,
        -270230.4,
        -281748.516,
        -293076.784,
        -304215.204,
        -315163.776,
        -325922.5,
        -336491.376,
        -346870.404,
        -357059.584,
        -367058.916,
        -376868.4,
        -386488.036,
        -395917.824,
        -405157.764,
        -414207.856,
        -423068.1,
        -431738.496,
        -440219.044,
        -448509.744,
        -456610.596,
        -464521.6,
        -472242.756,
        -479774.064,
        -487115.524,
        -494267.136,
        -501228.9,
        -508000.816,
        -514582.884,
        -520975.104,
        -527177.476,
        -533190.0,
        -539012.676,
        -544645.504,
        -550088.484,
        -555341.616,
        -560404.9,
        -565278.336,
        -569961.924,
        -574455.664,
        -578759.556,
        -582873.6,
        -586797.796,
        -590532.144,
        -594076.644,
        -597431.296,
        -600596.1,
        -603571.056,
        -606356.164,
        -608951.424,
        -611356.836,
        -613572.4,
        -615598.116,
        -617433.984,
        -619080.004,
        -620536.176,
        -621802.5,
        -622878.976,
        -623765.604,
        -624462.384,
        -624969.316,
        -625286.4,
        -625413.636,
        -625351.024,
        -625098.564,
        -624656.256,
        -624024.1,
        -623202.096,
        -622190.244,
        -620988.544,
        -619596.996,
        -618015.6,
        -616244.356,
        -614283.264,
        -612132.324,
        -609791.536,
        -607260.9,
        -604540.416,
        -601630.084,
        -598529.904,
        -595239.876,
        -591760.0,
        -588090.276,
        -584230.704,
        -580181.284,
        -575942.016,
        -571512.9,
        -566893.936,
        -562085.124,
        -557086.464,
        -551897.956,
        -546519.6,
        -540951.396,
        -535193.344,
        -529245.444,
        -523107.696,
        -516780.1,
        -510262.656,
        -503555.364,
        -496658.224,
        -489571.236,
        -482294.4
      ]
    },
    [Industry.DH]: {
      median: [
        -4645.93160258668,
        -9291.86320517335,
        -9756.45636543202,
        -10244.2791837036,
        -10756.4931428888,
        -11294.3178000332,
        -11859.0336900349,
        -12451.9853745367,
        -13074.5846432635,
        -13728.3138754267,
        -14414.729569198,
        -15135.4660476579,
        -15892.2393500408,
        -16686.8513175428,
        -17521.19388342,
        -18397.253577591,
        -19317.1162564705,
        -20282.972069294,
        -21297.1206727588,
        -22361.9767063967,
        -23480.0755417165,
        -24654.0793188023,
        -25886.7832847425,
        -27181.1224489796,
        -28540.1785714286,
        -29967.1875,
        -52729.6536,
        -76682.2113,
        -101772.7872,
        -127949.3079,
        -155159.7,
        -183351.8901,
        -212473.8048,
        -242473.3707,
        -273298.5144,
        -304897.1625,
        -337217.2416,
        -370206.6783,
        -403813.3992,
        -437985.3309,
        -472670.4,
        -507816.5331,
        -543371.6568,
        -579283.6977,
        -615500.5824,
        -651970.2375,
        -688640.5896,
        -725459.5653,
        -762375.0912,
        -799335.0939,
        -836287.5,
        -873180.2361,
        -909961.2288,
        -946578.4047,
        -982979.6904,
        -1019113.0125,
        -1054926.2976,
        -1090367.4723,
        -1125384.4632,
        -1159925.1969,
        -1193937.6,
        -1227369.5991,
        -1260169.1208,
        -1292284.0917,
        -1323662.4384,
        -1354252.0875,
        -1384000.9656,
        -1412856.9993,
        -1440768.1152,
        -1467682.2399,
        -1493547.3,
        -1518311.2221,
        -1541921.9328,
        -1564327.3587,
        -1585475.4264,
        -1605314.0625,
        -1623791.1936,
        -1640854.7463,
        -1656452.6472,
        -1670532.8229,
        -1683043.2,
        -1693931.7051,
        -1703146.2648,
        -1710634.8057,
        -1716345.2544,
        -1720225.5375,
        -1722223.5816,
        -1722287.3133,
        -1720364.6592,
        -1716403.5459,
        -1710351.9,
        -1702157.6481,
        -1691768.7168,
        -1679133.0327,
        -1664198.5224,
        -1646913.1125,
        -1627224.7296,
        -1605081.3003,
        -1580430.7512,
        -1553221.0089,
        -1523400.0,
        -1490915.6511,
        -1455715.8888,
        -1417748.6397,
        -1376961.8304,
        -1333303.3875,
        -1286721.2376,
        -1237163.3073,
        -1184577.5232,
        -1128911.8119,
        -1070114.1,
        -1008132.3141,
        -942914.3808,
        -874408.226699999,
        -802561.7784,
        -727322.962499999,
        -648639.705599999,
        -566459.9343,
        -480731.575199999,
        -391402.5549,
        -298420.799999999
      ],
      average: [
        -4370.73737315575,
        -8741.47474631151,
        -8741.47474631151,
        -9178.54848362708,
        -9637.47590780844,
        -10119.3497031989,
        -10625.3171883588,
        -11156.5830477767,
        -11714.4122001656,
        -12300.1328101739,
        -12915.1394506826,
        -14206.6533957508,
        -15627.3187353259,
        -17190.0506088585,
        -18909.0556697443,
        -20799.9612367188,
        -22879.9573603907,
        -25167.9530964297,
        -27684.7484060727,
        -30453.22324668,
        -33498.545571348,
        -36848.4001284828,
        -40533.240141331,
        -44586.5641554642,
        -49045.2205710106,
        -53949.7426281116,
        -59344.7168909228,
        -65279.1885800151,
        -71807.1074380166,
        -78987.8181818183,
        -86886.6000000001,
        -118469.0978,
        -151352.9344,
        -185486.6046,
        -220818.6032,
        -257297.425,
        -294871.5648,
        -333489.5174,
        -373099.7776,
        -413650.8402,
        -455091.2,
        -497369.3518,
        -540433.7904,
        -584233.0106,
        -628715.5072,
        -673829.775,
        -719524.3088,
        -765747.6034,
        -812448.1536,
        -859574.4542,
        -907075.0,
        -954898.2858,
        -1002992.8064,
        -1051307.0566,
        -1099789.5312,
        -1148388.725,
        -1197053.1328,
        -1245731.2494,
        -1294371.5696,
        -1342922.5882,
        -1391332.8,
        -1439550.6998,
        -1487524.7824,
        -1535203.5426,
        -1582535.4752,
        -1629469.075,
        -1675952.8368,
        -1721935.2554,
        -1767364.8256,
        -1812190.0422,
        -1856359.4,
        -1899821.3938,
        -1942524.5184,
        -1984417.2686,
        -2025448.1392,
        -2065565.625,
        -2104718.2208,
        -2142854.4214,
        -2179922.7216,
        -2215871.6162,
        -2250649.6,
        -2284205.1678,
        -2316486.8144,
        -2347443.0346,
        -2377022.3232,
        -2405173.175,
        -2431844.0848,
        -2456983.5474,
        -2480540.0576,
        -2502462.1102,
        -2522698.2,
        -2541196.8218,
        -2557906.4704,
        -2572775.6406,
        -2585752.8272,
        -2596786.525,
        -2605825.2288,
        -2612817.4334,
        -2617711.6336,
        -2620456.3242,
        -2621000.0,
        -2619291.1558,
        -2615278.2864,
        -2608909.8866,
        -2600134.4512,
        -2588900.475,
        -2575156.4528,
        -2558850.8794,
        -2539932.2496,
        -2518349.0582,
        -2494049.8,
        -2466982.9698,
        -2437097.0624,
        -2404340.5726,
        -2368661.9952,
        -2330009.825,
        -2288332.5568,
        -2243578.6854,
        -2195696.7056,
        -2144635.1122,
        -2090342.4
      ]
    }
  },
  [EvaluationType.FTE]: {
    all: {
      median: [
        1.0,
        0.5984,
        1.1968,
        1.7952,
        2.3936,
        2.992,
        3.5904,
        4.1888,
        4.7872,
        5.3856,
        5.984,
        6.5824,
        7.1808,
        7.7792,
        8.3776,
        8.976,
        9.5744,
        10.1728,
        10.7712,
        11.3696,
        11.968,
        12.5664,
        13.1648,
        13.7632,
        14.3616,
        14.96,
        15.5584,
        16.1568,
        16.7552,
        17.3536,
        17.952,
        18.5504,
        19.1488,
        19.7472,
        20.3456,
        20.944,
        21.5424,
        22.1408,
        22.7392,
        23.3376,
        23.936,
        24.5344,
        25.1328,
        25.7312,
        26.3296,
        26.928,
        27.5264,
        28.1248,
        28.7232,
        29.3216,
        29.92,
        30.5184,
        31.1168,
        31.7152,
        32.3136,
        32.912,
        33.5104,
        34.1088,
        34.7072,
        35.3056,
        35.904,
        36.5024,
        37.1008,
        37.6992,
        38.2976,
        38.896,
        39.4944,
        40.0928,
        40.6912,
        41.2896,
        41.888,
        42.4864,
        43.0848,
        43.6832,
        44.2816,
        44.88,
        45.4784,
        46.0768,
        46.6752,
        47.2736,
        47.872,
        48.4704,
        49.0688,
        49.6672,
        50.2656,
        50.864,
        51.4624,
        52.0608,
        52.6592,
        53.2576,
        53.856,
        54.4544,
        55.0528,
        55.6512,
        56.2496,
        56.848,
        57.4464,
        58.0448,
        58.6432,
        59.2416,
        59.84,
        60.4384,
        61.0368,
        61.6352,
        62.2336,
        62.832,
        63.4304,
        64.0288,
        64.6272,
        65.2256,
        65.824,
        66.4224,
        67.0208,
        67.6192,
        68.2176,
        68.816,
        69.4144,
        70.0128,
        70.6112,
        71.2096,
        71.808
      ],
      average: [
        1.0,
        2.7184,
        5.3616,
        7.9308,
        10.4272,
        12.852,
        15.2064,
        17.4916,
        19.7088,
        21.8592,
        23.944,
        25.9644,
        27.9216,
        29.8168,
        31.6512,
        33.426,
        35.1424,
        36.8016,
        38.4048,
        39.9532,
        41.448,
        42.8904,
        44.2816,
        45.6228,
        46.9152,
        48.16,
        49.3584,
        50.5116,
        51.6208,
        52.6872,
        53.712,
        54.6964,
        55.6416,
        56.5488,
        57.4192,
        58.254,
        59.0544,
        59.8216,
        60.5568,
        61.2612,
        61.936,
        62.5824,
        63.2016,
        63.7948,
        64.3632,
        64.908,
        65.4304,
        65.9316,
        66.4128,
        66.8752,
        67.32,
        67.7484,
        68.1616,
        68.5608,
        68.9472,
        69.322,
        69.6864,
        70.0416,
        70.3888,
        70.7292,
        71.064,
        71.3944,
        71.7216,
        72.0468,
        72.3712,
        72.6960000000001,
        73.0224,
        73.3516000000001,
        73.6848,
        74.0232000000001,
        74.368,
        74.7204,
        75.0816,
        75.4528000000001,
        75.8352,
        76.23,
        76.6384000000001,
        77.0616,
        77.5008,
        77.9572000000001,
        78.432,
        78.9264,
        79.4416000000001,
        79.9788,
        80.5392,
        81.124,
        81.7344000000001,
        82.3716000000001,
        83.0368000000001,
        83.7312,
        84.4560000000001,
        85.2124,
        86.0016000000001,
        86.8248,
        87.6832000000001,
        88.578,
        89.5104000000001,
        90.4816000000001,
        91.4928000000001,
        92.5452,
        93.64,
        94.7784,
        95.9616,
        97.1908,
        98.4672000000001,
        99.7920000000001,
        101.1664,
        102.5916,
        104.0688,
        105.5992,
        107.184,
        108.8244,
        110.5216,
        112.2768,
        114.0912,
        115.966,
        117.9024,
        119.9016,
        121.9648,
        124.0932,
        126.288
      ]
    },
    SaaS: {
      median: [
        1.0,
        0.8304,
        1.6608,
        2.4912,
        3.3216,
        4.152,
        4.9824,
        5.8128,
        6.6432,
        7.4736,
        8.304,
        9.1344,
        9.9648,
        10.7952,
        11.6256,
        12.456,
        13.2864,
        14.1168,
        14.9472,
        15.7776,
        16.608,
        17.4384,
        18.2688,
        19.0992,
        19.9296,
        20.76,
        21.5904,
        22.4208,
        23.2512,
        24.0816,
        24.912,
        25.7424,
        26.5728,
        27.4032,
        28.2336,
        29.064,
        29.8944,
        30.7248,
        31.5552,
        32.3856,
        33.216,
        34.0464,
        34.8768,
        35.7072,
        36.5376,
        37.368,
        38.1984,
        39.0288,
        39.8592,
        40.6896,
        41.52,
        42.3504,
        43.1808,
        44.0112,
        44.8416,
        45.672,
        46.5024,
        47.3328,
        48.1632,
        48.9936,
        49.824,
        50.6544,
        51.4848,
        52.3152,
        53.1456,
        53.976,
        54.8064,
        55.6368,
        56.4672,
        57.2976,
        58.128,
        58.9584,
        59.7888,
        60.6192,
        61.4496,
        62.28,
        63.1104,
        63.9408,
        64.7712,
        65.6016,
        66.432,
        67.2624,
        68.0928,
        68.9232,
        69.7536,
        70.584,
        71.4144,
        72.2448,
        73.0752,
        73.9056,
        74.736,
        75.5664,
        76.3968,
        77.2272,
        78.0576,
        78.888,
        79.7184,
        80.5488,
        81.3792,
        82.2096,
        83.04,
        83.8704,
        84.7008,
        85.5312,
        86.3616,
        87.192,
        88.0224,
        88.8528,
        89.6832,
        90.5136,
        91.344,
        92.1744,
        93.0048,
        93.8352,
        94.6656,
        95.496,
        96.3264,
        97.1568,
        97.9872,
        98.8176,
        99.648
      ],
      average: [
        1.0,
        1.0,
        0.76264,
        1.18371,
        1.63072,
        2.10325,
        2.60088,
        3.12319,
        3.66976,
        4.24017,
        4.834,
        5.45083,
        6.09024,
        6.75181,
        7.43512,
        8.13975,
        8.86528,
        9.61129,
        10.37736,
        11.16307,
        11.968,
        12.79173,
        13.63384,
        14.49391,
        15.37152,
        16.26625,
        17.17768,
        18.10539,
        19.04896,
        20.00797,
        20.982,
        21.97063,
        22.97344,
        23.99001,
        25.01992,
        26.06275,
        27.11808,
        28.18549,
        29.26456,
        30.35487,
        31.456,
        32.56753,
        33.68904,
        34.82011,
        35.96032,
        37.10925,
        38.26648,
        39.43159,
        40.60416,
        41.78377,
        42.97,
        44.16243,
        45.36064,
        46.56421,
        47.77272,
        48.98575,
        50.20288,
        51.42369,
        52.64776,
        53.87467,
        55.104,
        56.33533,
        57.56824,
        58.80231,
        60.03712,
        61.27225,
        62.50728,
        63.74179,
        64.97536,
        66.20757,
        67.438,
        68.66623,
        69.89184,
        71.11441,
        72.33352,
        73.54875,
        74.75968,
        75.96589,
        77.16696,
        78.36247,
        79.552,
        80.73513,
        81.91144,
        83.08051,
        84.24192,
        85.39525,
        86.54008,
        87.67599,
        88.80256,
        89.91937,
        91.026,
        92.12203,
        93.20704,
        94.28061,
        95.34232,
        96.39175,
        97.42848,
        98.45209,
        99.46216,
        100.45827,
        101.44,
        102.40693,
        103.35864,
        104.29471,
        105.21472,
        106.11825,
        107.00488,
        107.87419,
        108.72576,
        109.55917,
        110.374,
        111.16983,
        111.94624,
        112.70281,
        113.43912,
        114.15475,
        114.84928,
        115.52229,
        116.17336,
        116.80207,
        117.408
      ]
    },
    Transactional: {
      median: [
        1.0,
        0.735,
        1.47,
        2.205,
        2.94,
        3.675,
        4.41,
        5.145,
        5.88,
        6.615,
        7.35,
        8.085,
        8.82,
        9.555,
        10.29,
        11.025,
        11.76,
        12.495,
        13.23,
        13.965,
        14.7,
        15.435,
        16.17,
        16.905,
        17.64,
        18.375,
        19.11,
        19.845,
        20.58,
        21.315,
        22.05,
        22.785,
        23.52,
        24.255,
        24.99,
        25.725,
        26.46,
        27.195,
        27.93,
        28.665,
        29.4,
        30.135,
        30.87,
        31.605,
        32.34,
        33.075,
        33.81,
        34.545,
        35.28,
        36.015,
        36.75,
        37.485,
        38.22,
        38.955,
        39.69,
        40.425,
        41.16,
        41.895,
        42.63,
        43.365,
        44.1,
        44.835,
        45.57,
        46.305,
        47.04,
        47.775,
        48.51,
        49.245,
        49.98,
        50.715,
        51.45,
        52.185,
        52.92,
        53.655,
        54.39,
        55.125,
        55.86,
        56.595,
        57.33,
        58.065,
        58.8,
        59.535,
        60.27,
        61.005,
        61.74,
        62.475,
        63.21,
        63.945,
        64.68,
        65.415,
        66.15,
        66.885,
        67.62,
        68.355,
        69.09,
        69.825,
        70.56,
        71.295,
        72.03,
        72.765,
        73.5,
        74.235,
        74.97,
        75.705,
        76.44,
        77.175,
        77.91,
        78.645,
        79.38,
        80.115,
        80.85,
        81.585,
        82.32,
        83.055,
        83.79,
        84.525,
        85.26,
        85.995,
        86.73,
        87.465,
        88.2
      ],
      average: [
        1.0,
        1.7837,
        3.5624,
        5.3361,
        7.1048,
        8.8685,
        10.6272,
        12.3809,
        14.1296,
        15.8733,
        17.612,
        19.3457,
        21.0744,
        22.7981,
        24.5168,
        26.2305,
        27.9392,
        29.6429,
        31.3416,
        33.0353,
        34.724,
        36.4077,
        38.0864,
        39.7601,
        41.4288,
        43.0925,
        44.7512,
        46.4049,
        48.0536,
        49.6973,
        51.336,
        52.9697,
        54.5984,
        56.2221,
        57.8408,
        59.4545,
        61.0632,
        62.6669,
        64.2656,
        65.8593,
        67.448,
        69.0317,
        70.6104,
        72.1841,
        73.7528,
        75.3165,
        76.8752,
        78.4289,
        79.9776,
        81.5213,
        83.06,
        84.5937,
        86.1224,
        87.6461,
        89.1648,
        90.6785,
        92.1872,
        93.6909,
        95.1896,
        96.6833,
        98.172,
        99.6557,
        101.1344,
        102.6081,
        104.0768,
        105.5405,
        106.9992,
        108.4529,
        109.9016,
        111.3453,
        112.784,
        114.2177,
        115.6464,
        117.0701,
        118.4888,
        119.9025,
        121.3112,
        122.7149,
        124.1136,
        125.5073,
        126.896,
        128.2797,
        129.6584,
        131.0321,
        132.4008,
        133.7645,
        135.1232,
        136.4769,
        137.8256,
        139.1693,
        140.508,
        141.8417,
        143.1704,
        144.4941,
        145.8128,
        147.1265,
        148.4352,
        149.7389,
        151.0376,
        152.3313,
        153.62,
        154.9037,
        156.1824,
        157.4561,
        158.7248,
        159.9885,
        161.2472,
        162.5009,
        163.7496,
        164.9933,
        166.232,
        167.4657,
        168.6944,
        169.9181,
        171.1368,
        172.3505,
        173.5592,
        174.7629,
        175.9616,
        177.1553,
        178.344
      ]
    },
    [CustomerFocus.B2B]: {
      median: [
        1.0,
        0.5551,
        1.1102,
        1.6653,
        2.2204,
        2.7755,
        3.3306,
        3.8857,
        4.4408,
        4.9959,
        5.551,
        6.1061,
        6.6612,
        7.2163,
        7.7714,
        8.3265,
        8.8816,
        9.4367,
        9.9918,
        10.5469,
        11.102,
        11.6571,
        12.2122,
        12.7673,
        13.3224,
        13.8775,
        14.4326,
        14.9877,
        15.5428,
        16.0979,
        16.653,
        17.2081,
        17.7632,
        18.3183,
        18.8734,
        19.4285,
        19.9836,
        20.5387,
        21.0938,
        21.6489,
        22.204,
        22.7591,
        23.3142,
        23.8693,
        24.4244,
        24.9795,
        25.5346,
        26.0897,
        26.6448,
        27.1999,
        27.755,
        28.3101,
        28.8652,
        29.4203,
        29.9754,
        30.5305,
        31.0856,
        31.6407,
        32.1958,
        32.7509,
        33.306,
        33.8611,
        34.4162,
        34.9713,
        35.5264,
        36.0815,
        36.6366,
        37.1917,
        37.7468,
        38.3019,
        38.857,
        39.4121,
        39.9672,
        40.5223,
        41.0774,
        41.6325,
        42.1876,
        42.7427,
        43.2978,
        43.8529,
        44.408,
        44.9631,
        45.5182,
        46.0733,
        46.6284,
        47.1835,
        47.7386,
        48.2937,
        48.8488,
        49.4039,
        49.959,
        50.5141,
        51.0692,
        51.6243,
        52.1794,
        52.7345,
        53.2896,
        53.8447,
        54.3998,
        54.9549,
        55.51,
        56.0651,
        56.6202,
        57.1753,
        57.7304,
        58.2855,
        58.8406,
        59.3957,
        59.9508,
        60.5059,
        61.061,
        61.6161,
        62.1712,
        62.7263,
        63.2814,
        63.8365,
        64.3916,
        64.9467,
        65.5018,
        66.0569,
        66.612
      ],
      average: [
        1.0,
        0.0081,
        0.0822,
        0.2205,
        0.4212,
        0.6825,
        1.0026,
        1.3797,
        1.812,
        2.2977,
        2.835,
        3.4221,
        4.0572,
        4.7385,
        5.4642,
        6.2325,
        7.0416,
        7.8897,
        8.775,
        9.6957,
        10.65,
        11.6361,
        12.6522,
        13.6965,
        14.7672,
        15.8625,
        16.9806,
        18.1197,
        19.278,
        20.4537,
        21.645,
        22.8501,
        24.0672,
        25.2945,
        26.5302,
        27.7725,
        29.0196,
        30.2697,
        31.521,
        32.7717,
        34.02,
        35.2641,
        36.5022,
        37.7325,
        38.9532,
        40.1625,
        41.3586,
        42.5397,
        43.704,
        44.8497,
        45.975,
        47.0781,
        48.1572,
        49.2105,
        50.2362,
        51.2325,
        52.1976,
        53.1297,
        54.027,
        54.8877,
        55.71,
        56.4921,
        57.2322,
        57.9285,
        58.5792,
        59.1825,
        59.7366,
        60.2397,
        60.69,
        61.0857,
        61.6,
        61.9,
        62.2,
        62.8,
        63.6,
        63.5,
        64.1822,
        65.1822,
        66.1822,
        67.1822,
        68.1822,
        69.1822,
        70.1822,
        71.1822,
        72.1822,
        73.1822,
        74.1822,
        75.1822,
        76.1822,
        77.1822,
        78.1822,
        79.1822,
        80.1822,
        81.1822,
        82.1822,
        83.1822,
        84.1822,
        85.1822,
        86.1822,
        87.1822,
        88.1822,
        89.1822,
        90.1822,
        91.1822,
        92.1822,
        93.1822,
        94.1822,
        95.1822,
        96.1822,
        97.1822,
        98.1822,
        99.1822,
        100.1822,
        101.0,
        101.5,
        102.0,
        102.33,
        102.5,
        103.0,
        103.0,
        104.0
      ]
    },
    [CustomerFocus.B2C]: {
      median: [
        1.0,
        1.0,
        2.0,
        3.0,
        3.0,
        5.0,
        5.0,
        9.11880000000006,
        10.0188000000001,
        10.9188000000001,
        11.8188000000001,
        12.7188000000001,
        13.6188000000001,
        14.5188000000001,
        15.4188000000001,
        16.3188000000001,
        17.2188000000001,
        18.1188000000001,
        19.0188000000001,
        19.9188000000001,
        20.8188000000001,
        21.7188000000001,
        22.6188000000001,
        23.5188000000001,
        24.4188,
        25.3188,
        26.2188,
        27.1188,
        28.0188,
        28.9188,
        29.8188,
        30.7188,
        31.6188,
        32.5188,
        33.4188,
        34.3188,
        35.2188,
        36.1188,
        37.0188,
        37.9188,
        38.8188,
        39.7188,
        40.6188,
        41.5188,
        42.4188,
        43.3188,
        44.2188,
        45.1188,
        46.0188,
        46.9188,
        47.8188,
        48.7188,
        49.6188,
        50.5188,
        51.4188,
        52.3188,
        53.2188,
        54.1188,
        55.0188,
        58.2743,
        61.608,
        65.0199,
        68.51,
        72.0783,
        75.7248,
        79.4495,
        83.2524,
        87.1335,
        91.0928,
        95.1303,
        99.246,
        103.4399,
        107.712,
        112.0623,
        116.4908,
        120.9975,
        125.5824,
        130.2455,
        134.9868,
        139.8063,
        144.704,
        149.6799,
        154.734,
        159.8663,
        165.0768,
        170.3655,
        175.7324,
        181.1775,
        186.7008,
        192.3023,
        197.982,
        203.7399,
        209.576,
        215.4903,
        221.4828,
        227.5535,
        233.7024,
        239.9295,
        246.2348,
        252.6183,
        259.08,
        265.6199,
        272.238,
        278.9343,
        285.7088,
        292.5615,
        299.4924,
        306.5015,
        313.5888,
        320.7543,
        327.998,
        335.3199,
        342.72,
        350.1983,
        357.7548,
        365.3895,
        373.1024,
        380.8935,
        388.7628,
        396.7103,
        404.736
      ],
      average: [
        1.0,
        1.0,
        1.0,
        3.32069999999994,
        4.52069999999994,
        5.72069999999994,
        6.92069999999994,
        8.12069999999994,
        9.32069999999994,
        10.5206999999999,
        11.7206999999999,
        12.9206999999999,
        14.1206999999999,
        15.3206999999999,
        16.5206999999999,
        17.7206999999999,
        18.9206999999999,
        20.1206999999999,
        21.3206999999999,
        22.5206999999999,
        23.7206999999999,
        24.9206999999999,
        26.1206999999999,
        27.3206999999999,
        28.5206999999999,
        29.7206999999999,
        30.9206999999999,
        32.1206999999999,
        33.3206999999999,
        34.5206999999999,
        35.7206999999999,
        36.9206999999999,
        38.1206999999999,
        39.3206999999999,
        40.5207,
        41.7207,
        42.9207,
        44.1207,
        45.3207,
        46.5207,
        47.7207,
        48.9207,
        50.1207,
        51.3207,
        52.5207,
        53.7207,
        54.9207,
        56.1207,
        57.3207,
        58.5207,
        61.72,
        64.9995,
        68.3592,
        71.7991,
        75.3192,
        78.9195,
        82.6,
        86.3607,
        90.2016,
        94.1227,
        98.124,
        102.2055,
        106.3672,
        110.6091,
        114.9312,
        119.3335,
        123.816,
        128.3787,
        133.0216,
        137.7447,
        142.548,
        147.4315,
        152.3952,
        157.4391,
        162.5632,
        167.7675,
        173.052,
        178.4167,
        183.8616,
        189.3867,
        194.992,
        200.6775,
        206.4432,
        212.2891,
        218.2152,
        224.2215,
        230.308,
        236.4747,
        242.7216,
        249.0487,
        255.456,
        261.9435,
        268.5112,
        275.1591,
        281.8872,
        288.6955,
        295.584,
        302.5527,
        309.6016,
        316.7307,
        323.94,
        331.2295,
        338.5992,
        346.0491,
        353.5792,
        361.1895,
        368.88,
        376.6507,
        384.5016,
        392.4327,
        400.444,
        408.5355,
        416.7072,
        424.9591,
        433.2912,
        441.7035,
        450.196,
        458.7687,
        467.4216,
        476.1547,
        484.968
      ]
    },
    [Industry.OTHER]: {
      median: [
        1.0,
        1.3249,
        2.6498,
        3.9747,
        5.2996,
        6.6245,
        7.9494,
        9.2743,
        10.5992,
        11.9241,
        13.249,
        14.5739,
        15.8988,
        17.2237,
        18.5486,
        19.8735,
        21.1984,
        22.5233,
        23.8482,
        25.1731,
        26.498,
        27.8229,
        29.1478,
        30.4727,
        31.7976,
        33.1225,
        34.4474,
        35.7723,
        37.0972,
        38.4221,
        39.747,
        41.0719,
        42.3968,
        43.7217,
        45.0466,
        46.3715,
        47.6964,
        49.0213,
        50.3462,
        51.6711,
        52.996,
        54.3209,
        55.6458,
        56.9707,
        58.2956,
        59.6205,
        60.9454,
        62.2703,
        63.5952,
        64.9201,
        66.245,
        67.5699,
        68.8948,
        70.2197,
        71.5446,
        72.8695,
        74.1944,
        75.5193,
        76.8442,
        78.1691,
        79.494,
        80.8189,
        82.1438,
        83.4687,
        84.7936,
        86.1185,
        87.4434,
        88.7683,
        90.0932,
        91.4181,
        92.743,
        94.0679,
        95.3928,
        96.7177,
        98.0426,
        99.3675,
        100.6924,
        102.0173,
        103.3422,
        104.6671,
        105.992,
        107.3169,
        108.6418,
        109.9667,
        111.2916,
        112.6165,
        113.9414,
        115.2663,
        116.5912,
        117.9161,
        119.241,
        120.5659,
        121.8908,
        123.2157,
        124.5406,
        125.8655,
        127.1904,
        128.5153,
        129.8402,
        131.1651,
        132.49,
        133.8149,
        135.1398,
        136.4647,
        137.7896,
        139.1145,
        140.4394,
        141.7643,
        143.0892,
        144.4141,
        145.739,
        147.0639,
        148.3888,
        149.7137,
        151.0386,
        152.3635,
        153.6884,
        155.0133,
        156.3382,
        157.6631,
        158.988
      ],
      average: [
        1.0,
        1.3789,
        2.7578,
        4.1367,
        5.5156,
        6.8945,
        8.2734,
        9.6523,
        11.0312,
        12.4101,
        13.789,
        15.1679,
        16.5468,
        17.9257,
        19.3046,
        20.6835,
        22.0624,
        23.4413,
        24.8202,
        26.1991,
        27.578,
        28.9569,
        30.3358,
        31.7147,
        33.0936,
        34.4725,
        35.8514,
        37.2303,
        38.6092,
        39.9881,
        41.367,
        42.7459,
        44.1248,
        45.5037,
        46.8826,
        48.2615,
        49.6404,
        51.0193,
        52.3982,
        53.7771,
        55.156,
        56.5349,
        57.9138,
        59.2927,
        60.6716,
        62.0505,
        63.4294,
        64.8083,
        66.1872,
        67.5661,
        68.945,
        70.3239,
        71.7028,
        73.0817,
        74.4606,
        75.8395,
        77.2184,
        78.5973,
        79.9762,
        81.3551,
        82.734,
        84.1129,
        85.4918,
        86.8707,
        88.2496,
        89.6285,
        91.0074,
        92.3863,
        93.7652,
        95.1441,
        96.523,
        97.9019,
        99.2808,
        100.6597,
        102.0386,
        103.4175,
        104.7964,
        106.1753,
        107.5542,
        108.9331,
        110.312,
        111.6909,
        113.0698,
        114.4487,
        115.8276,
        117.2065,
        118.5854,
        119.9643,
        121.3432,
        122.7221,
        124.101,
        125.4799,
        126.8588,
        128.2377,
        129.6166,
        130.9955,
        132.3744,
        133.7533,
        135.1322,
        136.5111,
        137.89,
        139.2689,
        140.6478,
        142.0267,
        143.4056,
        144.7845,
        146.1634,
        147.5423,
        148.9212,
        150.3001,
        151.679,
        153.0579,
        154.4368,
        155.8157,
        157.1946,
        158.5735,
        159.9524,
        161.3313,
        162.7102,
        164.0891,
        165.468
      ]
    },
    [Industry.IT]: {
      median: [
        1.0,
        0.61852,
        1.25136,
        1.89804,
        2.55808,
        3.231,
        3.91632,
        4.61356,
        5.32224,
        6.04188,
        6.772,
        7.51212,
        8.26176,
        9.02044,
        9.78768,
        10.563,
        11.34592,
        12.13596,
        12.93264,
        13.73548,
        14.544,
        15.35772,
        16.17616,
        16.99884,
        17.82528,
        18.655,
        19.48752,
        20.32236,
        21.15904,
        21.99708,
        22.836,
        23.67532,
        24.51456,
        25.35324,
        26.19088,
        27.027,
        27.86112,
        28.69276,
        29.52144,
        30.34668,
        31.168,
        31.98492,
        32.79696,
        33.60364,
        34.40448,
        35.199,
        35.98672,
        36.76716,
        37.53984,
        38.30428,
        39.06,
        39.80652,
        40.54336,
        41.27004,
        41.98608,
        42.691,
        43.38432,
        44.06556,
        44.73424,
        45.38988,
        46.032,
        46.66012,
        47.27376,
        47.87244,
        48.45568,
        49.023,
        49.57392,
        50.10796,
        50.62464,
        51.12348,
        51.604,
        52.06572,
        52.50816,
        52.93084,
        53.33328,
        53.715,
        54.07552,
        54.41436,
        54.73104,
        55.02508,
        55.296,
        55.54332,
        55.76656,
        55.96524,
        56.13888,
        56.287,
        56.40912,
        56.50476,
        56.6004,
        56.69604,
        56.79168,
        56.88732,
        56.98296,
        57.0786,
        57.17424,
        57.26988,
        57.36552,
        57.46116,
        57.5568,
        57.65244,
        57.74808,
        57.84372,
        57.93936,
        58.0350000000001,
        58.1306400000001,
        58.2262800000001,
        58.3219200000001,
        58.4175600000001,
        58.5132000000001,
        58.6088400000001,
        58.7044800000001,
        58.8001200000001,
        58.8957600000001,
        58.9914000000001,
        59.0870400000001,
        59.1826800000001,
        59.2783200000001,
        59.3739600000001,
        59.4696000000001,
        59.5652400000001,
        59.6608800000001
      ],
      average: [
        1.0,
        0.7381,
        1.4762,
        2.2143,
        2.9524,
        3.6905,
        4.4286,
        5.1667,
        5.9048,
        6.6429,
        7.381,
        8.1191,
        8.8572,
        9.5953,
        10.3334,
        11.0715,
        11.8096,
        12.5477,
        13.2858,
        14.0239,
        14.762,
        15.5001,
        16.2382,
        16.9763,
        17.7144,
        18.4525,
        19.1906,
        19.9287,
        20.6668,
        21.4049,
        22.143,
        22.8811,
        23.6192,
        24.3573,
        25.0954,
        25.8335,
        26.5716,
        27.3097,
        28.0478,
        28.7859,
        29.524,
        30.2621,
        31.0002,
        31.7383,
        32.4764,
        33.2145,
        33.9526,
        34.6907,
        35.4288,
        36.1669,
        36.905,
        37.6431,
        38.3812,
        39.1193,
        39.8574,
        40.5955,
        41.3336,
        42.0717,
        42.8098,
        43.5479,
        44.286,
        45.0241,
        45.7622,
        46.5003,
        47.2384,
        47.9765,
        48.7146,
        49.4527,
        50.1908,
        50.9289,
        51.667,
        52.4051,
        53.1432,
        53.8813,
        54.6194,
        55.3575,
        56.0956,
        56.8337,
        57.5718,
        58.3099,
        59.048,
        59.7861,
        60.5242,
        61.2623,
        62.0004,
        62.7385,
        63.4766,
        64.2147,
        64.9528,
        65.6909,
        66.429,
        67.1671,
        67.9052,
        68.6433,
        69.3814,
        70.1195,
        70.8576,
        71.5957,
        72.3338,
        73.0719,
        73.81,
        74.5481,
        75.2862,
        76.0243,
        76.7624,
        77.5005,
        78.2386,
        78.9767,
        79.7148,
        80.4529,
        81.191,
        81.9291,
        82.6672,
        83.4053,
        84.1434,
        84.8815,
        85.6196,
        86.3577,
        87.0958,
        87.8339,
        88.572
      ]
    },
    [Industry.FIPT]: {
      median: [
        1.0,
        2.2553,
        4.5106,
        6.7659,
        9.0212,
        11.2765,
        13.5318,
        15.7871,
        18.0424,
        20.2977,
        22.553,
        24.8083,
        27.0636,
        29.3189,
        31.5742,
        33.8295,
        36.0848,
        38.3401,
        40.5954,
        42.8507,
        45.106,
        47.3613,
        49.6166,
        51.8719,
        54.1272,
        56.3825,
        58.6378,
        60.8931,
        63.1484,
        65.4037,
        67.659,
        69.9143,
        72.1696,
        74.4249,
        76.6802,
        78.9355,
        81.1908,
        83.4461,
        85.7014,
        87.9567,
        90.212,
        92.4673,
        94.7226,
        96.9779,
        99.2332,
        101.4885,
        103.7438,
        105.9991,
        108.2544,
        110.5097,
        112.765,
        115.0203,
        117.2756,
        119.5309,
        121.7862,
        124.0415,
        126.2968,
        128.5521,
        130.8074,
        133.0627,
        135.318,
        137.5733,
        139.8286,
        142.0839,
        144.3392,
        146.5945,
        148.8498,
        151.1051,
        153.3604,
        155.6157,
        157.871,
        160.1263,
        162.3816,
        164.6369,
        166.8922,
        169.1475,
        171.4028,
        173.6581,
        175.9134,
        178.1687,
        180.424,
        182.6793,
        184.9346,
        187.1899,
        189.4452,
        191.7005,
        193.9558,
        196.2111,
        198.4664,
        200.7217,
        202.977,
        205.2323,
        207.4876,
        209.7429,
        211.9982,
        214.2535,
        216.5088,
        218.7641,
        221.0194,
        223.2747,
        225.53,
        227.7853,
        230.0406,
        232.2959,
        234.5512,
        236.8065,
        239.0618,
        241.3171,
        243.5724,
        245.8277,
        248.083,
        250.3383,
        252.5936,
        254.8489,
        257.1042,
        259.3595,
        261.6148,
        263.8701,
        266.1254,
        268.3807,
        270.636
      ],
      average: [
        1.0,
        2.3988,
        4.7976,
        7.1964,
        9.5952,
        11.994,
        14.3928,
        16.7916,
        19.1904,
        21.5892,
        23.988,
        26.3868,
        28.7856,
        31.1844,
        33.5832,
        35.982,
        38.3808,
        40.7796,
        43.1784,
        45.5772,
        47.976,
        50.3748,
        52.7736,
        55.1724,
        57.5712,
        59.97,
        62.3688,
        64.7676,
        67.1664,
        69.5652,
        71.964,
        74.3628,
        76.7616,
        79.1604,
        81.5592,
        83.958,
        86.3568,
        88.7556,
        91.1544,
        93.5532,
        95.952,
        98.3508,
        100.7496,
        103.1484,
        105.5472,
        107.946,
        110.3448,
        112.7436,
        115.1424,
        117.5412,
        119.94,
        122.3388,
        124.7376,
        127.1364,
        129.5352,
        131.934,
        134.3328,
        136.7316,
        139.1304,
        141.5292,
        143.928,
        146.3268,
        148.7256,
        151.1244,
        153.5232,
        155.922,
        158.3208,
        160.7196,
        163.1184,
        165.5172,
        167.916,
        170.3148,
        172.7136,
        175.1124,
        177.5112,
        179.91,
        182.3088,
        184.7076,
        187.1064,
        189.5052,
        191.904,
        194.3028,
        196.7016,
        199.1004,
        201.4992,
        203.898,
        206.2968,
        208.6956,
        211.0944,
        213.4932,
        215.892,
        218.2908,
        220.6896,
        223.0884,
        225.4872,
        227.886,
        230.2848,
        232.6836,
        235.0824,
        237.4812,
        239.88,
        242.2788,
        244.6776,
        247.0764,
        249.4752,
        251.874,
        254.2728,
        256.6716,
        259.0704,
        261.4692,
        263.868,
        266.2668,
        268.6656,
        271.0644,
        273.4632,
        275.862,
        278.2608,
        280.6596,
        283.0584,
        285.4572,
        287.856
      ]
    },
    [Industry.DH]: {
      median: [
        1.0,
        0.83,
        1.678,
        3.0,
        3.0,
        3.0,
        3.0,
        3.0,
        3.0,
        5.0,
        5.0,
        5.0,
        5.0,
        5.0,
        5.0,
        6.0,
        7.0,
        8.0,
        8.0,
        8.0,
        8.0,
        8.0,
        8.0,
        9.0,
        10.0,
        10.0,
        10.0,
        11.0,
        12.0,
        13.0,
        14.0,
        15.0,
        17.0,
        19.0,
        21.0,
        23.0,
        25.0,
        27.0,
        30.0,
        33.0,
        36.0,
        40.0,
        50.358,
        51.944,
        53.548,
        55.17,
        56.81,
        58.468,
        60.144,
        61.838,
        63.55,
        65.28,
        67.028,
        68.794,
        70.578,
        72.38,
        74.2,
        76.038,
        77.894,
        79.768,
        81.66,
        83.57,
        85.498,
        87.444,
        89.408,
        91.39,
        93.39,
        95.408,
        97.444,
        99.498,
        101.57,
        103.66,
        105.768,
        107.894,
        110.038,
        112.2,
        114.38,
        116.578,
        118.794,
        121.028,
        123.28,
        125.55,
        127.838,
        130.144,
        132.468,
        134.81,
        137.17,
        139.548,
        141.944,
        144.358,
        146.79,
        149.24,
        151.708,
        154.194,
        156.698,
        159.22,
        161.76,
        164.318,
        166.894,
        169.488,
        172.1,
        174.73,
        177.378,
        180.044,
        182.728,
        185.43,
        188.15,
        190.888,
        193.644,
        196.418,
        199.21,
        202.02,
        204.848,
        207.694,
        210.558,
        213.44,
        216.34,
        219.258,
        222.194,
        225.148,
        228.12
      ],
      average: [
        1.0,
        2.0,
        2.0,
        2.0,
        4.0,
        4.0,
        4.0,
        4.0,
        4.0,
        4.0,
        5.0,
        5.0,
        5.0,
        5.0,
        5.0,
        5.0,
        5.0,
        6.0,
        7.0,
        8.0,
        8.0,
        8.0,
        8.0,
        8.0,
        8.0,
        9.0,
        10.0,
        10.0,
        10.0,
        11.0,
        12.0,
        13.0,
        14.0,
        15.0,
        17.0,
        20.4785,
        22.2444,
        24.0759,
        25.973,
        27.9357,
        29.964,
        32.0579,
        34.2174,
        36.4425,
        38.7332,
        41.0895,
        43.5114,
        45.9989,
        48.552,
        51.1707,
        53.855,
        56.6049,
        59.4204,
        62.3015,
        65.2482,
        68.2605,
        71.3384,
        74.4819,
        77.691,
        80.9657,
        84.306,
        87.7119,
        91.1834,
        94.7205,
        98.3232,
        101.9915,
        105.7254,
        109.5249,
        113.39,
        117.3207,
        121.317,
        125.3789,
        129.5064,
        133.6995,
        137.9582,
        142.2825,
        146.6724,
        151.1279,
        155.649,
        160.2357,
        164.888,
        169.6059,
        174.3894,
        179.2385,
        184.1532,
        189.1335,
        194.1794,
        199.2909,
        204.468,
        209.7107,
        215.019,
        220.3929,
        225.8324,
        231.3375,
        236.9082,
        242.5445,
        248.2464,
        254.0139,
        259.847,
        265.7457,
        271.71,
        277.7399,
        283.8354,
        289.9965,
        296.2232,
        302.5155,
        308.8734,
        315.2969,
        321.786,
        328.3407,
        334.961,
        341.6469,
        348.3984,
        355.2155,
        362.0982,
        369.0465,
        376.0604,
        383.1399,
        390.285,
        397.4957,
        404.772
      ]
    }
  },
  [EvaluationType.FTE_GROWTH]: {
    all: {
      median: [
        null,
        null,
        null,
        0.265066666666667,
        1.0,
        0.5,
        0.333333333333333,
        0.25,
        0.2,
        0.166666666666667,
        0.142857142857143,
        0.125,
        0.111111111111111,
        0.1,
        0.0909090909090909,
        0.0833333333333333,
        0.0769230769230769,
        0.0714285714285714,
        0.0666666666666667,
        0.0625,
        0.0588235294117647,
        0.0555555555555556,
        0.0526315789473684,
        0.05,
        0.0476190476190476,
        0.0454545454545454,
        0.0434782608695652,
        0.0416666666666667,
        0.04,
        0.0384615384615385,
        0.0370370370370371,
        0.0357142857142857,
        0.0344827586206897,
        0.0333333333333334,
        0.0322580645161291,
        0.03125,
        0.0303030303030303,
        0.0294117647058824,
        0.0285714285714285,
        0.0277777777777778,
        0.027027027027027,
        0.0263157894736842,
        0.0256410256410256,
        0.025,
        0.024390243902439,
        0.0238095238095238,
        0.0232558139534884,
        0.0227272727272727,
        0.0222222222222222,
        0.0217391304347826,
        0.0212765957446808,
        0.0208333333333333,
        0.0204081632653061,
        0.02,
        0.0196078431372549,
        0.0192307692307692,
        0.0188679245283019,
        0.0185185185185185,
        0.0181818181818182,
        0.0178571428571429,
        0.0175438596491228,
        0.0172413793103448,
        0.0169491525423728,
        0.0166666666666667,
        0.0163934426229508,
        0.0161290322580645,
        0.0158730158730159,
        0.015625,
        0.0153846153846154,
        0.0151515151515151,
        0.0149253731343284,
        0.0147058823529412,
        0.0144927536231884,
        0.0142857142857143,
        0.0140845070422535,
        0.0138888888888889,
        0.0136986301369863,
        0.0135135135135135,
        0.0133333333333333,
        0.0131578947368421,
        0.012987012987013,
        0.0128205128205129,
        0.0126582278481013,
        0.0125,
        0.0123456790123457,
        0.0121951219512195,
        0.0120481927710843,
        0.0119047619047619,
        0.011764705882353,
        0.0116279069767442,
        0.0114942528735632,
        0.0113636363636364,
        0.0112359550561798,
        0.0111111111111111,
        0.010989010989011,
        0.0108695652173913,
        0.010752688172043,
        0.0106382978723404,
        0.0105263157894737,
        0.0104166666666667,
        0.0103092783505155,
        0.010204081632653,
        0.0101010101010101,
        0.01,
        0.00990099009900991,
        0.00980392156862749,
        0.00970873786407767,
        0.00961538461538466,
        0.00952380952380949,
        0.00943396226415091,
        0.00934579439252333,
        0.0092592592592593,
        0.00917431192660552,
        0.00909090909090911,
        0.00900900900900899,
        0.0089285714285714,
        0.00884955752212383,
        0.00877192982456136,
        0.00869565217391307,
        0.00862068965517245,
        0.00854700854700859
      ],
      average: [
        null,
        null,
        null,
        2.31026666666667,
        0.94526191877575,
        0.465681886004178,
        0.305795127855954,
        0.225832438238453,
        0.177840024898848,
        0.145833333333333,
        0.122961878844703,
        0.105800454619256,
        0.0924462011418534,
        0.0817574340126964,
        0.0730076566375499,
        0.0657125666150937,
        0.0595369053687854,
        0.0542412294004651,
        0.0496499730748519,
        0.0456314878892733,
        0.0420851267336203,
        0.0389326334208224,
        0.0361122513340609,
        0.0335745994981664,
        0.0312797269319009,
        0.0291949703714409,
        0.0272933708584305,
        0.0255524861878453,
        0.023953488372093,
        0.0224804693831243,
        0.0211199011712161,
        0.0198602113876577,
        0.0186914468789383,
        0.0176050044682753,
        0.0165934138261384,
        0.0156501610305958,
        0.0147695441813089,
        0.0139465544626188,
        0.0131767775603392,
        0.0124563114687474,
        0.0117816975808069,
        0.0111498626083281,
        0.0105580693815988,
        0.0100038749677086,
        0.00948509485094847,
        0.0089997721576669,
        0.00854615109695459,
        0.00812265393889677,
        0.00772786097245337,
        0.00736049298185545,
        0.00701939585873849,
        0.00670352703093361,
        0.00641194344091689,
        0.00614379084967317,
        0.00589829427706053,
        0.00567474941902761,
        0.00547251490647723,
        0.00529100529100527,
        0.00512968465999264,
        0.00498806079808967,
        0.0048656798245615,
        0.00476212124656176,
        0.00467699337755841,
        0.00460992907801409,
        0.00456058178232481,
        0.00452862178200168,
        0.00451373274038547,
        0.00451560841881871,
        0.00453394959832713,
        0.0045684611845133,
        0.00461884948658242,
        0.0046848196642999,
        0.00476607333916923,
        0.00486230636833055,
        0.00497320678154815,
        0.00509845288326301,
        0.0052377115229653,
        0.00539063653817758,
        0.00555686737504924,
        0.0057360278920228,
        0.00592772535218574,
        0.00613154960981051,
        0.00634707249619019,
        0.00657384740922081,
        0.00681140911025964,
        0.00705927373063964,
        0.00731693898883212,
        0.00758388461767701,
        0.00785957299935928,
        0.00814345000391488,
        0.0084349460250864,
        0.00873347720528709,
        0.00903844683941009,
        0.00934924694515475,
        0.00966525998563609,
        0.00998586072817228,
        0.010310418221522,
        0.0106382978723405,
        0.0109688636004427,
        0.0113014800514799,
        0.0116355148450071,
        0.011970340835563,
        0.0123053383643884,
        0.0126398974797094,
        0.0129734201041588,
        0.0133053221288516,
        0.0136350354148748,
        0.0139620096844431,
        0.0142857142857143,
        0.0146056398171726,
        0.0149212995995773,
        0.0152322309856555,
        0.0155379964999736,
        0.0158381848037023,
        0.0161324114812488,
        0.0164203196479239,
        0.0167015803799183,
        0.0169758929698346,
        0.0172429850128485,
        0.0175026123301985,
        0.0177545587381656
      ]
    },
    SaaS: {
      median: [
        null,
        null,
        null,
        0.497066666666667,
        1.0,
        0.5,
        0.333333333333333,
        0.25,
        0.2,
        0.166666666666667,
        0.142857142857143,
        0.125,
        0.111111111111111,
        0.1,
        0.090909090909091,
        0.0833333333333333,
        0.0769230769230769,
        0.0714285714285715,
        0.0666666666666667,
        0.0625,
        0.0588235294117646,
        0.0555555555555556,
        0.0526315789473684,
        0.05,
        0.0476190476190476,
        0.0454545454545455,
        0.0434782608695653,
        0.0416666666666667,
        0.04,
        0.0384615384615385,
        0.0370370370370371,
        0.0357142857142857,
        0.0344827586206897,
        0.0333333333333334,
        0.0322580645161291,
        0.03125,
        0.0303030303030303,
        0.0294117647058823,
        0.0285714285714285,
        0.0277777777777778,
        0.027027027027027,
        0.0263157894736842,
        0.0256410256410256,
        0.025,
        0.024390243902439,
        0.0238095238095238,
        0.0232558139534883,
        0.0227272727272728,
        0.0222222222222222,
        0.0217391304347826,
        0.0212765957446808,
        0.0208333333333333,
        0.0204081632653061,
        0.02,
        0.0196078431372549,
        0.0192307692307692,
        0.0188679245283019,
        0.0185185185185185,
        0.0181818181818182,
        0.0178571428571429,
        0.0175438596491228,
        0.0172413793103448,
        0.0169491525423729,
        0.0166666666666667,
        0.0163934426229508,
        0.0161290322580645,
        0.0158730158730159,
        0.015625,
        0.0153846153846154,
        0.0151515151515151,
        0.0149253731343283,
        0.0147058823529412,
        0.0144927536231884,
        0.0142857142857143,
        0.0140845070422535,
        0.0138888888888889,
        0.0136986301369863,
        0.0135135135135135,
        0.0133333333333333,
        0.0131578947368421,
        0.012987012987013,
        0.0128205128205128,
        0.0126582278481013,
        0.0125,
        0.0123456790123457,
        0.0121951219512195,
        0.0120481927710843,
        0.0119047619047619,
        0.0117647058823529,
        0.0116279069767442,
        0.0114942528735632,
        0.0113636363636364,
        0.0112359550561797,
        0.0111111111111111,
        0.010989010989011,
        0.0108695652173914,
        0.0107526881720431,
        0.0106382978723404,
        0.0105263157894736,
        0.0104166666666667,
        0.0103092783505155,
        0.0102040816326531,
        0.0101010101010101,
        0.00999999999999993,
        0.00990099009900991,
        0.00980392156862749,
        0.00970873786407767,
        0.00961538461538458,
        0.00952380952380949,
        0.00943396226415091,
        0.0093457943925234,
        0.0092592592592593,
        0.00917431192660552,
        0.00909090909090904,
        0.00900900900900899,
        0.00892857142857147,
        0.00884955752212391,
        0.00877192982456143,
        0.00869565217391299,
        0.00862068965517238,
        0.00854700854700852
      ],
      average: [
        null,
        null,
        null,
        0.0612366666666666,
        0.21024,
        0.585951431868247,
        0.399075787143811,
        0.305073832417583,
        0.248268156424581,
        0.210094275783581,
        0.182592157377553,
        0.16177897192187,
        0.145439923399298,
        0.132244517997518,
        0.121344822715073,
        0.112174561265237,
        0.104340910067078,
        0.0975626486189867,
        0.0916330354126356,
        0.0863965943546058,
        0.0817340856430302,
        0.0775524796287302,
        0.0737780915106687,
        0.0703517713903743,
        0.0672254652029084,
        0.0643597108371522,
        0.0617217852187574,
        0.0592843128070613,
        0.0570242065626681,
        0.0549218520778126,
        0.0529604719920422,
        0.0511256257559468,
        0.0494048121823453,
        0.0477871508912401,
        0.0462631249081159,
        0.0448243710998441,
        0.0434635083520182,
        0.0421739957601783,
        0.0409500148679628,
        0.0397863713065232,
        0.038678412190102,
        0.0376219563868379,
        0.0366132353721165,
        0.0356488428280774,
        0.0347256915093039,
        0.033840976175041,
        0.0329921416101213,
        0.0321768549334377,
        0.0313929815342535,
        0.0306385640905565,
        0.0299118042158584,
        0.0292110463558414,
        0.0285347636175482,
        0.0278815452641378,
        0.0272500856497253,
        0.026639174403183,
        0.0260476876983417,
        0.0254745804718677,
        0.0249188794700499,
        0.0243796770225134,
        0.0238561254550188,
        0.0233474320654858,
        0.0228528545975317,
        0.0223716971544715,
        0.0219033065041068,
        0.0214470687309531,
        0.0210024061979878,
        0.0205687747846665,
        0.0201456613719914,
        0.0197325815489013,
        0.0193290775172772,
        0.0189347161754855,
        0.0185490873626687,
        0.0181718022479908,
        0.0178024918507977,
        0.0174408056791751,
        0.017086410475739,
        0.0167389890606734,
        0.0163982392630738,
        0.0160638729325755,
        0.0157356150240588,
        0.0154132027489486,
        0.0150963847872585,
        0.0147849205551086,
        0.0144785795229413,
        0.0141771405801192,
        0.013880391441988,
        0.0135881280958459,
        0.013300154282586,
        0.0130162810110645,
        0.0127363261025055,
        0.0124601137624861,
        0.0121874741782555,
        0.0119182431393228,
        0.0116522616794268,
        0.0113893757381417,
        0.0111294358405191,
        0.0108722967932813,
        0.0106178173961983,
        0.0103658601673761,
        0.0101162910812762,
        0.00986897931836595,
        0.00962379702537179,
        0.00938061908517351,
        0.00913932289543292,
        0.00889978815510732,
        0.00866189665803755,
        0.00842553209284791,
        0.00819057984842379,
        0.00795692682427185,
        0.0077244612450856,
        0.00749307247886799,
        0.00726265085797939,
        0.00703308750249147,
        0.00680427414524247,
        0.00657610295799116,
        0.00634846637807877,
        0.0061212569349974,
        0.00589436707627152,
        0.00566768899204247,
        0.00544111443774176
      ]
    },
    Transactional: {
      median: [
        null,
        null,
        null,
        0.401666666666667,
        1.0,
        0.5,
        0.333333333333333,
        0.25,
        0.2,
        0.166666666666667,
        0.142857142857143,
        0.125,
        0.111111111111111,
        0.1,
        0.0909090909090909,
        0.0833333333333333,
        0.0769230769230769,
        0.0714285714285715,
        0.0666666666666667,
        0.0625,
        0.0588235294117647,
        0.0555555555555556,
        0.0526315789473684,
        0.05,
        0.0476190476190476,
        0.0454545454545455,
        0.0434782608695652,
        0.0416666666666667,
        0.04,
        0.0384615384615385,
        0.0370370370370371,
        0.0357142857142857,
        0.0344827586206897,
        0.0333333333333333,
        0.032258064516129,
        0.03125,
        0.0303030303030304,
        0.0294117647058824,
        0.0285714285714286,
        0.0277777777777778,
        0.027027027027027,
        0.0263157894736842,
        0.0256410256410257,
        0.025,
        0.024390243902439,
        0.0238095238095238,
        0.0232558139534884,
        0.0227272727272728,
        0.0222222222222222,
        0.0217391304347826,
        0.0212765957446808,
        0.0208333333333333,
        0.0204081632653061,
        0.02,
        0.0196078431372549,
        0.0192307692307692,
        0.0188679245283019,
        0.0185185185185185,
        0.0181818181818182,
        0.0178571428571429,
        0.0175438596491229,
        0.0172413793103448,
        0.0169491525423728,
        0.0166666666666667,
        0.0163934426229508,
        0.0161290322580645,
        0.0158730158730159,
        0.015625,
        0.0153846153846154,
        0.0151515151515151,
        0.0149253731343283,
        0.0147058823529412,
        0.0144927536231885,
        0.0142857142857143,
        0.0140845070422535,
        0.0138888888888889,
        0.0136986301369863,
        0.0135135135135135,
        0.0133333333333333,
        0.0131578947368421,
        0.012987012987013,
        0.0128205128205128,
        0.0126582278481013,
        0.0125,
        0.0123456790123457,
        0.0121951219512195,
        0.0120481927710843,
        0.0119047619047619,
        0.0117647058823529,
        0.0116279069767441,
        0.0114942528735632,
        0.0113636363636365,
        0.0112359550561799,
        0.0111111111111111,
        0.010989010989011,
        0.0108695652173913,
        0.010752688172043,
        0.0106382978723404,
        0.0105263157894736,
        0.0104166666666667,
        0.0103092783505154,
        0.010204081632653,
        0.0101010101010101,
        0.01,
        0.00990099009900991,
        0.00980392156862742,
        0.00970873786407767,
        0.00961538461538458,
        0.00952380952380949,
        0.00943396226415091,
        0.00934579439252333,
        0.00925925925925923,
        0.00917431192660552,
        0.00909090909090911,
        0.00900900900900899,
        0.0089285714285714,
        0.00884955752212398,
        0.00877192982456143,
        0.00869565217391307,
        0.00862068965517238,
        0.00854700854700852
      ],
      average: [
        null,
        null,
        null,
        1.44536666666667,
        0.994393676066603,
        0.496491129575567,
        0.330522291561253,
        0.247536876477874,
        0.197744827197384,
        0.16454945799458,
        0.140837903544977,
        0.123053731174272,
        0.109221144941506,
        0.0981546672723143,
        0.0890999033376926,
        0.0815539232433663,
        0.07516854474715,
        0.0696950662402924,
        0.0649511065362842,
        0.0607998797388615,
        0.0571367848624798,
        0.0538804655792939,
        0.0509666932039364,
        0.0483440847828591,
        0.0459710445867221,
        0.04381353974122,
        0.0418434561281285,
        0.0400373653110879,
        0.0383755873991993,
        0.0368414701728668,
        0.035420828403897,
        0.0341015033212913,
        0.0328730132220463,
        0.0317262739597943,
        0.0306533735324157,
        0.0296473889344743,
        0.0287022363092094,
        0.027812547544294,
        0.0269735680226055,
        0.0261810714145344,
        0.0254312882877564,
        0.024720845989145,
        0.0240467177756216,
        0.0234061795753765,
        0.0227967730767169,
        0.0222162740899358,
        0.021662665323804,
        0.0211341128743587,
        0.0206289458485192,
        0.0201456386454929,
        0.0196827955001281,
        0.0192391369583484,
        0.0188134880086554,
        0.0184047676378522,
        0.0180119796155033,
        0.0176342043417276,
        0.0172705916178814,
        0.0169203542204996,
        0.0165827621762601,
        0.0162571376503463,
        0.0159428503728751,
        0.0156393135384538,
        0.0153459801227306,
        0.0150623395672901,
        0.0147879147906241,
        0.014522259488364,
        0.0142649556906326,
        0.014015611548395,
        0.0137738593241457,
        0.0135393535652603,
        0.0133117694409279,
        0.0130908012258238,
        0.0128761609156381,
        0.012667576961271,
        0.0124647931099996,
        0.0122675673432117,
        0.0120756709014514,
        0.0118888873885126,
        0.0117070119472071,
        0.0115298505002011,
        0.0113572190500095,
        0.0111889430328345,
        0.0110248567214815,
        0.0108648026730551,
        0.010708631217566,
        0.0105561999839578,
        0.0104073734603963,
        0.0102620225859663,
        0.0101200243711897,
        0.00998126154501966,
        0.00984562222617898,
        0.00971299961690712,
        0.00958329171735432,
        0.00945640105901443,
        0.0093322344557348,
        0.00921070277096381,
        0.00909172070001474,
        0.00897520656622734,
        0.00886108213000377,
        0.00874927240977878,
        0.00863970551406479,
        0.00853231248377891,
        0.00842702714412602,
        0.00832378596536909,
        0.00822252793186995,
        0.00812319441883336,
        0.00802572907623141,
        0.00793007771942378,
        0.00783618822602879,
        0.00774401043863082,
        0.00765349607294485,
        0.00756459863108061,
        0.00747727331958329,
        0.00739147697194276,
        0.00730716797529281,
        0.00722430620103583,
        0.00714285293915129,
        0.00706277083596281,
        0.00698402383514988,
        0.00690657712181212,
        0.00683039706940088
      ]
    },
    [CustomerFocus.B2B]: {
      median: [
        null,
        null,
        null,
        0.221766666666667,
        1.0,
        0.5,
        0.333333333333333,
        0.25,
        0.2,
        0.166666666666667,
        0.142857142857143,
        0.125,
        0.111111111111111,
        0.1,
        0.0909090909090909,
        0.0833333333333333,
        0.0769230769230769,
        0.0714285714285714,
        0.0666666666666667,
        0.0625,
        0.0588235294117647,
        0.0555555555555556,
        0.0526315789473684,
        0.05,
        0.0476190476190476,
        0.0454545454545455,
        0.0434782608695652,
        0.0416666666666666,
        0.04,
        0.0384615384615385,
        0.0370370370370371,
        0.0357142857142857,
        0.0344827586206897,
        0.0333333333333333,
        0.032258064516129,
        0.03125,
        0.0303030303030304,
        0.0294117647058824,
        0.0285714285714286,
        0.0277777777777778,
        0.027027027027027,
        0.0263157894736842,
        0.0256410256410257,
        0.0250000000000001,
        0.024390243902439,
        0.0238095238095238,
        0.0232558139534883,
        0.0227272727272727,
        0.0222222222222222,
        0.0217391304347826,
        0.0212765957446808,
        0.0208333333333333,
        0.0204081632653061,
        0.0199999999999999,
        0.0196078431372549,
        0.0192307692307692,
        0.0188679245283019,
        0.0185185185185185,
        0.0181818181818182,
        0.0178571428571429,
        0.0175438596491229,
        0.0172413793103448,
        0.0169491525423729,
        0.0166666666666666,
        0.0163934426229508,
        0.0161290322580645,
        0.0158730158730159,
        0.015625,
        0.0153846153846153,
        0.0151515151515151,
        0.0149253731343283,
        0.0147058823529412,
        0.0144927536231884,
        0.0142857142857143,
        0.0140845070422535,
        0.0138888888888888,
        0.0136986301369863,
        0.0135135135135135,
        0.0133333333333333,
        0.0131578947368421,
        0.012987012987013,
        0.0128205128205129,
        0.0126582278481013,
        0.0125,
        0.0123456790123457,
        0.0121951219512195,
        0.0120481927710843,
        0.0119047619047619,
        0.011764705882353,
        0.0116279069767441,
        0.0114942528735632,
        0.0113636363636364,
        0.0112359550561798,
        0.0111111111111111,
        0.010989010989011,
        0.0108695652173914,
        0.010752688172043,
        0.0106382978723404,
        0.0105263157894737,
        0.0104166666666667,
        0.0103092783505155,
        0.010204081632653,
        0.0101010101010101,
        0.01,
        0.00990099009900991,
        0.00980392156862749,
        0.00970873786407767,
        0.00961538461538466,
        0.00952380952380949,
        0.00943396226415098,
        0.0093457943925234,
        0.0092592592592593,
        0.00917431192660552,
        0.00909090909090904,
        0.00900900900900899,
        0.0089285714285714,
        0.00884955752212391,
        0.00877192982456143,
        0.00869565217391307,
        0.00862068965517238,
        0.00854700854700859
      ],
      average: [
        null,
        null,
        null,
        -0.259833333333333,
        17.0,
        2.43430656934307,
        1.18231292517007,
        0.758547008547009,
        0.551648351648351,
        0.430580490724117,
        0.351598173515982,
        0.296192052980132,
        0.255255255255255,
        0.223809523809524,
        0.198912948189708,
        0.178719313812482,
        0.162013295346629,
        0.147963105303613,
        0.135980746089049,
        0.125639059304703,
        0.116620403817636,
        0.108683760683761,
        0.101642996379839,
        0.0953521126760564,
        0.0896950009023642,
        0.084578176127472,
        0.0799255284196692,
        0.0756744677393141,
        0.0717730496453901,
        0.068177802904491,
        0.0648520670872034,
        0.0617647058823529,
        0.0588891007494978,
        0.0562023562023563,
        0.0536846665878925,
        0.0513188073394495,
        0.0490897230623258,
        0.0469841916005156,
        0.0449905482041588,
        0.0430984575941777,
        0.0412987244670413,
        0.039583135052822,
        0.0379443239136206,
        0.0363756613756614,
        0.0348711579198107,
        0.0334253825796801,
        0.0320333929636254,
        0.0306906749638027,
        0.0293930905695612,
        0.0281368324846586,
        0.0269183844737975,
        0.025734486545854,
        0.0245821042281219,
        0.0234584013050571,
        0.0223607154919166,
        0.021286536592659,
        0.0202334867558753,
        0.0191993024950135,
        0.0181818181818182,
        0.0171789507563566,
        0.0161886854245366,
        0.0152090621355988,
        0.0142381626484622,
        0.0132740980075391,
        0.0123149962561137,
        0.0113589902187929,
        0.0104042051839767,
        0.00944874631268429,
        0.00849068559117994,
        0.00752804813129641,
        0.00752715125296666,
        0.00664579557313109,
        0.00608052839426139,
        0.00649350649350648,
        0.00915455035002692,
        0.00696677384780275,
        0.00733651804670924,
        0.00829245283018879,
        0.0140797900262468,
        0.0155806438545267,
        0.0153416116669888,
        0.0151098029379501,
        0.0148848951061442,
        0.0146665845337933,
        0.0144545851389519,
        0.0142486271447746,
        0.0140484559342083,
        0.0138538309998864,
        0.0136645249801181,
        0.0134803227728485,
        0.0133010207203301,
        0.0131264258580088,
        0.0129563552217999,
        0.0127906352085257,
        0.0126291009848173,
        0.0124715959402461,
        0.0123179711808746,
        0.0121680850597818,
        0.0120218027414519,
        0.0118789957972113,
        0.0117395418291615,
        0.011603324120294,
        0.0114702313086846,
        0.0113401570838559,
        0.0112129999035682,
        0.0110886627294521,
        0.0109670527800382,
        0.0108480812998605,
        0.0107316633434282,
        0.0106177175729597,
        0.0105061660688658,
        0.0103969341520573,
        0.0102899502172208,
        0.00956656773495257,
        0.0077897042009553,
        0.00604831330648881,
        0.00438943894389439,
        0.00328407224958949,
        0.00326797385620914,
        0.00218248151405587,
        0.00487804878048781
      ]
    },
    [CustomerFocus.B2C]: {
      median: [
        null,
        null,
        null,
        0.666666666666667,
        0.666666666666667,
        0.5,
        0.222222222222222,
        0.679866666666673,
        0.33458666666667,
        0.394586666666671,
        0.0986971969996047,
        0.0898311174991012,
        0.0824266402901414,
        0.0761498629302464,
        0.0707613925842057,
        0.0660851176315092,
        0.0619885940986856,
        0.0583703011907539,
        0.0551511140525035,
        0.0522684507631191,
        0.0496721637194514,
        0.047321597577134,
        0.0451834447858303,
        0.0432301573577726,
        0.0414387535222939,
        0.0397899092790067,
        0.0382672585335985,
        0.0368568480023587,
        0.0355467083748044,
        0.0343265137992584,
        0.0331873091729721,
        0.0321212899910059,
        0.0311216233038714,
        0.0301823010986357,
        0.0292980194538848,
        0.0284640783331435,
        0.027676298018377,
        0.0269309490466444,
        0.0262246931710898,
        0.0255545333742205,
        0.02491777135453,
        0.024311971214626,
        0.0237349283205165,
        0.0231846424928127,
        0.0226592948427444,
        0.0221572276876717,
        0.0216769270788173,
        0.0212170075532547,
        0.0207761987866699,
        0.0203533338760888,
        0.019947339024974,
        0.0195572244387076,
        0.0191820762679352,
        0.018821049461718,
        0.0184733614128427,
        0.0181382862947108,
        0.017815150003563,
        0.01750332563187,
        0.0172022294089313,
        0.0316648753197492,
        0.0461281477046795,
        0.0605920158200471,
        0.0585489658391436,
        0.0566501103752759,
        0.0548801213167045,
        0.0532258064516128,
        0.0516757470695063,
        0.050220007183908,
        0.0488498983631112,
        0.0475577881238258,
        0.0463369427372939,
        0.0451813974320692,
        0.0440858485677014,
        0.0430455635491607,
        0.042056305158841,
        0.0411142676767677,
        0.0402160226945191,
        0.0393584729437861,
        0.0385388127853881,
        0.0377544942603422,
        0.0370031978072178,
        0.0362828069114906,
        0.0355913860820292,
        0.0349271616541353,
        0.0342885050030099,
        0.0336739178202592,
        0.0330820191622624,
        0.0325115340253748,
        0.0319612832410319,
        0.0314301745153428,
        0.0309171944639926,
        0.0304214015151515,
        0.0299419195714247,
        0.0294779323372832,
        0.0290286782314118,
        0.0285934458144062,
        0.0281715696715816,
        0.0277624266985968,
        0.0273654327443877,
        0.0269800395716945,
        0.0266057321004712,
        0.0262420259037309,
        0.0258884649291045,
        0.0255446194225721,
        0.0252100840336135,
        0.024884476083427,
        0.0245674339799731,
        0.0242586157654227,
        0.0239576977832011,
        0.0236643734532162,
        0.0233783521450955,
        0.0230993581403418,
        0.0228271296752685,
        0.0225614180574272,
        0.0223019868489762,
        0.0220486111111112,
        0.0218010767042558,
        0.0215591796392389,
        0.0213227254751436,
        0.021091528759933,
        0.0208654125103212
      ],
      average: [
        null,
        null,
        null,
        0.773566666666647,
        1.17356666666665,
        1.57356666666665,
        0.361369590748945,
        0.265445616829256,
        0.209764539304633,
        0.173392864883611,
        0.147770512394253,
        0.128745695065822,
        0.114060851464257,
        0.102382963474878,
        0.0928742250806849,
        0.0849816227240863,
        0.0783254028862914,
        0.0726361473787433,
        0.067717415226261,
        0.0634226006437396,
        0.0596400721644875,
        0.0562833302846531,
        0.0532843117665083,
        0.0505887263023436,
        0.0481527404928434,
        0.0459405758651186,
        0.0439227399005151,
        0.0420747036363064,
        0.0403758996255136,
        0.0388089532255093,
        0.0373590861967518,
        0.0360136491730367,
        0.0347617516446655,
        0.0335939665236125,
        0.0325020923221934,
        0.0314789602499431,
        0.0305182766329186,
        0.0296144933330373,
        0.0287627005299529,
        0.0279585374889041,
        0.0271981178902421,
        0.0264779670216921,
        0.0257949686913569,
        0.0251463201503751,
        0.0245294936499274,
        0.0239422035207011,
        0.0233823778709177,
        0.0228481341642438,
        0.0223377580709113,
        0.0218496850914137,
        0.0332574849090146,
        0.0446540255091092,
        0.0560399995215368,
        0.0544345430978613,
        0.0529219455534274,
        0.0514941661107796,
        0.0501440825859935,
        0.0488653623511668,
        0.0476523546145122,
        0.0465000000000001,
        0.0454037542539604,
        0.0443595235561232,
        0.0433636094162195,
        0.0424126615303086,
        0.0415036372797941,
        0.0406337668002918,
        0.0398005227417997,
        0.0390015939971043,
        0.038234862800471,
        0.0374983846998772,
        0.0367903709883338,
        0.0361091732470516,
        0.0354532697083808,
        0.0348212531919073,
        0.0342118204047304,
        0.033623762428213,
        0.0330559562395872,
        0.032507357138639,
        0.031976991968051,
        0.0314639530314587,
        0.0309673926263629,
        0.0304865181201512,
        0.0300205875069368,
        0.0295689053909904,
        0.0291308193494537,
        0.0287057166329528,
        0.028293021167832,
        0.0278921908281367,
        0.0275027149492801,
        0.0271241120586345,
        0.0267559278011558,
        0.0263977330406523,
        0.026049122119489,
        0.0257097112614306,
        0.0253791371039938,
        0.0250570553481568,
        0.0247431395145573,
        0.0244370797964576,
        0.0241385820007585,
        0.0238473665692324,
        0.0235631676729376,
        0.0232857323734761,
        0.0230148198453765,
        0.0227502006544422,
        0.0224916560873956,
        0.0222389775285943,
        0.0219919658799864,
        0.0217504310208293,
        0.0215141913040107,
        0.0212830730860984,
        0.021056910288498,
        0.0208355439873333,
        0.0206188220298665,
        0.0204065986754702,
        0.0201987342593239,
        0.0199950948771704,
        0.0197955520896012,
        0.0195999826444664,
        0.0194082682161224,
        0.0192202951603301,
        0.0190359542837163
      ]
    },
    [Industry.OTHER]: {
      median: [
        null,
        null,
        null,
        0.991566666666667,
        1.0,
        0.5,
        0.333333333333333,
        0.25,
        0.2,
        0.166666666666667,
        0.142857142857143,
        0.125,
        0.111111111111111,
        0.1,
        0.0909090909090909,
        0.0833333333333333,
        0.0769230769230769,
        0.0714285714285714,
        0.0666666666666667,
        0.0625,
        0.0588235294117647,
        0.0555555555555556,
        0.0526315789473684,
        0.05,
        0.0476190476190476,
        0.0454545454545455,
        0.0434782608695653,
        0.0416666666666667,
        0.04,
        0.0384615384615385,
        0.0370370370370371,
        0.0357142857142857,
        0.0344827586206897,
        0.0333333333333333,
        0.032258064516129,
        0.03125,
        0.0303030303030303,
        0.0294117647058823,
        0.0285714285714285,
        0.0277777777777778,
        0.027027027027027,
        0.0263157894736842,
        0.0256410256410257,
        0.0250000000000001,
        0.024390243902439,
        0.0238095238095238,
        0.0232558139534883,
        0.0227272727272727,
        0.0222222222222222,
        0.0217391304347826,
        0.0212765957446809,
        0.0208333333333333,
        0.0204081632653061,
        0.02,
        0.0196078431372549,
        0.0192307692307692,
        0.0188679245283019,
        0.0185185185185185,
        0.0181818181818182,
        0.0178571428571429,
        0.0175438596491228,
        0.0172413793103448,
        0.0169491525423729,
        0.0166666666666667,
        0.0163934426229508,
        0.0161290322580645,
        0.0158730158730159,
        0.015625,
        0.0153846153846154,
        0.0151515151515151,
        0.0149253731343283,
        0.0147058823529412,
        0.0144927536231884,
        0.0142857142857143,
        0.0140845070422535,
        0.0138888888888889,
        0.0136986301369863,
        0.0135135135135135,
        0.0133333333333333,
        0.0131578947368421,
        0.012987012987013,
        0.0128205128205129,
        0.0126582278481013,
        0.0125,
        0.0123456790123457,
        0.0121951219512195,
        0.0120481927710843,
        0.0119047619047619,
        0.011764705882353,
        0.0116279069767442,
        0.0114942528735632,
        0.0113636363636364,
        0.0112359550561798,
        0.0111111111111111,
        0.010989010989011,
        0.0108695652173913,
        0.010752688172043,
        0.0106382978723404,
        0.0105263157894737,
        0.0104166666666667,
        0.0103092783505155,
        0.010204081632653,
        0.0101010101010102,
        0.00999999999999993,
        0.00990099009900991,
        0.00980392156862742,
        0.00970873786407767,
        0.00961538461538458,
        0.00952380952380957,
        0.00943396226415091,
        0.0093457943925234,
        0.00925925925925923,
        0.00917431192660552,
        0.00909090909090904,
        0.00900900900900907,
        0.0089285714285714,
        0.00884955752212391,
        0.00877192982456136,
        0.00869565217391307,
        0.00862068965517238,
        0.00854700854700859
      ],
      average: [
        null,
        null,
        null,
        1.04556666666667,
        1.0,
        0.5,
        0.333333333333333,
        0.25,
        0.2,
        0.166666666666667,
        0.142857142857143,
        0.125,
        0.111111111111111,
        0.1,
        0.0909090909090909,
        0.0833333333333333,
        0.0769230769230769,
        0.0714285714285715,
        0.0666666666666667,
        0.0625,
        0.0588235294117646,
        0.0555555555555556,
        0.0526315789473684,
        0.05,
        0.0476190476190476,
        0.0454545454545455,
        0.0434782608695652,
        0.0416666666666667,
        0.04,
        0.0384615384615385,
        0.037037037037037,
        0.0357142857142857,
        0.0344827586206897,
        0.0333333333333334,
        0.0322580645161291,
        0.03125,
        0.0303030303030303,
        0.0294117647058823,
        0.0285714285714286,
        0.0277777777777778,
        0.027027027027027,
        0.0263157894736842,
        0.0256410256410257,
        0.0250000000000001,
        0.024390243902439,
        0.0238095238095238,
        0.0232558139534883,
        0.0227272727272728,
        0.0222222222222222,
        0.0217391304347826,
        0.0212765957446808,
        0.0208333333333333,
        0.0204081632653061,
        0.0199999999999999,
        0.0196078431372549,
        0.0192307692307692,
        0.0188679245283019,
        0.0185185185185185,
        0.0181818181818182,
        0.0178571428571429,
        0.0175438596491228,
        0.0172413793103448,
        0.0169491525423728,
        0.0166666666666667,
        0.0163934426229508,
        0.0161290322580645,
        0.0158730158730159,
        0.015625,
        0.0153846153846154,
        0.0151515151515151,
        0.0149253731343283,
        0.0147058823529411,
        0.0144927536231884,
        0.0142857142857143,
        0.0140845070422535,
        0.0138888888888889,
        0.0136986301369863,
        0.0135135135135135,
        0.0133333333333333,
        0.0131578947368421,
        0.012987012987013,
        0.0128205128205129,
        0.0126582278481013,
        0.0125,
        0.0123456790123457,
        0.0121951219512195,
        0.0120481927710843,
        0.0119047619047619,
        0.0117647058823529,
        0.0116279069767441,
        0.0114942528735632,
        0.0113636363636364,
        0.0112359550561798,
        0.0111111111111111,
        0.010989010989011,
        0.0108695652173913,
        0.0107526881720431,
        0.0106382978723404,
        0.0105263157894737,
        0.0104166666666667,
        0.0103092783505155,
        0.010204081632653,
        0.0101010101010101,
        0.01,
        0.00990099009900991,
        0.00980392156862749,
        0.00970873786407767,
        0.00961538461538466,
        0.00952380952380949,
        0.00943396226415098,
        0.00934579439252333,
        0.00925925925925923,
        0.00917431192660545,
        0.00909090909090904,
        0.00900900900900907,
        0.0089285714285714,
        0.00884955752212391,
        0.00877192982456136,
        0.00869565217391307,
        0.00862068965517238,
        0.00854700854700852
      ]
    },
    [Industry.IT]: {
      median: [
        null,
        null,
        null,
        0.299346666666667,
        1.04526935264826,
        0.527330264672037,
        0.354449853533118,
        0.267841506129597,
        0.215747446610956,
        0.180914736283041,
        0.155948985165469,
        0.137152777777778,
        0.122471813409071,
        0.110673360897815,
        0.100972827910097,
        0.0928470446974979,
        0.0859337238538253,
        0.0799740081408465,
        0.0747779986746189,
        0.0702032096119133,
        0.0661406266994947,
        0.0625054126612973,
        0.0592305474581158,
        0.0562623762376237,
        0.0535574290975484,
        0.0510801080108011,
        0.048800977007843,
        0.0466954796782996,
        0.0447429643527204,
        0.0429259341363087,
        0.0412294635071911,
        0.0396407398445298,
        0.0381486997365105,
        0.0367437379576108,
        0.0354174727099782,
        0.0341625548245614,
        0.0329725116000953,
        0.031841618151051,
        0.0307647907647907,
        0.0297374979900305,
        0.0287556861033933,
        0.0278157163065216,
        0.0269143115490723,
        0.0260485112936345,
        0.0252156328669886,
        0.0244132383001351,
        0.0236391057635423,
        0.022891204866343,
        0.022167675218046,
        0.0214668077557499,
        0.0207870284242786,
        0.0201268838652483,
        0.0194850288270658,
        0.0188602150537633,
        0.0182512814483657,
        0.0176571453377323,
        0.0170767946917425,
        0.0165092811712834,
        0.0159537138975428,
        0.015409253850239,
        0.0148751088151381,
        0.0143505288119347,
        0.0138348019426357,
        0.0133272506082725,
        0.0128272280482776,
        0.0123341151624072,
        0.0118473175798017,
        0.0113662629437869,
        0.0108903983844318,
        0.0104191881537711,
        0.00995211140106286,
        0.00948866006750859,
        0.00902833688160517,
        0.00857065343771798,
        0.00811512834164208,
        0.00766128540782988,
        0.00720865189367862,
        0.00675675675675673,
        0.00630512892115798,
        0.00585329553927546,
        0.00540078023521732,
        0.00494710131581636,
        0.00449176993472792,
        0.0040342881944444,
        0.00357414717017276,
        0.00311082483839783,
        0.00264378389157272,
        0.00217246941869879,
        0.00185596437306423,
        0.00169547051966072,
        0.00169260076496217,
        0.00168974070854625,
        0.00168689030133325,
        0.00168404949457394,
        0.00168121823984683,
        0.00167839648905573,
        0.0016755841944267,
        0.00167278130850542,
        0.00166998778415469,
        0.00166720357455145,
        0.00166442863318461,
        0.0016616629138521,
        0.00165890637065846,
        0.00165615895801219,
        0.00165342063062342,
        0.00165069134350127,
        0.00164797105195147,
        0.00164525971157385,
        0.00164255727825998,
        0.00163986370819069,
        0.001637178957834,
        0.00163450298394211,
        0.00163183574355001,
        0.00162917719397226,
        0.00162652729280148,
        0.00162388599790549,
        0.0016212532674255,
        0.00161862905977358,
        0.00161601333363078,
        0.00161340604794476,
        0.00161080716192763
      ],
      average: [
        null,
        null,
        null,
        0.404766666666667,
        1.0,
        0.5,
        0.333333333333333,
        0.25,
        0.2,
        0.166666666666667,
        0.142857142857143,
        0.125,
        0.111111111111111,
        0.1,
        0.0909090909090909,
        0.0833333333333334,
        0.0769230769230769,
        0.0714285714285714,
        0.0666666666666667,
        0.0625,
        0.0588235294117647,
        0.0555555555555556,
        0.0526315789473684,
        0.05,
        0.0476190476190476,
        0.0454545454545455,
        0.0434782608695653,
        0.0416666666666667,
        0.04,
        0.0384615384615384,
        0.0370370370370371,
        0.0357142857142857,
        0.0344827586206897,
        0.0333333333333333,
        0.032258064516129,
        0.03125,
        0.0303030303030304,
        0.0294117647058824,
        0.0285714285714285,
        0.0277777777777778,
        0.027027027027027,
        0.0263157894736842,
        0.0256410256410256,
        0.025,
        0.024390243902439,
        0.0238095238095238,
        0.0232558139534883,
        0.0227272727272727,
        0.0222222222222221,
        0.0217391304347826,
        0.0212765957446808,
        0.0208333333333333,
        0.0204081632653061,
        0.0199999999999999,
        0.0196078431372549,
        0.0192307692307692,
        0.0188679245283019,
        0.0185185185185185,
        0.0181818181818182,
        0.0178571428571429,
        0.0175438596491228,
        0.0172413793103448,
        0.0169491525423729,
        0.0166666666666666,
        0.0163934426229508,
        0.0161290322580645,
        0.0158730158730159,
        0.015625,
        0.0153846153846153,
        0.0151515151515151,
        0.0149253731343284,
        0.0147058823529412,
        0.0144927536231884,
        0.0142857142857142,
        0.0140845070422535,
        0.0138888888888889,
        0.0136986301369863,
        0.0135135135135135,
        0.0133333333333333,
        0.0131578947368421,
        0.012987012987013,
        0.0128205128205129,
        0.0126582278481013,
        0.0125,
        0.0123456790123457,
        0.0121951219512194,
        0.0120481927710843,
        0.0119047619047619,
        0.011764705882353,
        0.0116279069767442,
        0.0114942528735632,
        0.0113636363636364,
        0.0112359550561797,
        0.0111111111111111,
        0.010989010989011,
        0.0108695652173914,
        0.010752688172043,
        0.0106382978723404,
        0.0105263157894736,
        0.0104166666666667,
        0.0103092783505155,
        0.010204081632653,
        0.0101010101010101,
        0.01,
        0.00990099009900991,
        0.00980392156862749,
        0.00970873786407767,
        0.00961538461538458,
        0.00952380952380949,
        0.00943396226415098,
        0.0093457943925234,
        0.00925925925925923,
        0.00917431192660545,
        0.00909090909090904,
        0.00900900900900907,
        0.00892857142857147,
        0.00884955752212391,
        0.00877192982456136,
        0.00869565217391299,
        0.00862068965517245,
        0.00854700854700859
      ]
    },
    [Industry.FIPT]: {
      median: [
        null,
        null,
        null,
        1.92196666666667,
        1.0,
        0.5,
        0.333333333333333,
        0.25,
        0.2,
        0.166666666666667,
        0.142857142857143,
        0.125,
        0.111111111111111,
        0.0999999999999999,
        0.0909090909090909,
        0.0833333333333333,
        0.0769230769230769,
        0.0714285714285714,
        0.0666666666666666,
        0.0625,
        0.0588235294117647,
        0.0555555555555556,
        0.0526315789473684,
        0.05,
        0.0476190476190476,
        0.0454545454545454,
        0.0434782608695652,
        0.0416666666666667,
        0.04,
        0.0384615384615385,
        0.0370370370370371,
        0.0357142857142857,
        0.0344827586206897,
        0.0333333333333334,
        0.0322580645161291,
        0.03125,
        0.0303030303030303,
        0.0294117647058824,
        0.0285714285714285,
        0.0277777777777778,
        0.027027027027027,
        0.0263157894736842,
        0.0256410256410256,
        0.025,
        0.024390243902439,
        0.0238095238095238,
        0.0232558139534884,
        0.0227272727272727,
        0.0222222222222222,
        0.0217391304347826,
        0.0212765957446808,
        0.0208333333333333,
        0.0204081632653061,
        0.02,
        0.0196078431372549,
        0.0192307692307692,
        0.0188679245283019,
        0.0185185185185185,
        0.0181818181818182,
        0.0178571428571429,
        0.0175438596491229,
        0.0172413793103449,
        0.0169491525423728,
        0.0166666666666666,
        0.0163934426229508,
        0.0161290322580646,
        0.0158730158730159,
        0.015625,
        0.0153846153846153,
        0.0151515151515151,
        0.0149253731343284,
        0.0147058823529412,
        0.0144927536231884,
        0.0142857142857142,
        0.0140845070422535,
        0.0138888888888889,
        0.0136986301369864,
        0.0135135135135135,
        0.0133333333333333,
        0.0131578947368421,
        0.012987012987013,
        0.0128205128205129,
        0.0126582278481013,
        0.0125,
        0.0123456790123457,
        0.0121951219512195,
        0.0120481927710843,
        0.0119047619047619,
        0.011764705882353,
        0.0116279069767441,
        0.0114942528735632,
        0.0113636363636363,
        0.0112359550561798,
        0.0111111111111111,
        0.010989010989011,
        0.0108695652173913,
        0.010752688172043,
        0.0106382978723404,
        0.0105263157894737,
        0.0104166666666667,
        0.0103092783505154,
        0.010204081632653,
        0.0101010101010101,
        0.01,
        0.00990099009900991,
        0.00980392156862742,
        0.00970873786407767,
        0.00961538461538466,
        0.00952380952380957,
        0.00943396226415098,
        0.00934579439252333,
        0.00925925925925923,
        0.00917431192660552,
        0.00909090909090911,
        0.00900900900900899,
        0.00892857142857147,
        0.00884955752212391,
        0.00877192982456143,
        0.00869565217391299,
        0.00862068965517238,
        0.00854700854700852
      ],
      average: [
        null,
        null,
        null,
        2.06546666666667,
        1.0,
        0.5,
        0.333333333333333,
        0.25,
        0.2,
        0.166666666666667,
        0.142857142857143,
        0.125,
        0.111111111111111,
        0.1,
        0.0909090909090909,
        0.0833333333333333,
        0.0769230769230769,
        0.0714285714285715,
        0.0666666666666667,
        0.0625,
        0.0588235294117646,
        0.0555555555555555,
        0.0526315789473684,
        0.05,
        0.0476190476190477,
        0.0454545454545454,
        0.0434782608695652,
        0.0416666666666667,
        0.04,
        0.0384615384615385,
        0.0370370370370371,
        0.0357142857142857,
        0.0344827586206897,
        0.0333333333333333,
        0.032258064516129,
        0.03125,
        0.0303030303030304,
        0.0294117647058823,
        0.0285714285714285,
        0.0277777777777778,
        0.027027027027027,
        0.0263157894736842,
        0.0256410256410256,
        0.025,
        0.024390243902439,
        0.0238095238095238,
        0.0232558139534884,
        0.0227272727272727,
        0.0222222222222223,
        0.0217391304347826,
        0.0212765957446808,
        0.0208333333333333,
        0.0204081632653061,
        0.02,
        0.0196078431372549,
        0.0192307692307692,
        0.0188679245283018,
        0.0185185185185185,
        0.0181818181818182,
        0.0178571428571429,
        0.0175438596491228,
        0.0172413793103448,
        0.0169491525423729,
        0.0166666666666667,
        0.0163934426229508,
        0.0161290322580645,
        0.0158730158730158,
        0.015625,
        0.0153846153846154,
        0.0151515151515152,
        0.0149253731343283,
        0.0147058823529411,
        0.0144927536231884,
        0.0142857142857143,
        0.0140845070422536,
        0.0138888888888888,
        0.0136986301369863,
        0.0135135135135135,
        0.0133333333333333,
        0.0131578947368421,
        0.012987012987013,
        0.0128205128205128,
        0.0126582278481013,
        0.0125,
        0.0123456790123457,
        0.0121951219512194,
        0.0120481927710843,
        0.0119047619047619,
        0.011764705882353,
        0.0116279069767442,
        0.0114942528735632,
        0.0113636363636363,
        0.0112359550561798,
        0.0111111111111111,
        0.010989010989011,
        0.0108695652173913,
        0.010752688172043,
        0.0106382978723404,
        0.0105263157894737,
        0.0104166666666667,
        0.0103092783505154,
        0.0102040816326531,
        0.0101010101010101,
        0.01,
        0.00990099009900991,
        0.00980392156862742,
        0.00970873786407767,
        0.00961538461538466,
        0.00952380952380957,
        0.00943396226415091,
        0.00934579439252333,
        0.00925925925925923,
        0.00917431192660545,
        0.00909090909090904,
        0.00900900900900907,
        0.00892857142857147,
        0.00884955752212391,
        0.00877192982456136,
        0.00869565217391299,
        0.00862068965517238,
        0.00854700854700852
      ]
    },
    [Industry.DH]: {
      median: [
        null,
        null,
        null,
        0.666666666666667,
        0.871485943775101,
        0.262614223281685,
        0.0,
        0.0,
        0.0,
        0.222222222222222,
        0.222222222222222,
        0.222222222222222,
        0.0,
        0.0,
        0.0,
        0.0666666666666667,
        0.133333333333333,
        0.2,
        0.111111111111111,
        0.0476190476190476,
        0.0,
        0.0,
        0.0,
        0.0416666666666667,
        0.0833333333333333,
        0.0833333333333333,
        0.0370370370370371,
        0.0333333333333334,
        0.0666666666666667,
        0.1,
        0.0909090909090909,
        0.0833333333333333,
        0.102564102564103,
        0.119047619047619,
        0.133333333333333,
        0.117647058823529,
        0.105263157894737,
        0.0952380952380953,
        0.101449275362319,
        0.106666666666667,
        0.111111111111111,
        0.111111111111111,
        0.175333333333333,
        0.14762962962963,
        0.1129,
        0.0318519401088209,
        0.0312259356229786,
        0.030626727422126,
        0.0300525647997099,
        0.0295018482661503,
        0.0289731134979818,
        0.028465017291833,
        0.0279763252369093,
        0.0275059008654603,
        0.0270526960784314,
        0.0266157426747031,
        0.0261941448382126,
        0.0257870724588398,
        0.0253937551809892,
        0.0250134770889488,
        0.0246455719508667,
        0.0242894189539631,
        0.0239444388727309,
        0.0236100906196424,
        0.023285868134498,
        0.0229712975742122,
        0.0226659347696812,
        0.022369362920544,
        0.0220811905022431,
        0.0218010493628869,
        0.0215285929901057,
        0.0212634949304216,
        0.0210054473456753,
        0.0207541596928228,
        0.0205093575149527,
        0.0202707813327282,
        0.0200381856266335,
        0.0198113379014522,
        0.019590017825312,
        0.0193740164364399,
        0.0191631354114841,
        0.018957186389885,
        0.0187559903493407,
        0.018559377027904,
        0.0183671843886898,
        0.0181792581235627,
        0.0179954511925252,
        0.017815623395839,
        0.0176396409761887,
        0.0174673762484509,
        0.0172987072548514,
        0.0171335174434989,
        0.0169716953684589,
        0.0168131344097009,
        0.0166577325113909,
        0.0165053919371424,
        0.0163560190409484,
        0.0162095240526363,
        0.0160658208767743,
        0.0159248269040554,
        0.0157864628342604,
        0.0156506525099764,
        0.0155173227603134,
        0.0153864032539221,
        0.0152578263606708,
        0.0151315270213894,
        0.0150074426251361,
        0.014885512893481,
        0.0147656797713423,
        0.0146478873239437,
        0.0145320816394955,
        0.0144182107372291,
        0.0143062244804448,
        0.0141960744942523,
        0.0140877140877141,
        0.0139810981801141,
        0.0138761832310996,
        0.0137729271744602,
        0.0136712893553224,
        0.0135712304705556,
        0.0134727125122003
      ],
      average: [
        null,
        null,
        null,
        0.333333333333333,
        0.333333333333333,
        0.333333333333333,
        0.333333333333333,
        0.0,
        0.0,
        0.0,
        0.0833333333333333,
        0.0833333333333333,
        0.0833333333333333,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0666666666666667,
        0.133333333333333,
        0.2,
        0.111111111111111,
        0.0476190476190476,
        0.0,
        0.0,
        0.0,
        0.0416666666666667,
        0.0833333333333333,
        0.0833333333333333,
        0.0370370370370371,
        0.0333333333333334,
        0.0666666666666667,
        0.1,
        0.0909090909090909,
        0.0833333333333333,
        0.102564102564103,
        0.15425,
        0.160986666666667,
        0.138743137254902,
        0.0894352613716825,
        0.0852843861825898,
        0.0815213553802765,
        0.0780926346590691,
        0.0749542699842854,
        0.07206981711387,
        0.069408788473356,
        0.0669454721866651,
        0.0646580229128078,
        0.062527753968172,
        0.0605385804159214,
        0.0586765767132292,
        0.0569296222300968,
        0.0552871148459384,
        0.0537397377796278,
        0.0522792684059048,
        0.0508984204547662,
        0.0495907129538003,
        0.0483503607457284,
        0.0471721825276408,
        0.0460515232088836,
        0.0449841880389805,
        0.0439663864643624,
        0.0429946840689398,
        0.0420659612650788,
        0.0411773776480914,
        0.0403263411236103,
        0.0395104810744061,
        0.0387276249597501,
        0.0379757778428692,
        0.0372531044253688,
        0.0365579132356085,
        0.0358886426739491,
        0.0352438486639034,
        0.0346221936964236,
        0.034022437086311,
        0.0334434262862412,
        0.0328840891260973,
        0.0323434268639749,
        0.031820507950959,
        0.0313144624251049,
        0.030824476861359,
        0.0303497898137935,
        0.0298896876947491,
        0.0294435010425268,
        0.029010601135316,
        0.0285903969142583,
        0.0281823321830341,
        0.0277858830552588,
        0.0274005556243389,
        0.0270258838333769,
        0.0266614275252679,
        0.0263067706553586,
        0.0259615196509967,
        0.025625301904004,
        0.0252977643836127,
        0.0249785723587284,
        0.0246674082195469,
        0.0243639703895823,
        0.0240679723200801,
        0.0237791415595901,
        0.0234972188921974,
        0.0232219575385442,
        0.0229531224143438,
        0.0226904894415978,
        0.0224338449081742,
        0.0221829848718171,
        0.021937714605014,
        0.0216978480774768,
        0.0214632074732837,
        0.0212336227399918,
        0.0210089311672679,
        0.0207889769927963,
        0.0205736110334197,
        0.020362690339638,
        0.0201560778717521,
        0.0199536421960803,
        0.0197552571998034,
        0.0195608018231187,
        0.0193701598074776,
        0.0191832194587945,
        0.0189998734245882,
        0.0188200184841099
      ]
    }
  },
  [EvaluationType.MRR]: {
    all: {
      median: [
        null,
        23.407,
        102.759937276832,
        253.169072467878,
        472.270016929968,
        765.990609514651,
        1137.18981165351,
        1588.27879337319,
        2121.35173687271,
        2738.26544427931,
        3440.69166297096,
        4230.15344866999,
        5108.05152905323,
        6075.68406616957,
        7134.2618759978,
        8284.92041397568,
        9528.72939253351,
        10866.7006230331,
        12299.7944989208,
        13828.9254205498,
        15454.9663828633,
        17178.752891814,
        19001.0863359655,
        20922.7369110767,
        22944.4461743149,
        25066.929288872,
        27290.8770076969,
        29616.9574357686,
        32045.8176031043,
        34578.0848750116,
        37214.3682215713,
        39955.2593647233,
        42801.3338184002,
        45753.1518347749,
        48811.2592677368,
        51976.1883631016,
        55248.4584837243,
        58628.5767765651,
        62117.0387878271,
        65714.3290314871,
        69420.9215158754,
        73237.2802323868,
        77163.8596099163,
        81201.1049381949,
        85349.4527628374,
        89609.3312545996,
        93981.1605550751,
        98465.353100818,
        103062.313927676,
        107772.440956935,
        112596.125264711,
        117533.7513359,
        122585.697303842,
        127752.335176793,
        133034.031052134,
        138431.145319231,
        143944.032851724,
        149573.043189988,
        155318.520714423,
        161180.804810197,
        167160.230023999,
        173257.12621331,
        179471.818688681,
        185804.628349444,
        192255.871813256,
        198825.861539868,
        205514.905949436,
        212323.309535719,
        219251.372974441,
        226299.3932271,
        233467.66364048,
        240756.474042099,
        248166.110831821,
        255696.857069827,
        263348.992561145,
        271122.793936925,
        279018.534732615,
        287036.485463194,
        295176.913695628,
        303440.084118666,
        311826.258610113,
        320335.696301712,
        328968.653641728,
        337725.384455367,
        346606.140003107,
        355611.169037056,
        364740.717855411,
        373995.030355118,
        383374.348082804,
        392878.910284049,
        402508.953951095,
        412264.713869034,
        422146.422660548,
        432154.310829261,
        442288.606801774,
        452549.53696841,
        462937.32572275,
        473452.195499978,
        484094.36681412,
        494864.058294184,
        505761.486719267,
        516786.867052659,
        527940.412474988,
        539222.334416435,
        550632.842588059,
        562172.145012268,
        573840.448052458,
        585637.956441855,
        597564.873311598,
        609621.400218064,
        621807.737169509,
        634124.082651974,
        646570.633654579,
        659147.585694129,
        671855.132839125,
        684693.46773317,
        697662.78161779,
        710763.264354704,
        723995.104447541,
        737358.489063044,
        750853.60405176
      ],
      average: [
        null,
        1797.31657948656,
        1977.04823743521,
        2174.75306117874,
        2392.22836729661,
        2631.45120402627,
        2894.5963244289,
        3184.05595687179,
        3502.46155255897,
        3852.70770781487,
        4237.97847859635,
        4661.77632645599,
        5127.95395910159,
        5640.74935501175,
        6204.82429051292,
        6825.30671956422,
        7507.83739152064,
        8258.6211306727,
        9084.48324373998,
        9992.93156811397,
        10992.2247249254,
        12091.4471974179,
        13300.5919171597,
        14630.6511088757,
        16093.7162197632,
        17703.0878417396,
        19473.3966259135,
        21420.7362885049,
        23562.8099173554,
        25919.0909090909,
        28511.0,
        31362.1,
        34498.31,
        37948.141,
        41742.9551,
        43830.102855,
        46021.60799775,
        48322.6883976375,
        50738.8228175194,
        53275.7639583954,
        55939.5521563151,
        58736.5297641309,
        61673.3562523375,
        64757.0240649543,
        67994.875268202,
        68865.57,
        75510.5824,
        82485.846,
        89798.2464,
        97454.6692,
        105462.0,
        113827.1244,
        122556.928,
        131658.2964,
        141138.1152,
        151003.27,
        161260.6464,
        171917.13,
        182979.6064,
        194454.9612,
        206350.08,
        218671.8484,
        231427.152,
        244622.8764,
        258265.9072,
        272363.13,
        286921.4304,
        301947.694,
        317448.8064,
        333431.6532,
        349903.12,
        366870.0924,
        384339.456,
        402318.0964,
        420812.8992,
        439830.75,
        459378.5344,
        479463.138,
        500091.4464,
        521270.3452,
        543006.72,
        565307.4564,
        588179.44,
        611629.5564,
        635664.6912,
        660291.73,
        685517.5584,
        711349.062,
        737793.1264,
        764856.6372,
        792546.48,
        820869.5404,
        849832.704,
        879442.8564,
        909706.8832,
        940631.67,
        972224.1024,
        1004491.066,
        1037439.4464,
        1071076.1292,
        1105408.0,
        1140441.9444,
        1176184.848,
        1212643.5964,
        1249825.0752,
        1287736.17,
        1326383.7664,
        1365774.75,
        1405916.0064,
        1446814.4212,
        1488476.88,
        1530910.2684,
        1574121.472,
        1618117.3764,
        1662904.8672,
        1708490.83,
        1754882.1504,
        1802085.714,
        1850108.4064,
        1898957.1132,
        1948638.72
      ]
    },
    SaaS: {
      median: [
        null,
        20.0,
        33.0476084642343,
        87.3116715006485,
        173.955437810448,
        296.923171992856,
        459.589687353468,
        664.938497454864,
        915.663666754397,
        1214.23442336893,
        1562.9391287416,
        1963.91676240607,
        2419.18035803598,
        2930.63498983444,
        3500.09192244471,
        4129.27997291795,
        4819.85479251143,
        5573.40656139326,
        6391.46644908521,
        7275.51209900292,
        8226.97233010274,
        9247.23120238183,
        10337.6315595531,
        11499.478137628,
        12734.0403097557,
        14042.5545237244,
        15426.2264778099,
        16886.2330723233,
        18423.724167654,
        20039.824174399,
        21735.6334969971,
        23512.2298489158,
        25370.6694546901,
        27311.9881518588,
        29337.2024039818,
        31447.3102343726,
        33643.2920888869,
        35926.1116350156,
        38296.7165036154,
        40756.0389788208,
        43304.9966410215,
        45944.4929672094,
        48675.4178925132,
        51498.6483363087,
        54415.0486959246,
        57425.4713106443,
        60530.7568984203,
        63731.7349674732,
        67029.2242047344,
        70424.0328428925,
        73916.9590076465,
        77508.7910466091,
        81200.3078411778,
        84992.2791025698,
        88885.4656531139,
        92880.6196937943,
        96978.4850589619,
        101179.797459047,
        105485.284712045,
        109895.666964478,
        114411.656902482,
        119033.959953634,
        123763.274480043,
        128600.291963256,
        133545.69718142,
        138600.168379162,
        143764.377430584,
        149038.989995761,
        154424.665671087,
        159922.058133804,
        165531.815281025,
        171254.579363522,
        177090.98711457,
        183041.669874073,
        189107.253708228,
        195288.35952493,
        201585.60318514,
        207999.59561039,
        214530.942886632,
        221180.24636458,
        227948.102756711,
        234835.104231094,
        241841.838502158,
        248968.888918573,
        256216.834548327,
        263586.250261167,
        271077.706808471,
        278691.770900701,
        286429.005282507,
        294289.968805588,
        302275.216499418,
        310385.299639896,
        318620.765816024,
        326982.158994677,
        335470.019583551,
        344084.884492354,
        352827.287192303,
        361697.757773998,
        370696.823003726,
        379825.006378271,
        389082.828178259,
        398470.805520106,
        407989.452406621,
        417639.279776314,
        427420.795551429,
        437334.504684791,
        447380.909205462,
        457560.508263282,
        467873.798172316,
        478321.272453237,
        488903.421874706,
        499620.734493749,
        510473.695695199,
        521462.788230198,
        532588.492253816,
        543851.285361801,
        555251.6426265,
        566790.036631959,
        578466.937508235,
        590282.812964961,
        602238.128324156
      ],
      average: [
        null,
        20.0,
        30.9181154721864,
        86.554791082678,
        179.679309866443,
        316.623323218275,
        503.009478095787,
        743.956208879882,
        1044.19864862476,
        1408.16733569528,
        1840.04294358295,
        2343.79620753582,
        2923.21813604184,
        3581.94353978005,
        4323.46978918052,
        5151.17205989644,
        6068.31592686015,
        7078.06791338756,
        8183.50443389117,
        9387.61945449299,
        10693.3311160256,
        12091.4471974179,
        13300.5919171597,
        14630.6511088757,
        16093.7162197632,
        17703.0878417396,
        19473.3966259135,
        21420.7362885049,
        23562.8099173554,
        25919.0909090909,
        28511.0,
        31362.1,
        34498.31,
        37948.141,
        41133.8899037807,
        43830.102855,
        46021.60799775,
        48322.6883976375,
        50738.8228175194,
        53275.7639583954,
        55939.5521563151,
        58736.5297641309,
        61673.3562523375,
        64757.0240649543,
        67994.875268202,
        68865.57,
        75510.5824,
        82485.846,
        89798.2464,
        97454.6692,
        105462.0,
        113827.1244,
        120973.110636488,
        126967.323359108,
        133138.137024852,
        139487.339606853,
        146016.703873609,
        152727.987791309,
        159622.934908553,
        166703.27472451,
        173970.72304152,
        181426.982303044,
        189073.741917785,
        196912.678570795,
        204945.45652227,
        213173.727894701,
        221599.132949039,
        230223.30035042,
        239047.847424025,
        248074.380401563,
        257304.494658876,
        266739.774945072,
        276381.795603651,
        286232.120785972,
        296292.304657455,
        306563.891596845,
        317048.416388876,
        327747.404410623,
        338662.371811838,
        349794.825689548,
        361146.264257142,
        372718.177008223,
        384512.044875417,
        396529.340384383,
        408771.527803202,
        421240.063287359,
        433936.395020475,
        446861.963350986,
        460018.200924918,
        473406.532814907,
        487028.376645636,
        500885.142715791,
        514978.234116712,
        529309.046847826,
        543878.969929005,
        558689.385509962,
        573741.668976783,
        589037.189055709,
        604577.30791426,
        620363.381259807,
        636396.758435664,
        652678.782514803,
        669210.790391265,
        685994.112869355,
        703030.074750672,
        720319.994919086,
        737865.186423694,
        755666.956559843,
        773726.606948266,
        792045.433612411,
        810624.727054016,
        829465.772326958,
        848569.849109485,
        867938.231774823,
        887572.189460243,
        907472.986134638,
        927641.880664625,
        948080.126879253,
        968788.973633322,
        989769.664869393,
        1011023.43967849
      ]
    },
    Transactional: {
      median: [
        null,
        92.461,
        267.644289432505,
        498.398031303856,
        774.742493222336,
        1090.8353184335,
        1442.6989427205,
        1827.3928475453,
        2242.62558366943,
        2686.54457130638,
        3157.61070819065,
        3654.51820449105,
        4176.14057158644,
        4721.49270065053,
        5289.70333649111,
        5879.99454022748,
        6491.66600841827,
        7124.08286225934,
        7776.66597609857,
        8448.88420294247,
        9140.24804293868,
        9850.30442711335,
        10578.6323753708,
        11324.8393485841,
        12088.5581580613,
        12869.4443272507,
        13667.1738238432,
        14481.4410978592,
        15311.9573745029,
        16158.4491606873,
        17020.6569319626,
        17898.3339727099,
        18791.2453472951,
        19699.1669837276,
        20621.8848544542,
        21559.1942414119,
        22510.8990744945,
        23476.8113342454,
        24456.7505109619,
        25450.5431135263,
        26458.0222222252,
        27479.0270806091,
        28513.4027221056,
        29560.999627668,
        30621.6734112091,
        31695.2845299834,
        32781.6980174242,
        33880.7832362409,
        34992.4136498397,
        36116.4666103518,
        37252.8231617459,
        38401.3678566703,
        39561.9885858152,
        40734.5764187122,
        41919.0254550049,
        43115.2326853163,
        44323.097860932,
        45542.5233715921,
        46773.4141307513,
        48015.6774677321,
        49269.2230262441,
        50533.9626687951,
        51809.8103865587,
        53096.682214306,
        54394.496150038,
        55703.172078991,
        57022.6317017125,
        58352.7984659312,
        59693.5975019665,
        61044.9555614449,
        62406.8009591076,
        63779.063517512,
        65161.6745144431,
        66554.5666328675,
        67957.6739132727,
        69370.9317082478,
        70794.2766391718,
        72227.6465548831,
        73670.980492219,
        75124.2186383135,
        76587.3022945554,
        78060.1738421151,
        79542.7767089494,
        81035.055338209,
        82536.9551579672,
        84048.422552204,
        85569.4048329756,
        87099.8502137114,
        88639.7077835769,
        90188.92748285,
        91747.4600792602,
        93315.2571452412,
        94892.2710360525,
        96478.454868729,
        98073.762501816,
        99678.1485158558,
        101291.568194586,
        102913.977506821,
        104545.333088982,
        106185.592228245,
        107834.712846284,
        109492.653483575,
        111159.373284247,
        112834.83198144,
        114518.989883165,
        116211.807858634,
        117913.247325038,
        119623.270234767,
        121341.839063041,
        123068.916795936,
        124804.466918794,
        126548.453405003,
        128300.840705125,
        130061.593736357,
        131830.677872325,
        133608.058933182,
        135393.703176012,
        137187.577285509,
        138989.648364949,
        140799.883927416,
        142618.25188729
      ],
      average: [
        null,
        66.471,
        215.307259458877,
        428.192456394817,
        697.405123673364,
        1018.13590141616,
        1386.96490661089,
        1801.27677352093,
        2258.97588287661,
        2758.32738658102,
        3297.85997939864,
        3876.30239354576,
        4492.53979943177,
        5145.58267627815,
        5834.5438707665,
        6558.62124191207,
        7317.0842401327,
        8109.2633323608,
        8934.54153382868,
        9792.34753055068,
        10682.1500239718,
        11603.4530291534,
        12555.7919271295,
        13538.7301210784,
        14551.8561812759,
        15594.7813896661,
        16667.1376141253,
        17768.5754569846,
        18898.762633439,
        20057.382543995,
        21244.1330117625,
        22458.7251606246,
        23700.8824144772,
        24970.3396010511,
        26266.8421465145,
        27590.1453492309,
        28940.013722829,
        30316.2204002092,
        31718.5465913221,
        33146.7810885673,
        34600.7198145048,
        36080.1654072815,
        37584.9268397772,
        39114.8190689821,
        40669.6627125534,
        42249.2837498691,
        43853.5132452157,
        45482.1870910216,
        47135.1457692862,
        48812.2341295598,
        50513.3011820089,
        52238.1999042589,
        53986.7870608429,
        55758.9230342052,
        57554.471666315,
        59373.3001100379,
        61215.2786894974,
        63080.2807687295,
        64968.1826280015,
        66878.8633472218,
        68812.2046959191,
        70768.0910293196,
        72746.409190086,
        74747.0484153252,
        76769.9002485019,
        78814.8584559256,
        80881.8189475076,
        82970.6797015069,
        85081.3406930085,
        87213.7038258947,
        89367.6728680927,
        91543.1533898937,
        93740.0527051587,
        95958.2798152347,
        98197.7453554235,
        100458.361543851,
        102740.042132603,
        105042.702360993,
        107366.258910845,
        109710.629863681,
        112075.734659709,
        114461.494058505,
        116867.830101319,
        119294.666074892,
        121741.926476731,
        124209.536981754,
        126697.424410229,
        129205.516696962,
        131733.74286165,
        134282.032980361,
        136850.318158066,
        139438.530502196,
        142046.603097155,
        144674.469979749,
        147322.066115506,
        149989.327375811,
        152676.190515859,
        155382.593153358,
        158108.473747959,
        160853.771581394,
        163618.426738265,
        166402.38008748,
        169205.573264295,
        172027.948652939,
        174869.449369801,
        177730.019247148,
        180609.602817366,
        183508.145297684,
        186425.592575377,
        189361.891193422,
        192316.988336594,
        195290.831817969,
        198283.370065849,
        201294.552111056,
        204324.327574606,
        207372.646655748,
        210439.460120336,
        213524.719289538,
        216628.376028871,
        219750.382737532,
        222890.692338037
      ]
    },
    [CustomerFocus.B2B]: {
      median: [
        null,
        6.9685,
        38.2118484577136,
        103.399799550213,
        209.535102612509,
        362.393635668828,
        566.993968712163,
        827.826838711461,
        1148.98810182976,
        1534.26398034358,
        1987.18959434845,
        2511.09119782922,
        3109.11783150847,
        3784.26576309374,
        4539.39782020097,
        5377.25899209727,
        6300.48923395778,
        7311.63412717294,
        8413.15386539678,
        9607.43091165865,
        10896.7765854902,
        12283.4367776497,
        13769.5969455333,
        15357.3865095233,
        17048.8827458961,
        18846.1142531781,
        20751.0640543951,
        22765.6723864015,
        24891.8392185973,
        27131.4265362741,
        29486.260418153,
        31958.1329330793,
        34548.8038770827,
        37260.0023689239,
        40093.4283196928,
        43050.7537898944,
        46133.6242456726,
        49343.6597243207,
        52682.4559179528,
        56151.5851831279,
        59752.5974832909,
        63487.0212701003,
        67356.3643090271,
        71362.1144540153,
        75505.7403754775,
        79788.6922454529,
        84212.4023833593,
        88778.2858654268,
        93487.7411005971,
        98342.1503754052,
        103342.880370122,
        108491.282648228,
        113788.694121095,
        119236.437489599,
        124835.821664222,
        130588.142165075,
        136494.681503166,
        142556.709544095,
        148775.483855305,
        155152.250037894,
        161688.242043932,
        168384.682480149,
        175242.782898801,
        182263.744076443,
        189448.756281316,
        196798.999529973,
        204315.643833733,
        211999.849435541,
        219852.767037714,
        227875.538021074,
        236069.294655921,
        244435.160305237,
        252974.249620542,
        261687.668730748,
        270576.515424371,
        279641.879325397,
        288884.84206314,
        298306.477436341,
        307907.8515718,
        317690.023077775,
        327654.043192404,
        337800.955927349,
        348131.79820689,
        358647.600002666,
        369349.384464245,
        380238.168045703,
        391314.960628377,
        402580.765639962,
        414036.580170082,
        425683.395082507,
        437522.195124116,
        449553.959030768,
        461779.659630183,
        474200.263941948,
        486816.733274774,
        499630.023321098,
        512641.084249118,
        525850.860792391,
        539260.292337041,
        552870.313006702,
        566681.851745247,
        580695.832397407,
        594913.173787339,
        609334.789795221,
        623961.589431933,
        638794.476911912,
        653834.351724218,
        669082.108701883,
        684538.638089604,
        700204.825609821,
        716081.55252726,
        732169.695711944,
        748470.127700776,
        764983.716757707,
        781711.326932519,
        798653.818118328,
        815812.046107763,
        833186.862647938,
        850779.115494197,
        868589.648462712,
        886619.301481932
      ],
      average: [
        null,
        205.853006448563,
        247.023607738275,
        296.42832928593,
        355.713995143116,
        426.85679417174,
        512.228153006088,
        614.673783607305,
        737.608540328766,
        885.130248394519,
        1062.15629807342,
        1274.58755768811,
        1529.50506922573,
        1835.40608307087,
        2202.48729968505,
        2642.98475962206,
        3171.58171154647,
        3805.89805385576,
        4567.07766462692,
        5480.4931975523,
        6576.59183706276,
        7891.91020447531,
        9470.29224537037,
        11364.3506944444,
        13637.2208333333,
        16364.665,
        19977.665,
        20710.665,
        21290.665,
        21490.665,
        23153.165,
        28428.165,
        32920.665,
        33845.165,
        34272.665,
        34272.665,
        34272.665,
        37357.44,
        39749.2183333333,
        45626.2483333333,
        48850.565,
        52183.1066666667,
        55330.4266666667,
        64199.7816666667,
        71623.2833333333,
        74890.255,
        78565.5883333333,
        84464.5883333333,
        92485.2,
        93000.0,
        97627.5,
        106042.7088,
        114828.4384,
        123991.4436,
        133538.4792,
        143476.3,
        153811.6608,
        164551.3164,
        175702.0216,
        187270.5312,
        199263.6,
        211687.9828,
        224550.4344,
        237857.7096,
        251616.5632,
        265833.75,
        280516.0248,
        295670.1424,
        311302.8576,
        327420.9252,
        344031.1,
        361140.1368,
        378754.7904,
        396881.8156,
        415527.9672,
        434700.0,
        454404.6688,
        474648.7284,
        495438.9336,
        516782.0392,
        538684.8,
        561153.9708,
        584196.3064,
        607818.5616,
        632027.4912,
        656829.85,
        682232.3928,
        708241.8744,
        734865.0496,
        762108.6732,
        789979.5,
        818484.2848,
        847629.7824,
        877422.7476,
        907869.9352,
        938978.1,
        970753.9968,
        1003204.3804,
        1036336.0056,
        1070155.6272,
        1093660.0,
        1110616.604,
        1127692.416,
        1144887.436,
        1162201.664,
        1179635.1,
        1197187.744,
        1214859.596,
        1232650.656,
        1250560.924,
        1268590.4,
        1286739.084,
        1305006.976,
        1323394.076,
        1341900.384,
        1360525.9,
        1379270.624,
        1398134.556,
        1417117.696,
        1436220.044,
        1455441.6
      ]
    },
    [CustomerFocus.B2C]: {
      median: [
        null,
        23.407,
        102.759937276832,
        253.169072467878,
        472.270016929968,
        765.990609514651,
        1137.18981165351,
        1588.27879337319,
        2121.35173687271,
        2738.26544427931,
        3440.69166297096,
        4230.15344866999,
        5108.05152905323,
        6075.68406616957,
        7134.2618759978,
        8284.92041397568,
        9528.72939253351,
        10866.7006230331,
        12299.7944989208,
        13828.9254205498,
        15454.9663828633,
        17178.752891814,
        19001.0863359655,
        20922.7369110767,
        22944.4461743149,
        25066.929288872,
        27290.8770076969,
        29616.9574357686,
        32045.8176031043,
        34578.0848750116,
        37214.3682215713,
        39955.2593647233,
        42801.3338184002,
        45753.1518347749,
        48811.2592677368,
        51976.1883631016,
        55248.4584837243,
        58628.5767765651,
        62117.0387878271,
        65714.3290314871,
        69420.9215158754,
        73237.2802323868,
        77163.8596099163,
        81201.1049381949,
        85349.4527628374,
        89609.3312545996,
        93981.1605550751,
        98465.353100818,
        103062.313927676,
        107772.440956935,
        112596.125264711,
        117533.7513359,
        122585.697303842,
        127752.335176793,
        133034.031052134,
        138431.145319231,
        143944.032851724,
        149573.043189988,
        155318.520714423,
        161180.804810197,
        167160.230023999,
        173257.12621331,
        179471.818688681,
        185804.628349444,
        192255.871813256,
        198825.861539868,
        205514.905949436,
        212323.309535719,
        219251.372974441,
        226299.3932271,
        233467.66364048,
        240756.474042099,
        248166.110831821,
        255696.857069827,
        263348.992561145,
        271122.793936925,
        279018.534732615,
        287036.485463194,
        295176.913695628,
        303440.084118666,
        311826.258610113,
        320335.696301712,
        328968.653641728,
        337725.384455367,
        346606.140003107,
        355611.169037056,
        364740.717855411,
        373995.030355118,
        383374.348082804,
        392878.910284049,
        402508.953951095,
        412264.713869034,
        422146.422660548,
        432154.310829261,
        442288.606801774,
        452549.53696841,
        462937.32572275,
        473452.195499978,
        484094.36681412,
        494864.058294184,
        505761.486719267,
        516786.867052659,
        527940.412474988,
        539222.334416435,
        550632.842588059,
        562172.145012268,
        573840.448052458,
        585637.956441855,
        597564.873311598,
        609621.400218064,
        621807.737169509,
        634124.082651974,
        646570.633654579,
        659147.585694129,
        671855.132839125,
        684693.46773317,
        697662.78161779,
        710763.264354704,
        723995.104447541,
        737358.489063044,
        750853.60405176
      ],
      average: [
        null,
        1797.31657948656,
        1977.04823743521,
        2174.75306117874,
        2392.22836729661,
        2631.45120402627,
        2894.5963244289,
        3184.05595687179,
        3502.46155255897,
        3852.70770781487,
        4237.97847859635,
        4661.77632645599,
        5127.95395910159,
        5640.74935501175,
        6204.82429051292,
        6825.30671956422,
        7507.83739152064,
        8258.6211306727,
        9084.48324373998,
        9992.93156811397,
        10992.2247249254,
        12091.4471974179,
        13300.5919171597,
        14630.6511088757,
        16093.7162197632,
        17703.0878417396,
        19473.3966259135,
        21420.7362885049,
        23562.8099173554,
        25919.0909090909,
        28511.0,
        31362.1,
        34498.31,
        37948.141,
        41742.9551,
        43830.102855,
        46021.60799775,
        48322.6883976375,
        50738.8228175194,
        53275.7639583954,
        55939.5521563151,
        58736.5297641309,
        61673.3562523375,
        64757.0240649543,
        67994.875268202,
        68865.57,
        75510.5824,
        82485.846,
        89798.2464,
        97454.6692,
        105462.0,
        113827.1244,
        122556.928,
        131658.2964,
        141138.1152,
        151003.27,
        161260.6464,
        171917.13,
        182979.6064,
        194454.9612,
        206350.08,
        218671.8484,
        231427.152,
        244622.8764,
        258265.9072,
        272363.13,
        286921.4304,
        301947.694,
        317448.8064,
        333431.6532,
        349903.12,
        366870.0924,
        384339.456,
        402318.0964,
        420812.8992,
        439830.75,
        459378.5344,
        479463.138,
        500091.4464,
        521270.3452,
        543006.72,
        565307.4564,
        588179.44,
        611629.5564,
        635664.6912,
        660291.73,
        685517.5584,
        711349.062,
        737793.1264,
        764856.6372,
        792546.48,
        820869.5404,
        849832.704,
        879442.8564,
        909706.8832,
        940631.67,
        972224.1024,
        1004491.066,
        1037439.4464,
        1071076.1292,
        1105408.0,
        1140441.9444,
        1176184.848,
        1212643.5964,
        1249825.0752,
        1287736.17,
        1326383.7664,
        1365774.75,
        1405916.0064,
        1446814.4212,
        1488476.88,
        1530910.2684,
        1574121.472,
        1618117.3764,
        1662904.8672,
        1708490.83,
        1754882.1504,
        1802085.714,
        1850108.4064,
        1898957.1132,
        1948638.72
      ]
    },
    [Industry.OTHER]: { median: null, average: null },
    [Industry.IT]: {
      median: [
        null,
        6.3239,
        36.0446829588344,
        99.7674224889955,
        205.445875109171,
        359.77317404578,
        568.64990168028,
        837.416786792008,
        1170.99122906914,
        1573.95572195919,
        2050.61907929817,
        2605.06093876774,
        3241.1653284584,
        3962.64673373558,
        4773.07082406802,
        5675.87125906992,
        6674.36354138634,
        7771.75659673186,
        8971.16257203027,
        10275.6052139882,
        11688.0271007826,
        13211.2959356181,
        14848.2100644107,
        16601.5033454336,
        18473.8494728647,
        20467.8658364261,
        22586.1169840383,
        24831.1177424808,
        27205.336041619,
        29711.1954802318,
        32351.0776654178,
        35127.3243526376,
        38042.2394094268,
        41098.0906224988,
        44297.1113652066,
        47641.5021400368,
        51133.4320088857,
        54775.0399222362,
        58568.4359569806,
        62515.7024714552,
        66618.8951852457,
        70880.044190459,
        75301.1549004083,
        79884.2089410085,
        84631.1649896198,
        89543.9595655805,
        94624.5077762426,
        99874.7040219435,
        105296.422663015,
        110891.518651631,
        116661.828131049,
        122609.169004536,
        128735.341476115,
        135042.128565021,
        141531.296595646,
        148204.595664566,
        155063.760086135,
        162110.50881799,
        169346.545867725,
        176773.560681873,
        184393.228518258,
        192207.210802701,
        200217.155470985,
        208424.697296915,
        216831.458207255,
        225439.047584274,
        234249.062556557,
        243263.088278726,
        252482.698200644,
        261909.454326656,
        271544.907465371,
        281390.597470459,
        291448.05347292,
        301718.794105234,
        312204.327717785,
        322906.152587928,
        333825.757122055,
        344964.620050958,
        356324.210618831,
        367905.988766169,
        379711.405306851,
        391741.902099665,
        403778.475000312,
        415878.866942133,
        428190.433201483,
        440714.279349084,
        453451.503556904,
        466403.19673387,
        479570.442657525,
        492954.31810183,
        506555.892961253,
        520376.23037129,
        534416.386825567,
        548677.412289645,
        563160.350311672,
        577866.238129989,
        592796.106777807,
        607950.98118507,
        623331.880277603,
        638939.817073648,
        654775.798777885,
        670840.826873012,
        687135.897208999,
        703662.000090077,
        720420.12035953,
        737411.237482415,
        754636.325626216,
        772096.353739549,
        789792.28562897,
        807725.080033936,
        825895.690700009,
        844305.066450312,
        862954.151255364,
        881843.88430127,
        900975.200056371,
        920349.028336388,
        939966.294368089,
        959827.918851562,
        979934.818021088,
        1000287.90370471,
        1020888.08338249
      ],
      average: [
        205.853006448563,
        247.023607738275,
        296.42832928593,
        355.713995143116,
        426.85679417174,
        512.228153006088,
        614.673783607305,
        737.608540328766,
        885.130248394519,
        1062.15629807342,
        1274.58755768811,
        1529.50506922573,
        1835.40608307087,
        2202.48729968505,
        2642.98475962206,
        3171.58171154647,
        3805.89805385576,
        4567.07766462692,
        5480.4931975523,
        6576.59183706276,
        7891.91020447531,
        9470.29224537037,
        11364.3506944444,
        13637.2208333333,
        16364.665,
        19977.665,
        20710.665,
        21290.665,
        21490.665,
        23153.165,
        28428.165,
        32920.665,
        33845.165,
        34272.665,
        34272.665,
        34272.665,
        39569.532,
        42410.332,
        48760.732,
        52321.932,
        56154.132,
        59914.332,
        66836.932,
        74820.532,
        77283.332,
        81568.732,
        87417.532,
        96664.266,
        103714.8288,
        113224.2461,
        123092.5,
        133323.1239,
        143919.6512,
        154885.6153,
        166224.5496,
        177939.9875,
        190035.4624,
        202514.5077,
        215380.6568,
        228637.4431,
        242288.4,
        256337.0609,
        270786.9592,
        285641.6283,
        300904.6016,
        316579.4125,
        332669.5944,
        349178.6807,
        366110.2048,
        383467.7001,
        401254.7,
        419474.7379,
        438131.3472,
        457228.0613,
        476768.4136,
        496755.9375,
        517194.1664,
        538086.6337,
        559436.8728,
        581248.4171,
        603524.8,
        626269.5549,
        649486.2152,
        673178.3143,
        697349.3856,
        722002.9625,
        747142.5784,
        772771.7667,
        798894.0608,
        825512.9941,
        852632.1,
        880254.9119,
        901839.347199999,
        909811.736699998,
        918403.0112,
        927676.1875,
        937696.051200001,
        948529.1567,
        960243.827199999,
        972910.154699998,
        986600.0,
        1001386.9927,
        1017346.5312,
        1034555.7827,
        1053093.6832,
        1073040.9375,
        1094480.0192,
        1117495.1707,
        1142172.4032,
        1168599.4967,
        1196866.0,
        1227063.2307,
        1259284.2752,
        1293623.9887,
        1330178.9952,
        1369047.6875,
        1410330.2272,
        1454128.5447,
        1500546.3392,
        1549689.0787,
        1601664.0
      ]
    },
    [Industry.FIPT]: {
      median: [
        null,
        23.407,
        102.759937276832,
        253.169072467878,
        472.270016929968,
        765.990609514651,
        1137.18981165351,
        1588.27879337319,
        2121.35173687271,
        2738.26544427931,
        3440.69166297096,
        4230.15344866999,
        5108.05152905323,
        6075.68406616957,
        7134.2618759978,
        8284.92041397568,
        9528.72939253351,
        10866.7006230331,
        12299.7944989208,
        13828.9254205498,
        15454.9663828633,
        17178.752891814,
        19001.0863359655,
        20922.7369110767,
        22944.4461743149,
        25066.929288872,
        27290.8770076969,
        29616.9574357686,
        32045.8176031043,
        34578.0848750116,
        37214.3682215713,
        39955.2593647233,
        42801.3338184002,
        45753.1518347749,
        48811.2592677368,
        51976.1883631016,
        55248.4584837243,
        58628.5767765651,
        62117.0387878271,
        65714.3290314871,
        69420.9215158754,
        73237.2802323868,
        77163.8596099163,
        81201.1049381949,
        85349.4527628374,
        89609.3312545996,
        93981.1605550751,
        98465.353100818,
        103062.313927676,
        107772.440956935,
        112596.125264711,
        117533.7513359,
        122585.697303842,
        127752.335176793,
        133034.031052134,
        138431.145319231,
        143944.032851724,
        149573.043189988,
        155318.520714423,
        161180.804810197,
        167160.230023999,
        173257.12621331,
        179471.818688681,
        185804.628349444,
        192255.871813256,
        198825.861539868,
        205514.905949436,
        212323.309535719,
        219251.372974441,
        226299.3932271,
        233467.66364048,
        240756.474042099,
        248166.110831821,
        255696.857069827,
        263348.992561145,
        271122.793936925,
        279018.534732615,
        287036.485463194,
        295176.913695628,
        303440.084118666,
        311826.258610113,
        320335.696301712,
        328968.653641728,
        337725.384455367,
        346606.140003107,
        355611.169037056,
        364740.717855411,
        373995.030355118,
        383374.348082804,
        392878.910284049,
        402508.953951095,
        412264.713869034,
        422146.422660548,
        432154.310829261,
        442288.606801774,
        452549.53696841,
        462937.32572275,
        473452.195499978,
        484094.36681412,
        494864.058294184,
        505761.486719267,
        516786.867052659,
        527940.412474988,
        539222.334416435,
        550632.842588059,
        562172.145012268,
        573840.448052458,
        585637.956441855,
        597564.873311598,
        609621.400218064,
        621807.737169509,
        634124.082651974,
        646570.633654579,
        659147.585694129,
        671855.132839125,
        684693.46773317,
        697662.78161779,
        710763.264354704,
        723995.104447541,
        737358.489063044,
        750853.60405176
      ],
      average: [
        null,
        1797.31657948656,
        1977.04823743521,
        2174.75306117874,
        2392.22836729661,
        2631.45120402627,
        2894.5963244289,
        3184.05595687179,
        3502.46155255897,
        3852.70770781487,
        4237.97847859635,
        4661.77632645599,
        5127.95395910159,
        5640.74935501175,
        6204.82429051292,
        6825.30671956422,
        7507.83739152064,
        8258.6211306727,
        9084.48324373998,
        9992.93156811397,
        10992.2247249254,
        12091.4471974179,
        13300.5919171597,
        14630.6511088757,
        16093.7162197632,
        17703.0878417396,
        19473.3966259135,
        21420.7362885049,
        23562.8099173554,
        25919.0909090909,
        28511.0,
        31362.1,
        34498.31,
        37948.141,
        41742.9551,
        43830.102855,
        46021.60799775,
        48322.6883976375,
        50738.8228175194,
        53275.7639583954,
        55939.5521563151,
        58736.5297641309,
        61673.3562523375,
        64757.0240649543,
        67994.875268202,
        68865.57,
        75510.5824,
        82485.846,
        89798.2464,
        97454.6692,
        105462.0,
        113827.1244,
        122556.928,
        131658.2964,
        141138.1152,
        151003.27,
        161260.6464,
        171917.13,
        182979.6064,
        194454.9612,
        206350.08,
        218671.8484,
        231427.152,
        244622.8764,
        258265.9072,
        272363.13,
        286921.4304,
        301947.694,
        317448.8064,
        333431.6532,
        349903.12,
        366870.0924,
        384339.456,
        402318.0964,
        420812.8992,
        439830.75,
        459378.5344,
        479463.138,
        500091.4464,
        521270.3452,
        543006.72,
        565307.4564,
        588179.44,
        611629.5564,
        635664.6912,
        660291.73,
        685517.5584,
        711349.062,
        737793.1264,
        764856.6372,
        792546.48,
        820869.5404,
        849832.704,
        879442.8564,
        909706.8832,
        940631.67,
        972224.1024,
        1004491.066,
        1037439.4464,
        1071076.1292,
        1105408.0,
        1140441.9444,
        1176184.848,
        1212643.5964,
        1249825.0752,
        1287736.17,
        1326383.7664,
        1365774.75,
        1405916.0064,
        1446814.4212,
        1488476.88,
        1530910.2684,
        1574121.472,
        1618117.3764,
        1662904.8672,
        1708490.83,
        1754882.1504,
        1802085.714,
        1850108.4064,
        1898957.1132,
        1948638.72
      ]
    },
    [Industry.DH]: {
      median: [
        null,
        50.251,
        172.467462617531,
        354.806145037607,
        591.929029506465,
        880.404479132573,
        1217.73727001938,
        1601.98568746703,
        2031.57146661037,
        2505.17204745074,
        3021.6538296373,
        3580.02809480292,
        4179.42045123561,
        4819.04882856331,
        5498.20713353045,
        6216.25279725518,
        6972.59708209718,
        7766.69739759,
        8598.05111230012,
        9466.19050145152,
        10370.6785716919,
        11311.1055735328,
        12287.0860601806,
        13298.2563857285,
        14344.2725604748,
        15424.8083993691,
        16539.5539132017,
        17688.213902449,
        18870.5067215698,
        20086.1631876493,
        21334.925612062,
        22616.5469375913,
        23930.7899664424,
        25277.4266669987,
        26656.237549114,
        28067.0110993263,
        29509.5432686761,
        30983.637006888,
        32489.1018375657,
        34025.7534697925,
        35593.4134421543,
        37191.9087957285,
        38821.0717730236,
        40480.7395402373,
        42170.7539305202,
        43890.9612062118,
        45641.2118382513,
        47421.3603011734,
        49231.2648822783,
        51070.7875037154,
        52939.7935563624,
        54838.151744493,
        56765.7339403344,
        58722.4150477049,
        60708.0728740028,
        62722.5880098893,
        64765.8437160694,
        66837.725816633,
        68938.1225984661,
        71066.9247162875,
        73224.0251029056,
        75409.3188843258,
        77622.7032993703,
        79864.0776235011,
        82133.343096563,
        84430.4028541863,
        86755.1618626091,
        89107.5268567016,
        91487.4062809862,
        93894.7102334691,
        96329.3504121088,
        98791.2400637622,
        101280.29393546,
        103796.428227873,
        106339.560550847,
        108909.609880875,
        111506.496520418,
        114130.142058947,
        116780.46933563,
        119457.402403569,
        122160.866495494,
        124890.787990857,
        127647.09438423,
        130429.714254958,
        133238.577237994,
        136073.613995852,
        138934.756191635,
        141821.936463078,
        144735.088397546,
        147674.146507966,
        150639.046209624,
        153629.723797798,
        156646.116426189,
        159688.162086105,
        162755.799586378,
        165848.968533962,
        168967.609315199,
        172111.663077711,
        175281.071712899,
        178475.77783902,
        181695.724784815,
        184940.856573664,
        188211.117908257,
        191506.45415574,
        194826.811333333,
        198172.136094399,
        201542.375714934,
        204937.478080472,
        208357.391673388,
        211802.065560579,
        215271.449381512,
        218765.493336619,
        222284.148176037,
        225827.365188664,
        229395.096191537,
        232987.293519506,
        236603.910015198,
        240244.899019264,
        243910.214360892,
        247599.810348582,
        251313.641761169
      ],
      average: [
        null,
        1105.99126808554,
        1216.59039489409,
        1338.2494343835,
        1472.07437782185,
        1619.28181560403,
        1781.20999716444,
        1959.33099688088,
        2155.26409656897,
        2370.79050622586,
        2607.86955684845,
        2868.6565125333,
        3155.52216378663,
        3471.07438016529,
        3600.0,
        4200.0,
        4400.0,
        4400.0,
        4445.316,
        5311.564,
        6243.0,
        7239.624,
        8301.436,
        9428.436,
        10620.624,
        11878.0,
        13200.564,
        14588.316,
        16041.256,
        17559.384,
        19142.7,
        20791.204,
        22504.896,
        24283.776,
        26127.844,
        28037.1,
        30011.544,
        32051.176,
        34155.996,
        36326.004,
        38561.2,
        40861.584,
        43227.156,
        45657.916,
        48153.864,
        50715.0,
        53341.324,
        56032.836,
        58789.536,
        61611.424,
        64498.5,
        67450.764,
        70468.216,
        73550.856,
        76698.684,
        79911.7,
        83189.904,
        86533.296,
        89941.876,
        93415.644,
        96954.6,
        100558.744,
        104228.076,
        107962.596,
        111762.304,
        115627.2,
        119557.284,
        123552.556,
        127613.016,
        131738.664,
        135929.5,
        140185.524,
        144506.736,
        148893.136,
        153344.724,
        157861.5,
        162443.464,
        167090.616,
        171802.956,
        176580.484,
        181423.2,
        186331.104,
        191304.196,
        196342.476,
        201445.944,
        206614.6,
        211848.444,
        217147.476,
        222511.696,
        227941.104,
        233435.7,
        238995.484,
        244620.456,
        250310.616,
        256065.964,
        261886.5,
        267772.224,
        273723.136,
        279739.236,
        285820.524,
        291967.0,
        298178.664,
        304455.516,
        310797.556,
        317204.784,
        323677.2,
        330214.804,
        336817.596,
        343485.576,
        350218.744,
        357017.1,
        363880.644,
        370809.376,
        377803.296,
        384862.404,
        391986.7,
        399176.184,
        406430.856,
        413750.716,
        421135.764,
        428586.0
      ]
    }
  },
  [EvaluationType.MRR_GROWTH]: {
    all: {
      median: [
        null,
        null,
        null,
        null,
        6.39214788923495,
        2.1513918420402,
        1.16393987960725,
        0.787691176965478,
        0.589807547038925,
        0.469307649499543,
        0.388767361944818,
        0.331361316331579,
        0.288477764847427,
        0.255277393937643,
        0.228841850346343,
        0.207311200552909,
        0.189446175319916,
        0.174390325255303,
        0.161533399812849,
        0.150429151354493,
        0.140743908661808,
        0.132223303766614,
        0.124670108923282,
        0.117929093530223,
        0.111876442541382,
        0.106412213239707,
        0.101454861023288,
        0.0969372023010264,
        0.0928033935842643,
        0.0890066408792881,
        0.0855074417224155,
        0.0822722209762227,
        0.0792722613481235,
        0.0764828570366907,
        0.0738826380958872,
        0.071453026702618,
        0.0691777962695634,
        0.0670427114297469,
        0.0650352321200252,
        0.0631442688477659,
        0.0613599791130308,
        0.0596735971405157,
        0.0580772907379311,
        0.0565640403742247,
        0.0551275365588756,
        0.0537620923732883,
        0.052462568609165,
        0.0512243094454908,
        0.0500430869744054,
        0.0489150531886943,
        0.0478366982865709,
        0.0468048143455511,
        0.0458164635763311,
        0.0448689504973323,
        0.0439597974767708,
        0.0430867231765041,
        0.0422476235040358,
        0.0414405547388933,
        0.0406637185493894,
        0.0399154486573457,
        0.0391941989432569,
        0.0384985328136744,
        0.0378271136773457,
        0.0371786963975992,
        0.0365521196062387,
        0.0359462987793802,
        0.0353602199885689,
        0.0347929342516261,
        0.0342435524171444,
        0.0337112405247755,
        0.0331952155904697,
        0.032694741771986,
        0.032209126875236,
        0.031737719166651,
        0.0312799044607697,
        0.0308351034557129,
        0.0304027692923105,
        0.0299823853152446,
        0.0295734630170314,
        0.029175540147604,
        0.0287881789741993,
        0.0284109646777525,
        0.0280435038734899,
        0.0276854232446542,
        0.0273363682793688,
        0.0269960021017116,
        0.0266640043888621,
        0.0263400703670609,
        0.02602390987974,
        0.0257152465218614,
        0.0254138168350368,
        0.0251193695585066,
        0.0248316649315169,
        0.0245504740430092,
        0.0242755782249469,
        0.0240067684858802,
        0.023743844981684,
        0.0234866165206401,
        0.0232349001003118,
        0.0229885204738309,
        0.0227473097434684,
        0.0225111069794729,
        0.0222797578624045,
        0.0220531143472516,
        0.0218310343478287,
        0.0216133814400266,
        0.0214000245826167,
        0.0211908378544137,
        0.0209857002066849,
        0.0207844952297817,
        0.0205871109330698,
        0.0203934395372405,
        0.0202033772782571,
        0.0200168242221153,
        0.019833684089795,
        0.0196538640916978,
        0.0194772747710218,
        0.0193038298554832,
        0.0191334461168872,
        0.0189660432380649,
        0.0188015436867267
      ],
      average: [
        null,
        null,
        null,
        null,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333334,
        0.110333333333334,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.0901666666666668,
        0.0709166666666667,
        0.0525416666666667,
        0.0525416666666667,
        0.0525416666666667,
        0.0525416666666667,
        0.0525416666666667,
        0.0525416666666667,
        0.0525416666666667,
        0.0525416666666667,
        0.0388726141762931,
        0.0553533689475875,
        0.0710395240554975,
        0.101321441178807,
        0.0968698077476358,
        0.0928488749451633,
        0.0891957952533023,
        0.0858596070222975,
        0.0827985321727258,
        0.0799779810654693,
        0.0773690574228492,
        0.0749474227588441,
        0.0726924232016385,
        0.0705864104797202,
        0.0686142084073897,
        0.0667626896749614,
        0.0650204371627723,
        0.0633774706695423,
        0.0618250247346646,
        0.0603553667130386,
        0.0589616468166191,
        0.0576377737335772,
        0.0563783108574386,
        0.0551783892335208,
        0.0540336341499013,
        0.0529401029305427,
        0.0518942319765484,
        0.0508927914825814,
        0.0499328465547835,
        0.0490117236931794,
        0.0481269817897646,
        0.04727638694405,
        0.0464578905189606,
        0.0456696099579214,
        0.0449098119635605,
        0.0441768977034477,
        0.0434693897615922,
        0.0427859205983468,
        0.0421252223176906,
        0.0414861175710471,
        0.0408675114519474,
        0.0402683842569124,
        0.0396877850056052,
        0.0391248256282113,
        0.0385786757405978,
        0.0380485579384935,
        0.0375337435510161,
        0.0370335488016342,
        0.0365473313312804,
        0.0360744870440316,
        0.0356144472406654,
        0.0351666760096273,
        0.0347306678485952,
        0.0343059454929898,
        0.0338920579305317,
        0.0334885785833361,
        0.0330951036411257,
        0.0327112505309674,
        0.0323366565105373,
        0.0319709773733223,
        0.0316138862554026,
        0.0312650725345471,
        0.0309242408133115,
        0.0305911099786846,
        0.0302654123315725,
        0.0299468927800869,
        0.0296353080911903,
        0.0293304261957822,
        0.0290320255427817,
        0.0287398944981824,
        0.02845383078543,
        0.0281736409638126,
        0.0278991399418523,
        0.0276301505229618,
        0.0273665029808716,
        0.027108034662551
      ]
    },
    SaaS: {
      median: [
        null,
        null,
        null,
        null,
        2.56592396350746,
        2.66156993694112,
        1.42126098170069,
        0.940821522692537,
        0.694613459107675,
        0.547332223198957,
        0.450167263450656,
        0.381600484148377,
        0.330783444414819,
        0.291693138894887,
        0.260733243119839,
        0.235630718093066,
        0.21488173600945,
        0.197453351215645,
        0.182613473454929,
        0.169829273024769,
        0.158704001432482,
        0.14893633441015,
        0.140293421680922,
        0.132592553542897,
        0.125688400886807,
        0.119463952093473,
        0.113823957724744,
        0.108690110956288,
        0.103997451378421,
        0.0996916453336908,
        0.09572690376245,
        0.0920643701015954,
        0.0886708591502408,
        0.0855178610373023,
        0.0825807475867659,
        0.0798381347476185,
        0.0772713664762035,
        0.0748640939503211,
        0.072601930214858,
        0.0704721649627486,
        0.0684635275958445,
        0.0665659893050451,
        0.0647705968826509,
        0.0630693324930366,
        0.0614549947967337,
        0.0599210977325578,
        0.0584617839749109,
        0.0570717506451257,
        0.055746185301283,
        0.0544807105863809,
        0.0532713361999405,
        0.0521144170880146,
        0.0510066169330096,
        0.0499448761764203,
        0.0489263839317854,
        0.047948553247123,
        0.0470089992603014,
        0.0461055198604818,
        0.0452360785267713,
        0.0443987890635821,
        0.0435919019927225,
        0.042813792396332,
        0.0420629490334307,
        0.0413379645772369,
        0.0406375268409081,
        0.0399604108770075,
        0.0393054718508762,
        0.0386716386009653,
        0.0380579078101231,
        0.0374633387213084,
        0.0368870483393471,
        0.0363282070673898,
        0.0357860347328296,
        0.0352597969627412,
        0.0347488018735168,
        0.0342523970433867,
        0.0337699667400398,
        0.0333009293786091,
        0.0328447351880271,
        0.032400864066078,
        0.0319688236056117,
        0.0315481472761895,
        0.031138392747065,
        0.0307391403388825,
        0.0303499915926706,
        0.0299705679459513,
        0.0296005095066701,
        0.0292394739166854,
        0.0288871352972158,
        0.0285431832694842,
        0.0282073220443474,
        0.0278792695753247,
        0.0275587567699363,
        0.0272455267547062,
        0.0269393341896427,
        0.0266399446283358,
        0.0263471339201797,
        0.0260606876515179,
        0.025780400622784,
        0.0255060763589723,
        0.0252375266509791,
        0.0249745711255625,
        0.0247170368418739,
        0.0244647579126531,
        0.0242175751483529,
        0.0239753357225869,
        0.0237378928574256,
        0.0235051055271881,
        0.0232768381794592,
        0.0230529604721883,
        0.0228333470257949,
        0.022617877189282,
        0.0224064348194656,
        0.0221989080724389,
        0.0219951892065165,
        0.0217951743959081,
        0.021598763554461,
        0.0214058601688324,
        0.0212163711405078,
        0.0210302066361316,
        0.0208472799456395
      ],
      average: [
        null,
        null,
        null,
        null,
        2.66132183110739,
        3.0802352534833,
        1.60381912159859,
        1.0468222513263,
        0.765973605493479,
        0.599828231882824,
        0.491107192251439,
        0.414862810705819,
        0.358634176952769,
        0.315554337517299,
        0.281548025276204,
        0.254052647934483,
        0.231380195273614,
        0.212375573210543,
        0.196222810312915,
        0.182329747255501,
        0.170256593883956,
        0.15917967642902,
        0.138940884911774,
        0.122734439191712,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.103859859552142,
        0.0901666666666668,
        0.0709166666666667,
        0.0582552773448908,
        0.0525416666666667,
        0.0525416666666667,
        0.0525416666666667,
        0.0525416666666667,
        0.0525416666666667,
        0.0525416666666667,
        0.0525416666666667,
        0.0388726141762931,
        0.0553533689475875,
        0.0710395240554975,
        0.101321441178807,
        0.0968698077476358,
        0.0928488749451633,
        0.0891957952533023,
        0.0804423281427488,
        0.0679717919222352,
        0.0565507056618933,
        0.0510147223983749,
        0.0500112417655221,
        0.0490464295310577,
        0.0481180953015807,
        0.047224210888472,
        0.0463628955797271,
        0.045532402986622,
        0.0447311092723358,
        0.043957502596188,
        0.0432101736295989,
        0.0424878070190486,
        0.0417891736875783,
        0.0411131238803585,
        0.0404585808718169,
        0.0398245352620773,
        0.0392100397993841,
        0.0386142046727684,
        0.0380361932259536,
        0.0374752180491402,
        0.0369305374104384,
        0.0364014519929731,
        0.0358873019076092,
        0.0353874639544813,
        0.0349013491095297,
        0.0344284002147552,
        0.0339680898532,
        0.0335199183916689,
        0.0330834121759151,
        0.0326581218646705,
        0.0322436208901643,
        0.0318395040341256,
        0.0314453861092436,
        0.0310609007371327,
        0.0306856992146269,
        0.0303194494610741,
        0.0299618350399485,
        0.0296125542487267,
        0.0292713192715626,
        0.0289378553897241,
        0.0286119002453024,
        0.0282932031539955,
        0.0279815244632384,
        0.0276766349521953,
        0.0273783152704861,
        0.0270863554127468,
        0.0268005542263969,
        0.0265207189501754,
        0.0262466647812458,
        0.0259782144688097,
        0.0257151979323646,
        0.0254574519028793,
        0.0252048195852947,
        0.0249571503408957,
        0.0247142993881858,
        0.0244761275210355,
        0.0242425008429496,
        0.0240132905163743,
        0.0237883725260867,
        0.0235676274557182,
        0.0233509402766054,
        0.0231382001481542,
        0.0229293002290096,
        0.0227241374983467,
        0.0225226125866507,
        0.02232462961542,
        0.0221300960452296
      ]
    },
    Transactional: {
      median: [
        null,
        null,
        null,
        null,
        2.4597091141935,
        1.02523020479463,
        0.631557411350028,
        0.452903325312108,
        0.351959715572209,
        0.2873885862471,
        0.242644023776313,
        0.209857087618321,
        0.184821798750915,
        0.165091281244526,
        0.149147351351776,
        0.135999091300845,
        0.124972716610282,
        0.115594354357187,
        0.107521156292191,
        0.100499018895207,
        0.0943356602134341,
        0.088882924781565,
        0.0840248298383439,
        0.0796692932687283,
        0.0757422861905697,
        0.0721836204212725,
        0.0689438617526472,
        0.0659820332722981,
        0.0632638826547289,
        0.0607605583264486,
        0.0584475862805925,
        0.0563040708414757,
        0.0543120642421822,
        0.0524560648564068,
        0.0507226144790334,
        0.049099972584046,
        0.047577850932978,
        0.0461471958866494,
        0.0448000087125124,
        0.0435291963728869,
        0.0423284469305411,
        0.0411921249621516,
        0.0401151833304956,
        0.0390930884072436,
        0.0381217564142186,
        0.0371974990017702,
        0.0363169765380293,
        0.0354771578642589,
        0.034675285495936,
        0.0339088454290875,
        0.0331755408564272,
        0.0324732692152759,
        0.0318001020848831,
        0.0311542675289257,
        0.0305341345431925,
        0.0299381993214157,
        0.0293650730960782,
        0.0288134713474876,
        0.0282822042048328,
        0.0277701678884294,
        0.0272763370637521,
        0.0267997579959387,
        0.0263395424086668,
        0.0258948619643213,
        0.02546494329332,
        0.0250490635099379,
        0.0246465461599577,
        0.024256757552424,
        0.0238791034336836,
        0.0235130259670307,
        0.023158000985696,
        0.0228135354907531,
        0.0224791653688434,
        0.0221544533075152,
        0.0218389868885075,
        0.0215323768415012,
        0.0212342554428141,
        0.0209442750451786,
        0.0206621067262768,
        0.0203874390449709,
        0.0201199768953641,
        0.0198594404498238,
        0.0196055641830102,
        0.0193580959697794,
        0.0191167962504879,
        0.0188814372579299,
        0.0186518023006297,
        0.0184276850977897,
        0.0182088891615668,
        0.0179952272228271,
        0.0177865206968219,
        0.0175825991856085,
        0.0173833000142834,
        0.0171884677983879,
        0.0169979540400644,
        0.0168116167507644,
        0.0166293200984822,
        0.0164509340776907,
        0.0162763342002762,
        0.0161054012059431,
        0.0159380207906684,
        0.0157740833518986,
        0.0156134837493171,
        0.0154561210800585,
        0.0153018984673838,
        0.0151507228618712,
        0.0150025048542716,
        0.0148571584992418,
        0.014714601149217,
        0.0145747532977529,
        0.0144375384317171,
        0.0143028828917414,
        0.0141707157404175,
        0.0140409686377212,
        0.0139135757232223,
        0.0137884735046426,
        0.0136656007523729,
        0.0135448983995769,
        0.0134263094475396,
        0.0133097788759452,
        0.013195253557785
      ],
      average: [
        null,
        null,
        null,
        null,
        3.16395683166777,
        1.24291929585499,
        0.746371898817414,
        0.527608995296385,
        0.406245695273269,
        0.32958355649654,
        0.276948593330711,
        0.238651878037408,
        0.209572948844229,
        0.186759768296413,
        0.168394282179955,
        0.153297209352983,
        0.14067092109027,
        0.129957000465886,
        0.120753036564737,
        0.112761823753132,
        0.105759162337374,
        0.0995727829726719,
        0.0940681618293349,
        0.0891387373857672,
        0.0846990157359376,
        0.0806796172946626,
        0.0770236564549565,
        0.0736840541311326,
        0.0706215145784227,
        0.0678029826988514,
        0.0652004538985457,
        0.0627900460334314,
        0.060551268549793,
        0.0584664416480768,
        0.0565202307589342,
        0.0546992704988632,
        0.0529918586771488,
        0.0513877055986601,
        0.0498777273531868,
        0.048453874348832,
        0.0471089882768499,
        0.0458366821590404,
        0.0446312392481521,
        0.0434875274144343,
        0.0424009263211938,
        0.041367265215795,
        0.0403827695745065,
        0.0394440151658198,
        0.0385478883566655,
        0.0376915516940564,
        0.0368724139622599,
        0.0360881040512084,
        0.0353364480821789,
        0.0346154493268702,
        0.033923270529993,
        0.0332582183064194,
        0.0326187293344067,
        0.0320033581083075,
        0.0314107660491301,
        0.0308397118005555,
        0.0302890425625744,
        0.0297576863356203,
        0.0292446449655358,
        0.0287489878945758,
        0.0282698465362293,
        0.0278064092024242,
        0.0273579165208639,
        0.0269236572881354,
        0.0265029647110141,
        0.0260952129942271,
        0.025699814237991,
        0.0253162156130127,
        0.0249438967844342,
        0.0245823675595008,
        0.0242311657366191,
        0.0238898551359662,
        0.0235580237940437,
        0.0232352823064465,
        0.022921262304875,
        0.0226156150558489,
        0.022318010169952,
        0.0220281344115514,
        0.0217456905999925,
        0.0214703965941773,
        0.0212019843532272,
        0.0209401990666738,
        0.0206847983482351,
        0.0204355514878352,
        0.0201922387570002,
        0.0199546507632582,
        0.0197225878495418,
        0.0194958595349855,
        0.0192742839938228,
        0.0190576875693889,
        0.0188459043205067,
        0.0186387755977611,
        0.0184361496473891,
        0.0182378812407106,
        0.0180438313272009,
        0.0178538667094589,
        0.0176678597384855,
        0.0174856880277903,
        0.0173072341850042,
        0.017132385559741,
        0.0169610340065842,
        0.0167930756621484,
        0.0166284107352428,
        0.0164669433092618,
        0.0163085811559619,
        0.0161532355598798,
        0.0160008211526891,
        0.0158512557568297,
        0.0157044602378387,
        0.0155603583647873,
        0.0154188766783431,
        0.0152799443659522,
        0.0151434931437106,
        0.015009457144504,
        0.0148777728120325,
        0.014748378800363,
        0.0146212158786765
      ]
    },
    [CustomerFocus.B2B]: {
      median: [
        null,
        null,
        null,
        null,
        9.68963204001384,
        2.82793426904279,
        1.49450376492854,
        0.983592929283311,
        0.723517549923491,
        0.568653909922194,
        0.466829817308804,
        0.395160197577422,
        0.342151865941657,
        0.301443501558879,
        0.269246377049836,
        0.243170923233482,
        0.221639073326865,
        0.203568580149193,
        0.188193456787881,
        0.174956872654557,
        0.163444650719662,
        0.153342530604419,
        0.144407874562451,
        0.136450441377093,
        0.12931901862399,
        0.122891936675787,
        0.117070213336258,
        0.111772517603467,
        0.106931414511609,
        0.102490527154882,
        0.0984023653051405,
        0.0946266452018889,
        0.0911289758500997,
        0.0878798219976508,
        0.0848536782342589,
        0.0820284057981652,
        0.0793846959436369,
        0.0769056326045032,
        0.0745763335919361,
        0.0723836543754386,
        0.0703159420894474,
        0.0683628301166926,
        0.0665150656587691,
        0.0647643642826342,
        0.0631032866504823,
        0.0615251335884016,
        0.0600238563914966,
        0.0585939798481861,
        0.0572305359302542,
        0.0559290064651964,
        0.0546852734041035,
        0.0534955755374987,
        0.0523564707053402,
        0.051264802705177,
        0.0502176722314445,
        0.049212411284908,
        0.0482465605786319,
        0.047317849539316,
        0.0464241785629604,
        0.0455636032341074,
        0.0447343202599175,
        0.0439346549057221,
        0.043163049748445,
        0.0424180545895232,
        0.0416983173903011,
        0.0410025761110706,
        0.0403296513504483,
        0.0396784396950389,
        0.0390479077007537,
        0.0384370864368946,
        0.0378450665326192,
        0.0372709936726373,
        0.0367140644953685,
        0.0361735228522157,
        0.0356486563914542,
        0.0351387934343204,
        0.0346433001146042,
        0.0341615777561458,
        0.0336930604655148,
        0.0332372129195337,
        0.0327935283295192,
        0.0323615265659866,
        0.0319407524292552,
        0.0315307740529052,
        0.0311311814283131,
        0.0307415850397108,
        0.0303616146002075,
        0.0299909178802071,
        0.0296291596203955,
        0.0292760205223099,
        0.0289311963100614,
        0.0285943968574753,
        0.0282653453753506,
        0.0279437776540893,
        0.0276294413573435,
        0.0273220953627013,
        0.0270215091458182,
        0.0267274622046761,
        0.0264397435209599,
        0.0261581510557942,
        0.0258824912772984,
        0.0256125787176559,
        0.0253482355575658,
        0.0250892912361157,
        0.0248355820842859,
        0.0245869509804353,
        0.024343247026238,
        0.0241043252416807,
        0.0238700462778109,
        0.0236402761460501,
        0.0234148859629816,
        0.0231937517095543,
        0.0229767540038113,
        0.0227637778862182,
        0.0225547126168166,
        0.0223494514834374,
        0.0221478916202669,
        0.0219499338361523,
        0.021755482451992,
        0.0215644451467105,
        0.0213767328112454
      ],
      average: [
        null,
        null,
        null,
        null,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.252641338314391,
        0.172895544556926,
        0.100338137077661,
        0.0252448588628017,
        0.0393114690748301,
        0.111746940110451,
        0.177286277553533,
        0.153931438747143,
        0.0685294554420473,
        0.0136894764023348,
        0.00421035028193832,
        0.0300022870510167,
        0.0532645412248443,
        0.110424088053199,
        0.102550968874384,
        0.104269457821484,
        0.070896166773982,
        0.104735852196501,
        0.124179246940623,
        0.117836481129209,
        0.0745890733255956,
        0.05976317757005,
        0.0783143503339209,
        0.0612414500753401,
        0.0519464702166869,
        0.0488637057604892,
        0.0782381304659499,
        0.0900154280300121,
        0.0864298630590999,
        0.0831613434185656,
        0.0801674060031672,
        0.0774129858444575,
        0.0748688612683767,
        0.0725104763968584,
        0.0703170381929562,
        0.0682708160712478,
        0.0663565928946326,
        0.0645612304505188,
        0.0628733224435072,
        0.0612829150688118,
        0.0597812802616847,
        0.0583607303638745,
        0.0570144656199599,
        0.0557364478950793,
        0.0545212954853977,
        0.053364195009561,
        0.052260827219726,
        0.0512073042233682,
        0.050200116111824,
        0.0492360853847039,
        0.048312327867712,
        0.0474262190648524,
        0.046575365079365,
        0.0457575773922154,
        0.0449708509110587,
        0.0442133448028235,
        0.0434833657044016,
        0.0427793529722762,
        0.0420998656862752,
        0.0414435711673647,
        0.0408092348063628,
        0.0401957110311217,
        0.0396019352652746,
        0.0390269167529918,
        0.0384697321421186,
        0.0379295197331426,
        0.0374054743141847,
        0.0368968425130019,
        0.0364029186061655,
        0.0359230407334021,
        0.0354565874717698,
        0.0350029747300746,
        0.0345616529288596,
        0.0341321044355447,
        0.0300555637406386,
        0.0238920575304449,
        0.0179216266424544,
        0.0156134557357862,
        0.0154824085450104,
        0.0153536795622114,
        0.0152272052708594,
        0.0151029245127634,
        0.0149807783779916,
        0.0148607101009547,
        0.014742664962248,
        0.0146265901958843,
        0.0145124349015722,
        0.0144001499617213,
        0.0142896879628785,
        0.0141810031213196,
        0.0140740512125429,
        0.0139687895044226,
        0.0138651766938064,
        0.0137631728463463,
        0.0136627393393745
      ]
    },
    [CustomerFocus.B2C]: {
      median: [
        null,
        null,
        null,
        null,
        6.39214788923495,
        2.1513918420402,
        1.16393987960725,
        0.787691176965478,
        0.589807547038925,
        0.469307649499543,
        0.388767361944818,
        0.331361316331579,
        0.288477764847427,
        0.255277393937643,
        0.228841850346343,
        0.207311200552909,
        0.189446175319916,
        0.174390325255303,
        0.161533399812849,
        0.150429151354493,
        0.140743908661808,
        0.132223303766614,
        0.124670108923282,
        0.117929093530223,
        0.111876442541382,
        0.106412213239707,
        0.101454861023288,
        0.0969372023010264,
        0.0928033935842643,
        0.0890066408792881,
        0.0855074417224155,
        0.0822722209762227,
        0.0792722613481235,
        0.0764828570366907,
        0.0738826380958872,
        0.071453026702618,
        0.0691777962695634,
        0.0670427114297469,
        0.0650352321200252,
        0.0631442688477659,
        0.0613599791130308,
        0.0596735971405157,
        0.0580772907379311,
        0.0565640403742247,
        0.0551275365588756,
        0.0537620923732883,
        0.052462568609165,
        0.0512243094454908,
        0.0500430869744054,
        0.0489150531886943,
        0.0478366982865709,
        0.0468048143455511,
        0.0458164635763311,
        0.0448689504973323,
        0.0439597974767708,
        0.0430867231765041,
        0.0422476235040358,
        0.0414405547388933,
        0.0406637185493894,
        0.0399154486573457,
        0.0391941989432569,
        0.0384985328136744,
        0.0378271136773457,
        0.0371786963975992,
        0.0365521196062387,
        0.0359462987793802,
        0.0353602199885689,
        0.0347929342516261,
        0.0342435524171444,
        0.0337112405247755,
        0.0331952155904697,
        0.032694741771986,
        0.032209126875236,
        0.031737719166651,
        0.0312799044607697,
        0.0308351034557129,
        0.0304027692923105,
        0.0299823853152446,
        0.0295734630170314,
        0.029175540147604,
        0.0287881789741993,
        0.0284109646777525,
        0.0280435038734899,
        0.0276854232446542,
        0.0273363682793688,
        0.0269960021017116,
        0.0266640043888621,
        0.0263400703670609,
        0.02602390987974,
        0.0257152465218614,
        0.0254138168350368,
        0.0251193695585066,
        0.0248316649315169,
        0.0245504740430092,
        0.0242755782249469,
        0.0240067684858802,
        0.023743844981684,
        0.0234866165206401,
        0.0232349001003118,
        0.0229885204738309,
        0.0227473097434684,
        0.0225111069794729,
        0.0222797578624045,
        0.0220531143472516,
        0.0218310343478287,
        0.0216133814400266,
        0.0214000245826167,
        0.0211908378544137,
        0.0209857002066849,
        0.0207844952297817,
        0.0205871109330698,
        0.0203934395372405,
        0.0202033772782571,
        0.0200168242221153,
        0.019833684089795,
        0.0196538640916978,
        0.0194772747710218,
        0.0193038298554832,
        0.0191334461168872,
        0.0189660432380649,
        0.0188015436867267
      ],
      average: [
        null,
        null,
        null,
        null,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333334,
        0.110333333333334,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.0901666666666668,
        0.0709166666666667,
        0.0525416666666667,
        0.0525416666666667,
        0.0525416666666667,
        0.0525416666666667,
        0.0525416666666667,
        0.0525416666666667,
        0.0525416666666667,
        0.0525416666666667,
        0.0388726141762931,
        0.0553533689475875,
        0.0710395240554975,
        0.101321441178807,
        0.0968698077476358,
        0.0928488749451633,
        0.0891957952533023,
        0.0858596070222975,
        0.0827985321727258,
        0.0799779810654693,
        0.0773690574228492,
        0.0749474227588441,
        0.0726924232016385,
        0.0705864104797202,
        0.0686142084073897,
        0.0667626896749614,
        0.0650204371627723,
        0.0633774706695423,
        0.0618250247346646,
        0.0603553667130386,
        0.0589616468166191,
        0.0576377737335772,
        0.0563783108574386,
        0.0551783892335208,
        0.0540336341499013,
        0.0529401029305427,
        0.0518942319765484,
        0.0508927914825814,
        0.0499328465547835,
        0.0490117236931794,
        0.0481269817897646,
        0.04727638694405,
        0.0464578905189606,
        0.0456696099579214,
        0.0449098119635605,
        0.0441768977034477,
        0.0434693897615922,
        0.0427859205983468,
        0.0421252223176906,
        0.0414861175710471,
        0.0408675114519474,
        0.0402683842569124,
        0.0396877850056052,
        0.0391248256282113,
        0.0385786757405978,
        0.0380485579384935,
        0.0375337435510161,
        0.0370335488016342,
        0.0365473313312804,
        0.0360744870440316,
        0.0356144472406654,
        0.0351666760096273,
        0.0347306678485952,
        0.0343059454929898,
        0.0338920579305317,
        0.0334885785833361,
        0.0330951036411257,
        0.0327112505309674,
        0.0323366565105373,
        0.0319709773733223,
        0.0316138862554026,
        0.0312650725345471,
        0.0309242408133115,
        0.0305911099786846,
        0.0302654123315725,
        0.0299468927800869,
        0.0296353080911903,
        0.0293304261957822,
        0.0290320255427817,
        0.0287398944981824,
        0.02845383078543,
        0.0281736409638126,
        0.0278991399418523,
        0.0276301505229618,
        0.0273665029808716,
        0.027108034662551
      ]
    },
    [Industry.OTHER]: { median: null, average: null },
    [Industry.IT]: {
      median: [
        null,
        null,
        null,
        null,
        10.4957370772873,
        2.99377018108946,
        1.56658512198877,
        1.02536480933971,
        0.751601391788906,
        0.58929393833146,
        0.482914566016652,
        0.408221022241298,
        0.353082699688642,
        0.310804945644644,
        0.2774100025885,
        0.250394090178418,
        0.228106531640821,
        0.209416947928162,
        0.193526312968812,
        0.17985443607076,
        0.167970198899334,
        0.1575467813134,
        0.148332052960988,
        0.140128475127677,
        0.132779139984762,
        0.126157872399832,
        0.120162082394602,
        0.114707518122737,
        0.109724356202008,
        0.105154248827998,
        0.100948065795577,
        0.0970641482354721,
        0.0934669439643609,
        0.0901259307602736,
        0.0870147592276504,
        0.0841105648145363,
        0.0813934113433253,
        0.0788458376788546,
        0.0764524859356538,
        0.0741997946378901,
        0.0720757439874322,
        0.0700696432148846,
        0.0681719521309647,
        0.0663741306370427,
        0.0646685112206887,
        0.0630481904472315,
        0.0615069362294923,
        0.0600391082654301,
        0.0586395895150525,
        0.0573037269719279,
        0.0560272802925043,
        0.0548063770945257,
        0.053637473936851,
        0.0525173221564983,
        0.0514429378725343,
        0.0504115755761938,
        0.0494207048172454,
        0.0484679895715642,
        0.0475512699372462,
        0.0466685458585534,
        0.0458179626205607,
        0.0449977978939361,
        0.044206450140097,
        0.0434424282130971,
        0.0427043420166488,
        0.0419908940935623,
        0.0413008720408568,
        0.0406331416575884,
        0.0399866407441531,
        0.0393603734819938,
        0.0387534053313245,
        0.0381648583920558,
        0.0375939071796204,
        0.0370397747730562,
        0.0365017292976669,
        0.0359790807088384,
        0.0354711778473899,
        0.0349774057400708,
        0.0344971831217559,
        0.03402996015838,
        0.0335752163519067,
        0.0331324586105863,
        0.0325014970576651,
        0.0317499563526454,
        0.0310140689626345,
        0.0304918056439604,
        0.0301150483962108,
        0.0297474834944185,
        0.0293887787236589,
        0.0290386176802169,
        0.0286966988423782,
        0.0283627347059735,
        0.0280364509794682,
        0.0277175858338522,
        0.027405889203058,
        0.0271011221309499,
        0.0268030561613329,
        0.0265114727676933,
        0.0262261628196999,
        0.0259469260837189,
        0.0256735707548553,
        0.0254059130181987,
        0.0251437766372062,
        0.024886992567245,
        0.0246353985925503,
        0.0243888389849437,
        0.0241471641828101,
        0.0239102304889492,
        0.0236778997860086,
        0.0234500392683263,
        0.0232265211890936,
        0.0230072226218017,
        0.0227920252350825,
        0.0225808150800256,
        0.0223734823892173,
        0.0221699213867195,
        0.0219700301083232,
        0.0217737102314277,
        0.0215808669139384,
        0.0213914086416516,
        0.021205247083591
      ],
      average: [
        null,
        null,
        null,
        null,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.242666666666667,
        0.252641338314391,
        0.172895544556926,
        0.100338137077661,
        0.0252448588628017,
        0.0393114690748301,
        0.111746940110451,
        0.177286277553533,
        0.153931438747143,
        0.0685294554420473,
        0.0136894764023348,
        0.00421035028193832,
        0.0515169256120974,
        0.0791463303675587,
        0.140909837815841,
        0.107426087323954,
        0.108022419316752,
        0.0762471463034367,
        0.0924723753192702,
        0.110804550114555,
        0.0966324162083065,
        0.0734713556271553,
        0.0561209588833183,
        0.0835925569737771,
        0.0905007603485446,
        0.0984040367707169,
        0.091134104647661,
        0.0951593114908561,
        0.0903675851457051,
        0.0860954574811625,
        0.082259862949401,
        0.0787947441884851,
        0.075646893853286,
        0.0727729612088537,
        0.0701372596196231,
        0.0677101354531184,
        0.0654667374232766,
        0.0633860760888905,
        0.0614502966334126,
        0.0596441104898128,
        0.057954346702116,
        0.0563695945517306,
        0.054879916464893,
        0.0534766155600059,
        0.0521520460525839,
        0.0508994575549944,
        0.0497128663903564,
        0.0485869485930266,
        0.0475169504374119,
        0.0464986132249665,
        0.0455281097393589,
        0.044601990304701,
        0.0437171367898281,
        0.0428707232210821,
        0.0420601819178055,
        0.0412831742643569,
        0.0405375653916751,
        0.0398214021691135,
        0.0391328940102502,
        0.0384703960798297,
        0.0378323945569783,
        0.0372174936654452,
        0.0366244042273362,
        0.0360519335345349,
        0.0354989763632722,
        0.0349645069833166,
        0.034447572034984,
        0.0339472841653651,
        0.0308197664747088,
        0.0223541652959107,
        0.0144458530456283,
        0.00954968324096694,
        0.0102161481601832,
        0.0109342504080851,
        0.0117022297718511,
        0.0125179523631112,
        0.0133789046023112,
        0.014282193867353,
        0.0152245563770149,
        0.016202372694101,
        0.017211691010215,
        0.018248258121156,
        0.0193075577305935,
        0.0203848554430302,
        0.0214752495405139,
        0.0225737263966305,
        0.0236752191809716,
        0.0247746683606795,
        0.0258670824224741,
        0.0269475972247516,
        0.0280115324459619,
        0.029054443718984,
        0.0300721692236816,
        0.0310608697394055,
        0.0320170614217555,
        0.0329376408475806,
        0.0338199021532487
      ]
    },
    [Industry.FIPT]: {
      median: [
        null,
        null,
        null,
        null,
        6.39214788923495,
        2.1513918420402,
        1.16393987960725,
        0.787691176965478,
        0.589807547038925,
        0.469307649499543,
        0.388767361944818,
        0.331361316331579,
        0.288477764847427,
        0.255277393937643,
        0.228841850346343,
        0.207311200552909,
        0.189446175319916,
        0.174390325255303,
        0.161533399812849,
        0.150429151354493,
        0.140743908661808,
        0.132223303766614,
        0.124670108923282,
        0.117929093530223,
        0.111876442541382,
        0.106412213239707,
        0.101454861023288,
        0.0969372023010264,
        0.0928033935842643,
        0.0890066408792881,
        0.0855074417224155,
        0.0822722209762227,
        0.0792722613481235,
        0.0764828570366907,
        0.0738826380958872,
        0.071453026702618,
        0.0691777962695634,
        0.0670427114297469,
        0.0650352321200252,
        0.0631442688477659,
        0.0613599791130308,
        0.0596735971405157,
        0.0580772907379311,
        0.0565640403742247,
        0.0551275365588756,
        0.0537620923732883,
        0.052462568609165,
        0.0512243094454908,
        0.0500430869744054,
        0.0489150531886943,
        0.0478366982865709,
        0.0468048143455511,
        0.0458164635763311,
        0.0448689504973323,
        0.0439597974767708,
        0.0430867231765041,
        0.0422476235040358,
        0.0414405547388933,
        0.0406637185493894,
        0.0399154486573457,
        0.0391941989432569,
        0.0384985328136744,
        0.0378271136773457,
        0.0371786963975992,
        0.0365521196062387,
        0.0359462987793802,
        0.0353602199885689,
        0.0347929342516261,
        0.0342435524171444,
        0.0337112405247755,
        0.0331952155904697,
        0.032694741771986,
        0.032209126875236,
        0.031737719166651,
        0.0312799044607697,
        0.0308351034557129,
        0.0304027692923105,
        0.0299823853152446,
        0.0295734630170314,
        0.029175540147604,
        0.0287881789741993,
        0.0284109646777525,
        0.0280435038734899,
        0.0276854232446542,
        0.0273363682793688,
        0.0269960021017116,
        0.0266640043888621,
        0.0263400703670609,
        0.02602390987974,
        0.0257152465218614,
        0.0254138168350368,
        0.0251193695585066,
        0.0248316649315169,
        0.0245504740430092,
        0.0242755782249469,
        0.0240067684858802,
        0.023743844981684,
        0.0234866165206401,
        0.0232349001003118,
        0.0229885204738309,
        0.0227473097434684,
        0.0225111069794729,
        0.0222797578624045,
        0.0220531143472516,
        0.0218310343478287,
        0.0216133814400266,
        0.0214000245826167,
        0.0211908378544137,
        0.0209857002066849,
        0.0207844952297817,
        0.0205871109330698,
        0.0203934395372405,
        0.0202033772782571,
        0.0200168242221153,
        0.019833684089795,
        0.0196538640916978,
        0.0194772747710218,
        0.0193038298554832,
        0.0191334461168872,
        0.0189660432380649,
        0.0188015436867267
      ],
      average: [
        null,
        null,
        null,
        null,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333334,
        0.110333333333334,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.0901666666666668,
        0.0709166666666667,
        0.0525416666666667,
        0.0525416666666667,
        0.0525416666666667,
        0.0525416666666667,
        0.0525416666666667,
        0.0525416666666667,
        0.0525416666666667,
        0.0525416666666667,
        0.0388726141762931,
        0.0553533689475875,
        0.0710395240554975,
        0.101321441178807,
        0.0968698077476358,
        0.0928488749451633,
        0.0891957952533023,
        0.0858596070222975,
        0.0827985321727258,
        0.0799779810654693,
        0.0773690574228492,
        0.0749474227588441,
        0.0726924232016385,
        0.0705864104797202,
        0.0686142084073897,
        0.0667626896749614,
        0.0650204371627723,
        0.0633774706695423,
        0.0618250247346646,
        0.0603553667130386,
        0.0589616468166191,
        0.0576377737335772,
        0.0563783108574386,
        0.0551783892335208,
        0.0540336341499013,
        0.0529401029305427,
        0.0518942319765484,
        0.0508927914825814,
        0.0499328465547835,
        0.0490117236931794,
        0.0481269817897646,
        0.04727638694405,
        0.0464578905189606,
        0.0456696099579214,
        0.0449098119635605,
        0.0441768977034477,
        0.0434693897615922,
        0.0427859205983468,
        0.0421252223176906,
        0.0414861175710471,
        0.0408675114519474,
        0.0402683842569124,
        0.0396877850056052,
        0.0391248256282113,
        0.0385786757405978,
        0.0380485579384935,
        0.0375337435510161,
        0.0370335488016342,
        0.0365473313312804,
        0.0360744870440316,
        0.0356144472406654,
        0.0351666760096273,
        0.0347306678485952,
        0.0343059454929898,
        0.0338920579305317,
        0.0334885785833361,
        0.0330951036411257,
        0.0327112505309674,
        0.0323366565105373,
        0.0319709773733223,
        0.0316138862554026,
        0.0312650725345471,
        0.0309242408133115,
        0.0305911099786846,
        0.0302654123315725,
        0.0299468927800869,
        0.0296353080911903,
        0.0293304261957822,
        0.0290320255427817,
        0.0287398944981824,
        0.02845383078543,
        0.0281736409638126,
        0.0278991399418523,
        0.0276301505229618,
        0.0273665029808716,
        0.027108034662551
      ]
    },
    [Industry.DH]: {
      median: [
        null,
        null,
        null,
        null,
        3.59314925412075,
        1.3682523179942,
        0.810706669966971,
        0.568793784170782,
        0.435847770262744,
        0.352411752827133,
        0.295397591725673,
        0.254065494559592,
        0.222772245055794,
        0.19827938608725,
        0.178599998652908,
        0.162449345133279,
        0.148960810189307,
        0.137529089572085,
        0.127718868195356,
        0.119209212387394,
        0.111758406471451,
        0.105180985233248,
        0.099332304735697,
        0.0940979189162475,
        0.0893861042796575,
        0.0851224970081162,
        0.0812461782822334,
        0.0777067717649738,
        0.0744622609021973,
        0.0714773262741329,
        0.0687220641142691,
        0.0661709879035665,
        0.0638022427491641,
        0.0615969815038509,
        0.0595388651012897,
        0.0576136592047865,
        0.0558089062022368,
        0.0541136566354098,
        0.0525182478769607,
        0.0510141206411049,
        0.0495936659968992,
        0.0482500971318033,
        0.046977341319699,
        0.045769948476804,
        0.044623013409873,
        0.0435321094244084,
        0.0424932314035793,
        0.0415027468191502,
        0.0405573534147972,
        0.0396540425256168,
        0.0387900671774731,
        0.0379629142552801,
        0.0371702801476269,
        0.0364100493716886,
        0.0356802757616597,
        0.0349791658691844,
        0.0343050642782941,
        0.0336564405821945,
        0.0330318778066411,
        0.0324300620959085,
        0.0318497735036304,
        0.0312898777528922,
        0.0307493188486516,
        0.0302271124414148,
        0.0297223398545368,
        0.0292341426990355,
        0.0287617180095843,
        0.0283043138438066,
        0.0278612252941931,
        0.027431790868216,
        0.0270153891975965,
        0.0266114360423322,
        0.0262193815591477,
        0.0258387078075354,
        0.0254689264696368,
        0.0251095767628691,
        0.0247602235265682,
        0.0244204554659437,
        0.0240898835384816,
        0.0237681394694792,
        0.0234548743848329,
        0.0231497575504035,
        0.0228524752083961,
        0.0225627295021665,
        0.0222802374816951,
        0.0220047301827787,
        0.0217359517736167,
        0.0214736587631381,
        0.0212176192658939,
        0.0209676123188753,
        0.0207234272460194,
        0.0204848630665635,
        0.0202517279437677,
        0.0200238386708142,
        0.0198010201910128,
        0.0195831051496472,
        0.0193699334750747,
        0.0191613519868613,
        0.01895721402895,
        0.0187573791260075,
        0.0185617126612675,
        0.0183700855743047,
        0.0181823740773345,
        0.0179984593887092,
        0.0178182274824186,
        0.01764156885248,
        0.0174683782911969,
        0.0172985546803413,
        0.0171320007943946,
        0.0169686231150316,
        0.0168083316561266,
        0.016651039798557,
        0.0164966641342156,
        0.0163451243185902,
        0.0161963429314114,
        0.0160502453448198,
        0.0159067595986149,
        0.0157658162821231,
        0.0156273484222895,
        0.0154912913776133,
        0.0153575827375731
      ],
      average: [
        null,
        null,
        null,
        null,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.110333333333333,
        0.0849809523809525,
        0.110333333333333,
        0.0892063492063493,
        0.0740740740740741,
        0.0194695238095238,
        0.0690578787878788,
        0.139621212121212,
        0.209532010772687,
        0.187632870469037,
        0.170080410059266,
        0.155671068000217,
        0.143612261782178,
        0.133359976140263,
        0.124527899678964,
        0.116833810405792,
        0.110066509279452,
        0.104064650093952,
        0.0987027449720895,
        0.0938816532516176,
        0.0895219587623481,
        0.0855592586172499,
        0.081940747471128,
        0.0786226985457286,
        0.0755685773384133,
        0.0727476094175218,
        0.0701336792269001,
        0.0677044736205623,
        0.0654408086943212,
        0.0633260955430165,
        0.0613459124716037,
        0.0594876596071263,
        0.0577402778938314,
        0.0560940188334483,
        0.0545402545473817,
        0.0530713201222518,
        0.0516803819867688,
        0.0503613274188013,
        0.0491086713118472,
        0.0479174771224247,
        0.0467832895338651,
        0.0457020768511977,
        0.0446701815184309,
        0.0436842774474303,
        0.0427413330846719,
        0.0418385793319376,
        0.0409734815897852,
        0.0401437153162408,
        0.039347144593693,
        0.0385818032791168,
        0.0378458783801903,
        0.037137695355463,
        0.0364557050827648,
        0.0357984722783065,
        0.0351646651808467,
        0.0345530463420372,
        0.0339624643865279,
        0.0333918466243628,
        0.0328401924142282,
        0.0323065671897203,
        0.0317900970723795,
        0.0312899640051281,
        0.0308054013482112,
        0.0303356898870073,
        0.029880154207327,
        0.0294381593992202,
        0.0290091080549723,
        0.0285924375310221,
        0.0281876174470478,
        0.0277941473985313,
        0.0274115548617818,
        0.027039393272741,
        0.026677240262937,
        0.026324696037754,
        0.0259813818837673,
        0.0256469387932896,
        0.0253210261955004,
        0.0250033207846266,
        0.0246935154365998,
        0.0243913182064785,
        0.0240964513996787,
        0.023808650710739,
        0.0235276644239433,
        0.0232532526706738,
        0.0229851867388358,
        0.0227232484301405,
        0.0224672294614097,
        0.0222169309064178,
        0.0219721626750993,
        0.0217327430272292,
        0.021498498117938,
        0.0212692615726523,
        0.0210448740892578,
        0.0208251830654679,
        0.0206100422495519,
        0.0203993114127285,
        0.0201928560416692,
        0.0199905470496857,
        0.0197922605052854,
        0.0195978773768888,
        0.019407283292593,
        0.0192203683139573,
        0.0190370267228626,
        0.0188571568205694,
        0.0186806607381687,
        0.0185074442576751,
        0.0183374166430731,
        0.0181704904806735
      ]
    }
  },
  [EvaluationType.GMV]: {
    all: {
      median: [
        0.0,
        157.87,
        661.543966685345,
        1529.52631964607,
        2772.15696369026,
        4396.8384095054,
        6409.3805577266,
        8814.58291777515,
        11616.5434473558,
        14818.8431145249,
        18424.6653721333,
        22436.8781020256,
        26858.0923427781,
        31690.7058603813,
        36936.9363913464,
        42598.8475997294,
        48678.3697430569,
        55177.3164009103,
        62097.3982116338,
        69440.2342940351,
        77207.3618491874,
        85400.2443113937,
        94020.278328052,
        103068.79978363,
        112547.089035553,
        122456.375494407,
        132797.841654115,
        143572.626657198,
        154781.829464341,
        166426.511685044,
        178507.700116232,
        191026.389027886,
        203983.542228402,
        217380.094937218,
        231216.955488127,
        245495.006883157,
        260215.108214124,
        275378.095966517,
        290984.785218444,
        307035.970745646,
        323532.428042201,
        340474.914265325,
        357864.169111655,
        375700.91563151,
        393985.860986886,
        412719.697158281,
        431903.101604868,
        451536.737882065,
        471621.256220108,
        492157.294066849,
        513145.476597707,
        534586.417195357,
        556480.717901537,
        578828.969843101,
        601631.753634235,
        624889.639756615,
        648603.188919081,
        672772.952398279,
        697399.472361621,
        722483.282173745,
        748024.906687589,
        774024.862521134,
        800483.65832068,
        827401.795011599,
        854779.766037274,
        882618.057587017,
        910917.148813608,
        939677.512041081,
        968899.612963353,
        998583.910834192,
        1028730.85864908,
        1059340.90331936,
        1090414.48583916,
        1121952.04144545,
        1153953.99977163,
        1186420.78499498,
        1219352.81597829,
        1252750.50640602,
        1286614.26491518,
        1320944.49522134,
        1355741.59623981,
        1391005.96220246,
        1426737.98277023,
        1462938.04314158,
        1499606.52415709,
        1536743.80240043,
        1574350.25029572,
        1612426.23620168,
        1650972.12450247,
        1689988.27569556,
        1729475.04647662,
        1769432.78982171,
        1809861.85506675,
        1850762.58798443,
        1892135.3308587,
        1933980.42255696,
        1976298.19859996,
        2019088.99122952,
        2062353.12947431,
        2106090.93921351,
        2150302.74323867,
        2194988.86131373,
        2240149.61023325,
        2285785.30387897,
        2331896.25327479,
        2378482.76664016,
        2425545.14944193,
        2473083.70444485,
        2521098.73176064,
        2569590.52889563,
        2618559.39079729,
        2668005.60989933,
        2717929.47616578,
        2768331.27713373,
        2819211.29795508,
        2870569.82143718,
        2922407.12808244,
        2974723.49612685,
        3027519.20157767,
        3080794.51825007,
        3134549.7178029
      ],
      average: [
        15792.0,
        16792.0151157084,
        17855.3553474024,
        18986.0307047825,
        20188.3051280401,
        21466.7125677927,
        22826.0740832663,
        24271.5160232071,
        25808.4893580833,
        27442.7902364826,
        29180.5818432263,
        31028.41764163,
        32993.266087561,
        35082.5369084924,
        37304.1090466566,
        39666.3603716749,
        42178.1992747151,
        44849.0982633216,
        47689.129683611,
        50709.0037045449,
        53920.1087075246,
        57334.5542336241,
        60965.216650419,
        64825.7877106301,
        68930.8261857017,
        73295.812769033,
        77937.20845591,
        82872.5166202985,
        88120.3490225966,
        93700.4959972732,
        99634.0010850799,
        105943.240391285,
        112652.006969198,
        119785.600547217,
        127370.922937758,
        135436.579487877,
        144012.986954171,
        153132.488208761,
        162829.474208938,
        173140.513690434,
        184104.491073409,
        195762.753101221,
        208159.264764968,
        221340.775101829,
        235356.993492434,
        250260.777122127,
        266108.330313049,
        282959.416478764,
        300877.583500723,
        319930.403376516,
        340189.727043638,
        361731.955339759,
        384638.327121326,
        408995.225627031,
        434894.504241493,
        462433.832887642,
        491717.066354118,
        522854.63594669,
        555963.965940667,
        591169.916404815,
        628605.254066724,
        668411.152995322,
        710737.726988697,
        755744.595674924,
        803601.486460753,
        854488.874598194,
        908598.66378277,
        966134.909850094,
        1027314.59029992,
        1092368.42254964,
        1161541.73400305,
        1235095.38721538,
        1313306.76364376,
        1396470.80969276,
        1484901.14899989,
        1578931.26515571,
        1678915.75931859,
        1785231.68746699,
        1898279.98233202,
        2018486.96537271,
        2146305.95449588,
        2282218.97358343,
        2426738.57027416,
        2580409.74885487,
        2743812.02555042,
        2917561.61396308,
        3102313.74890315,
        3298765.15737409,
        3507656.68603076,
        3729776.09501925,
        3965961.02873423,
        4217102.17469703,
        4484146.62246701,
        4768101.4352532,
        5070037.4476952,
        5391093.30413533,
        5732479.75261111,
        6095484.210761,
        6481475.62086249,
        6891909.61231118,
        7328333.9910097,
        7792394.57636741,
        8285841.40792334,
        8810535.34499816,
        9368455.08426367,
        9961704.62169392,
        10592521.1870381,
        11263283.6807374,
        11976521.6451022,
        12734924.8035818,
        13541353.2041005,
        14398848.0047114,
        15310642.9422429,
        16280176.5271883,
        17311105.0108248,
        18407316.1734666,
        19572943.9858457,
        20812384.198913,
        22130310.9208508,
        23531694.2438103,
        25021818.9868472
      ]
    },
    SaaS: { median: null, average: null },
    Transactional: {
      median: [
        0.0,
        157.87,
        661.543966685345,
        1529.52631964607,
        2772.15696369026,
        4396.8384095054,
        6409.3805577266,
        8814.58291777515,
        11616.5434473558,
        14818.8431145249,
        18424.6653721333,
        22436.8781020256,
        26858.0923427781,
        31690.7058603813,
        36936.9363913464,
        42598.8475997294,
        48678.3697430569,
        55177.3164009103,
        62097.3982116338,
        69440.2342940351,
        77207.3618491874,
        85400.2443113937,
        94020.278328052,
        103068.79978363,
        112547.089035553,
        122456.375494407,
        132797.841654115,
        143572.626657198,
        154781.829464341,
        166426.511685044,
        178507.700116232,
        191026.389027886,
        203983.542228402,
        217380.094937218,
        231216.955488127,
        245495.006883157,
        260215.108214124,
        275378.095966517,
        290984.785218444,
        307035.970745646,
        323532.428042201,
        340474.914265325,
        357864.169111655,
        375700.91563151,
        393985.860986886,
        412719.697158281,
        431903.101604868,
        451536.737882065,
        471621.256220108,
        492157.294066849,
        513145.476597707,
        534586.417195357,
        556480.717901537,
        578828.969843101,
        601631.753634235,
        624889.639756615,
        648603.188919081,
        672772.952398279,
        697399.472361621,
        722483.282173745,
        748024.906687589,
        774024.862521134,
        800483.65832068,
        827401.795011599,
        854779.766037274,
        882618.057587017,
        910917.148813608,
        939677.512041081,
        968899.612963353,
        998583.910834192,
        1028730.85864908,
        1059340.90331936,
        1090414.48583916,
        1121952.04144545,
        1153953.99977163,
        1186420.78499498,
        1219352.81597829,
        1252750.50640602,
        1286614.26491518,
        1320944.49522134,
        1355741.59623981,
        1391005.96220246,
        1426737.98277023,
        1462938.04314158,
        1499606.52415709,
        1536743.80240043,
        1574350.25029572,
        1612426.23620168,
        1650972.12450247,
        1689988.27569556,
        1729475.04647662,
        1769432.78982171,
        1809861.85506675,
        1850762.58798443,
        1892135.3308587,
        1933980.42255696,
        1976298.19859996,
        2019088.99122952,
        2062353.12947431,
        2106090.93921351,
        2150302.74323867,
        2194988.86131373,
        2240149.61023325,
        2285785.30387897,
        2331896.25327479,
        2378482.76664016,
        2425545.14944193,
        2473083.70444485,
        2521098.73176064,
        2569590.52889563,
        2618559.39079729,
        2668005.60989933,
        2717929.47616578,
        2768331.27713373,
        2819211.29795508,
        2870569.82143718,
        2922407.12808244,
        2974723.49612685,
        3027519.20157767,
        3080794.51825007,
        3134549.7178029
      ],
      average: [
        15792.0,
        16792.0151157084,
        17855.3553474024,
        18986.0307047825,
        20188.3051280401,
        21466.7125677927,
        22826.0740832663,
        24271.5160232071,
        25808.4893580833,
        27442.7902364826,
        29180.5818432263,
        31028.41764163,
        32993.266087561,
        35082.5369084924,
        37304.1090466566,
        39666.3603716749,
        42178.1992747151,
        44849.0982633216,
        47689.129683611,
        50709.0037045449,
        53920.1087075246,
        57334.5542336241,
        60965.216650419,
        64825.7877106301,
        68930.8261857017,
        73295.812769033,
        77937.20845591,
        82872.5166202985,
        88120.3490225966,
        93700.4959972732,
        99634.0010850799,
        105943.240391285,
        112652.006969198,
        119785.600547217,
        127370.922937758,
        135436.579487877,
        144012.986954171,
        153132.488208761,
        162829.474208938,
        173140.513690434,
        184104.491073409,
        195762.753101221,
        208159.264764968,
        221340.775101829,
        235356.993492434,
        250260.777122127,
        266108.330313049,
        282959.416478764,
        300877.583500723,
        319930.403376516,
        340189.727043638,
        361731.955339759,
        384638.327121326,
        408995.225627031,
        434894.504241493,
        462433.832887642,
        491717.066354118,
        522854.63594669,
        555963.965940667,
        591169.916404815,
        628605.254066724,
        668411.152995322,
        710737.726988697,
        755744.595674924,
        803601.486460753,
        854488.874598194,
        908598.66378277,
        966134.909850094,
        1027314.59029992,
        1092368.42254964,
        1161541.73400305,
        1235095.38721538,
        1313306.76364376,
        1396470.80969276,
        1484901.14899989,
        1578931.26515571,
        1678915.75931859,
        1785231.68746699,
        1898279.98233202,
        2018486.96537271,
        2146305.95449588,
        2282218.97358343,
        2426738.57027416,
        2580409.74885487,
        2743812.02555042,
        2917561.61396308,
        3102313.74890315,
        3298765.15737409,
        3507656.68603076,
        3729776.09501925,
        3965961.02873423,
        4217102.17469703,
        4484146.62246701,
        4768101.4352532,
        5070037.4476952,
        5391093.30413533,
        5732479.75261111,
        6095484.210761,
        6481475.62086249,
        6891909.61231118,
        7328333.9910097,
        7792394.57636741,
        8285841.40792334,
        8810535.34499816,
        9368455.08426367,
        9961704.62169392,
        10592521.1870381,
        11263283.6807374,
        11976521.6451022,
        12734924.8035818,
        13541353.2041005,
        14398848.0047114,
        15310642.9422429,
        16280176.5271883,
        17311105.0108248,
        18407316.1734666,
        19572943.9858457,
        20812384.198913,
        22130310.9208508,
        23531694.2438103,
        25021818.9868472
      ]
    },
    [CustomerFocus.B2B]: {
      median: [
        0.0,
        109.63,
        526.795361500787,
        1319.5267764377,
        2531.36324818704,
        4195.82035910667,
        6340.60553866156,
        8989.56000215306,
        12163.7363624783,
        15882.0661656123,
        20161.8051889808,
        25018.8468659012,
        30467.9520831261,
        36522.9231735817,
        43196.7391322378,
        50501.6629842534,
        58449.3285986686,
        67050.811975477,
        76316.6905691246,
        86257.0932340445,
        96881.7427076308,
        108199.992075475,
        120220.856326327,
        132953.039857854,
        146404.960611321,
        160584.771375481,
        175500.378694631,
        191159.459734385,
        207569.477395078,
        224737.693912424,
        242671.183144948,
        261376.841715528,
        280861.39914817,
        301131.427119868,
        322193.347929789,
        344053.442273509,
        366717.856397936,
        390192.608702374,
        414483.595842704,
        439596.59838838,
        465537.286075825,
        492311.222696553,
        519923.870653855,
        548380.595217983,
        577686.668506448,
        607847.273213127,
        638867.506107331,
        670752.381321789,
        703506.833446585,
        737135.720444314,
        771643.826400328,
        807035.864120511,
        843316.477587918,
        880490.244288549,
        918561.677415584,
        957535.2279606,
        997415.286699534,
        1038206.1860805,
        1079912.20201996,
        1122537.55561324,
        1166086.41476482,
        1210562.89574352,
        1255971.06466721,
        1302314.93892125,
        1349598.4885148,
        1397825.63737848,
        1447000.26460694,
        1497126.20564935,
        1548207.2534509,
        1600247.15954782,
        1653249.63511871,
        1707218.35199431,
        1762156.94362806,
        1818069.00602941,
        1874958.09866188,
        1932827.74530762,
        1991681.43490015,
        2051522.6223269,
        2112354.72920298,
        2174181.14461758,
        2237005.22585432,
        2300830.2990868,
        2365659.6600504,
        2431496.57469158,
        2498344.27979555,
        2566205.98359336,
        2635084.8663493,
        2704984.08092952,
        2775906.75335255,
        2847855.98332265,
        2920834.84474666,
        2994846.38623496,
        3069893.63158731,
        3145979.58026415,
        3223107.20784389,
        3301279.4664669,
        3380499.28526651,
        3460769.57078772,
        3542093.20739402,
        3624473.05766272,
        3707911.96276943,
        3792412.74286171,
        3877978.19742282,
        3964611.10562546,
        4052314.22667607,
        4141090.30015012,
        4230942.04631855,
        4321872.16646567,
        4413883.34319906,
        4506978.24075132,
        4601159.5052745,
        4696429.76512688,
        4792791.63115289,
        4890247.69695594,
        4988800.53916474,
        5088452.71769318,
        5189206.77599395,
        5291065.2413062,
        5394030.62489727,
        5498105.42229895,
        5603292.11353816
      ],
      average: [
        0.0,
        81.261,
        425.168591237522,
        1119.33851099898,
        2224.5398281451,
        3789.68533213119,
        5856.5311507284,
        8461.9260021323,
        11639.0945826929,
        15418.450452313,
        19828.1484832296,
        24894.479995288,
        30642.1665853713,
        37094.5854301401,
        44273.9463885842,
        52201.4341051968,
        60897.3240177184,
        70381.0784681552,
        80671.4273498413,
        91786.4365344922,
        103743.566501312,
        116559.723008704,
        130251.301229215,
        144834.22445956,
        160323.978286851,
        176735.640917172,
        194083.910238276,
        212383.128083627,
        231647.302082917,
        251890.125418969,
        273124.994758677,
        295365.026583448,
        318623.072110215,
        342911.730965894,
        368243.363754852,
        394630.103639634,
        422083.86703895,
        450616.363533356,
        480239.105057559,
        510963.414448472,
        542800.433409864,
        575761.129947232,
        609856.305320467,
        645096.600556477,
        681492.5025594,
        719054.349851985,
        757792.337978247,
        797716.524594405,
        838836.834272471,
        881163.063038392,
        924704.882664678,
        969471.844735445,
        1015473.38450028,
        1062718.82453177,
        1111217.37820029,
        1160978.15297838,
        1212010.15358618,
        1264322.28498814,
        1317923.35525068,
        1372822.07826956,
        1429027.07637491,
        1486546.88282158,
        1545389.94417147,
        1605564.62257437,
        1667079.19795315,
        1729941.87009868,
        1794160.76067965,
        1859743.91517197,
        1926699.30471218,
        1995034.82787876,
        2064758.31240546,
        2135877.51682992,
        2208400.13208102,
        2282333.78300814,
        2357686.02985506,
        2434464.36968136,
        2512676.23773385,
        2592329.00877038,
        2673429.99833833,
        2755986.46400991,
        2840005.60657619,
        2925494.57120183,
        3012460.44854221,
        3100910.27582468,
        3190851.03789545,
        3282289.66823374,
        3375233.0499343,
        3469688.01666008,
        3565661.35356584,
        3663159.79819427,
        3762190.04134555,
        3862758.72792139,
        3964872.45774478,
        4068537.78635618,
        4173761.22578722,
        4280549.24531271,
        4388908.27218184,
        4498844.69232932,
        4610364.85106721,
        4723475.0537582,
        4838181.56647099,
        4954490.61661838,
        5072408.39357884,
        5191941.04930195,
        5313094.69889839,
        5435875.4212151,
        5560289.25939588,
        5686342.22142825,
        5814040.28067669,
        5943389.37640308,
        6074395.41427447,
        6207064.26685865,
        6341401.7741082,
        6477413.74383299,
        6615105.95216168,
        6754484.14399272,
        6895554.03343498,
        7038321.30423829,
        7182791.61021444,
        7328970.57564879,
        7476863.79570269
      ]
    },
    [CustomerFocus.B2C]: {
      median: [
        35914.0,
        37495.7349416866,
        39147.1331184836,
        40871.2626590629,
        42671.326819521,
        44550.6699347008,
        46512.783631623,
        48561.3133165717,
        50700.0649478858,
        52933.0121070397,
        55264.3033811509,
        57698.2700706295,
        60239.4342362916,
        62892.5171008842,
        65662.4478206339,
        68554.3726431147,
        71573.664468448,
        74725.9328316016,
        78017.0343243308,
        81453.0834761256,
        85040.4641143811,
        88785.8412248941,
        92696.1733347248,
        96778.7254404266,
        101041.082505666,
        105491.163553309,
        110137.236378154,
        114987.932907644,
        120052.265239112,
        125339.642383327,
        130859.887745476,
        136623.257376044,
        142640.459025505,
        148922.672038226,
        155481.568122544,
        162329.333035604,
        169478.68922325,
        176942.919457021,
        184735.891512187,
        192872.083932649,
        201366.612930593,
        210235.260470868,
        219494.503592251,
        229161.545020107,
        239254.345127286,
        249791.655302654,
        260793.05278926,
        272278.977056841,
        284270.767776268,
        296790.704466467,
        309862.047887484,
        323509.083256594,
        337757.165367747,
        352632.765698181,
        368163.521589711,
        384378.287596083,
        401307.189091779,
        418981.678241874,
        437434.592436942,
        456700.215301562,
        476814.340389781,
        497814.337685871,
        519739.223033934,
        542629.730625343,
        566528.388678696,
        591479.598452893,
        617529.716740127,
        644727.141992055,
        673122.404239161,
        702768.258970386,
        733719.785147413,
        766034.487535743,
        799772.403542657,
        834996.21476057,
        871771.36342301,
        910166.173989585,
        950251.980085831,
        992103.257033781,
        1035797.76021949,
        1081416.66955456,
        1129044.74030015,
        1178770.46053349,
        1230686.21554975,
        1284888.45950439,
        1341477.89461516,
        1400559.65825644,
        1462243.51829378,
        1526644.07702129,
        1593880.98408104,
        1664079.15875974,
        1737369.02207607,
        1813886.73908954,
        1893774.47188121,
        1977180.64367629,
        2064260.21459931,
        2155174.96957412,
        2250093.81890373,
        2349193.11208837,
        2452656.96546477,
        2560677.60427545,
        2673455.71980357,
        2791200.84223675,
        2914131.72995271,
        3042476.77594993,
        3176474.43217842,
        3316373.65255907,
        3462434.35551451,
        3614927.90687091,
        3774137.62402789,
        3940359.30233326,
        4113901.76464045,
        4295087.43506976,
        4484252.93803933,
        4681749.72367886,
        4887944.72078791,
        5103221.0185521,
        5327978.5782835,
        5562634.97650784,
        5807626.1807789,
        6063407.35966156,
        6330453.72838843
      ],
      average: [
        25091.0,
        26514.963025164,
        28019.7387200913,
        29609.9133608854,
        31290.3335037338,
        33066.1207563091,
        34942.6873874752,
        36925.7528228755,
        39021.3610766764,
        41235.8991725976,
        43576.1166103708,
        46049.145936958,
        48662.5244852266,
        51424.2173463361,
        54342.6416458529,
        57426.6921975818,
        60685.7686133017,
        64129.8039510322,
        67769.2949891448,
        71615.3342185885,
        75679.6436507366,
        79974.6105438948,
        84513.3251573576,
        89309.6206480808,
        94378.1152315683,
        99734.2567354707,
        105394.369681687,
        111375.705040469,
        117696.492808162,
        124375.997568845,
        131434.577209191,
        138893.744965516,
        146776.234992117,
        155106.071650741,
        163908.642732367,
        173210.776834464,
        183040.825129558,
        193428.74777433,
        204406.205222595,
        216006.654720465,
        228265.452277808,
        241219.960426783,
        254909.662095877,
        269376.280946524,
        284663.908539048,
        300819.138715536,
        317891.209609186,
        335932.153712973,
        354996.956464995,
        375143.723833848,
        396433.859414802,
        418932.251576522,
        442707.471228734,
        467831.980813587,
        494382.355157685,
        522439.514857897,
        552088.972912268,
        583421.095347716,
        616531.376638848,
        651520.730757343,
        688495.79873894,
        727569.27370546,
        768860.244332457,
        812494.557809318,
        858605.203398072,
        907332.717759884,
        958825.613284637,
        1013240.83072904,
        1070744.21754282,
        1131511.03334088,
        1195726.48406195,
        1263586.28644183,
        1335297.26452151,
        1411077.98000839,
        1491159.3984116,
        1575785.59298192,
        1665214.48860156,
        1759718.64789118,
        1859586.10193002,
        1965121.22812097,
        2076645.67787622,
        2194499.35695088,
        2319041.4614124,
        2450651.57240333,
        2589730.81303393,
        2736703.07093071,
        2892016.29016701,
        3056143.83651309,
        3229585.94016688,
        3412871.22036245,
        3606558.296503,
        3811237.49072876,
        4027532.62710899,
        4256102.93294154,
        4497645.04795491,
        4752895.14753642,
        5022631.18645767,
        5307675.26993585,
        5608896.15925735,
        5927211.91960037,
        6263592.7181266,
        6619063.78087016,
        6994708.5174355,
        7391671.82302824,
        7811163.56788251,
        8254462.28472012,
        8722919.06548022,
        9217961.67919596,
        9741098.92356857,
        10293925.2235019,
        10878125.4906123,
        11495480.2585262,
        12147871.1096139,
        12837286.4097035,
        13565827.3682487,
        14335714.4424244,
        15149294.1046667,
        16009045.9942837,
        16917590.4749345,
        17877696.6210097,
        18892290.6572544
      ]
    },
    [Industry.OTHER]: {
      median: [
        0.0,
        157.87,
        661.543966685345,
        1529.52631964607,
        2772.15696369026,
        4396.8384095054,
        6409.3805577266,
        8814.58291777515,
        11616.5434473558,
        14818.8431145249,
        18424.6653721333,
        22436.8781020256,
        26858.0923427781,
        31690.7058603813,
        36936.9363913464,
        42598.8475997294,
        48678.3697430569,
        55177.3164009103,
        62097.3982116338,
        69440.2342940351,
        77207.3618491874,
        85400.2443113937,
        94020.278328052,
        103068.79978363,
        112547.089035553,
        122456.375494407,
        132797.841654115,
        143572.626657198,
        154781.829464341,
        166426.511685044,
        178507.700116232,
        191026.389027886,
        203983.542228402,
        217380.094937218,
        231216.955488127,
        245495.006883157,
        260215.108214124,
        275378.095966517,
        290984.785218444,
        307035.970745646,
        323532.428042201,
        340474.914265325,
        357864.169111655,
        375700.91563151,
        393985.860986886,
        412719.697158281,
        431903.101604868,
        451536.737882065,
        471621.256220108,
        492157.294066849,
        513145.476597707,
        534586.417195357,
        556480.717901537,
        578828.969843101,
        601631.753634235,
        624889.639756615,
        648603.188919081,
        672772.952398279,
        697399.472361621,
        722483.282173745,
        748024.906687589,
        774024.862521134,
        800483.65832068,
        827401.795011599,
        854779.766037274,
        882618.057587017,
        910917.148813608,
        939677.512041081,
        968899.612963353,
        998583.910834192,
        1028730.85864908,
        1059340.90331936,
        1090414.48583916,
        1121952.04144545,
        1153953.99977163,
        1186420.78499498,
        1219352.81597829,
        1252750.50640602,
        1286614.26491518,
        1320944.49522134,
        1355741.59623981,
        1391005.96220246,
        1426737.98277023,
        1462938.04314158,
        1499606.52415709,
        1536743.80240043,
        1574350.25029572,
        1612426.23620168,
        1650972.12450247,
        1689988.27569556,
        1729475.04647662,
        1769432.78982171,
        1809861.85506675,
        1850762.58798443,
        1892135.3308587,
        1933980.42255696,
        1976298.19859996,
        2019088.99122952,
        2062353.12947431,
        2106090.93921351,
        2150302.74323867,
        2194988.86131373,
        2240149.61023325,
        2285785.30387897,
        2331896.25327479,
        2378482.76664016,
        2425545.14944193,
        2473083.70444485,
        2521098.73176064,
        2569590.52889563,
        2618559.39079729,
        2668005.60989933,
        2717929.47616578,
        2768331.27713373,
        2819211.29795508,
        2870569.82143718,
        2922407.12808244,
        2974723.49612685,
        3027519.20157767,
        3080794.51825007,
        3134549.7178029
      ],
      average: [
        15792.0,
        16792.0151157084,
        17855.3553474024,
        18986.0307047825,
        20188.3051280401,
        21466.7125677927,
        22826.0740832663,
        24271.5160232071,
        25808.4893580833,
        27442.7902364826,
        29180.5818432263,
        31028.41764163,
        32993.266087561,
        35082.5369084924,
        37304.1090466566,
        39666.3603716749,
        42178.1992747151,
        44849.0982633216,
        47689.129683611,
        50709.0037045449,
        53920.1087075246,
        57334.5542336241,
        60965.216650419,
        64825.7877106301,
        68930.8261857017,
        73295.812769033,
        77937.20845591,
        82872.5166202985,
        88120.3490225966,
        93700.4959972732,
        99634.0010850799,
        105943.240391285,
        112652.006969198,
        119785.600547217,
        127370.922937758,
        135436.579487877,
        144012.986954171,
        153132.488208761,
        162829.474208938,
        173140.513690434,
        184104.491073409,
        195762.753101221,
        208159.264764968,
        221340.775101829,
        235356.993492434,
        250260.777122127,
        266108.330313049,
        282959.416478764,
        300877.583500723,
        319930.403376516,
        340189.727043638,
        361731.955339759,
        384638.327121326,
        408995.225627031,
        434894.504241493,
        462433.832887642,
        491717.066354118,
        522854.63594669,
        555963.965940667,
        591169.916404815,
        628605.254066724,
        668411.152995322,
        710737.726988697,
        755744.595674924,
        803601.486460753,
        854488.874598194,
        908598.66378277,
        966134.909850094,
        1027314.59029992,
        1092368.42254964,
        1161541.73400305,
        1235095.38721538,
        1313306.76364376,
        1396470.80969276,
        1484901.14899989,
        1578931.26515571,
        1678915.75931859,
        1785231.68746699,
        1898279.98233202,
        2018486.96537271,
        2146305.95449588,
        2282218.97358343,
        2426738.57027416,
        2580409.74885487,
        2743812.02555042,
        2917561.61396308,
        3102313.74890315,
        3298765.15737409,
        3507656.68603076,
        3729776.09501925,
        3965961.02873423,
        4217102.17469703,
        4484146.62246701,
        4768101.4352532,
        5070037.4476952,
        5391093.30413533,
        5732479.75261111,
        6095484.210761,
        6481475.62086249,
        6891909.61231118,
        7328333.9910097,
        7792394.57636741,
        8285841.40792334,
        8810535.34499816,
        9368455.08426367,
        9961704.62169392,
        10592521.1870381,
        11263283.6807374,
        11976521.6451022,
        12734924.8035818,
        13541353.2041005,
        14398848.0047114,
        15310642.9422429,
        16280176.5271883,
        17311105.0108248,
        18407316.1734666,
        19572943.9858457,
        20812384.198913,
        22130310.9208508,
        23531694.2438103,
        25021818.9868472
      ]
    },
    [Industry.IT]: { median: null, average: null },
    [Industry.FIPT]: { median: null, average: null },
    [Industry.DH]: { median: null, average: null }
  },
  [EvaluationType.GMV_GROWTH]: {
    all: {
      median: [
        null,
        null,
        null,
        null,
        5.51991504336956,
        1.8821094444338,
        1.06347831905016,
        0.726561306259428,
        0.547340639298326,
        0.43735180964098,
        0.36341603998751,
        0.310486353756484,
        0.270809471769825,
        0.240005091369029,
        0.215420021498917,
        0.195357077189981,
        0.178681871350732,
        0.164608309834789,
        0.15257494596347,
        0.142170363418545,
        0.133086365226669,
        0.125087613817794,
        0.117991364727333,
        0.111653592416741,
        0.105959278153718,
        0.100815475384777,
        0.0961462697178784,
        0.091889056949439,
        0.0879917544497605,
        0.0844106842201007,
        0.0811089464438721,
        0.0780551560627361,
        0.0752224513652658,
        0.072587708720083,
        0.0701309151836144,
        0.0678346631976064,
        0.0656837405609383,
        0.0636647953796762,
        0.0617660604898276,
        0.0599771254032318,
        0.0582887464919495,
        0.0566926881414423,
        0.0551815891392919,
        0.0537488497469403,
        0.0523885358162396,
        0.0510952970254947,
        0.0498642968693053,
        0.0486911524786041,
        0.0475718826985846,
        0.0465028631330255,
        0.0454807870891583,
        0.044502631539479,
        0.043565627364891,
        0.0426672332642023,
        0.0418051128138911,
        0.0409771142433858,
        0.0401812525583004,
        0.0394156936998458,
        0.03867874047501,
        0.0379688200309282,
        0.0372844726793562,
        0.0366243419045684,
        0.0359871654110595,
        0.0353717670870556,
        0.0347770497763718,
        0.0342019887654113,
        0.0336456259040943,
        0.0331070642899397,
        0.0325854634533611,
        0.0320800349899229,
        0.0315900385919151,
        0.0311147784372943,
        0.0306535998990314,
        0.0302058865421759,
        0.0297710573797382,
        0.0293485643617241,
        0.0289378900745638,
        0.0285385456306288,
        0.028150068729798,
        0.0277720218769066,
        0.0274039907406776,
        0.0270455826411857,
        0.0266964251542767,
        0.0263561648225298,
        0.0260244659633892,
        0.0257010095660318,
        0.0253854922693539,
        0.0250776254142196,
        0.024777134163726,
        0.0244837566858917,
        0.0241972433936285,
        0.0239173562374017,
        0.0236438680463345,
        0.0233765619139567,
        0.0231152306250916,
        0.0228596761207194,
        0.0226097089978907,
        0.0223651480420649,
        0.022125819789431,
        0.0218915581170046,
        0.0216622038584586,
        0.0214376044438183,
        0.0212176135613285,
        0.0210020908398911,
        0.0207909015506524,
        0.0205839163263894,
        0.0203810108974776,
        0.0201820658433075,
        0.0199869663581012,
        0.0197956020301725,
        0.0196078666337319,
        0.0194236579324158,
        0.0192428774937806,
        0.0190654305140459,
        0.0188912256524389,
        0.0187201748745317,
        0.018552193304003,
        0.0183871990823076,
        0.0182251132357535,
        0.0180658595495515,
        0.0179093644483983
      ],
      average: [
        null,
        null,
        null,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009466,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009466,
        0.0674187501009466,
        0.0674187501009467,
        0.0674187501009466,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009465,
        0.0674187501009467,
        0.0674187501009466,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009466,
        0.0674187501009467,
        0.0674187501009466,
        0.0674187501009467,
        0.0674187501009466,
        0.0674187501009467,
        0.0674187501009466,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009466,
        0.0674187501009465,
        0.0674187501009465,
        0.0674187501009467,
        0.0674187501009468,
        0.0674187501009467,
        0.0674187501009465,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009465,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009465,
        0.0674187501009465,
        0.0674187501009466,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009465,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009465,
        0.0674187501009467,
        0.0674187501009468,
        0.0674187501009467,
        0.0674187501009466,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009465,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009465,
        0.0674187501009465,
        0.0674187501009466,
        0.0674187501009465,
        0.0674187501009469,
        0.0674187501009469,
        0.0674187501009469,
        0.0674187501009465,
        0.0674187501009465,
        0.0674187501009465,
        0.0674187501009465,
        0.0674187501009465,
        0.0674187501009465,
        0.0674187501009469,
        0.0674187501009469,
        0.067418750100947,
        0.0674187501009465,
        0.0674187501009465,
        0.0674187501009465,
        0.0674187501009465,
        0.0674187501009465,
        0.0674187501009469,
        0.0674187501009469,
        0.067418750100947,
        0.0674187501009465,
        0.0674187501009465,
        0.0674187501009465,
        0.0674187501009466,
        0.0674187501009465,
        0.0674187501009465,
        0.0674187501009469,
        0.0674187501009469,
        0.0674187501009469,
        0.0674187501009465,
        0.0674187501009465,
        0.0674187501009465,
        0.0674187501009466,
        0.0674187501009466,
        0.0674187501009469,
        0.0674187501009468,
        0.0674187501009468,
        0.0674187501009466,
        0.0674187501009465,
        0.0674187501009466,
        0.0674187501009465,
        0.0674187501009466,
        0.0674187501009465,
        0.0674187501009469,
        0.0674187501009468,
        0.0674187501009469,
        0.0674187501009466,
        0.0674187501009466
      ]
    },
    SaaS: { median: null, average: null },
    Transactional: {
      median: [
        null,
        null,
        null,
        null,
        5.51991504336956,
        1.8821094444338,
        1.06347831905016,
        0.726561306259428,
        0.547340639298326,
        0.43735180964098,
        0.36341603998751,
        0.310486353756484,
        0.270809471769825,
        0.240005091369029,
        0.215420021498917,
        0.195357077189981,
        0.178681871350732,
        0.164608309834789,
        0.15257494596347,
        0.142170363418545,
        0.133086365226669,
        0.125087613817794,
        0.117991364727333,
        0.111653592416741,
        0.105959278153718,
        0.100815475384777,
        0.0961462697178784,
        0.091889056949439,
        0.0879917544497605,
        0.0844106842201007,
        0.0811089464438721,
        0.0780551560627361,
        0.0752224513652658,
        0.072587708720083,
        0.0701309151836144,
        0.0678346631976064,
        0.0656837405609383,
        0.0636647953796762,
        0.0617660604898276,
        0.0599771254032318,
        0.0582887464919495,
        0.0566926881414423,
        0.0551815891392919,
        0.0537488497469403,
        0.0523885358162396,
        0.0510952970254947,
        0.0498642968693053,
        0.0486911524786041,
        0.0475718826985846,
        0.0465028631330255,
        0.0454807870891583,
        0.044502631539479,
        0.043565627364891,
        0.0426672332642023,
        0.0418051128138911,
        0.0409771142433858,
        0.0401812525583004,
        0.0394156936998458,
        0.03867874047501,
        0.0379688200309282,
        0.0372844726793562,
        0.0366243419045684,
        0.0359871654110595,
        0.0353717670870556,
        0.0347770497763718,
        0.0342019887654113,
        0.0336456259040943,
        0.0331070642899397,
        0.0325854634533611,
        0.0320800349899229,
        0.0315900385919151,
        0.0311147784372943,
        0.0306535998990314,
        0.0302058865421759,
        0.0297710573797382,
        0.0293485643617241,
        0.0289378900745638,
        0.0285385456306288,
        0.028150068729798,
        0.0277720218769066,
        0.0274039907406776,
        0.0270455826411857,
        0.0266964251542767,
        0.0263561648225298,
        0.0260244659633892,
        0.0257010095660318,
        0.0253854922693539,
        0.0250776254142196,
        0.024777134163726,
        0.0244837566858917,
        0.0241972433936285,
        0.0239173562374017,
        0.0236438680463345,
        0.0233765619139567,
        0.0231152306250916,
        0.0228596761207194,
        0.0226097089978907,
        0.0223651480420649,
        0.022125819789431,
        0.0218915581170046,
        0.0216622038584586,
        0.0214376044438183,
        0.0212176135613285,
        0.0210020908398911,
        0.0207909015506524,
        0.0205839163263894,
        0.0203810108974776,
        0.0201820658433075,
        0.0199869663581012,
        0.0197956020301725,
        0.0196078666337319,
        0.0194236579324158,
        0.0192428774937806,
        0.0190654305140459,
        0.0188912256524389,
        0.0187201748745317,
        0.018552193304003,
        0.0183871990823076,
        0.0182251132357535,
        0.0180658595495515,
        0.0179093644483983
      ],
      average: [
        null,
        null,
        null,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009466,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009466,
        0.0674187501009466,
        0.0674187501009467,
        0.0674187501009466,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009465,
        0.0674187501009467,
        0.0674187501009466,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009466,
        0.0674187501009467,
        0.0674187501009466,
        0.0674187501009467,
        0.0674187501009466,
        0.0674187501009467,
        0.0674187501009466,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009466,
        0.0674187501009465,
        0.0674187501009465,
        0.0674187501009467,
        0.0674187501009468,
        0.0674187501009467,
        0.0674187501009465,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009465,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009465,
        0.0674187501009465,
        0.0674187501009466,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009465,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009465,
        0.0674187501009467,
        0.0674187501009468,
        0.0674187501009467,
        0.0674187501009466,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009465,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009465,
        0.0674187501009465,
        0.0674187501009466,
        0.0674187501009465,
        0.0674187501009469,
        0.0674187501009469,
        0.0674187501009469,
        0.0674187501009465,
        0.0674187501009465,
        0.0674187501009465,
        0.0674187501009465,
        0.0674187501009465,
        0.0674187501009465,
        0.0674187501009469,
        0.0674187501009469,
        0.067418750100947,
        0.0674187501009465,
        0.0674187501009465,
        0.0674187501009465,
        0.0674187501009465,
        0.0674187501009465,
        0.0674187501009469,
        0.0674187501009469,
        0.067418750100947,
        0.0674187501009465,
        0.0674187501009465,
        0.0674187501009465,
        0.0674187501009466,
        0.0674187501009465,
        0.0674187501009465,
        0.0674187501009469,
        0.0674187501009469,
        0.0674187501009469,
        0.0674187501009465,
        0.0674187501009465,
        0.0674187501009465,
        0.0674187501009466,
        0.0674187501009466,
        0.0674187501009469,
        0.0674187501009468,
        0.0674187501009468,
        0.0674187501009466,
        0.0674187501009465,
        0.0674187501009466,
        0.0674187501009465,
        0.0674187501009466,
        0.0674187501009465,
        0.0674187501009469,
        0.0674187501009468,
        0.0674187501009469,
        0.0674187501009466,
        0.0674187501009466
      ]
    },
    [CustomerFocus.B2B]: {
      median: [
        null,
        null,
        null,
        null,
        7.36335324329423,
        2.32160042003989,
        1.26840390860405,
        0.850424075985062,
        0.633004221774967,
        0.501606172511025,
        0.414267408866577,
        0.352279653782405,
        0.306129059141668,
        0.270496909564806,
        0.242189316501655,
        0.219177961665744,
        0.200115466487332,
        0.184073098436241,
        0.170390610654547,
        0.158586165156905,
        0.148300121719948,
        0.139258753092252,
        0.131250126101235,
        0.124107653110929,
        0.117698617787931,
        0.111916008260635,
        0.106672598791942,
        0.10189659088096,
        0.0975283555162213,
        0.0935179657617045,
        0.0898233050775841,
        0.0864086007822741,
        0.0832432753917061,
        0.0803010383808135,
        0.0775591617261571,
        0.0749978973223037,
        0.0726000049273724,
        0.0703503669556446,
        0.0682356720550846,
        0.0662441535719872,
        0.0643653721205513,
        0.0625900338272051,
        0.0609098376101831,
        0.059317346229109,
        0.0578058769019218,
        0.0563694081139863,
        0.0550024998929675,
        0.0537002253349284,
        0.0524581115734296,
        0.0512720887077926,
        0.0501384454671243,
        0.049053790596803,
        0.0480150191245663,
        0.0470192828021809,
        0.0460639641323382,
        0.0451466534838632,
        0.0442651288754338,
        0.0434173380719665,
        0.0426013826909899,
        0.0418155040607471,
        0.0410580706089792,
        0.0403275665926603,
        0.0396225820053061,
        0.0389418035208845,
        0.038284006352235,
        0.0376480469181463,
        0.037032856226912,
        0.0364374338960889,
        0.0358608427382133,
        0.0353022038510143,
        0.0347606921581328,
        0.0342355323528727,
        0.0337259952031404,
        0.0332313941806037,
        0.0327510823813929,
        0.0322844497093366,
        0.031830920296016,
        0.0313899501347132,
        0.0309610249078853,
        0.0305436579899266,
        0.0301373886089602,
        0.0297417801530793,
        0.0293564186079659,
        0.0289809111141714,
        0.0286148846334798,
        0.0282579847148832,
        0.0279098743515663,
        0.0275702329212048,
        0.0272387552025411,
        0.0269151504619444,
        0.0265991416041868,
        0.0262904643822535,
        0.025988866661439,
        0.0256941077334365,
        0.0254059576765015,
        0.0251241967581296,
        0.0248486148769667,
        0.0245790110410065,
        0.0243151928793273,
        0.0240569761849032,
        0.0238041844861937,
        0.0235566486454169,
        0.023314206481606,
        0.0230767024166619,
        0.0228439871427988,
        0.0226159173098803,
        0.0223923552312766,
        0.0221731686069757,
        0.0219582302627757,
        0.0217474179044797,
        0.021540613886105,
        0.0213377049911674,
        0.0211385822262094,
        0.0209431406257521,
        0.0207512790679737,
        0.0205629001003997,
        0.0203779097750072,
        0.0201962174921285,
        0.0200177358526268,
        0.0198423805178352,
        0.0196700700767824
      ],
      average: [
        null,
        null,
        null,
        null,
        8.79174851464252,
        2.63779028698608,
        1.41071194971561,
        0.934633175975121,
        0.690418734242462,
        0.544231107430399,
        0.447739772963945,
        0.379622467435613,
        0.329123348681999,
        0.290268099776004,
        0.259488132803797,
        0.234527231832208,
        0.2138923000178,
        0.196557525807115,
        0.18179572862099,
        0.169077558086605,
        0.158008794216152,
        0.148289990801117,
        0.139689719407392,
        0.132026365084621,
        0.125155454355585,
        0.118960653365896,
        0.113347256061168,
        0.108237396038231,
        0.103566473407742,
        0.0992804523049242,
        0.095333791692299,
        0.0916878431529262,
        0.0883095973958876,
        0.0851706941832964,
        0.082246633388301,
        0.0795161411528573,
        0.0769606567556508,
        0.0745639142355708,
        0.0723115989955837,
        0.0701910641859243,
        0.0681910950841324,
        0.0663017122677012,
        0.0645140063362714,
        0.0628199984439894,
        0.0612125220644066,
        0.0596851223142563,
        0.0582319698706397,
        0.056847787074456,
        0.0555277842558738,
        0.0542676046709103,
        0.0530632767217817,
        0.0519111723621735,
        0.0508079707739776,
        0.0497506265528337,
        0.0487363417633248,
        0.0477625413260508,
        0.046826851282505,
        0.0459270795530017,
        0.045061198860539,
        0.044227331541616,
        0.043423736005287,
        0.0426487946356654,
        0.0419010029615847,
        0.0411789599413542,
        0.0404813592309784,
        0.0398069813217187,
        0.0391546864477033,
        0.0385234081770826,
        0.0379121476111171,
        0.0373199681250083,
        0.0367459905923898,
        0.0361893890423795,
        0.0356493867042107,
        0.0351252523996695,
        0.0346162972482236,
        0.0341218716536535,
        0.0336413625445733,
        0.0331741908441957,
        0.032719809147484,
        0.0322776995860929,
        0.0318473718636623,
        0.0314283614458046,
        0.0310202278907581,
        0.0306225533081518,
        0.0302349409345183,
        0.0298570138154131,
        0.0294884135849075,
        0.0291287993342084,
        0.028777846561867,
        0.0284352461988339,
        0.0281007037021764,
        0.0277739382119058,
        0.0274546817658389,
        0.0271426785678865,
        0.026837684305588,
        0.0265394655130542,
        0.0262477989758395,
        0.0259624711745693,
        0.0256832777643864,
        0.0254100230875899,
        0.0251425197169874,
        0.0248805880277572,
        0.0246240557957436,
        0.0243727578203174,
        0.0241265355700531,
        0.0238852368496443,
        0.0236487154865685,
        0.0234168310361718,
        0.0231894485038966,
        0.0229664380835242,
        0.022747674910355,
        0.0225330388283342,
        0.0223224141702344,
        0.0221156895500223,
        0.0219127576666519,
        0.0217135151185407,
        0.0215178622280684,
        0.0213257028754675,
        0.0211369443415181,
        0.0209514971585204,
        0.020769274969034
      ]
    },
    [CustomerFocus.B2C]: {
      median: [
        null,
        null,
        null,
        0.046010494134719,
        0.046010494134719,
        0.046010494134719,
        0.046010494134719,
        0.0460104941347189,
        0.0460104941347189,
        0.046010494134719,
        0.046010494134719,
        0.046010494134719,
        0.046010494134719,
        0.046010494134719,
        0.046010494134719,
        0.046010494134719,
        0.046010494134719,
        0.046010494134719,
        0.046010494134719,
        0.046010494134719,
        0.046010494134719,
        0.046010494134719,
        0.046010494134719,
        0.046010494134719,
        0.046010494134719,
        0.046010494134719,
        0.046010494134719,
        0.046010494134719,
        0.0460104941347189,
        0.046010494134719,
        0.046010494134719,
        0.046010494134719,
        0.046010494134719,
        0.0460104941347189,
        0.046010494134719,
        0.046010494134719,
        0.0460104941347191,
        0.0460104941347189,
        0.046010494134719,
        0.046010494134719,
        0.046010494134719,
        0.046010494134719,
        0.0460104941347189,
        0.046010494134719,
        0.046010494134719,
        0.046010494134719,
        0.046010494134719,
        0.046010494134719,
        0.046010494134719,
        0.046010494134719,
        0.0460104941347188,
        0.046010494134719,
        0.046010494134719,
        0.0460104941347191,
        0.0460104941347189,
        0.0460104941347189,
        0.0460104941347189,
        0.046010494134719,
        0.046010494134719,
        0.046010494134719,
        0.0460104941347189,
        0.0460104941347188,
        0.046010494134719,
        0.046010494134719,
        0.046010494134719,
        0.0460104941347189,
        0.0460104941347189,
        0.046010494134719,
        0.046010494134719,
        0.0460104941347191,
        0.0460104941347188,
        0.0460104941347189,
        0.046010494134719,
        0.0460104941347191,
        0.046010494134719,
        0.0460104941347189,
        0.0460104941347188,
        0.0460104941347189,
        0.046010494134719,
        0.0460104941347191,
        0.046010494134719,
        0.0460104941347188,
        0.0460104941347189,
        0.046010494134719,
        0.0460104941347191,
        0.046010494134719,
        0.0460104941347189,
        0.0460104941347188,
        0.0460104941347189,
        0.046010494134719,
        0.046010494134719,
        0.046010494134719,
        0.0460104941347189,
        0.046010494134719,
        0.0460104941347191,
        0.046010494134719,
        0.0460104941347189,
        0.0460104941347188,
        0.0460104941347189,
        0.0460104941347189,
        0.0460104941347189,
        0.046010494134719,
        0.0460104941347192,
        0.0460104941347193,
        0.0460104941347192,
        0.0460104941347189,
        0.0460104941347189,
        0.0460104941347189,
        0.0460104941347189,
        0.0460104941347188,
        0.0460104941347188,
        0.0460104941347188,
        0.0460104941347188,
        0.0460104941347193,
        0.0460104941347193,
        0.0460104941347192,
        0.0460104941347189,
        0.0460104941347188,
        0.046010494134719,
        0.0460104941347189,
        0.046010494134719
      ],
      average: [
        null,
        null,
        null,
        0.0600336556386144,
        0.0600336556386144,
        0.0600336556386144,
        0.0600336556386144,
        0.0600336556386144,
        0.0600336556386144,
        0.0600336556386145,
        0.0600336556386145,
        0.0600336556386144,
        0.0600336556386144,
        0.0600336556386144,
        0.0600336556386144,
        0.0600336556386144,
        0.0600336556386144,
        0.0600336556386145,
        0.0600336556386144,
        0.0600336556386144,
        0.0600336556386145,
        0.0600336556386144,
        0.0600336556386144,
        0.0600336556386144,
        0.0600336556386144,
        0.0600336556386144,
        0.0600336556386144,
        0.0600336556386144,
        0.0600336556386144,
        0.0600336556386144,
        0.0600336556386144,
        0.0600336556386145,
        0.0600336556386144,
        0.0600336556386145,
        0.0600336556386144,
        0.0600336556386144,
        0.0600336556386145,
        0.0600336556386144,
        0.0600336556386144,
        0.0600336556386144,
        0.0600336556386146,
        0.0600336556386144,
        0.0600336556386144,
        0.0600336556386144,
        0.0600336556386144,
        0.0600336556386144,
        0.0600336556386144,
        0.0600336556386144,
        0.0600336556386144,
        0.0600336556386144,
        0.0600336556386144,
        0.0600336556386144,
        0.0600336556386145,
        0.0600336556386144,
        0.0600336556386144,
        0.0600336556386144,
        0.0600336556386144,
        0.0600336556386144,
        0.0600336556386144,
        0.0600336556386146,
        0.0600336556386144,
        0.0600336556386144,
        0.0600336556386145,
        0.0600336556386144,
        0.0600336556386144,
        0.0600336556386144,
        0.0600336556386144,
        0.0600336556386144,
        0.0600336556386144,
        0.0600336556386144,
        0.0600336556386144,
        0.0600336556386144,
        0.0600336556386146,
        0.0600336556386147,
        0.0600336556386142,
        0.0600336556386143,
        0.0600336556386142,
        0.0600336556386146,
        0.0600336556386146,
        0.0600336556386146,
        0.0600336556386146,
        0.0600336556386142,
        0.0600336556386143,
        0.0600336556386142,
        0.0600336556386147,
        0.0600336556386147,
        0.0600336556386146,
        0.0600336556386142,
        0.0600336556386142,
        0.0600336556386142,
        0.0600336556386147,
        0.0600336556386147,
        0.0600336556386146,
        0.0600336556386142,
        0.0600336556386142,
        0.0600336556386143,
        0.0600336556386147,
        0.0600336556386146,
        0.0600336556386146,
        0.0600336556386146,
        0.0600336556386142,
        0.0600336556386143,
        0.0600336556386142,
        0.0600336556386146,
        0.0600336556386146,
        0.0600336556386146,
        0.0600336556386144,
        0.0600336556386142,
        0.0600336556386143,
        0.0600336556386145,
        0.0600336556386146,
        0.0600336556386146,
        0.0600336556386143,
        0.0600336556386142,
        0.0600336556386143,
        0.0600336556386146,
        0.0600336556386147,
        0.0600336556386146,
        0.0600336556386147,
        0.0600336556386142,
        0.0600336556386143
      ]
    },
    [Industry.OTHER]: {
      median: [
        null,
        null,
        null,
        null,
        5.51991504336956,
        1.8821094444338,
        1.06347831905016,
        0.726561306259428,
        0.547340639298326,
        0.43735180964098,
        0.36341603998751,
        0.310486353756484,
        0.270809471769825,
        0.240005091369029,
        0.215420021498917,
        0.195357077189981,
        0.178681871350732,
        0.164608309834789,
        0.15257494596347,
        0.142170363418545,
        0.133086365226669,
        0.125087613817794,
        0.117991364727333,
        0.111653592416741,
        0.105959278153718,
        0.100815475384777,
        0.0961462697178784,
        0.091889056949439,
        0.0879917544497605,
        0.0844106842201007,
        0.0811089464438721,
        0.0780551560627361,
        0.0752224513652658,
        0.072587708720083,
        0.0701309151836144,
        0.0678346631976064,
        0.0656837405609383,
        0.0636647953796762,
        0.0617660604898276,
        0.0599771254032318,
        0.0582887464919495,
        0.0566926881414423,
        0.0551815891392919,
        0.0537488497469403,
        0.0523885358162396,
        0.0510952970254947,
        0.0498642968693053,
        0.0486911524786041,
        0.0475718826985846,
        0.0465028631330255,
        0.0454807870891583,
        0.044502631539479,
        0.043565627364891,
        0.0426672332642023,
        0.0418051128138911,
        0.0409771142433858,
        0.0401812525583004,
        0.0394156936998458,
        0.03867874047501,
        0.0379688200309282,
        0.0372844726793562,
        0.0366243419045684,
        0.0359871654110595,
        0.0353717670870556,
        0.0347770497763718,
        0.0342019887654113,
        0.0336456259040943,
        0.0331070642899397,
        0.0325854634533611,
        0.0320800349899229,
        0.0315900385919151,
        0.0311147784372943,
        0.0306535998990314,
        0.0302058865421759,
        0.0297710573797382,
        0.0293485643617241,
        0.0289378900745638,
        0.0285385456306288,
        0.028150068729798,
        0.0277720218769066,
        0.0274039907406776,
        0.0270455826411857,
        0.0266964251542767,
        0.0263561648225298,
        0.0260244659633892,
        0.0257010095660318,
        0.0253854922693539,
        0.0250776254142196,
        0.024777134163726,
        0.0244837566858917,
        0.0241972433936285,
        0.0239173562374017,
        0.0236438680463345,
        0.0233765619139567,
        0.0231152306250916,
        0.0228596761207194,
        0.0226097089978907,
        0.0223651480420649,
        0.022125819789431,
        0.0218915581170046,
        0.0216622038584586,
        0.0214376044438183,
        0.0212176135613285,
        0.0210020908398911,
        0.0207909015506524,
        0.0205839163263894,
        0.0203810108974776,
        0.0201820658433075,
        0.0199869663581012,
        0.0197956020301725,
        0.0196078666337319,
        0.0194236579324158,
        0.0192428774937806,
        0.0190654305140459,
        0.0188912256524389,
        0.0187201748745317,
        0.018552193304003,
        0.0183871990823076,
        0.0182251132357535,
        0.0180658595495515,
        0.0179093644483983
      ],
      average: [
        null,
        null,
        null,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009466,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009466,
        0.0674187501009466,
        0.0674187501009467,
        0.0674187501009466,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009465,
        0.0674187501009467,
        0.0674187501009466,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009466,
        0.0674187501009467,
        0.0674187501009466,
        0.0674187501009467,
        0.0674187501009466,
        0.0674187501009467,
        0.0674187501009466,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009466,
        0.0674187501009465,
        0.0674187501009465,
        0.0674187501009467,
        0.0674187501009468,
        0.0674187501009467,
        0.0674187501009465,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009465,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009465,
        0.0674187501009465,
        0.0674187501009466,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009465,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009465,
        0.0674187501009467,
        0.0674187501009468,
        0.0674187501009467,
        0.0674187501009466,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009465,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009467,
        0.0674187501009465,
        0.0674187501009465,
        0.0674187501009466,
        0.0674187501009465,
        0.0674187501009469,
        0.0674187501009469,
        0.0674187501009469,
        0.0674187501009465,
        0.0674187501009465,
        0.0674187501009465,
        0.0674187501009465,
        0.0674187501009465,
        0.0674187501009465,
        0.0674187501009469,
        0.0674187501009469,
        0.067418750100947,
        0.0674187501009465,
        0.0674187501009465,
        0.0674187501009465,
        0.0674187501009465,
        0.0674187501009465,
        0.0674187501009469,
        0.0674187501009469,
        0.067418750100947,
        0.0674187501009465,
        0.0674187501009465,
        0.0674187501009465,
        0.0674187501009466,
        0.0674187501009465,
        0.0674187501009465,
        0.0674187501009469,
        0.0674187501009469,
        0.0674187501009469,
        0.0674187501009465,
        0.0674187501009465,
        0.0674187501009465,
        0.0674187501009466,
        0.0674187501009466,
        0.0674187501009469,
        0.0674187501009468,
        0.0674187501009468,
        0.0674187501009466,
        0.0674187501009465,
        0.0674187501009466,
        0.0674187501009465,
        0.0674187501009466,
        0.0674187501009465,
        0.0674187501009469,
        0.0674187501009468,
        0.0674187501009469,
        0.0674187501009466,
        0.0674187501009466
      ]
    },
    [Industry.IT]: { median: null, average: null },
    [Industry.FIPT]: { median: null, average: null },
    [Industry.DH]: { median: null, average: null }
  },
  [EvaluationType.CUSTOMERS]: {
    all: {
      median: [
        0.0,
        1.3665,
        3.14222275860116,
        5.11417281466572,
        7.22544007659793,
        9.44674738876543,
        11.7598757480146,
        14.1522636766989,
        16.6146668493194,
        19.1399660287347,
        21.7224915036479,
        24.3576097863519,
        27.0414556997683,
        29.7707506444392,
        32.5426747242209,
        35.354774008517,
        38.2048915481211,
        41.091114939081,
        44.0117357148504,
        46.9652173832728,
        49.950169905805,
        52.9653290606328,
        56.0095395651674,
        59.0817411320724,
        62.1809568426761,
        65.3062833714939,
        68.4568827044251,
        71.6319750733847,
        74.8308328900121,
        78.0527755063566,
        81.2971646650296,
        84.5634005280224,
        87.8509181942153,
        91.159184631977,
        94.4876959662469,
        97.8359750698685,
        101.203569417301,
        104.590049165607,
        107.995005433129,
        111.418048750811,
        114.858807664848,
        118.316927472452,
        121.792069075097,
        125.283907935797,
        128.792133128753,
        132.316446471283,
        135.856561729221,
        139.412203888112,
        142.983108483464,
        146.569020984128,
        150.169696223611,
        153.784897874682,
        157.414397963216,
        161.057976417627,
        164.71542065067,
        168.386525170719,
        172.071091219961,
        175.768926437175,
        179.479844543038,
        183.203665046074,
        186.940212967583,
        190.689318584013,
        194.450817185406,
        198.224548848674,
        202.010358224568,
        205.808094337316,
        209.617610395992,
        213.438763616757,
        217.271415055198,
        221.115429448051,
        224.970675063649,
        228.837023560497,
        232.714349853433,
        236.602531986854,
        240.501451014552,
        244.41099088573,
        248.331038336797,
        252.26148278858,
        256.202216248614,
        260.1531332182,
        264.114130603936,
        268.085107633455,
        272.065965775119,
        276.056608661443,
        280.056942016021,
        284.066873583772,
        288.08631306429,
        292.115172048164,
        296.153363956061,
        300.200803980459,
        304.257409029855,
        308.323097675344,
        312.397790099422,
        316.481408046909,
        320.573874777878,
        324.67511502249,
        328.785054937635,
        332.903622065289,
        337.030745292513,
        341.166354812989,
        345.31038209005,
        349.4627598211,
        353.623421903388,
        357.79230340105,
        361.969340513368,
        366.154470544189,
        370.347631872461,
        374.548763923809,
        378.757807143138,
        382.974702968189,
        387.199393804024,
        391.431822998389,
        395.671934817926,
        399.919674425183,
        404.17498785641,
        408.437822000088,
        412.708124576166,
        416.985844115987,
        421.270929942858,
        425.56333215325,
        429.863001598599
      ],
      average: [
        0.0,
        0.0181,
        0.163066632676187,
        0.589957765567617,
        1.46910092222931,
        2.98120641184589,
        5.31505117415905,
        8.66607611109064,
        13.2354331740008,
        19.2292908924605,
        26.8583033642193,
        36.3371897356667,
        47.88439212551,
        61.7217913902516,
        78.074466848127,
        97.1704902446661,
        119.240746944476,
        144.518779150791,
        173.240647214608,
        205.644805992675,
        241.971993867334,
        282.465132526958,
        327.369235972977,
        376.931327501515,
        431.400363627241,
        491.027164090114,
        556.064347223779,
        626.766270075454,
        703.388972757606,
        786.190126585799,
        875.428985618342,
        971.366341264428,
        1074.26447967012,
        1184.38714162765,
        1301.99948478415,
        1427.36804795182,
        1560.76071734417,
        1702.44669458206,
        1852.69646633001,
        2011.78177543775,
        2179.97559347454,
        2357.552094555,
        2544.78663036486,
        2741.95570630349,
        2949.33695866793,
        3167.20913280956,
        3395.85206220095,
        3635.54664835523,
        3886.57484154568,
        4149.21962227712,
        4423.76498346478,
        4710.4959132796,
        5009.69837862232,
        5321.65930919124,
        5646.66658211149,
        5985.0090070956,
        6336.97631210749,
        6702.85912950412,
        7082.94898263047,
        7477.53827284526,
        7886.92026695653,
        8311.3890850473,
        8751.23968867285,
        9206.76786941241,
        9678.27023775921,
        10166.0442123333,
        10670.388009403,
        11191.6006327022,
        11729.9818635294,
        12285.8322511177,
        12859.4531032644,
        13451.1464772092,
        14061.2151707505,
        14689.9627135915,
        15337.6933589062,
        16004.7120751164,
        16691.3245378733,
        17397.8371222336,
        18124.5568950252,
        18871.7916073934,
        19639.8496875229,
        20429.0402335285,
        21239.673006507,
        22072.0584237487,
        22926.5075520983,
        23803.332101463,
        24702.8444184622,
        25625.3574802143,
        26571.1848882545,
        27540.6408625818,
        28534.0402358299,
        29551.6984475568,
        30593.9315386517,
        31661.0561458538,
        32753.3894963805,
        33871.2494026611,
        35014.9542571735,
        36184.8230273791,
        37381.1752507565,
        38604.3310299262,
        39854.6110278691,
        41132.3364632311,
        42437.8291057152,
        43771.4112715562,
        45133.4058190771,
        46524.1361443241,
        47943.9261767789,
        49393.1003751453,
        50871.9837232087,
        52380.901725766,
        53920.1804046259,
        55490.1462946744,
        57091.1264400071,
        58723.4483901251,
        60387.440196192,
        62083.4304073538,
        63811.7480671147,
        65572.7227097742,
        67366.6843569167,
        69193.963513959,
        71054.8911667501
      ]
    },
    SaaS: {
      median: [
        0.0,
        0.6921,
        1.4334,
        2.2239,
        3.0636,
        3.9525,
        4.8906,
        5.8779,
        6.9144,
        8.0001,
        9.135,
        10.3191,
        11.5524,
        12.8349,
        14.1666,
        15.5475,
        16.9776,
        18.4569,
        19.9854,
        21.5631,
        23.19,
        24.8661,
        26.5914,
        28.3659,
        30.1896,
        32.0625,
        33.9846,
        35.9559,
        37.9764,
        40.0461,
        42.165,
        44.3331,
        46.5504,
        48.8169,
        51.1326,
        53.4975,
        55.9116,
        58.3749,
        60.8874,
        63.4491,
        66.06,
        68.7201,
        71.4294,
        74.1879,
        76.9956,
        79.8525,
        82.7586,
        85.7139,
        88.7184,
        91.7721,
        94.875,
        98.0271,
        101.2284,
        104.4789,
        107.7786,
        111.1275,
        114.5256,
        117.9729,
        121.4694,
        125.0151,
        128.61,
        132.2541,
        135.9474,
        139.6899,
        143.4816,
        147.3225,
        151.2126,
        155.1519,
        159.1404,
        163.1781,
        167.265,
        171.4011,
        175.5864,
        179.8209,
        184.1046,
        188.4375,
        192.8196,
        197.2509,
        201.7314,
        206.2611,
        210.84,
        215.4681,
        220.1454,
        224.8719,
        229.6476,
        234.4725,
        239.3466,
        244.2699,
        249.2424,
        254.2641,
        259.335,
        264.4551,
        269.6244,
        274.8429,
        280.1106,
        285.4275,
        290.7936,
        296.2089,
        301.6734,
        307.1871,
        312.75,
        318.3621,
        324.0234,
        329.7339,
        335.4936,
        341.3025,
        347.1606,
        353.0679,
        359.0244,
        365.0301,
        371.085,
        377.1891,
        383.3424,
        389.5449,
        395.7966,
        402.0975,
        408.4476,
        414.8469,
        421.2954,
        427.7931,
        434.34
      ],
      average: [
        0.0,
        1.5347,
        3.1078,
        4.7193,
        6.3692,
        8.0575,
        9.7842,
        11.5493,
        13.3528,
        15.1947,
        17.075,
        18.9937,
        20.9508,
        22.9463,
        24.9802,
        27.0525,
        29.1632,
        31.3123,
        33.4998,
        35.7257,
        37.99,
        40.2927,
        42.6338,
        45.0133,
        47.4312,
        49.8875,
        52.3822,
        54.9153,
        57.4868,
        60.0967,
        62.745,
        65.4317,
        68.1568,
        70.9203,
        73.7222,
        76.5625,
        79.4412,
        82.3583,
        85.3138,
        88.3077,
        91.34,
        94.4107,
        97.5198,
        100.6673,
        103.8532,
        107.0775,
        110.3402,
        113.6413,
        116.9808,
        120.3587,
        123.775,
        127.2297,
        130.7228,
        134.2543,
        137.8242,
        141.4325,
        145.0792,
        148.7643,
        152.4878,
        156.2497,
        160.05,
        163.8887,
        167.7658,
        171.6813,
        175.6352,
        179.6275,
        183.6582,
        187.7273,
        191.8348,
        195.9807,
        200.165,
        204.3877,
        208.6488,
        212.9483,
        217.2862,
        221.6625,
        226.0772,
        230.5303,
        235.0218,
        239.5517,
        244.12,
        248.7267,
        253.3718,
        258.0553,
        262.7772,
        267.5375,
        272.3362,
        277.1733,
        282.0488,
        286.9627,
        291.915,
        296.9057,
        301.9348,
        307.0023,
        312.1082,
        317.2525,
        322.4352,
        327.6563,
        332.9158,
        338.2137,
        343.55,
        348.9247,
        354.3378,
        359.7893,
        365.2792,
        370.8075,
        376.3742,
        381.9793,
        387.6228,
        393.3047,
        399.025,
        404.7837,
        410.5808,
        416.4163,
        422.2902,
        428.2025,
        434.1532,
        440.1423,
        446.1698,
        452.2357,
        458.34
      ]
    },
    Transactional: {
      median: [
        0.0,
        0.4282,
        1.65147679782195,
        3.63741283064174,
        6.36939657576888,
        9.83605275546518,
        14.0287316532104,
        18.9404627796633,
        24.5654149019357,
        30.8985803374992,
        37.9355742825862,
        45.6724993237001,
        54.1058496687232,
        63.2324409668762,
        73.0493573578331,
        83.553910545535,
        94.7436075184874,
        106.616124646021,
        119.169286578742,
        132.401048837137,
        146.309483278239,
        160.892765840821,
        176.149166117771,
        192.077038410629,
        208.674813998923,
        225.940994414512,
        243.874145554512,
        262.472892499379,
        281.735914928224,
        301.661943043265,
        322.249753930971,
        343.498168299864,
        365.406047544911,
        387.972291096457,
        411.195834018209,
        435.075644824131,
        459.610723488526,
        484.800099627236,
        510.642830830972,
        537.13800113431,
        564.284719606095,
        592.08211904876,
        620.529354795693,
        649.625603597064,
        679.370062585665,
        709.761948315322,
        740.800495865268,
        772.484958004598,
        804.814604411563,
        837.788720943043,
        871.406608949988,
        905.66758463507,
        940.570978449158,
        976.116134523566,
        1012.30241013531,
        1049.12917520285,
        1086.59581181013,
        1124.70171375671,
        1163.44628613227,
        1202.82894491365,
        1242.84911658297,
        1283.50623776529,
        1324.79975488455,
        1366.72912383662,
        1409.29380967829,
        1452.49328633123,
        1496.32703629991,
        1540.79455040273,
        1585.89532751545,
        1631.62887432622,
        1677.99470510153,
        1724.99234146247,
        1772.62131217062,
        1820.88115292312,
        1869.77140615635,
        1919.29162085778,
        1969.44135238549,
        2020.22016229505,
        2071.62761817322,
        2123.6632934783,
        2176.32676738657,
        2229.61762464477,
        2283.53545542802,
        2338.07985520317,
        2393.25042459717,
        2449.04676927024,
        2505.46849979362,
        2562.51523153173,
        2620.18658452842,
        2678.48218339723,
        2737.40165721545,
        2796.94463942171,
        2857.11076771715,
        2917.89968396979,
        2979.31103412203,
        3041.34446810135,
        3103.99963973364,
        3167.27620665949,
        3231.17383025307,
        3295.69217554349,
        3360.83091113875,
        3426.58970915179,
        3492.96824512906,
        3559.96619798099,
        3627.58324991469,
        3695.81908636857,
        3764.67339594889,
        3834.14587036812,
        3904.23620438509,
        3974.94409574686,
        4046.26924513216,
        4118.21135609645,
        4190.77013501854,
        4263.94529104856,
        4337.73653605744,
        4412.1435845877,
        4487.16615380559,
        4562.80396345446,
        4639.05673580931,
        4715.92419563268,
        4793.40607013145
      ],
      average: [
        5.0,
        10.0,
        15.0,
        25.0,
        50.984,
        127.12,
        228.612,
        355.46,
        507.664,
        685.224,
        888.14,
        1116.412,
        1370.04,
        1649.024,
        1953.364,
        2283.06,
        2638.112,
        3018.52,
        3424.284,
        3855.404,
        4311.88,
        4793.712,
        5300.9,
        5833.444,
        6391.344,
        6974.6,
        7583.212,
        8217.18,
        8876.504,
        9561.184,
        10271.22,
        11006.612,
        11767.36,
        12553.464,
        13364.924,
        14201.74,
        15063.912,
        15951.44,
        16864.324,
        17802.564,
        18766.16,
        19755.112,
        20769.42,
        21809.084,
        22874.104,
        23964.48,
        25080.212,
        26221.3,
        27387.744,
        28579.544,
        29796.7,
        31039.212,
        32307.08,
        33600.304,
        34918.884,
        36262.82,
        37632.112,
        39026.76,
        40446.764,
        41892.124,
        43362.84,
        44858.912,
        46380.34,
        47927.124,
        49499.264,
        51096.76,
        52719.612,
        54367.82,
        56041.384,
        57740.304,
        59464.58,
        61214.212,
        62989.2,
        64789.544,
        66615.244,
        68466.3,
        70342.712,
        72244.48,
        74171.604,
        76124.084,
        78101.92,
        80105.112,
        82133.66,
        84187.564,
        86266.824,
        88371.44,
        90501.412,
        92656.74,
        94837.424,
        97043.464,
        99274.86,
        101531.612,
        103813.72,
        106121.184,
        108454.004,
        110812.18,
        113195.712,
        115604.6,
        118038.844,
        120498.444,
        122983.4,
        125493.712,
        128029.38,
        130590.404,
        133176.784,
        135788.52,
        138425.612,
        141088.06,
        143775.864,
        146489.024,
        149227.54,
        151991.412,
        154780.64,
        157595.224,
        160435.164,
        163300.46,
        166191.112,
        169107.12,
        172048.484,
        175015.204,
        178007.28
      ]
    },
    [CustomerFocus.B2B]: {
      median: [
        0.0,
        0.0363,
        0.144427098139759,
        0.323947998347743,
        0.574633241792607,
        0.896323049412674,
        1.28889447243934,
        1.75224750454067,
        2.2862978404063,
        2.89097260698373,
        3.56620763147252,
        4.31194558582759,
        5.12813466839641,
        6.0147276333286,
        6.97168105519128,
        7.9989547584072,
        9.09651136564961,
        10.264315934257,
        11.5023356591789,
        12.81053962715,
        14.1888986109488,
        15.637384895476,
        17.1559721294111,
        18.7446351976655,
        20.4033501109199,
        22.1320939093233,
        23.930844578035,
        25.7995809727421,
        27.7382827536422,
        29.7469303266565,
        31.8255047908532,
        33.9739878912373,
        36.1923619762014,
        38.4806099590423,
        40.838715283042,
        43.2666618896871,
        45.7644341896608,
        48.3320170362933,
        50.9693957012029,
        53.6765558518914,
        56.4534835310906,
        59.3001651376828,
        62.2165874090386,
        65.2027374046359,
        68.2586024908382,
        71.3841703267263,
        74.5794288508877,
        77.8443662690797,
        81.1789710426912,
        84.5832318779342,
        88.0571377157079,
        91.6006777220767,
        95.2138412793165,
        98.8966179774843,
        102.648997606471,
        106.470970148499,
        110.362525771038,
        114.323654820102,
        118.354347813901,
        122.454595436832,
        126.624388533772,
        130.863718104661,
        135.172575299359,
        139.550951412746,
        143.99883788007,
        148.5162262725,
        153.103108292903,
        157.7594757718,
        162.485320663515,
        167.280635042491,
        172.14541109977,
        177.079641139623,
        182.083317576328,
        187.156432931076,
        192.298979829015,
        197.510950996406,
        202.792339257902,
        208.143137533927,
        213.563338838165,
        219.052936275144,
        224.611923037909,
        230.240292405794,
        235.93803774226,
        241.70515249283,
        247.541630183089,
        253.447464416758,
        259.422648873837,
        265.46717730882,
        271.581043548956,
        277.764241492587,
        284.016765107532,
        290.338608429528,
        296.729765560726,
        303.190230668231,
        309.719997982693,
        316.319061796941,
        322.987416464667,
        329.72505639914,
        336.531976071968,
        343.4081700119,
        350.35363280366,
        357.368359086814,
        364.452343554678,
        371.605580953256,
        378.828066080201,
        386.119793783822,
        393.480758962105,
        400.910956561768,
        408.410381577346,
        415.979029050292,
        423.616894068116,
        431.323971763532,
        439.100257313646,
        446.945745939152,
        454.860432903547,
        462.844313512389,
        470.897383112547,
        479.01963709149,
        487.211070876581,
        495.471679934402,
        503.801459770086
      ],
      average: [
        0.0,
        0.0396,
        0.164156552216027,
        0.377145942703759,
        0.680489233218511,
        1.07555382679992,
        1.56340852617455,
        2.14493341526843,
        2.82087793801208,
        3.5918955075229,
        4.45856585682396,
        5.42141033723147,
        6.48090286267558,
        7.63747801635036,
        8.89153722685384,
        10.24345358427,
        11.6935756698565,
        13.2422306525813,
        14.8897268291715,
        16.6363557340129,
        18.4823939112201,
        20.4281044176314,
        22.473738108794,
        24.6195347479618,
        26.8657239692879,
        29.212526119797,
        31.6601529997418,
        34.2088085171242,
        36.8586892692115,
        39.6099850615557,
        42.4628793731986,
        45.4175497752825,
        48.4741683091124,
        51.6329018287642,
        54.8939123125536,
        58.2573571470452,
        61.7233893867492,
        65.2921579922146,
        68.9638080488607,
        72.738480968578,
        76.6163146758679,
        80.59744378007,
        84.6819997350342,
        88.8701109874357,
        93.1619031147865,
        97.5574989540835,
        102.057018721921,
        106.660580126814,
        111.368298474388,
        116.180286766038,
        121.096655791578,
        126.117514216373,
        131.242968663371,
        136.473123790443,
        141.808082363367,
        147.247945324797,
        152.792811859485,
        158.442779456045,
        164.197943965487,
        170.05839965675,
        176.024239269427,
        182.095554063887,
        188.272433868943,
        194.554967127233,
        200.943240938466,
        207.437341100654,
        214.037352149457,
        220.743357395754,
        227.555438961558,
        234.473677814344,
        241.498153799915,
        248.628945673864,
        255.866131131718,
        263.209786837846,
        270.659988453183,
        278.216810661844,
        285.880327196688,
        293.650610863875,
        301.527733566486,
        309.511766327231,
        317.602779310319,
        325.800841842504,
        334.106022433367,
        342.518388794856,
        351.038007860143,
        359.664945801794,
        368.399268049319,
        377.241039306116,
        386.19032356583,
        395.247184128163,
        404.411683614166,
        413.683883981008,
        423.063846536289,
        432.551631951863,
        442.147300277246,
        451.850910952588,
        461.662522821233,
        471.582194141916,
        481.609982600556,
        491.745945321714,
        501.990138879707,
        512.342619309374,
        522.803442116552,
        533.372662288227,
        544.050334302399,
        554.836512137674,
        565.731249282577,
        576.734598744597,
        587.846613059007,
        599.06734429741,
        610.396844076078,
        621.835163564053,
        633.382353491037,
        645.038464155072,
        656.803545430016,
        668.67764677283,
        680.660817230674,
        692.753105447823,
        704.954559672402,
        717.265227762965,
        729.685157194899
      ]
    },
    [CustomerFocus.B2C]: {
      median: [
        0.0,
        0.0861,
        0.676591542220292,
        2.25973330889701,
        5.31679576079016,
        10.3247546062246,
        17.7574499938814,
        28.0862490179238,
        41.7804766952767,
        59.3077192489969,
        81.1340492691243,
        107.724199531523,
        139.54170124576,
        177.048996634952,
        220.70753238351,
        270.977838442203,
        328.31959537696,
        393.191692585894,
        466.052279120173,
        547.358808430915,
        637.56807806706,
        737.136265130991,
        846.518958135653,
        966.171185783028,
        1096.54744308841,
        1238.10171520043,
        1391.28749920787,
        1556.55782417736,
        1734.36526962827,
        1925.16198261999,
        2129.39969360199,
        2347.5297311557,
        2580.00303574029,
        2827.2701725395,
        3089.78134349474,
        3367.98639859908,
        3662.334846518,
        3973.27586459504,
        4301.25830829425,
        4646.73072012537,
        5010.14133809314,
        5391.93810370739,
        5792.5686695875,
        6212.48040669092,
        6652.12041119299,
        7111.93551104249,
        7592.3722722154,
        8093.87700468691,
        8616.8957681406,
        9161.87437743118,
        9729.25840781704,
        10319.493199976,
        10933.0238648184,
        11570.2952881079,
        12231.7521349039,
        12917.8388538323,
        13628.9996811975,
        14365.6786449421,
        15128.3195684638,
        15917.366074296,
        16733.2615876607,
        17576.4493398987,
        18447.3723717842,
        19346.4735367298,
        20274.1955038857,
        21230.9807611401,
        22217.2716180247,
        23233.5102085288,
        24280.138493828,
        25357.5982649295,
        26466.3311452395,
        27606.7785930544,
        28779.3819039806,
        29984.5822132844,
        31222.8204981768,
        32494.537580034,
        33800.174126558,
        35140.1706538777,
        36514.9675285951,
        37925.0049697769,
        39370.7230508952,
        40852.5617017171,
        42370.960710148,
        43926.3597240276,
        45519.198252882,
        47149.9156696339,
        48818.9512122688,
        50526.7439854658,
        52273.7329621852,
        54060.3569852232,
        55887.0547687282,
        57754.2648996841,
        59662.4258393589,
        61611.9759247224,
        63603.3533698314,
        65636.9962671862,
        67713.3425890562,
        69832.8301887789,
        71995.8968020295,
        74202.9800480663,
        76454.5174309487,
        78750.9463407304,
        81092.7040546296,
        83480.2277381746,
        85913.9544463266,
        88394.321124581,
        90921.7646100476,
        93496.7216325088,
        96119.6288154583,
        98790.9226771195,
        101511.039631447,
        104280.415989105,
        107099.487958435,
        109968.691646396,
        112888.463059495,
        115859.238104702,
        118881.452590341,
        121955.542226972,
        125081.942628257,
        128261.089311804,
        131493.417700011
      ],
      average: [
        0.0,
        0.0467,
        0.431510138069035,
        1.58443400118563,
        3.98717343161365,
        8.15723093341734,
        14.6402427111966,
        24.0052859220015,
        36.8416650531219,
        53.7565546919293,
        75.3731872877928,
        102.329411215743,
        135.276512926609,
        174.878234606739,
        221.809940954808,
        276.757902493139,
        340.418671815113,
        413.49853523132,
        496.713026493148,
        590.786492282534,
        696.451701354574,
        814.449490856571,
        945.528444587334,
        1090.44459891486,
        1249.96117281496,
        1424.84831908139,
        1615.88289422747,
        1823.84824497832,
        2049.53400956089,
        2293.73593225292,
        2557.25568986105,
        2840.90072897378,
        3145.48411298109,
        3471.82437797708,
        3820.74539676681,
        4193.07625028862,
        4589.6511058404,
        5011.30910156475,
        5458.89423670572,
        5933.25526719988,
        6435.24560620828,
        6965.72322923444,
        7525.55058350694,
        8115.5945013355,
        8736.72611717516,
        9389.82078815732,
        10075.7580178669,
        10795.4213831634,
        11549.6984638612,
        12339.4807750973,
        13165.6637022321,
        14029.1464381361,
        14930.83192273,
        15871.6267846551,
        16852.4412849585,
        17874.1892626862,
        18937.788082287,
        20044.1585827332,
        21194.2250282732,
        22388.9150607366,
        23629.1596533141,
        24915.8930657468,
        26250.0528008543,
        27632.5795623442,
        29064.417213842,
        30546.5127390901,
        32079.8162032619,
        33665.2807153459,
        35303.862391552,
        36996.5203196987,
        38744.2165245404,
        40547.9159339972,
        42408.5863462503,
        44327.1983976688,
        46304.7255315378,
        48342.1439675532,
        50440.4326720588,
        52600.5733289934,
        54823.5503115257,
        57110.35065435,
        59461.9640266185,
        61879.3827054901,
        64363.6015502705,
        66915.6179771279,
        69536.4319343594,
        72227.0458781944,
        74988.4647491131,
        77821.6959486666,
        80727.7493167794,
        83707.6371095206,
        86762.3739773288,
        89892.9769436751,
        93100.4653841526,
        96385.8610059762,
        99750.1878278857,
        103194.472160433,
        106719.742586648,
        110327.029943068,
        114017.367301123,
        117791.789948866,
        121651.335373034,
        125597.043241439,
        129629.95538567,
        133751.115784111,
        137961.570545241,
        142262.367891247,
        146654.5581419,
        151139.193698718,
        155717.329029395,
        160390.020652485,
        165158.327122351,
        170023.309014351,
        174986.028910274,
        180047.551384006,
        185208.942987427,
        190471.272236534,
        195835.609597781,
        201303.02747463,
        206874.600194311,
        212551.403994789,
        218334.517011923
      ]
    },
    [Industry.OTHER]: {
      median: [
        0.0,
        1.673,
        4.18299839775624,
        7.14986681209069,
        10.4587421372572,
        14.0476663518957,
        17.8767970228009,
        21.9179564496242,
        26.1499710715436,
        30.5562435329563,
        35.1233507723814,
        39.8401724858612,
        44.6973181729764,
        49.6867335806971,
        54.8014206281345,
        60.0352321797622,
        65.3827179280568,
        70.8390062115093,
        76.3997117392741,
        82.0608624015135,
        87.8188404092657,
        93.6703343741884,
        99.6122998653256,
        105.641926622387,
        111.756611058913,
        117.953933015052,
        124.231635958038,
        130.587610005048,
        137.019877275704,
        143.526579182128,
        150.105965341823,
        156.756383858617,
        163.476272763903,
        170.264152447476,
        177.118618936873,
        184.038337907771,
        191.02203932721,
        198.06851264694,
        205.176602477003,
        212.345204680106,
        219.573262836085,
        226.859765032952,
        234.203740947114,
        241.604259180383,
        249.060424825745,
        256.571377237442,
        264.136287984056,
        271.754358965896,
        279.424820680275,
        287.146930620217,
        294.91997179379,
        302.743251352754,
        310.616099320447,
        318.53786740997,
        326.507927924645,
        334.525672733624,
        342.590512316214,
        350.70187486917,
        358.859205471773,
        367.061965304006,
        375.309630913626,
        383.601693528283,
        391.93765840924,
        400.317044243543,
        408.739382571768,
        417.204217248753,
        425.711103934917,
        434.259609616007,
        442.849312149274,
        451.479799834264,
        460.150671006533,
        468.861533652776,
        477.612005045935,
        486.401711398987,
        495.23028753623,
        504.097376580927,
        513.002629658322,
        521.945705613044,
        530.926270740051,
        539.943998528286,
        548.998569416288,
        558.089670559075,
        567.216995605616,
        576.380244486326,
        585.579123209978,
        594.813343669546,
        604.08262345644,
        613.386685682728,
        622.725258810866,
        632.098076490577,
        641.50487740247,
        650.945405108069,
        660.419407905911,
        669.926638693412,
        679.466854834199,
        689.039818030654,
        698.645294201384,
        708.28305336341,
        717.952869518823,
        727.654520545701,
        737.387788093091,
        747.152457479848,
        756.94831759718,
        766.775160814703,
        776.632782889852,
        786.520982880517,
        796.439563060725,
        806.388328839265,
        816.367088681112,
        826.375654031526,
        836.413839242725,
        846.481461503002,
        856.578340768208,
        866.704299695475,
        876.859163579109,
        887.042760288547,
        897.25492020831,
        907.495476179854,
        917.764263445258,
        928.06111959266,
        938.385884503399
      ],
      average: [
        15.012,
        15.9530475711817,
        16.9530859851044,
        18.0158131627168,
        19.1451588341555,
        20.3452990700182,
        21.6206717235491,
        22.9759928408394,
        24.416274099723,
        25.9468413418528,
        27.5733542664856,
        29.301827358799,
        31.1386521301275,
        33.0906207523583,
        35.1649511738813,
        37.369313809966,
        39.7118599062621,
        42.2012516803026,
        44.84669435247,
        47.6579701848654,
        50.6454746539514,
        53.820254890725,
        57.1940505305663,
        60.779337123814,
        64.5893722675923,
        68.6382446294741,
        72.9409260442585,
        77.5133268765057,
        82.3723548535474,
        87.5359775865243,
        93.0232890106394,
        98.8545799903087,
        105.051413350292,
        111.636703610251,
        118.634801717575,
        126.071585091806,
        133.974553313607,
        142.372929812136,
        151.297769926827,
        160.782075743164,
        170.860918127101,
        181.571566409367,
        192.953626199215,
        205.049185837203,
        217.902972028576,
        231.56251523273,
        246.078325420346,
        261.504078848089,
        277.896816541542,
        295.317155220302,
        313.829511445213,
        333.50233981656,
        354.408386104049,
        376.62495624458,
        400.234202202505,
        425.323425749436,
        451.985401286885,
        480.318718905498,
        510.428148949406,
        542.425029433793,
        576.427677748264,
        612.561828168389,
        650.961096793255,
        691.767475628259,
        735.131857640148,
        781.214594725851,
        830.186090658336,
        882.227431202092,
        937.531053728246,
        996.301458805411,
        1058.75596639758,
        1125.1255194653,
        1195.65553794168,
        1270.60682624103,
        1350.25653765597,
        1434.89919920895,
        1524.84780074814,
        1620.4349523146,
        1722.01411406078,
        1829.96090326792,
        1944.67448329575,
        2066.57903960034,
        2196.12534827812,
        2333.79244293625,
        2480.08938605291,
        2635.5571513779,
        2800.77062433385,
        2976.3407278155,
        3162.91668124741,
        3361.18840125395,
        3571.88905281831,
        3795.79776036462,
        4033.74248878775,
        4286.60310508446,
        4555.31463190699,
        4840.8707050701,
        5144.32724779665,
        5466.80637528818,
        5809.50054405888,
        6173.67696137605,
        6560.68227111249,
        6971.94753333773,
        7408.99351606181,
        7873.43631869911,
        8366.99334804667,
        8891.48966887487,
        9448.86475261335,
        10041.1796490876,
        10670.6246078255,
        11339.5271771158,
        12050.3608107671,
        12805.7540143933,
        13608.5000650458,
        14461.5673401349,
        15368.1102938329,
        16331.4811215483,
        17355.2421556033,
        18443.1790379508,
        19599.3147186418,
        20827.9243318049,
        22133.5510041471
      ]
    },
    [Industry.IT]: {
      median: [
        0.0,
        0.0329,
        0.127311212835555,
        0.280951924556516,
        0.492648781570212,
        0.761596852274118,
        1.0871832906922,
        1.46891373489577,
        1.90637428217818,
        2.39920923744712,
        2.94710695911122,
        3.54979029654288,
        4.2070098271299,
        4.91853889788936,
        5.68416988268648,
        6.50371128822486,
        7.37698547059619,
        8.30382680222403,
        9.28408017828753,
        10.3175997838449,
        11.4042480644545,
        12.5438948579473,
        13.7364166554638,
        14.9816959673719,
        16.2796207751674,
        17.6300840545241,
        19.0329833577249,
        20.4882204460346,
        21.9957009643815,
        23.555334152111,
        25.1670325846868,
        26.8307119420886,
        28.5462908003608,
        30.3136904433364,
        32.1328346920222,
        34.0036497495087,
        35.9260640595842,
        37.9000081774892,
        39.9254146514638,
        42.002217913924,
        44.1303541812533,
        46.3097613613262,
        48.5403789679925,
        50.8221480418406,
        53.1550110766447,
        55.5389119509639,
        57.9737958644273,
        60.459609278286,
        62.9962998598619,
        65.5838164305604,
        68.2221089171504,
        70.9111283060432,
        73.650826600331,
        76.4411567793676,
        79.2820727606956,
        82.1735293641417,
        85.1154822779208,
        88.1078880265997,
        91.1507039407907,
        94.2438881284502,
        97.3873994476721,
        100.581197480876,
        103.825242510295,
        107.119495494677,
        110.463918047124,
        113.858472413992,
        117.303121454794,
        120.797828623028,
        124.342557947895,
        127.937274016831,
        131.581941958826,
        135.276527428469,
        139.020996590684,
        142.815316106116,
        146.659453117131,
        150.553375234398,
        154.497050524021,
        158.490447495184,
        162.533535088296,
        166.626282663598,
        170.768659990212,
        174.960637235611,
        179.202184955488,
        183.493274084005,
        187.833875924395,
        192.223962139917,
        196.663504745132,
        201.152476097489,
        205.69084888921,
        210.278596139453,
        214.915691186757,
        219.602107681726,
        224.337819579974,
        229.122801135298,
        233.957026893081,
        238.840471683904,
        243.773110617365,
        248.754919076104,
        253.785872710001,
        258.865947430572,
        263.995119405525,
        269.173365053489,
        274.400661038906,
        279.676984267069,
        285.002311879309,
        290.376621248333,
        295.799889973681,
        301.27209587733,
        306.793216999419,
        312.363231594086,
        317.98211812544,
        323.649855263626,
        329.366421881011,
        335.131797048473,
        340.945960031783,
        346.808890288094,
        352.720567462512,
        358.680971384776,
        364.690082065999,
        370.747879695516,
        376.854344637805
      ],
      average: [
        0.0,
        0.0335,
        0.138581338967653,
        0.318000422745495,
        0.573277239106491,
        0.905492507135136,
        1.31549027989106,
        1.8039651444378,
        2.37150828044946,
        3.01863489153176,
        3.74580191235714,
        4.55342012388939,
        5.44186281749955,
        6.41147221064326,
        7.4625643333482,
        8.59543283766723,
        9.81035202619586,
        11.1075793002813,
        12.4873571678491,
        13.9499149108948,
        15.4954699857327,
        17.1242292104286,
        18.8363897806223,
        20.6321401454137,
        22.5116607679812,
        24.4751247903843,
        26.5226986180549,
        28.6545424364598,
        30.8708106700789,
        33.1716523920093,
        35.5572116910583,
        38.0276280020333,
        40.5830364040074,
        43.2235678905872,
        45.9493496155929,
        48.7605051170561,
        51.6571545220253,
        54.6394147343172,
        57.7073996070634,
        60.8612201016582,
        64.1009844345049,
        67.426798212782,
        70.838764560304,
        74.3369842344199,
        77.9215557347835,
        81.592575404737,
        85.350137525961,
        89.1943344069795,
        93.1252564660422,
        97.14299230885,
        101.247628801548,
        105.43925113936,
        109.717942911211,
        114.083786160639,
        118.536861443284,
        123.0772478812,
        127.705023214222,
        132.420263848604,
        137.22304490311,
        142.11344025274,
        147.091522570243,
        152.15736336558,
        157.31103302345,
        162.552600839017,
        167.88213505195,
        173.299702878884,
        178.805370544383,
        184.399203310517,
        190.081265505124,
        195.851620548832,
        201.710330980917,
        207.657458484065,
        213.693063908096,
        219.817207292702,
        226.029947889271,
        232.33134418182,
        238.721453907112,
        245.200334073973,
        251.768040981875,
        258.424630238805,
        265.170156778458,
        272.004674876804,
        278.928238168032,
        285.940899659927,
        293.042711748693,
        300.233726233255,
        307.51399432905,
        314.883566681361,
        322.342493378176,
        329.890823962625,
        337.528607445004,
        345.255892314387,
        353.07272654988,
        360.979157631494,
        368.975232550681,
        377.060997820536,
        385.236499485677,
        393.501783131821,
        401.856893895069,
        410.301876470906,
        418.836775122937,
        427.461633691354,
        436.176495601167,
        444.981403870187,
        453.876401116778,
        462.861529567394,
        471.936831063901,
        481.102347070689,
        490.358118681595,
        499.704186626628,
        509.140591278518,
        518.667372659084,
        528.284570445434,
        537.992223975996,
        547.7903722564,
        557.679053965198,
        567.658307459443,
        577.728170780119,
        587.888681657438,
        598.139877516005,
        608.48179547985
      ]
    },
    [Industry.FIPT]: {
      median: [
        0.0,
        0.0861,
        0.676591542220292,
        2.25973330889701,
        5.31679576079016,
        10.3247546062246,
        17.7574499938814,
        28.0862490179238,
        41.7804766952767,
        59.3077192489969,
        81.1340492691243,
        107.724199531523,
        139.54170124576,
        177.048996634952,
        220.70753238351,
        270.977838442203,
        328.31959537696,
        393.191692585894,
        466.052279120173,
        547.358808430915,
        637.56807806706,
        737.136265130991,
        846.518958135653,
        966.171185783028,
        1096.54744308841,
        1238.10171520043,
        1391.28749920787,
        1556.55782417736,
        1734.36526962827,
        1925.16198261999,
        2129.39969360199,
        2347.5297311557,
        2580.00303574029,
        2827.2701725395,
        3089.78134349474,
        3367.98639859908,
        3662.334846518,
        3973.27586459504,
        4301.25830829425,
        4646.73072012537,
        5010.14133809314,
        5391.93810370739,
        5792.5686695875,
        6212.48040669092,
        6652.12041119299,
        7111.93551104249,
        7592.3722722154,
        8093.87700468691,
        8616.8957681406,
        9161.87437743118,
        9729.25840781704,
        10319.493199976,
        10933.0238648184,
        11570.2952881079,
        12231.7521349039,
        12917.8388538323,
        13628.9996811975,
        14365.6786449421,
        15128.3195684638,
        15917.366074296,
        16733.2615876607,
        17576.4493398987,
        18447.3723717842,
        19346.4735367298,
        20274.1955038857,
        21230.9807611401,
        22217.2716180247,
        23233.5102085288,
        24280.138493828,
        25357.5982649295,
        26466.3311452395,
        27606.7785930544,
        28779.3819039806,
        29984.5822132844,
        31222.8204981768,
        32494.537580034,
        33800.174126558,
        35140.1706538777,
        36514.9675285951,
        37925.0049697769,
        39370.7230508952,
        40852.5617017171,
        42370.960710148,
        43926.3597240276,
        45519.198252882,
        47149.9156696339,
        48818.9512122688,
        50526.7439854658,
        52273.7329621852,
        54060.3569852232,
        55887.0547687282,
        57754.2648996841,
        59662.4258393589,
        61611.9759247224,
        63603.3533698314,
        65636.9962671862,
        67713.3425890562,
        69832.8301887789,
        71995.8968020295,
        74202.9800480663,
        76454.5174309487,
        78750.9463407304,
        81092.7040546296,
        83480.2277381746,
        85913.9544463266,
        88394.321124581,
        90921.7646100476,
        93496.7216325088,
        96119.6288154583,
        98790.9226771195,
        101511.039631447,
        104280.415989105,
        107099.487958435,
        109968.691646396,
        112888.463059495,
        115859.238104702,
        118881.452590341,
        121955.542226972,
        125081.942628257,
        128261.089311804,
        131493.417700011
      ],
      average: [
        0.0,
        0.0467,
        0.431510138069035,
        1.58443400118563,
        3.98717343161365,
        8.15723093341734,
        14.6402427111966,
        24.0052859220015,
        36.8416650531219,
        53.7565546919293,
        75.3731872877928,
        102.329411215743,
        135.276512926609,
        174.878234606739,
        221.809940954808,
        276.757902493139,
        340.418671815113,
        413.49853523132,
        496.713026493148,
        590.786492282534,
        696.451701354574,
        814.449490856571,
        945.528444587334,
        1090.44459891486,
        1249.96117281496,
        1424.84831908139,
        1615.88289422747,
        1823.84824497832,
        2049.53400956089,
        2293.73593225292,
        2557.25568986105,
        2840.90072897378,
        3145.48411298109,
        3471.82437797708,
        3820.74539676681,
        4193.07625028862,
        4589.6511058404,
        5011.30910156475,
        5458.89423670572,
        5933.25526719988,
        6435.24560620828,
        6965.72322923444,
        7525.55058350694,
        8115.5945013355,
        8736.72611717516,
        9389.82078815732,
        10075.7580178669,
        10795.4213831634,
        11549.6984638612,
        12339.4807750973,
        13165.6637022321,
        14029.1464381361,
        14930.83192273,
        15871.6267846551,
        16852.4412849585,
        17874.1892626862,
        18937.788082287,
        20044.1585827332,
        21194.2250282732,
        22388.9150607366,
        23629.1596533141,
        24915.8930657468,
        26250.0528008543,
        27632.5795623442,
        29064.417213842,
        30546.5127390901,
        32079.8162032619,
        33665.2807153459,
        35303.862391552,
        36996.5203196987,
        38744.2165245404,
        40547.9159339972,
        42408.5863462503,
        44327.1983976688,
        46304.7255315378,
        48342.1439675532,
        50440.4326720588,
        52600.5733289934,
        54823.5503115257,
        57110.35065435,
        59461.9640266185,
        61879.3827054901,
        64363.6015502705,
        66915.6179771279,
        69536.4319343594,
        72227.0458781944,
        74988.4647491131,
        77821.6959486666,
        80727.7493167794,
        83707.6371095206,
        86762.3739773288,
        89892.9769436751,
        93100.4653841526,
        96385.8610059762,
        99750.1878278857,
        103194.472160433,
        106719.742586648,
        110327.029943068,
        114017.367301123,
        117791.789948866,
        121651.335373034,
        125597.043241439,
        129629.95538567,
        133751.115784111,
        137961.570545241,
        142262.367891247,
        146654.5581419,
        151139.193698718,
        155717.329029395,
        160390.020652485,
        165158.327122351,
        170023.309014351,
        174986.028910274,
        180047.551384006,
        185208.942987427,
        190471.272236534,
        195835.609597781,
        201303.02747463,
        206874.600194311,
        212551.403994789,
        218334.517011923
      ]
    },
    [Industry.DH]: {
      median: [
        0.0,
        1.0,
        1.0,
        1.0,
        1.0,
        5.0,
        4.0,
        8.0,
        12.0,
        18.0,
        21.7224915036479,
        24.3576097863519,
        27.0414556997683,
        29.7707506444392,
        32.5426747242209,
        35.354774008517,
        38.2048915481211,
        41.091114939081,
        44.0117357148504,
        46.9652173832728,
        49.950169905805,
        52.9653290606328,
        56.0095395651674,
        59.0817411320724,
        62.1809568426761,
        65.3062833714939,
        68.4568827044251,
        71.6319750733847,
        74.8308328900121,
        78.0527755063566,
        81.2971646650296,
        84.5634005280224,
        87.8509181942153,
        91.159184631977,
        94.4876959662469,
        97.8359750698685,
        101.203569417301,
        104.590049165607,
        107.995005433129,
        111.418048750811,
        114.858807664848,
        118.316927472452,
        121.792069075097,
        125.283907935797,
        128.792133128753,
        132.316446471283,
        135.856561729221,
        139.412203888112,
        142.983108483464,
        146.569020984128,
        150.169696223611,
        153.784897874682,
        157.414397963216,
        161.057976417627,
        164.71542065067,
        168.386525170719,
        172.071091219961,
        175.768926437175,
        179.479844543038,
        183.203665046074,
        186.940212967583,
        190.689318584013,
        194.450817185406,
        198.224548848674,
        214.0,
        240.0,
        261.0,
        279.0,
        296.0,
        314.0,
        336.0,
        346.0,
        362.0,
        387.0,
        391.0,
        393.0,
        398.0,
        408.0,
        422.0,
        425.0,
        431.0,
        432.0,
        439.0,
        443.0,
        443.0,
        443.0,
        462.0,
        490.0,
        515.0,
        541.0,
        573.0,
        584.0,
        642.4,
        706.64,
        777.304,
        855.034400000001,
        940.537840000001,
        1034.591624,
        1058.591624,
        1082.591624,
        1106.591624,
        1130.591624,
        1154.591624,
        1178.591624,
        1202.591624,
        1226.591624,
        1250.591624,
        1274.591624,
        1298.591624,
        1322.591624,
        1346.591624,
        1370.591624,
        1394.591624,
        1418.591624,
        1442.591624,
        1466.591624,
        1490.591624,
        1514.591624,
        1538.591624,
        1562.591624,
        1586.591624
      ],
      average: [
        0.0,
        1.0,
        1.0,
        1.0,
        1.46910092222931,
        2.98120641184589,
        5.31505117415905,
        8.66607611109064,
        13.2354331740008,
        19.2292908924605,
        26.8583033642193,
        36.3371897356667,
        47.88439212551,
        61.7217913902516,
        78.074466848127,
        97.1704902446661,
        119.240746944476,
        144.518779150791,
        173.240647214608,
        205.644805992675,
        241.971993867334,
        282.465132526958,
        327.369235972977,
        376.931327501515,
        431.400363627241,
        491.027164090114,
        556.064347223779,
        626.766270075454,
        703.388972757606,
        786.190126585799,
        875.428985618342,
        971.366341264428,
        1074.26447967012,
        1184.38714162765,
        1301.99948478415,
        1427.36804795182,
        1560.76071734417,
        1702.44669458206,
        1852.69646633001,
        2011.78177543775,
        2179.97559347454,
        2357.552094555,
        2544.78663036486,
        2741.95570630349,
        2949.33695866793,
        3167.20913280956,
        3395.85206220095,
        3635.54664835523,
        3886.57484154568,
        4149.21962227712,
        4423.76498346478,
        4710.4959132796,
        5009.69837862232,
        5321.65930919124,
        5646.66658211149,
        5985.0090070956,
        6336.97631210749,
        6702.85912950412,
        7082.94898263047,
        7477.53827284526,
        7886.92026695653,
        8311.3890850473,
        8751.23968867285,
        9206.76786941241,
        9678.27023775921,
        10166.0442123333,
        10670.388009403,
        11191.6006327022,
        11729.9818635294,
        12285.8322511177,
        12859.4531032644,
        13451.1464772092,
        14061.2151707505,
        14689.9627135915,
        15337.6933589062,
        16004.7120751164,
        16691.3245378733,
        17397.8371222336,
        18124.5568950252,
        18871.7916073934,
        19639.8496875229,
        20429.0402335285,
        21239.673006507,
        22072.0584237487,
        22926.5075520983,
        23803.332101463,
        24702.8444184622,
        25625.3574802143,
        26571.1848882545,
        27540.6408625818,
        28534.0402358299,
        29551.6984475568,
        30593.9315386517,
        31661.0561458538,
        32753.3894963805,
        33871.2494026611,
        35014.9542571735,
        36184.8230273791,
        37381.1752507565,
        38604.3310299262,
        39854.6110278691,
        41132.3364632311,
        42437.8291057152,
        43771.4112715562,
        45133.4058190771,
        46524.1361443241,
        47943.9261767789,
        49393.1003751453,
        50871.9837232087,
        52380.901725766,
        53920.1804046259,
        55490.1462946744,
        57091.1264400071,
        58723.4483901251,
        60387.440196192,
        62083.4304073538,
        63811.7480671147,
        65572.7227097742,
        67366.6843569167,
        69193.963513959,
        71054.8911667501
      ]
    }
  },
  [EvaluationType.CUSTOMER_GROWTH]: {
    all: {
      median: [
        null,
        null,
        null,
        null,
        1.42918406551968,
        0.66879669950271,
        0.433155935748544,
        0.319557172373758,
        0.252923719511416,
        0.20918844265754,
        0.178304286388073,
        0.155343528848223,
        0.137608911446885,
        0.12350116681965,
        0.112012426638775,
        0.102476217762937,
        0.0944343101994453,
        0.0875613358695203,
        0.0816199221473162,
        0.0764328465123165,
        0.0718651301289651,
        0.0678121657050161,
        0.0641916343328857,
        0.060937872306791,
        0.0579978635426051,
        0.0553283356043877,
        0.0528936204469408,
        0.0506640548843091,
        0.048614768374522,
        0.046724752977547,
        0.0449761417381145,
        0.0433536429754629,
        0.0418440925570105,
        0.040436096418951,
        0.0391197428054227,
        0.0378863688655626,
        0.0367283699968538,
        0.0356390430734638,
        0.0346124567369192,
        0.033643343452282,
        0.0327270091854262,
        0.0318592574348707,
        0.0310363250257233,
        0.030254827594845,
        0.0295117131027601,
        0.0288042220266869,
        0.0281298531408152,
        0.0274863339899344,
        0.0268715953222939,
        0.0262837488759138,
        0.0257210680162372,
        0.0251819708071025,
        0.0246650051656366,
        0.024168835807821,
        0.0236922327377367,
        0.0232340610716494,
        0.0227932720197931,
        0.0223688948750537,
        0.0219600298798096,
        0.0215658418606412,
        0.021185554536191,
        0.020818445416559,
        0.0204638412237438,
        0.0201211137720768,
        0.0197896762556333,
        0.0194689798964796,
        0.0191585109134928,
        0.0188577877765464,
        0.0185663587152112,
        0.0182837994548558,
        0.0180097111563088,
        0.0177437185380338,
        0.01748546816224,
        0.0172346268684632,
        0.0169908803400294,
        0.0167539317904311,
        0.0165235007580773,
        0.0162993219991238,
        0.0160811444692035,
        0.0158687303858349,
        0.0156618543641485,
        0.0154603026193324,
        0.0152638722298573,
        0.0150723704561587,
        0.0148856141099475,
        0.0147034289698352,
        0.0145256492393318,
        0.0143521170436979,
        0.0141826819624165,
        0.0140172005943993,
        0.0138555361532648,
        0.0136975580903053,
        0.013543141742948,
        0.0133921680067225,
        0.0132445230289278,
        0.0131000979223328,
        0.0129587884974087,
        0.0128204950116978,
        0.0126851219350634,
        0.0125525777296489,
        0.0124227746434946,
        0.0122956285168152,
        0.0121710586000651,
        0.012048987382937,
        0.0119293404335562,
        0.0118120462471559,
        0.0116970361035938,
        0.0115842439331096,
        0.0114736061897753,
        0.011365061732123,
        0.011258551710496,
        0.0111540194606573,
        0.0110514104032884,
        0.0109506719489658,
        0.0108517534083009,
        0.0107546059068998,
        0.0106591823048499,
        0.0105654371204587,
        0.0104733264579726,
        0.010382807939054,
        0.0102938406377731
      ],
      average: [
        null,
        null,
        null,
        null,
        26.7219322694164,
        5.76071211487696,
        2.66973540840124,
        1.63296591358751,
        1.14654106934803,
        0.872631279384499,
        0.699748729795047,
        0.581815904271423,
        0.49672660653137,
        0.43268416912834,
        0.382870161544103,
        0.343090903816649,
        0.310635591626229,
        0.283679223118761,
        0.260950818773631,
        0.241539521967918,
        0.224776358452487,
        0.210159465207269,
        0.19730529928813,
        0.185915914588826,
        0.175756478646757,
        0.166639490554376,
        0.158413488658217,
        0.150954830609139,
        0.144161616164355,
        0.137949130354518,
        0.132246382431605,
        0.126993445962162,
        0.12213939238648,
        0.117640669540271,
        0.113459817535417,
        0.109564443072136,
        0.105926393628153,
        0.102521087624008,
        0.0993269673248171,
        0.0963250490784298,
        0.0934985513128814,
        0.0908325850816155,
        0.088313895246935,
        0.0859306429104323,
        0.0836722216347074,
        0.0815291014994917,
        0.0794926962039316,
        0.077555249344046,
        0.0757097367188996,
        0.0739497820946383,
        0.0722695843155754,
        0.0706638540209906,
        0.0691277585247836,
        0.0676568736572149,
        0.0662471415654764,
        0.0648948336314475,
        0.0635965177980394,
        0.0623490297053383,
        0.0611494471288289,
        0.059995067287775,
        0.0588833866551316,
        0.057812082953458,
        0.056778999065911,
        0.0557821286290749,
        0.0548196031062572,
        0.05388968016694,
        0.0529907332211267,
        0.0521212419769965,
        0.0512797839071225,
        0.0504650265229677,
        0.0496757203698116,
        0.0489106926650133,
        0.0481688415117698,
        0.0474491306285914,
        0.0467505845417079,
        0.0460722841936799,
        0.0454133629268295,
        0.0447730028046924,
        0.0441504312388234,
        0.043544917891777,
        0.0429557718302832,
        0.0423823389053591,
        0.0418239993385314,
        0.0412801654955457,
        0.04075027983075,
        0.0402338129871494,
        0.0397302620384951,
        0.0392391488612502,
        0.0387600186253111,
        0.038292438393568,
        0.0378359958212153,
        0.0373902979466531,
        0.0369549700665373,
        0.0365296546882192,
        0.036114010553465,
        0.0357077117278297,
        0.0353104467506198,
        0.0349219178407766,
        0.034541840154457,
        0.0341699410904066,
        0.0338059596396096,
        0.0334496457759061,
        0.033100759884664,
        0.0327590722267011,
        0.0324243624349954,
        0.0320964190418386,
        0.031775039034318,
        0.0314600274361729,
        0.0311511969141996,
        0.0308483674075633,
        0.0305513657784654,
        0.0302600254827412,
        0.0299741862590869,
        0.0296936938356822,
        0.0294183996531032,
        0.0291481606024636,
        0.0288828387778244,
        0.0286223012419786,
        0.0283664198047551,
        0.0281150708131021,
        0.0278681349521918
      ]
    },
    SaaS: {
      median: [
        null,
        null,
        null,
        null,
        1.14217598612917,
        0.585809962327334,
        0.39970322406583,
        0.306208382295339,
        0.249791271347249,
        0.211937185621396,
        0.184708824580207,
        0.164135716765012,
        0.148010649866877,
        0.135008210180624,
        0.124284094543129,
        0.11527474810429,
        0.107589463104504,
        0.100948710346872,
        0.0951471297636276,
        0.0900303929884083,
        0.0854802269070103,
        0.0814044252304182,
        0.0777300109909986,
        0.0743984476067271,
        0.0713622160290516,
        0.0685823236083847,
        0.0660264613497192,
        0.0636676206375706,
        0.0614830409356725,
        0.0594533994809413,
        0.0575621803375802,
        0.0557951780579518,
        0.0541401035306809,
        0.0525862682319459,
        0.0511243292257929,
        0.049746081666323,
        0.0484442887606546,
        0.0472125415097218,
        0.046045142296369,
        0.0449370077050201,
        0.0438835869526115,
        0.0428807930704875,
        0.0419249445618614,
        0.0410127157129882,
        0.0401410940903753,
        0.0393073440348091,
        0.0385089751832846,
        0.0377437152252856,
        0.0370094862402555,
        0.036304384076096,
        0.0356266603199715,
        0.0349747064870421,
        0.0343470401134986,
        0.0337422924901186,
        0.0331591978136658,
        0.0325965835674574,
        0.0320533619706946,
        0.0315285223597263,
        0.0310211243841534,
        0.0305302919172656,
        0.0300552075942865,
        0.0295951079037189,
        0.029149278767125,
        0.0287170515512013,
        0.0282977994633059,
        0.0278909342878201,
        0.0274959034260888,
        0.0271121872072796,
        0.0267392964414805,
        0.0263767701897858,
        0.0260241737291003,
        0.0256810966919776,
        0.0253471513640617,
        0.025021971123666,
        0.0247052090097439,
        0.0243965364060087,
        0.0240956418302878,
        0.0238022298193527,
        0.0235160199004975,
        0.0232367456420406,
        0.0229641537757242,
        0.0226980033846986,
        0.0224380651514027,
        0.0221841206602163,
        0.0219359617502544,
        0.0216933899141204,
        0.0214562157388273,
        0.0212242583854567,
        0.0209973451044365,
        0.0207753107836084,
        0.0205579975265065,
        0.020345254258505,
        0.0201369363586916,
        0.0199329053155185,
        0.0197330284044437,
        0.0195371783859324,
        0.0193452332223245,
        0.0191570758121971,
        0.0189725937409675,
        0.0187916790465814,
        0.0186142279992262,
        0.0184401408940927,
        0.0182693218562889,
        0.0181016786570743,
        0.0179371225406542,
        0.0177755680608253,
        0.0176169329268237,
        0.0174611378577714,
        0.0173081064451623,
        0.0171577650228742,
        0.0170100425442246,
        0.0168648704656285,
        0.0167221826364456,
        0.016581915194632,
        0.0164440064678433,
        0.0163083968796564,
        0.0161750288606011,
        0.0160438467637165,
        0.015914796784362,
        0.015787826884036,
        0.0156628867179676
      ],
      average: [
        null,
        null,
        null,
        null,
        1.05004235355444,
        0.53089001866272,
        0.357743733180768,
        0.271101551215223,
        0.219062984796773,
        0.184327793789988,
        0.159481527018954,
        0.14081690731532,
        0.126274293010063,
        0.114617862371889,
        0.105061151855615,
        0.0970798251140768,
        0.0903108562164706,
        0.0844949199766214,
        0.0794418260789206,
        0.0750089153453668,
        0.0710870807957256,
        0.0675914483071541,
        0.0644550001819418,
        0.061624111608318,
        0.0590553623857422,
        0.0567132181508569,
        0.0545683164753528,
        0.0525961814164517,
        0.0507762465547482,
        0.0490911034664447,
        0.0475259171851925,
        0.0460679669071857,
        0.0447062817093119,
        0.043431349111483,
        0.0422348800352123,
        0.041109617822433,
        0.0400491819690554,
        0.0390479394266584,
        0.0381008979591837,
        0.0372036172666073,
        0.0363521345146756,
        0.0355429016173233,
        0.034772732162654,
        0.0340387562951609,
        0.0333383821960858,
        0.0326692630624755,
        0.0320292686900315,
        0.03141646092754,
        0.0308290724008311,
        0.0302654880089034,
        0.0297242287795018,
        0.0292039377402104,
        0.028703367517263,
        0.0282213694203191,
        0.0277568838093621,
        0.0273089315712332,
        0.026876606559343,
        0.0264590688717947,
        0.0260555388612942,
        0.0256652917854524,
        0.0252876530189031,
        0.0249219937595008,
        0.0245677271700361,
        0.0242243049047172,
        0.0238912139763144,
        0.0235679739255557,
        0.023254134259235,
        0.0229492721276828,
        0.0226529902158634,
        0.0223649148254747,
        0.0220846941281316,
        0.0218119965720505,
        0.0215465094266935,
        0.0212879374516024,
        0.0210360016772047,
        0.0207904382867287,
        0.0205509975895558,
        0.0203174430773791,
        0.0200895505554615,
        0.0198671073420937,
        0.0196499115300678,
        0.0194377713046194,
        0.0192305043128477,
        0.0190279370801245,
        0.0188299044694438,
        0.0186362491800588,
        0.018446821282105,
        0.0182614777842218,
        0.0180800822314629,
        0.0179025043310438,
        0.0177286196036919,
        0.0175583090585743,
        0.0173914588899533,
        0.0172279601938921,
        0.0170677087034704,
        0.0169106045411128,
        0.0167565519867441,
        0.0166054592606026,
        0.0164572383196351,
        0.016311804666488,
        0.0161690771701932,
        0.0160289778977147,
        0.0158914319555949,
        0.0157563673409985,
        0.015623714801503,
        0.0154934077030449,
        0.0153653819054652,
        0.0152395756451504,
        0.0151159294242969,
        0.0149943859063667,
        0.0148748898173278,
        0.0147573878523141,
        0.0146418285873523,
        0.0145281623958398,
        0.0144163413694771,
        0.0143063192433743,
        0.0141980513250802,
        0.0140914944272919,
        0.0139866068040238,
        0.013883348090029,
        0.0137816792432811
      ]
    },
    Transactional: {
      median: [
        null,
        null,
        null,
        null,
        4.6249389504662,
        1.65197112637559,
        0.95226280384707,
        0.657888914821151,
        0.499162367691534,
        0.400840434742801,
        0.334295096585817,
        0.286406512380966,
        0.250359607439733,
        0.222279194508836,
        0.199805560891992,
        0.181422532912276,
        0.166112869015648,
        0.15316934787786,
        0.142085414477389,
        0.132488943247178,
        0.124100548467706,
        0.116706467077013,
        0.110140409674164,
        0.104271106485861,
        0.0989935706729755,
        0.0942228479686202,
        0.0898894658318467,
        0.0859360671747288,
        0.0823148844064274,
        0.0789858191778344,
        0.075914965112995,
        0.0730734588658732,
        0.070436577512977,
        0.0679830228611696,
        0.0656943490687913,
        0.0635545012161272,
        0.061549440545191,
        0.059666837971716,
        0.0578958218000509,
        0.0562267687876813,
        0.0546511301186991,
        0.0531612856715404,
        0.0517504213599234,
        0.0504124253979103,
        0.0491418001707503,
        0.0479335870414535,
        0.0467833019323524,
        0.0456868799235082,
        0.0446406274300166,
        0.0436411807763396,
        0.042685470191728,
        0.0417706884172183,
        0.0408942632498956,
        0.0400538334604204,
        0.0392472276102661,
        0.0384724453695459,
        0.0377276409978591,
        0.0370111087016587,
        0.0363212696241816,
        0.0356566602595576,
        0.0350159221125467,
        0.0343977924504924,
        0.033801096015265,
        0.0332247375809744,
        0.0326676952584736,
        0.0321290144607063,
        0.0316078024540539,
        0.0311032234303839,
        0.030614494042672,
        0.0301408793541289,
        0.0296816891568352,
        0.02923627462117,
        0.0288040252418763,
        0.0283843660505755,
        0.0279767550680133,
        0.0275806809723231,
        0.0271956609622551,
        0.0268212387965958,
        0.0264569829930907,
        0.0261024851719166,
        0.0257573585303653,
        0.0254212364367662,
        0.025093771132911,
        0.0247746325353589,
        0.0244635071269188,
        0.0241600969305141,
        0.0238641185583608,
        0.0235753023301005,
        0.0232933914541206,
        0.0230181412668412,
        0.0227493185252441,
        0.0224867007483359,
        0.0222300756036584,
        0.0219792403352727,
        0.0217340012300028,
        0.0214941731189751,
        0.0212595789117586,
        0.0210300491606612,
        0.0208054216529024,
        0.0205855410286392,
        0.0203702584229197,
        0.0201594311298548,
        0.019952922287413,
        0.0197506005813671,
        0.0195523399670601,
        0.0193580194077427,
        0.0191675226283513,
        0.0189807378836664,
        0.018797557739883,
        0.018617878868699,
        0.0184416018530942,
        0.0182686310040217,
        0.0180988741873219,
        0.0179322426601799,
        0.0177686509165365,
        0.0176080165408695,
        0.0174502600698347,
        0.017295304861276,
        0.017143076970134,
        0.0169935050308666,
        0.0168465201459439
      ],
      average: [
        null,
        null,
        null,
        1.33333333333333,
        1.36613333333333,
        2.49155555555556,
        2.71482666666667,
        1.99066373764318,
        0.997860289490245,
        0.665774325057302,
        0.499521746469364,
        0.399705316902518,
        0.333134858090201,
        0.285572094489608,
        0.249893408526601,
        0.222139499576655,
        0.199934021578825,
        0.181764381856121,
        0.166621989785639,
        0.15380848121687,
        0.142824960576707,
        0.133305531900976,
        0.124975748326245,
        0.117625722422702,
        0.11109219744532,
        0.105246278933766,
        0.0999848460017788,
        0.0952244160226707,
        0.0908966822470105,
        0.0869452152992689,
        0.0833229891519962,
        0.0799905007647155,
        0.0769143235816819,
        0.0740659824246778,
        0.0714210694444394,
        0.0689585429527099,
        0.0666601664687931,
        0.0645100563235526,
        0.0624943140770075,
        0.0606007257610108,
        0.0588185141905685,
        0.0571381337313016,
        0.0555510992686222,
        0.0540498429087251,
        0.0526275933034447,
        0.0512782735386929,
        0.0499964143381721,
        0.0487770799678099,
        0.0476158047243254,
        0.0465085382850831,
        0.0454515985096086,
        0.0444416305337161,
        0.0434755711987567,
        0.0425506180214588,
        0.0416642020422426,
        0.0408139639979844,
        0.0399977333538411,
        0.0392135098017451,
        0.03845944689354,
        0.037733837526844,
        0.0370351010434891,
        0.0363617717353112,
        0.0357124885813858,
        0.0350859860654883,
        0.034481085943413,
        0.0338966898474655,
        0.0333317726304629,
        0.0327853763643839,
        0.032256604919764,
        0.0317446190613087,
        0.0312486320032696,
        0.0307679053750706,
        0.0303017455536777,
        0.0298495003243948,
        0.029410555836282,
        0.0289843338223061,
        0.0285702890577529,
        0.0281679070334112,
        0.0277767018226485,
        0.0273962141237886,
        0.0270260094612073,
        0.0266656765303336,
        0.0263148256733046,
        0.0259730874733937,
        0.0256401114575559,
        0.0253155648975097,
        0.0249991317007343,
        0.0246905113836114,
        0.0243894181197002,
        0.0240955798568093,
        0.0238087374971319,
        0.0235286441352519,
        0.0232550643493105,
        0.0229877735410557,
        0.0227265573208864,
        0.0224712109343543,
        0.0222215387268954,
        0.0219773536438544,
        0.0217384767631139,
        0.0215047368578768,
        0.0212759699873535,
        0.0210520191133014,
        0.0208327337405287,
        0.0206179695796343,
        0.0204075882303968,
        0.0202014568843495,
        0.0199994480452024,
        0.0198014392658709,
        0.0196073129009728,
        0.019416955873744,
        0.019230259456399,
        0.0190471190630439,
        0.0188674340543084,
        0.0186911075529356,
        0.0185180462696142,
        0.018348160338399,
        0.0181813631611069,
        0.018017571260126,
        0.0178567041391065,
        0.0176986841510514,
        0.0175434363733472
      ]
    },
    [CustomerFocus.B2B]: {
      median: [
        null,
        null,
        null,
        null,
        4.94337228459694,
        1.73535290112321,
        0.992902645911471,
        0.683110650470097,
        0.516917344293231,
        0.414328757138233,
        0.345073041380905,
        0.295331563196698,
        0.257948029440376,
        0.228863100047548,
        0.20560985108483,
        0.186605869752409,
        0.170790982855945,
        0.157429026227655,
        0.145993278979754,
        0.136097166346113,
        0.127450697537267,
        0.119832161595148,
        0.113069202618434,
        0.107025844914215,
        0.101593398284532,
        0.0966839563190444,
        0.092225665738134,
        0.088159229284838,
        0.0844352830974465,
        0.0810124040218766,
        0.0778555773764652,
        0.0749350058062059,
        0.0722251739228933,
        0.0697041069411075,
        0.0673527779918085,
        0.0651546304911655,
        0.0630951903514631,
        0.0611617489378014,
        0.0593431021722506,
        0.0576293345282098,
        0.0560116391631854,
        0.0544821673337549,
        0.0530339016827106,
        0.0516605491004335,
        0.0503564497240761,
        0.0491164993102567,
        0.0479360827448182,
        0.0468110168703797,
        0.0457375011440861,
        0.0447120749031559,
        0.0437315802290532,
        0.0427931295733589,
        0.0418940774483679,
        0.0410319955995375,
        0.0402046511705054,
        0.0394099874483536,
        0.0386461068404586,
        0.0379112557870546,
        0.0372038113576187,
        0.0365222693159483,
        0.0358652334696224,
        0.0352314061455237,
        0.0346195796549661,
        0.0340286286305907,
        0.0334575031328984,
        0.0329052224377873,
        0.0323708694278844,
        0.0318535855203475,
        0.0313525660722164,
        0.0308670562116965,
        0.0303963470500265,
        0.0299397722340117,
        0.0294967048040298,
        0.0290665543263818,
        0.0286487642724784,
        0.028242809620403,
        0.0278481946571842,
        0.0274644509624199,
        0.0270911355560743,
        0.0267278291950362,
        0.0263741348047043,
        0.02602967603328,
        0.0256940959176918,
        0.0253670556512657,
        0.025048233444158,
        0.0247373234685522,
        0.0244340348813103,
        0.0241380909175736,
        0.0238492280493293,
        0.0235671952036199,
        0.0232917530354836,
        0.0230226732512355,
        0.0227597379780623,
        0.0225027391762599,
        0.0222514780908184,
        0.0220057647392822,
        0.0217654174331442,
        0.021530262330219,
        0.0213001330156975,
        0.0210748701097532,
        0.0208543208997632,
        0.0206383389953518,
        0.0204267840046328,
        0.0202195212301447,
        0.0200164213830816,
        0.0198173603145756,
        0.01962221876282,
        0.0194308821149944,
        0.0192432401829536,
        0.0190591869917873,
        0.0188786205803863,
        0.0187014428132237,
        0.0185275592026373,
        0.0183568787409164,
        0.0181893137415807,
        0.0180247796892715,
        0.0178631950977005,
        0.017704481375181,
        0.0175485626972344,
        0.0173953658858922,
        0.0172448202952359
      ],
      average: [
        null,
        null,
        null,
        null,
        5.39469051530733,
        1.85066686298438,
        1.04845582673423,
        0.717348690991791,
        0.540907102221307,
        0.432492413720294,
        0.359550002047654,
        0.307295867500035,
        0.268104250165585,
        0.237663280197374,
        0.213359419644695,
        0.19351988456544,
        0.177026048163316,
        0.163102409055795,
        0.151194881253604,
        0.140897309876393,
        0.131905351299137,
        0.123986550189966,
        0.116960358147961,
        0.110684450405819,
        0.105045144670075,
        0.0999505582676233,
        0.0953256336733817,
        0.0911084641559226,
        0.0872475402966807,
        0.0836996593360198,
        0.0804283186492021,
        0.0774024676023075,
        0.0745955279860316,
        0.0719846180234421,
        0.0695499323070666,
        0.0672742423383582,
        0.0651424911932394,
        0.0631414622715398,
        0.0612595068166912,
        0.0594863184025208,
        0.0578127452151484,
        0.0562306329476802,
        0.0547326926427275,
        0.0533123889840283,
        0.0519638454415576,
        0.0506817633788215,
        0.049461352783914,
        0.0482982727227572,
        0.0471885799600928,
        0.0461286844713043,
        0.0451153107911742,
        0.0441454643258203,
        0.0432164019003399,
        0.0423256059339611,
        0.0414707617322547,
        0.040649737466388,
        0.0398605664758381,
        0.0391014315861378,
        0.0383706511790974,
        0.0376666667913287,
        0.0369880320490487,
        0.0363334027742395,
        0.0357015281200595,
        0.0350912426127903,
        0.0345014589940075,
        0.0339311617707003,
        0.0333794013929976,
        0.0328452889894274,
        0.0323279915984226,
        0.0318267278423704,
        0.0313407639970357,
        0.0308694104148486,
        0.030412018265454,
        0.0299679765611774,
        0.0295367094387819,
        0.0291176736721227,
        0.0287103563931542,
        0.0283142730011972,
        0.0279289652425965,
        0.0275539994447684,
        0.0271889648903726,
        0.026833472318798,
        0.026487152543482,
        0.0261496551747676,
        0.025820647439005,
        0.025499813085555,
        0.0251868513741446,
        0.0248814761357813,
        0.0245834149010578,
        0.0242924080902805,
        0.0240082082603614,
        0.0237305794038896,
        0.0234592962962185,
        0.0231941438867568,
        0.0229349167310444,
        0.0226814184604243,
        0.0224334612864667,
        0.0221908655375053,
        0.0219534592248804,
        0.0217210776367117,
        0.021493562957157,
        0.0212707639093298,
        0.0210525354201746,
        0.0208387383057337,
        0.0206292389753857,
        0.0204239091537205,
        0.0202226256188485,
        0.0200252699560157,
        0.0198317283254916,
        0.019641891243771,
        0.0194556533772237,
        0.0192729133473425,
        0.0190935735468782,
        0.0189175399661122,
        0.0187447220286601,
        0.0185750324361766,
        0.0184083870214172,
        0.0182447046091334,
        0.0180839068843069,
        0.0179259182672999,
        0.0177706657954769
      ]
    },
    [CustomerFocus.B2C]: {
      median: [
        null,
        null,
        null,
        null,
        20.2504675214486,
        4.75331740641387,
        2.28606868842544,
        1.42751726676255,
        1.01554381641471,
        0.77995938360955,
        0.629582115876314,
        0.526112736954495,
        0.450947380952271,
        0.394059576835509,
        0.349606783939401,
        0.313970987461203,
        0.284799879538252,
        0.260501848063601,
        0.239963584967457,
        0.222384140471894,
        0.207173235590663,
        0.19388667906712,
        0.182184059826207,
        0.171800272012889,
        0.162525887889286,
        0.154193340189012,
        0.146667008800759,
        0.139835986726162,
        0.133608719524713,
        0.127908977288085,
        0.1226727891777,
        0.117846083187705,
        0.113382849344987,
        0.109243696088674,
        0.105394705263736,
        0.101806516238289,
        0.0984535875027974,
        0.0953135969702645,
        0.0923669515691791,
        0.0895963836242605,
        0.0869866166553209,
        0.0845240870801269,
        0.0821967112274879,
        0.0799936892968233,
        0.0779053396167582,
        0.0759229578857943,
        0.0740386971167653,
        0.0722454648229158,
        0.0705368346287451,
        0.0689069700019599,
        0.067350558213455,
        0.0658627529622478,
        0.0644391243692136,
        0.0630756152600385,
        0.0617685028347534,
        0.0605143649660325,
        0.0593100504878075,
        0.0581526529342983,
        0.0570394872714012,
        0.0559680692304841,
        0.0549360969116049,
        0.0539414343709442,
        0.0529820969474292,
        0.0520562381175018,
        0.0511621376956779,
        0.0502981912230402,
        0.0494629004065363,
        0.0486548644897887,
        0.047872772451298,
        0.0471153959390325,
        0.0463815828616368,
        0.0456702515662075,
        0.0449803855410017,
        0.0443110285887095,
        0.0436612804222915,
        0.0430302926408526,
        0.0424172650478928,
        0.0418214422784093,
        0.041242110705107,
        0.0406785955971143,
        0.0401302585075387,
        0.0395964948686244,
        0.0390767317755492,
        0.0385704259418288,
        0.0380770618110244,
        0.0375961498110139,
        0.0371272247383995,
        0.0366698442619337,
        0.0362235875347937,
        0.0357880539066634,
        0.0353628617272757,
        0.0349476472339905,
        0.0345420635165676,
        0.034145779552975,
        0.0337584793106151,
        0.0333798609078455,
        0.0330096358311277,
        0.0326475282035592,
        0.0322932741008765,
        0.0319466209114052,
        0.031607326736672,
        0.0312751598297122,
        0.030949898068336,
        0.0306313284608345,
        0.0303192466818287,
        0.0300134566361342,
        0.0297137700486893,
        0.0294200060787545,
        0.0291319909567095,
        0.0288495576419343,
        0.0285725455003603,
        0.0283008000003659,
        0.0280341724258434,
        0.0277725196052751,
        0.0275157036558325,
        0.0272635917414981,
        0.0270160558443435,
        0.0267729725481302,
        0.0265342228334592,
        0.0262996918837719,
        0.0260692689015238
      ],
      average: [
        null,
        null,
        null,
        null,
        28.1261486910325,
        5.96797163709768,
        2.74668192768761,
        1.67354249267309,
        1.17214752382839,
        0.890611646094662,
        0.713285975606886,
        0.592515259269589,
        0.505488485400266,
        0.44005501558095,
        0.389202310197866,
        0.348622700042757,
        0.315534668223361,
        0.288067332857678,
        0.264918811734696,
        0.245156764494791,
        0.228096870980018,
        0.213226046305973,
        0.200152371422845,
        0.188571534247089,
        0.178243785873226,
        0.168977773659415,
        0.160618979278575,
        0.153041306307391,
        0.146140863349873,
        0.139831304287166,
        0.134040290340686,
        0.128706771998127,
        0.123778878052392,
        0.119212259681618,
        0.114968779420297,
        0.111015464252835,
        0.107323662928157,
        0.10386836259799,
        0.100627630794136,
        0.0975821567823698,
        0.0947148722874908,
        0.0920106360488883,
        0.0894559700422557,
        0.0870388377772837,
        0.0847484570593713,
        0.0825751411347952,
        0.080510163332615,
        0.0785456412534654,
        0.0766744372951875,
        0.0748900728929858,
        0.0731866543213514,
        0.0715588082821114,
        0.0700016258075289,
        0.0685106132543907,
        0.067081649366486,
        0.0657109475477478,
        0.0643950226239842,
        0.0631306614831212,
        0.0619148970767187,
        0.0607449853427791,
        0.0596183846744121,
        0.058532737613018,
        0.0574858544900945,
        0.0564756987802098,
        0.0555003739600845,
        0.054558111696394,
        0.0536472612082645,
        0.0527662796705781,
        0.05191372354128,
        0.0510882407106478,
        0.0502885633831392,
        0.0495135016133543,
        0.0487619374271261,
        0.0480328194668932,
        0.0473251581076846,
        0.0466380209961699,
        0.0459705289707,
        0.0453218523248962,
        0.044691207381581,
        0.044077853347373,
        0.0434810894215295,
        0.0429002521353858,
        0.0423347129012245,
        0.0417838757516359,
        0.041247175252283,
        0.0407240745728119,
        0.0402140637020617,
        0.0397166577951938,
        0.0392313956414647,
        0.0387578382425398,
        0.0382955674921284,
        0.0378441849486434,
        0.0374033106933151,
        0.0369725822669094,
        0.0365516536788218,
        0.0361401944828463,
        0.0357378889144705,
        0.0353444350849469,
        0.0349595442278606,
        0.0345829399942199,
        0.0342143577924951,
        0.0338535441702584,
        0.0335002562344504,
        0.0331542611074335,
        0.032815335416333,
        0.0324832648132725,
        0.0321578435243781,
        0.0318388739255371,
        0.0315261661430942,
        0.0312195376777741,
        0.0309188130503038,
        0.030623823467233,
        0.0303344065056967,
        0.0300504058157973,
        0.0297716708395439,
        0.0294980565452283,
        0.0292294231762936,
        0.0289656360137639,
        0.0287065651513869,
        0.0284520852827188,
        0.0282020754994023
      ]
    },
    [Industry.OTHER]: {
      median: [
        null,
        null,
        null,
        null,
        1.75049654059717,
        0.786092256966588,
        0.500099302202877,
        0.365219646300991,
        0.287172365299776,
        0.236422786736414,
        0.200830680437874,
        0.174508815285271,
        0.154262795477116,
        0.138211783026193,
        0.125177237017761,
        0.114383551659101,
        0.105299632425139,
        0.097549694877443,
        0.0908604218187084,
        0.0850283020854141,
        0.0798987032043634,
        0.075352041003562,
        0.0712943903267601,
        0.0676509586100185,
        0.0643614537642716,
        0.0613767348494262,
        0.0586563495826773,
        0.0561666964418116,
        0.0538796341173249,
        0.0517714162883269,
        0.0498218662960455,
        0.0480137309888279,
        0.0463321699610308,
        0.0447643482161622,
        0.0432991086275217,
        0.0419267065412085,
        0.040638593192495,
        0.0394272377758589,
        0.0382859803552182,
        0.0372089095547959,
        0.0361907602942705,
        0.0352268278386817,
        0.0343128952059249,
        0.0334451715716483,
        0.0326202397761276,
        0.0318350114020015,
        0.0310866881791336,
        0.0303727287009818,
        0.0296908196189573,
        0.0290388506274327,
        0.0284148926700405,
        0.0278171788935432,
        0.027244087953553,
        0.0266941293401601,
        0.0261659304440352,
        0.0256582251268642,
        0.0251698435959031,
        0.0246997034123153,
        0.0242468014879129,
        0.0238102069458412,
        0.023389054738336,
        0.0229825399295371,
        0.0225899125638832,
        0.0222104730513131,
        0.0218435680095447,
        0.0214885865114896,
        0.0211449566924804,
        0.0208121426777021,
        0.0204896417951201,
        0.020176982043427,
        0.0198737197882009,
        0.0195794376626342,
        0.0192937426519515,
        0.0190162643430343,
        0.0187466533228709,
        0.0184845797112697,
        0.0182297318148993,
        0.01798181489109,
        0.017740550011119,
        0.0175056730137412,
        0.0172769335407343,
        0.0170540941470471,
        0.016836929478901,
        0.0166252255138843,
        0.0164187788576253,
        0.0162173960922227,
        0.0160208931720086,
        0.0158290948627154,
        0.0156418342204208,
        0.0154589521070467,
        0.0152802967394296,
        0.0151057232693012,
        0.0149350933917166,
        0.0147682749797244,
        0.0146051417432339,
        0.0144455729102482,
        0.0142894529287481,
        0.0141366711877076,
        0.013987121755801,
        0.0138407031365265,
        0.0136973180385404,
        0.0135568731601166,
        0.0134192789867321,
        0.0132844496008486,
        0.0131523025030443,
        0.0130227584437164,
        0.0128957412646248,
        0.0127711777496235,
        0.012648997483953,
        0.0125291327215322,
        0.012411518259728,
        0.0122960913210995,
        0.0121827914416954,
        0.0120715603654504,
        0.0119623419443307,
        0.0118550820438355,
        0.0117497284535486,
        0.0116462308024078,
        0.011544540478416,
        0.0114446105525202,
        0.0113463957064113
      ],
      average: [
        null,
        null,
        null,
        0.0666980451797843,
        0.0666980451797844,
        0.0666980451797843,
        0.0666980451797844,
        0.0666980451797844,
        0.0666980451797843,
        0.0666980451797843,
        0.0666980451797843,
        0.0666980451797843,
        0.0666980451797843,
        0.0666980451797844,
        0.0666980451797844,
        0.0666980451797843,
        0.0666980451797843,
        0.0666980451797844,
        0.0666980451797843,
        0.0666980451797843,
        0.0666980451797843,
        0.0666980451797844,
        0.0666980451797843,
        0.0666980451797844,
        0.0666980451797844,
        0.0666980451797843,
        0.0666980451797842,
        0.0666980451797843,
        0.0666980451797844,
        0.0666980451797844,
        0.0666980451797844,
        0.0666980451797844,
        0.0666980451797842,
        0.0666980451797844,
        0.0666980451797844,
        0.0666980451797844,
        0.0666980451797843,
        0.0666980451797843,
        0.0666980451797843,
        0.0666980451797844,
        0.0666980451797843,
        0.0666980451797844,
        0.0666980451797842,
        0.0666980451797843,
        0.0666980451797843,
        0.0666980451797844,
        0.0666980451797844,
        0.0666980451797844,
        0.0666980451797844,
        0.0666980451797843,
        0.0666980451797843,
        0.0666980451797843,
        0.0666980451797844,
        0.0666980451797842,
        0.0666980451797842,
        0.0666980451797842,
        0.0666980451797843,
        0.0666980451797843,
        0.0666980451797845,
        0.0666980451797844,
        0.0666980451797844,
        0.0666980451797842,
        0.0666980451797842,
        0.0666980451797843,
        0.0666980451797843,
        0.0666980451797843,
        0.0666980451797845,
        0.0666980451797842,
        0.0666980451797844,
        0.0666980451797841,
        0.0666980451797844,
        0.0666980451797841,
        0.0666980451797844,
        0.0666980451797841,
        0.0666980451797844,
        0.0666980451797841,
        0.0666980451797845,
        0.0666980451797844,
        0.0666980451797845,
        0.0666980451797844,
        0.0666980451797841,
        0.0666980451797844,
        0.0666980451797841,
        0.0666980451797844,
        0.0666980451797841,
        0.0666980451797844,
        0.0666980451797841,
        0.0666980451797845,
        0.0666980451797841,
        0.0666980451797844,
        0.0666980451797844,
        0.0666980451797844,
        0.0666980451797844,
        0.0666980451797841,
        0.0666980451797845,
        0.0666980451797841,
        0.0666980451797845,
        0.0666980451797841,
        0.0666980451797844,
        0.0666980451797841,
        0.0666980451797845,
        0.0666980451797841,
        0.0666980451797845,
        0.0666980451797844,
        0.0666980451797844,
        0.0666980451797844,
        0.0666980451797841,
        0.0666980451797844,
        0.0666980451797841,
        0.0666980451797845,
        0.0666980451797841,
        0.0666980451797845,
        0.0666980451797841,
        0.0666980451797844,
        0.0666980451797841,
        0.0666980451797844,
        0.0666980451797844,
        0.0666980451797844,
        0.0666980451797844,
        0.0666980451797841,
        0.0666980451797844
      ]
    },
    [Industry.IT]: {
      median: [
        null,
        null,
        null,
        null,
        4.65804236646618,
        1.66072211371215,
        0.956547242508155,
        0.660555071447264,
        0.501042612630629,
        0.402270698966674,
        0.335439081978116,
        0.287354557414432,
        0.251166170567984,
        0.222979345069612,
        0.200423068016371,
        0.181974177659719,
        0.166610899682816,
        0.153622954837951,
        0.14250165620034,
        0.132873349824247,
        0.124457527678687,
        0.117039585220423,
        0.110452590500488,
        0.10456477512293,
        0.0992707597208336,
        0.0944852770248399,
        0.0901386020019842,
        0.0861731717421614,
        0.0825410492722909,
        0.079201995633729,
        0.0761219867935996,
        0.0732720602317805,
        0.0706274088637443,
        0.0681666626280639,
        0.0658713139551626,
        0.063725254620225,
        0.0617143996014906,
        0.0598263794728209,
        0.0580502872052301,
        0.0563764684813268,
        0.0547963470490277,
        0.0533022784742985,
        0.0518874270518064,
        0.0505456617086632,
        0.0492714675703698,
        0.0480598705089513,
        0.0469063725044897,
        0.0458068960554425,
        0.0447577361945335,
        0.0437555189240398,
        0.0427971650910216,
        0.0418798588900627,
        0.0410010203168126,
        0.0401582810063256,
        0.0393494629809703,
        0.0385725599073927,
        0.0378257205237831,
        0.0371072339499492,
        0.0364155166354028,
        0.0357491007363509,
        0.0351066237424394,
        0.0344868191993023,
        0.0338885083942567,
        0.0333105928905249,
        0.0327520478106804,
        0.0322119157830749,
        0.0316893014761657,
        0.0311833666552129,
        0.0306933257040436,
        0.0302184415616334,
        0.0297580220293838,
        0.0293114164102369,
        0.0288780124453684,
        0.0284572335181709,
        0.0280485360987227,
        0.027651407404951,
        0.0272653632593707,
        0.0268899461225643,
        0.0265247232866661,
        0.0261692852138323,
        0.0258232440063422,
        0.0254862319962947,
        0.0251579004441528,
        0.0248379183364654,
        0.0245259712740376,
        0.0242217604427524,
        0.0239250016599114,
        0.0236354244897687,
        0.0233527714224139,
        0.0230767971108245,
        0.022807267661303,
        0.022543959973006,
        0.0222866611226505,
        0.0220351677908197,
        0.0217892857266499,
        0.0215488292479073,
        0.0213136207737732,
        0.0210834903878675,
        0.0208582754292406,
        0.0206378201092862,
        0.0204219751526582,
        0.0202105974604694,
        0.0200035497941785,
        0.0198007004786768,
        0.0196019231232571,
        0.0194070963591955,
        0.0192161035928218,
        0.0190288327730134,
        0.018845176172139,
        0.018665030179557,
        0.0184882951068415,
        0.0183148750039442,
        0.018144677485623,
        0.0179776135674266,
        0.0178135975106692,
        0.0176525466757957,
        0.0174943813836274,
        0.0173390247840057,
        0.0171864027313442,
        0.0170364436667182,
        0.0168890785060476
      ],
      average: [
        null,
        null,
        null,
        null,
        5.37091780205464,
        1.84467156949247,
        1.0455854623647,
        0.715586236105385,
        0.539675282332487,
        0.431561433197299,
        0.358808996191279,
        0.306684127485092,
        0.267585405659771,
        0.237214029701914,
        0.212964038042237,
        0.193167310896228,
        0.176708235066009,
        0.162813335296576,
        0.150929933903447,
        0.140652882915426,
        0.131678576307511,
        0.123775110571245,
        0.11676235783372,
        0.110498319065949,
        0.10486956797393,
        0.0997844257035353,
        0.0951679989105187,
        0.0909585145196648,
        0.0871045729690857,
        0.0835630625890669,
        0.0802975568929425,
        0.0772770693589318,
        0.0744750761324082,
        0.0718687418100408,
        0.069438300780826,
        0.0671665588846971,
        0.0650384889773268,
        0.0630409004075459,
        0.0611621671304753,
        0.0593920026812453,
        0.0577212728588838,
        0.0561418389547913,
        0.0546464258739255,
        0.0532285106602563,
        0.0518822278390592,
        0.050602288691236,
        0.0493839121264834,
        0.0482227652579406,
        0.0471149121273094,
        0.0460567693063254,
        0.0450450673229647,
        0.0440768170405275,
        0.0431492802636787,
        0.0422599439645507,
        0.0414064976195407,
        0.040586813227684,
        0.0397989276477996,
        0.0390410269466052,
        0.0383114324958057,
        0.0376085885944311,
        0.0369310514248099,
        0.0362774791775802,
        0.0356466232039289,
        0.0350373200725874,
        0.0344484844254931,
        0.0338791025400201,
        0.0333282265176061,
        0.0327949690288281,
        0.0322784985537815,
        0.0317780350641425,
        0.0312928460998629,
        0.0308222431990405,
        0.0303655786444637,
        0.0299222424945237,
        0.0294916598699414,
        0.0290732884709514,
        0.0286666163024529,
        0.0282711595870673,
        0.0278864608482676,
        0.0275120871476144,
        0.0271476284618498,
        0.026792696187077,
        0.0264469217585544,
        0.0261099553758379,
        0.0257814648239872,
        0.0254611343825148,
        0.0251486638145396,
        0.0248437674293684,
        0.0245461732123374,
        0.0242556220163769,
        0.0239718668102248,
        0.0236946719787359,
        0.0234238126711084,
        0.0231590741932504,
        0.0229002514408404,
        0.0226471483699335,
        0.0223995775022433,
        0.0221573594624872,
        0.0219203225453859,
        0.0216883023101339,
        0.0214611412003255,
        0.0212386881874841,
        0.0210207984365181,
        0.0208073329915345,
        0.020598158480578,
        0.0203931468379931,
        0.0201921750431755,
        0.019995124874614,
        0.0198018826781741,
        0.0196123391486736,
        0.0194263891238852,
        0.0192439313901186,
        0.0190648684986641,
        0.0188891065923641,
        0.0187165552416949,
        0.018547127289736,
        0.0183807387054791,
        0.0182173084449578,
        0.0180567583197083,
        0.0178990128721284,
        0.0177439992573001
      ]
    },
    [Industry.FIPT]: {
      median: [
        null,
        null,
        null,
        null,
        20.2504675214486,
        4.75331740641387,
        2.28606868842544,
        1.42751726676255,
        1.01554381641471,
        0.77995938360955,
        0.629582115876314,
        0.526112736954495,
        0.450947380952271,
        0.394059576835509,
        0.349606783939401,
        0.313970987461203,
        0.284799879538252,
        0.260501848063601,
        0.239963584967457,
        0.222384140471894,
        0.207173235590663,
        0.19388667906712,
        0.182184059826207,
        0.171800272012889,
        0.162525887889286,
        0.154193340189012,
        0.146667008800759,
        0.139835986726162,
        0.133608719524713,
        0.127908977288085,
        0.1226727891777,
        0.117846083187705,
        0.113382849344987,
        0.109243696088674,
        0.105394705263736,
        0.101806516238289,
        0.0984535875027974,
        0.0953135969702645,
        0.0923669515691791,
        0.0895963836242605,
        0.0869866166553209,
        0.0845240870801269,
        0.0821967112274879,
        0.0799936892968233,
        0.0779053396167582,
        0.0759229578857943,
        0.0740386971167653,
        0.0722454648229158,
        0.0705368346287451,
        0.0689069700019599,
        0.067350558213455,
        0.0658627529622478,
        0.0644391243692136,
        0.0630756152600385,
        0.0617685028347534,
        0.0605143649660325,
        0.0593100504878075,
        0.0581526529342983,
        0.0570394872714012,
        0.0559680692304841,
        0.0549360969116049,
        0.0539414343709442,
        0.0529820969474292,
        0.0520562381175018,
        0.0511621376956779,
        0.0502981912230402,
        0.0494629004065363,
        0.0486548644897887,
        0.047872772451298,
        0.0471153959390325,
        0.0463815828616368,
        0.0456702515662075,
        0.0449803855410017,
        0.0443110285887095,
        0.0436612804222915,
        0.0430302926408526,
        0.0424172650478928,
        0.0418214422784093,
        0.041242110705107,
        0.0406785955971143,
        0.0401302585075387,
        0.0395964948686244,
        0.0390767317755492,
        0.0385704259418288,
        0.0380770618110244,
        0.0375961498110139,
        0.0371272247383995,
        0.0366698442619337,
        0.0362235875347937,
        0.0357880539066634,
        0.0353628617272757,
        0.0349476472339905,
        0.0345420635165676,
        0.034145779552975,
        0.0337584793106151,
        0.0333798609078455,
        0.0330096358311277,
        0.0326475282035592,
        0.0322932741008765,
        0.0319466209114052,
        0.031607326736672,
        0.0312751598297122,
        0.030949898068336,
        0.0306313284608345,
        0.0303192466818287,
        0.0300134566361342,
        0.0297137700486893,
        0.0294200060787545,
        0.0291319909567095,
        0.0288495576419343,
        0.0285725455003603,
        0.0283008000003659,
        0.0280341724258434,
        0.0277725196052751,
        0.0275157036558325,
        0.0272635917414981,
        0.0270160558443435,
        0.0267729725481302,
        0.0265342228334592,
        0.0262996918837719,
        0.0260692689015238
      ],
      average: [
        null,
        null,
        null,
        null,
        28.1261486910325,
        5.96797163709768,
        2.74668192768761,
        1.67354249267309,
        1.17214752382839,
        0.890611646094662,
        0.713285975606886,
        0.592515259269589,
        0.505488485400266,
        0.44005501558095,
        0.389202310197866,
        0.348622700042757,
        0.315534668223361,
        0.288067332857678,
        0.264918811734696,
        0.245156764494791,
        0.228096870980018,
        0.213226046305973,
        0.200152371422845,
        0.188571534247089,
        0.178243785873226,
        0.168977773659415,
        0.160618979278575,
        0.153041306307391,
        0.146140863349873,
        0.139831304287166,
        0.134040290340686,
        0.128706771998127,
        0.123778878052392,
        0.119212259681618,
        0.114968779420297,
        0.111015464252835,
        0.107323662928157,
        0.10386836259799,
        0.100627630794136,
        0.0975821567823698,
        0.0947148722874908,
        0.0920106360488883,
        0.0894559700422557,
        0.0870388377772837,
        0.0847484570593713,
        0.0825751411347952,
        0.080510163332615,
        0.0785456412534654,
        0.0766744372951875,
        0.0748900728929858,
        0.0731866543213514,
        0.0715588082821114,
        0.0700016258075289,
        0.0685106132543907,
        0.067081649366486,
        0.0657109475477478,
        0.0643950226239842,
        0.0631306614831212,
        0.0619148970767187,
        0.0607449853427791,
        0.0596183846744121,
        0.058532737613018,
        0.0574858544900945,
        0.0564756987802098,
        0.0555003739600845,
        0.054558111696394,
        0.0536472612082645,
        0.0527662796705781,
        0.05191372354128,
        0.0510882407106478,
        0.0502885633831392,
        0.0495135016133543,
        0.0487619374271261,
        0.0480328194668932,
        0.0473251581076846,
        0.0466380209961699,
        0.0459705289707,
        0.0453218523248962,
        0.044691207381581,
        0.044077853347373,
        0.0434810894215295,
        0.0429002521353858,
        0.0423347129012245,
        0.0417838757516359,
        0.041247175252283,
        0.0407240745728119,
        0.0402140637020617,
        0.0397166577951938,
        0.0392313956414647,
        0.0387578382425398,
        0.0382955674921284,
        0.0378441849486434,
        0.0374033106933151,
        0.0369725822669094,
        0.0365516536788218,
        0.0361401944828463,
        0.0357378889144705,
        0.0353444350849469,
        0.0349595442278606,
        0.0345829399942199,
        0.0342143577924951,
        0.0338535441702584,
        0.0335002562344504,
        0.0331542611074335,
        0.032815335416333,
        0.0324832648132725,
        0.0321578435243781,
        0.0318388739255371,
        0.0315261661430942,
        0.0312195376777741,
        0.0309188130503038,
        0.030623823467233,
        0.0303344065056967,
        0.0300504058157973,
        0.0297716708395439,
        0.0294980565452283,
        0.0292294231762936,
        0.0289656360137639,
        0.0287065651513869,
        0.0284520852827188,
        0.0282020754994023
      ]
    },
    [Industry.DH]: {
      median: [
        null,
        null,
        null,
        null,
        0.0,
        1.33333333333333,
        1.0,
        2.33333333333333,
        0.466666666666667,
        1.16666666666667,
        0.571770479318661,
        0.343266938509775,
        0.167434364810524,
        0.12350116681965,
        0.112012426638775,
        0.102476217762937,
        0.0944343101994453,
        0.0875613358695203,
        0.0816199221473162,
        0.0764328465123165,
        0.0718651301289651,
        0.0678121657050161,
        0.0641916343328857,
        0.060937872306791,
        0.0579978635426051,
        0.0553283356043877,
        0.0528936204469408,
        0.0506640548843091,
        0.048614768374522,
        0.046724752977547,
        0.0449761417381145,
        0.0433536429754629,
        0.0418440925570105,
        0.040436096418951,
        0.0391197428054227,
        0.0378863688655626,
        0.0367283699968538,
        0.0356390430734638,
        0.0346124567369192,
        0.033643343452282,
        0.0327270091854262,
        0.0318592574348707,
        0.0310363250257233,
        0.030254827594845,
        0.0295117131027601,
        0.0288042220266869,
        0.0281298531408152,
        0.0274863339899344,
        0.0268715953222939,
        0.0262837488759138,
        0.0257210680162372,
        0.0251819708071025,
        0.0246650051656366,
        0.024168835807821,
        0.0236922327377367,
        0.0232340610716494,
        0.0227932720197931,
        0.0223688948750537,
        0.0219600298798096,
        0.0215658418606412,
        0.021185554536191,
        0.020818445416559,
        0.0204638412237438,
        0.0201211137720768,
        0.0407480985110293,
        0.0780817543375054,
        0.10556286042931,
        0.101246105919003,
        0.0777777777777778,
        0.0676883780332056,
        0.0681003584229391,
        0.0563063063063063,
        0.0509554140127388,
        0.0505952380952381,
        0.0433526011560694,
        0.0285451197053407,
        0.0094745908699397,
        0.0144927536231884,
        0.0245971162001696,
        0.0226130653266331,
        0.0187908496732026,
        0.0078988941548183,
        0.0109803921568628,
        0.00928074245939673,
        0.00848765432098767,
        0.00303720577069096,
        0.0142964635063958,
        0.0353649360421369,
        0.054176072234763,
        0.056998556998557,
        0.0564625850340136,
        0.0446601941747573,
        0.0624768946395564,
        0.0777428737638163,
        0.110333333333334,
        0.110333333333334,
        0.110333333333333,
        0.110333333333333,
        0.0793563487036311,
        0.0503448764308444,
        0.0231975587693333,
        0.0226716322478667,
        0.02216902428205,
        0.0216882176581521,
        0.0212278239910257,
        0.0207865703345861,
        0.0203632874282161,
        0.0199568993505646,
        0.0195664143879724,
        0.0191909169543583,
        0.0188295604239747,
        0.018481560758935,
        0.0181461908305568,
        0.0178227753479625,
        0.0175106863195014,
        0.0172093389828075,
        0.0169181881479938,
        0.0166367249058698,
        0.0163644736593696,
        0.0161009894417601,
        0.0158458554898228
      ],
      average: [
        null,
        null,
        null,
        null,
        0.156366974076438,
        0.660402137281964,
        1.43835039138635,
        1.63296591358751,
        1.14654106934803,
        0.872631279384499,
        0.699748729795047,
        0.581815904271423,
        0.49672660653137,
        0.43268416912834,
        0.382870161544103,
        0.343090903816649,
        0.310635591626229,
        0.283679223118761,
        0.260950818773631,
        0.241539521967918,
        0.224776358452487,
        0.210159465207269,
        0.19730529928813,
        0.185915914588826,
        0.175756478646757,
        0.166639490554376,
        0.158413488658217,
        0.150954830609139,
        0.144161616164355,
        0.137949130354518,
        0.132246382431605,
        0.126993445962162,
        0.12213939238648,
        0.117640669540271,
        0.113459817535417,
        0.109564443072136,
        0.105926393628153,
        0.102521087624008,
        0.0993269673248171,
        0.0963250490784298,
        0.0934985513128814,
        0.0908325850816155,
        0.088313895246935,
        0.0859306429104323,
        0.0836722216347074,
        0.0815291014994917,
        0.0794926962039316,
        0.077555249344046,
        0.0757097367188996,
        0.0739497820946383,
        0.0722695843155754,
        0.0706638540209906,
        0.0691277585247836,
        0.0676568736572149,
        0.0662471415654764,
        0.0648948336314475,
        0.0635965177980394,
        0.0623490297053383,
        0.0611494471288289,
        0.059995067287775,
        0.0588833866551316,
        0.057812082953458,
        0.056778999065911,
        0.0557821286290749,
        0.0548196031062572,
        0.05388968016694,
        0.0529907332211267,
        0.0521212419769965,
        0.0512797839071225,
        0.0504650265229677,
        0.0496757203698116,
        0.0489106926650133,
        0.0481688415117698,
        0.0474491306285914,
        0.0467505845417079,
        0.0460722841936799,
        0.0454133629268295,
        0.0447730028046924,
        0.0441504312388234,
        0.043544917891777,
        0.0429557718302832,
        0.0423823389053591,
        0.0418239993385314,
        0.0412801654955457,
        0.04075027983075,
        0.0402338129871494,
        0.0397302620384951,
        0.0392391488612502,
        0.0387600186253111,
        0.038292438393568,
        0.0378359958212153,
        0.0373902979466531,
        0.0369549700665373,
        0.0365296546882192,
        0.036114010553465,
        0.0357077117278297,
        0.0353104467506198,
        0.0349219178407766,
        0.034541840154457,
        0.0341699410904066,
        0.0338059596396096,
        0.0334496457759061,
        0.033100759884664,
        0.0327590722267011,
        0.0324243624349954,
        0.0320964190418386,
        0.031775039034318,
        0.0314600274361729,
        0.0311511969141996,
        0.0308483674075633,
        0.0305513657784654,
        0.0302600254827412,
        0.0299741862590869,
        0.0296936938356822,
        0.0294183996531032,
        0.0291481606024636,
        0.0288828387778244,
        0.0286223012419786,
        0.0283664198047551,
        0.0281150708131021,
        0.0278681349521918
      ]
    }
  },
  [EvaluationType.TAKE_RATE]: {
    all: {
      median: [
        0.0,
        0.0054501,
        0.0108008,
        0.0160527,
        0.0212064,
        0.0262625,
        0.0312216,
        0.0360843,
        0.0408512,
        0.0455229,
        0.0501,
        0.0545831,
        0.0589728,
        0.0632697,
        0.0674744,
        0.0715875,
        0.0756096,
        0.0795413,
        0.0833832,
        0.0871359,
        0.0908,
        0.0943761,
        0.0978648,
        0.1012667,
        0.1045824,
        0.1078125,
        0.1109576,
        0.1140183,
        0.1169952,
        0.1198889,
        0.1227,
        0.1254291,
        0.1280768,
        0.1306437,
        0.1331304,
        0.1355375,
        0.1378656,
        0.1401153,
        0.1422872,
        0.1443819,
        0.1464,
        0.1483421,
        0.1502088,
        0.1520007,
        0.1537184,
        0.1553625,
        0.1569336,
        0.1584323,
        0.1598592,
        0.1612149,
        0.1625,
        0.1637151,
        0.1648608,
        0.1659377,
        0.1669464,
        0.1678875,
        0.1687616,
        0.1695693,
        0.1703112,
        0.1709879,
        0.1716,
        0.1721481,
        0.1726328,
        0.1730547,
        0.1734144,
        0.1737125,
        0.1739496,
        0.1741263,
        0.1742432,
        0.1743009,
        0.1743586,
        0.1744163,
        0.174474,
        0.1745317,
        0.1745894,
        0.1746471,
        0.1747048,
        0.1747625,
        0.1748202,
        0.1748779,
        0.1749356,
        0.1749933,
        0.175051,
        0.1751087,
        0.1751664,
        0.1752241,
        0.1752818,
        0.1753395,
        0.1753972,
        0.1754549,
        0.1755126,
        0.1755703,
        0.175628,
        0.1756857,
        0.1757434,
        0.1758011,
        0.1758588,
        0.1759165,
        0.1759742,
        0.1760319,
        0.1760896,
        0.1761473,
        0.176205,
        0.1762627,
        0.1763204,
        0.1763781,
        0.1764358,
        0.1764935,
        0.176551199999999,
        0.176608899999999,
        0.176666599999999,
        0.176724299999999,
        0.176781999999999,
        0.176839699999999,
        0.176897399999999,
        0.176955099999999,
        0.177012799999999,
        0.177070499999999,
        0.177128199999999,
        0.177185899999999,
        0.177243599999999
      ],
      average: [
        0.0,
        0.0061402,
        0.0121616,
        0.0180654,
        0.0238528,
        0.029525,
        0.0350832,
        0.0405286,
        0.0458624,
        0.0510858,
        0.0562,
        0.0612062,
        0.0661056,
        0.0708994,
        0.0755888,
        0.080175,
        0.0846592,
        0.0890426,
        0.0933264,
        0.0975118,
        0.1016,
        0.1055922,
        0.1094896,
        0.1132934,
        0.1170048,
        0.120625,
        0.1241552,
        0.1275966,
        0.1309504,
        0.1342178,
        0.1374,
        0.1404982,
        0.1435136,
        0.1464474,
        0.1493008,
        0.152075,
        0.1547712,
        0.1573906,
        0.1599344,
        0.1624038,
        0.1648,
        0.1671242,
        0.1693776,
        0.1715614,
        0.1736768,
        0.175725,
        0.1777072,
        0.1796246,
        0.1814784,
        0.1832698,
        0.185,
        0.1866702,
        0.1882816,
        0.1898354,
        0.1913328,
        0.192775,
        0.1941632,
        0.1954986,
        0.1967824,
        0.1980158,
        0.1992,
        0.19921992,
        0.199239841992,
        0.199259765976199,
        0.199279691952797,
        0.199299619921992,
        0.199319549883984,
        0.199339481838973,
        0.199359415787157,
        0.199379351728735,
        0.199399289663908,
        0.199419229592875,
        0.199439171515834,
        0.199459115432985,
        0.199479061344529,
        0.199499009250663,
        0.199518959151588,
        0.199538911047503,
        0.199558864938608,
        0.199578820825102,
        0.199598778707185,
        0.199618738585055,
        0.199638700458914,
        0.19965866432896,
        0.199678630195392,
        0.199698598058412,
        0.199718567918218,
        0.19973853977501,
        0.199758513628987,
        0.19977848948035,
        0.199798467329298,
        0.199818447176031,
        0.199838429020749,
        0.199858412863651,
        0.199878398704937,
        0.199898386544808,
        0.199918376383462,
        0.1999383682211,
        0.199958362057923,
        0.199978357894128,
        0.199998355729918,
        0.200018355565491,
        0.200038357401047,
        0.200058361236787,
        0.200078367072911,
        0.200098374909618,
        0.200118384747109,
        0.200138396585584,
        0.200158410425243,
        0.200178426266285,
        0.200198444108912,
        0.200218463953323,
        0.200238485799718,
        0.200258509648298,
        0.200278535499263,
        0.200298563352813,
        0.200318593209148,
        0.200338625068469,
        0.200358658930976,
        0.200378694796869,
        0.200398732666348
      ]
    },
    SaaS: { median: null, average: null },
    Transactional: {
      median: [
        0.0,
        0.0054501,
        0.0108008,
        0.0160527,
        0.0212064,
        0.0262625,
        0.0312216,
        0.0360843,
        0.0408512,
        0.0455229,
        0.0501,
        0.0545831,
        0.0589728,
        0.0632697,
        0.0674744,
        0.0715875,
        0.0756096,
        0.0795413,
        0.0833832,
        0.0871359,
        0.0908,
        0.0943761,
        0.0978648,
        0.1012667,
        0.1045824,
        0.1078125,
        0.1109576,
        0.1140183,
        0.1169952,
        0.1198889,
        0.1227,
        0.1254291,
        0.1280768,
        0.1306437,
        0.1331304,
        0.1355375,
        0.1378656,
        0.1401153,
        0.1422872,
        0.1443819,
        0.1464,
        0.1483421,
        0.1502088,
        0.1520007,
        0.1537184,
        0.1553625,
        0.1569336,
        0.1584323,
        0.1598592,
        0.1612149,
        0.1625,
        0.1637151,
        0.1648608,
        0.1659377,
        0.1669464,
        0.1678875,
        0.1687616,
        0.1695693,
        0.1703112,
        0.1709879,
        0.1716,
        0.1721481,
        0.1726328,
        0.1730547,
        0.1734144,
        0.1737125,
        0.1739496,
        0.1741263,
        0.1742432,
        0.1743009,
        0.1743586,
        0.1744163,
        0.174474,
        0.1745317,
        0.1745894,
        0.1746471,
        0.1747048,
        0.1747625,
        0.1748202,
        0.1748779,
        0.1749356,
        0.1749933,
        0.175051,
        0.1751087,
        0.1751664,
        0.1752241,
        0.1752818,
        0.1753395,
        0.1753972,
        0.1754549,
        0.1755126,
        0.1755703,
        0.175628,
        0.1756857,
        0.1757434,
        0.1758011,
        0.1758588,
        0.1759165,
        0.1759742,
        0.1760319,
        0.1760896,
        0.1761473,
        0.176205,
        0.1762627,
        0.1763204,
        0.1763781,
        0.1764358,
        0.1764935,
        0.176551199999999,
        0.176608899999999,
        0.176666599999999,
        0.176724299999999,
        0.176781999999999,
        0.176839699999999,
        0.176897399999999,
        0.176955099999999,
        0.177012799999999,
        0.177070499999999,
        0.177128199999999,
        0.177185899999999,
        0.177243599999999
      ],
      average: [
        0.0,
        0.0061402,
        0.0121616,
        0.0180654,
        0.0238528,
        0.029525,
        0.0350832,
        0.0405286,
        0.0458624,
        0.0510858,
        0.0562,
        0.0612062,
        0.0661056,
        0.0708994,
        0.0755888,
        0.080175,
        0.0846592,
        0.0890426,
        0.0933264,
        0.0975118,
        0.1016,
        0.1055922,
        0.1094896,
        0.1132934,
        0.1170048,
        0.120625,
        0.1241552,
        0.1275966,
        0.1309504,
        0.1342178,
        0.1374,
        0.1404982,
        0.1435136,
        0.1464474,
        0.1493008,
        0.152075,
        0.1547712,
        0.1573906,
        0.1599344,
        0.1624038,
        0.1648,
        0.1671242,
        0.1693776,
        0.1715614,
        0.1736768,
        0.175725,
        0.1777072,
        0.1796246,
        0.1814784,
        0.1832698,
        0.185,
        0.1866702,
        0.1882816,
        0.1898354,
        0.1913328,
        0.192775,
        0.1941632,
        0.1954986,
        0.1967824,
        0.1980158,
        0.1992,
        0.19921992,
        0.199239841992,
        0.199259765976199,
        0.199279691952797,
        0.199299619921992,
        0.199319549883984,
        0.199339481838973,
        0.199359415787157,
        0.199379351728735,
        0.199399289663908,
        0.199419229592875,
        0.199439171515834,
        0.199459115432985,
        0.199479061344529,
        0.199499009250663,
        0.199518959151588,
        0.199538911047503,
        0.199558864938608,
        0.199578820825102,
        0.199598778707185,
        0.199618738585055,
        0.199638700458914,
        0.19965866432896,
        0.199678630195392,
        0.199698598058412,
        0.199718567918218,
        0.19973853977501,
        0.199758513628987,
        0.19977848948035,
        0.199798467329298,
        0.199818447176031,
        0.199838429020749,
        0.199858412863651,
        0.199878398704937,
        0.199898386544808,
        0.199918376383462,
        0.1999383682211,
        0.199958362057923,
        0.199978357894128,
        0.199998355729918,
        0.200018355565491,
        0.200038357401047,
        0.200058361236787,
        0.200078367072911,
        0.200098374909618,
        0.200118384747109,
        0.200138396585584,
        0.200158410425243,
        0.200178426266285,
        0.200198444108912,
        0.200218463953323,
        0.200238485799718,
        0.200258509648298,
        0.200278535499263,
        0.200298563352813,
        0.200318593209148,
        0.200338625068469,
        0.200358658930976,
        0.200378694796869,
        0.200398732666348
      ]
    },
    [CustomerFocus.B2B]: {
      median: [
        0.0137626790435825,
        0.0137626790435825,
        0.0137626790435825,
        0.0137626790435825,
        0.0235726377141883,
        0.0311818328140028,
        0.0373989979006767,
        0.0426555360827862,
        0.0472089565712824,
        0.0512253580871651,
        0.054818151671097,
        0.0580682288024244,
        0.0610353167577708,
        0.0637647730894384,
        0.0662918549398803,
        0.0686445118575854,
        0.0708452754283765,
        0.072912575032317,
        0.0748616769442592,
        0.0767053691895756,
        0.0784544705281911,
        0.0801182151263687,
        0.0817045476595186,
        0.083220352763184,
        0.084671635614865,
        0.0860636656280056,
        0.0874010919465325,
        0.0886880371307476,
        0.0899281737969745,
        0.0911247878025388,
        0.0922808307146795,
        0.0933989636729435,
        0.0944815942854707,
        0.095530907846007,
        0.0965488938894111,
        0.097537368896789,
        0.0984979958013534,
        0.0994323008211681,
        0.10034168804667,
        0.101227452133021,
        0.102090789385285,
        0.102932807474617,
        0.103754533983463,
        0.10455692394515,
        0.105340866516613,
        0.106107190901168,
        0.106856671620278,
        0.107590033218313,
        0.108307954471959,
        0.109011072165572,
        0.1096999844851,
        0.1103752540759,
        0.111037410803627,
        0.111686954252127,
        0.112324355987842,
        0.112950061616427,
        0.113564492654069,
        0.114168048233158,
        0.114761106659633,
        0.115344026837185,
        0.115917149571774,
        0.11648079876831,
        0.117035282530038,
        0.117580894169951,
        0.118117913142565,
        0.118646605903441,
        0.119167226703101,
        0.119680018321232,
        0.120185212746505,
        0.120683031806767,
        0.121173687753883,
        0.121657383807109,
        0.122134314658447,
        0.12260466694316,
        0.123068619678262,
        0.123526344671588,
        0.123978006903764,
        0.124423764885211,
        0.124863770990115,
        0.125298171769125,
        0.125727108242379,
        0.12615071617433,
        0.126569126331711,
        0.126982464725864,
        0.127390852840557,
        0.12779440784632,
        0.128193242802245,
        0.128587466846121,
        0.128977185373707,
        0.129362500207866,
        0.129743509758262,
        0.130120309172225,
        0.130492990477372,
        0.130861642716526,
        0.131226352075407,
        0.131587202003578,
        0.131944273329053,
        0.132297644366965,
        0.132647391022667,
        0.13299358688959,
        0.133336303342194,
        0.133675609624287,
        0.134011572932994,
        0.134344258498631,
        0.134673729660721,
        0.135000047940372,
        0.135323273109221,
        0.135643463255151,
        0.135960674844936,
        0.136274962784014,
        0.136586380473521,
        0.136894979864751,
        0.137200811511163,
        0.137503924618091,
        0.137804367090252,
        0.138102185577187,
        0.138397425516727,
        0.138690131176603,
        0.138980345694279,
        0.139268111115103,
        0.139553468428868
      ],
      average: [
        0.01,
        0.0120979608250541,
        0.0120979608250541,
        0.0120979608250541,
        0.0228860385419959,
        0.0312539217162788,
        0.0380909800960521,
        0.0438716305895742,
        0.0488790578129938,
        0.0532959216501082,
        0.0572469409872767,
        0.0608210727299389,
        0.06408399936705,
        0.0670856009048076,
        0.0698646498605722,
        0.0724518825413329,
        0.0748720770839918,
        0.0771455004021081,
        0.0792889409211062,
        0.0813164617187415,
        0.0832399602582747,
        0.0850695914146284,
        0.0868140920009368,
        0.0884810330973431,
        0.090077018638048,
        0.0916078434325575,
        0.0930786201758056,
        0.0944938824751623,
        0.0958576691315701,
        0.0971735936244928,
        0.0984449018123308,
        0.099674520168193,
        0.10086509635499,
        0.102019033554993,
        0.103138519673106,
        0.104225552305853,
        0.105281960192104,
        0.106309421724158,
        0.107309480989739,
        0.108283561729862,
        0.109232979529273,
        0.110158952501412,
        0.111062610685626,
        0.111945004338509,
        0.112807111271935,
        0.113649843366387,
        0.114474052368341,
        0.115280535064127,
        0.116070037909046,
        0.116843261179148,
        0.117600862703555,
        0.118343461227162,
        0.119071639446804,
        0.119785946758205,
        0.12048690174616,
        0.121174994446218,
        0.121850688402568,
        0.122514422543796,
        0.123166612895491,
        0.123807654146464,
        0.124437921083329,
        0.125057769906499,
        0.125667539439191,
        0.126267552239682,
        0.126858115625988,
        0.127439522621086,
        0.128012052825991,
        0.128575973227161,
        0.129131538944104,
        0.129678993922397,
        0.130218571576851,
        0.130750495389049,
        0.131274979463102,
        0.131792229043065,
        0.132302440995156,
        0.132805804257612,
        0.133302500260737,
        0.133792703319513,
        0.13427658100086,
        0.134754294467513,
        0.135225998800271,
        0.135691843300216,
        0.13615197177241,
        0.136606522792372,
        0.137055629956624,
        0.137499422118387,
        0.137938023609507,
        0.138371554449547,
        0.138800130542933,
        0.139223863864955,
        0.139642862637385,
        0.140057231494382,
        0.140467071639339,
        0.140872480993247,
        0.141273554335125,
        0.14167038343502,
        0.142063057180044,
        0.142451661693877,
        0.142836280450146,
        0.143216994380047,
        0.143593881974553,
        0.143967019381547,
        0.14433648049816,
        0.144702337058611,
        0.145064658717801,
        0.145423513130907,
        0.145778966029202,
        0.146131081292321,
        0.146479921017158,
        0.146825545583593,
        0.147168013717216,
        0.147507382549213,
        0.147843707673566,
        0.148177043201713,
        0.148507441814794,
        0.148834954813622,
        0.149159632166489,
        0.149481522554916,
        0.149800673417462,
        0.150117130991682,
        0.150430940354327
      ]
    },
    [CustomerFocus.B2C]: {
      median: [
        0.01,
        0.05,
        0.07,
        0.1,
        0.115,
        0.13,
        0.14,
        0.145,
        0.145786038541996,
        0.148730614433406,
        0.151364627324851,
        0.153747381819959,
        0.1559226662447,
        0.157923733936538,
        0.159776433240381,
        0.161501255027555,
        0.163114718055995,
        0.164630333601405,
        0.166059293947404,
        0.167410974479161,
        0.16869330683885,
        0.169913060943086,
        0.171076061333958,
        0.172187355398229,
        0.173251345758699,
        0.174271895621705,
        0.175252413450537,
        0.176195921650108,
        0.17710511275438,
        0.177982395749662,
        0.178829934541554,
        0.179649680112129,
        0.180443397569993,
        0.181212689036662,
        0.181959013115404,
        0.182683701537235,
        0.183387973461403,
        0.184072947816106,
        0.18473965399316,
        0.185389041153241,
        0.186021986352848,
        0.186639301667608,
        0.187241740457084,
        0.187830002892339,
        0.188404740847957,
        0.188966562244258,
        0.189516034912227,
        0.190053690042751,
        0.190580025272697,
        0.191095507452766,
        0.191600575135704,
        0.192095640818108,
        0.192581092964536,
        0.193057297838803,
        0.193524601164107,
        0.193983329630812,
        0.194433792268379,
        0.194876281695864,
        0.19531107526366,
        0.195738436097643,
        0.196158614055553,
        0.196571846604333,
        0.196978359626127,
        0.197378368159788,
        0.197772077083992,
        0.198159681747391,
        0.198541368550661,
        0.198917315484774,
        0.199287692629403,
        0.199652662614931,
        0.200012381051234,
        0.200366996926033,
        0.200716652975401,
        0.20106148602871,
        0.201401627330104,
        0.201737202838408,
        0.202068333507158,
        0.202395135546342,
        0.20271772066724,
        0.203036196311676,
        0.203350665866847,
        0.203661228866811,
        0.203967981181606,
        0.204271015194915,
        0.204570419971083,
        0.204866281412258,
        0.205158682406338,
        0.205447702966365,
        0.205733420361955,
        0.206015909243304,
        0.206295241758257,
        0.206571487662921,
        0.206844714426226,
        0.207114987328831,
        0.20738236955675,
        0.207646922290014,
        0.207908704786696,
        0.208167774462585,
        0.208424186966764,
        0.208677996253365,
        0.208929254649702,
        0.209178012921032,
        0.209424320332107,
        0.209668224705741,
        0.209909772478534,
        0.210149008753938,
        0.210385977352802,
        0.210620720861548,
        0.210853280678106,
        0.211083697055729,
        0.21131200914481,
        0.211538255032808,
        0.211762471782377,
        0.211984695467809,
        0.212204961209862,
        0.212423303209081,
        0.212639754777659,
        0.212854348369944,
        0.213067115611642,
        0.213278087327788,
        0.213487293569551
      ],
      average: [
        0.01,
        0.05,
        0.07,
        0.1,
        0.115,
        0.14,
        0.15,
        0.155,
        0.157886038541996,
        0.160830614433406,
        0.163464627324851,
        0.165847381819959,
        0.1680226662447,
        0.170023733936538,
        0.171876433240381,
        0.173601255027555,
        0.175214718055995,
        0.176730333601405,
        0.178159293947404,
        0.179510974479161,
        0.18079330683885,
        0.182013060943086,
        0.183176061333958,
        0.184287355398229,
        0.185351345758699,
        0.186371895621705,
        0.187352413450537,
        0.188295921650108,
        0.18920511275438,
        0.190082395749662,
        0.190929934541554,
        0.191749680112129,
        0.192543397569993,
        0.193312689036662,
        0.194059013115404,
        0.194783701537235,
        0.195487973461403,
        0.196172947816106,
        0.19683965399316,
        0.197489041153241,
        0.198121986352848,
        0.198739301667608,
        0.199341740457084,
        0.199930002892339,
        0.200504740847957,
        0.201066562244258,
        0.201616034912227,
        0.202153690042751,
        0.202680025272697,
        0.203195507452766,
        0.203700575135704,
        0.204195640818108,
        0.204681092964536,
        0.205157297838803,
        0.205624601164107,
        0.206083329630812,
        0.206533792268379,
        0.206976281695864,
        0.20741107526366,
        0.207838436097643,
        0.208258614055553,
        0.208671846604333,
        0.209078359626127,
        0.209478368159788,
        0.209872077083992,
        0.210259681747391,
        0.210641368550661,
        0.211017315484774,
        0.211387692629403,
        0.211752662614931,
        0.212112381051234,
        0.212466996926033,
        0.212816652975401,
        0.21316148602871,
        0.213501627330104,
        0.213837202838408,
        0.214168333507158,
        0.214495135546342,
        0.21481772066724,
        0.215136196311676,
        0.215450665866847,
        0.215761228866811,
        0.216067981181606,
        0.216371015194915,
        0.216670419971083,
        0.216966281412258,
        0.217258682406338,
        0.217547702966365,
        0.217833420361955,
        0.218115909243304,
        0.218395241758257,
        0.218671487662921,
        0.218944714426226,
        0.219214987328831,
        0.21948236955675,
        0.219746922290014,
        0.220008704786696,
        0.220267774462585,
        0.220524186966764,
        0.220777996253365,
        0.221029254649702,
        0.221278012921032,
        0.221524320332107,
        0.221768224705741,
        0.222009772478534,
        0.222249008753938,
        0.222485977352802,
        0.222720720861548,
        0.222953280678106,
        0.223183697055729,
        0.22341200914481,
        0.223638255032808,
        0.223862471782377,
        0.224084695467809,
        0.224304961209862,
        0.224523303209081,
        0.224739754777659,
        0.224954348369944,
        0.225167115611642,
        0.225378087327788,
        0.225587293569551
      ]
    },
    [Industry.OTHER]: {
      median: [
        0.0,
        0.0054501,
        0.0108008,
        0.0160527,
        0.0212064,
        0.0262625,
        0.0312216,
        0.0360843,
        0.0408512,
        0.0455229,
        0.0501,
        0.0545831,
        0.0589728,
        0.0632697,
        0.0674744,
        0.0715875,
        0.0756096,
        0.0795413,
        0.0833832,
        0.0871359,
        0.0908,
        0.0943761,
        0.0978648,
        0.1012667,
        0.1045824,
        0.1078125,
        0.1109576,
        0.1140183,
        0.1169952,
        0.1198889,
        0.1227,
        0.1254291,
        0.1280768,
        0.1306437,
        0.1331304,
        0.1355375,
        0.1378656,
        0.1401153,
        0.1422872,
        0.1443819,
        0.1464,
        0.1483421,
        0.1502088,
        0.1520007,
        0.1537184,
        0.1553625,
        0.1569336,
        0.1584323,
        0.1598592,
        0.1612149,
        0.1625,
        0.1637151,
        0.1648608,
        0.1659377,
        0.1669464,
        0.1678875,
        0.1687616,
        0.1695693,
        0.1703112,
        0.1709879,
        0.1716,
        0.1721481,
        0.1726328,
        0.1730547,
        0.1734144,
        0.1737125,
        0.1739496,
        0.1741263,
        0.1742432,
        0.1743009,
        0.1743586,
        0.1744163,
        0.174474,
        0.1745317,
        0.1745894,
        0.1746471,
        0.1747048,
        0.1747625,
        0.1748202,
        0.1748779,
        0.1749356,
        0.1749933,
        0.175051,
        0.1751087,
        0.1751664,
        0.1752241,
        0.1752818,
        0.1753395,
        0.1753972,
        0.1754549,
        0.1755126,
        0.1755703,
        0.175628,
        0.1756857,
        0.1757434,
        0.1758011,
        0.1758588,
        0.1759165,
        0.1759742,
        0.1760319,
        0.1760896,
        0.1761473,
        0.176205,
        0.1762627,
        0.1763204,
        0.1763781,
        0.1764358,
        0.1764935,
        0.176551199999999,
        0.176608899999999,
        0.176666599999999,
        0.176724299999999,
        0.176781999999999,
        0.176839699999999,
        0.176897399999999,
        0.176955099999999,
        0.177012799999999,
        0.177070499999999,
        0.177128199999999,
        0.177185899999999,
        0.177243599999999
      ],
      average: [
        0.0,
        0.0061402,
        0.0121616,
        0.0180654,
        0.0238528,
        0.029525,
        0.0350832,
        0.0405286,
        0.0458624,
        0.0510858,
        0.0562,
        0.0612062,
        0.0661056,
        0.0708994,
        0.0755888,
        0.080175,
        0.0846592,
        0.0890426,
        0.0933264,
        0.0975118,
        0.1016,
        0.1055922,
        0.1094896,
        0.1132934,
        0.1170048,
        0.120625,
        0.1241552,
        0.1275966,
        0.1309504,
        0.1342178,
        0.1374,
        0.1404982,
        0.1435136,
        0.1464474,
        0.1493008,
        0.152075,
        0.1547712,
        0.1573906,
        0.1599344,
        0.1624038,
        0.1648,
        0.1671242,
        0.1693776,
        0.1715614,
        0.1736768,
        0.175725,
        0.1777072,
        0.1796246,
        0.1814784,
        0.1832698,
        0.185,
        0.1866702,
        0.1882816,
        0.1898354,
        0.1913328,
        0.192775,
        0.1941632,
        0.1954986,
        0.1967824,
        0.1980158,
        0.1992,
        0.19921992,
        0.199239841992,
        0.199259765976199,
        0.199279691952797,
        0.199299619921992,
        0.199319549883984,
        0.199339481838973,
        0.199359415787157,
        0.199379351728735,
        0.199399289663908,
        0.199419229592875,
        0.199439171515834,
        0.199459115432985,
        0.199479061344529,
        0.199499009250663,
        0.199518959151588,
        0.199538911047503,
        0.199558864938608,
        0.199578820825102,
        0.199598778707185,
        0.199618738585055,
        0.199638700458914,
        0.19965866432896,
        0.199678630195392,
        0.199698598058412,
        0.199718567918218,
        0.19973853977501,
        0.199758513628987,
        0.19977848948035,
        0.199798467329298,
        0.199818447176031,
        0.199838429020749,
        0.199858412863651,
        0.199878398704937,
        0.199898386544808,
        0.199918376383462,
        0.1999383682211,
        0.199958362057923,
        0.199978357894128,
        0.199998355729918,
        0.200018355565491,
        0.200038357401047,
        0.200058361236787,
        0.200078367072911,
        0.200098374909618,
        0.200118384747109,
        0.200138396585584,
        0.200158410425243,
        0.200178426266285,
        0.200198444108912,
        0.200218463953323,
        0.200238485799718,
        0.200258509648298,
        0.200278535499263,
        0.200298563352813,
        0.200318593209148,
        0.200338625068469,
        0.200358658930976,
        0.200378694796869,
        0.200398732666348
      ]
    },
    [Industry.IT]: { median: null, average: null },
    [Industry.FIPT]: { median: null, average: null },
    [Industry.DH]: { median: null, average: null }
  },
  [EvaluationType.TAKE_RATE_GROWTH]: {
    all: {
      median: [
        null,
        null,
        null,
        null,
        0.963670391368966,
        0.477177616472854,
        0.314981280407657,
        0.233858646446356,
        0.185165159447882,
        0.152685961001358,
        0.129471820154472,
        0.112048116089613,
        0.0984844990103882,
        0.087622754491018,
        0.078725832721117,
        0.0713023631233381,
        0.0650121622198304,
        0.0596122381229029,
        0.0549243932250743,
        0.0508149758760792,
        0.047181778522604,
        0.0439453031306067,
        0.0410427848911873,
        0.0384240088105727,
        0.0360483215559873,
        0.0338824582485224,
        0.0318989361754654,
        0.0300748500703751,
        0.0283909565217392,
        0.0268309696676929,
        0.0253810133987263,
        0.0240291909411668,
        0.0227652434879292,
        0.0215802770986145,
        0.0204665424530671,
        0.0194172558964621,
        0.0184264530168696,
        0.017488868057183,
        0.0165998339942821,
        0.0157551992665321,
        0.0149512579996616,
        0.0141846912441877,
        0.0134525172476605,
        0.0127520491803279,
        0.0120808590413645,
        0.0114367467152391,
        0.0108177133394781,
        0.0102219382975624,
        0.00964775927266877,
        0.00909365489608331,
        0.00855822960343307,
        0.00804020037633113,
        0.00753838509964037,
        0.00705169230769228,
        0.00657911212832533,
        0.006119708262971,
        0.0056726108654032,
        0.00523701020207682,
        0.00481215099396914,
        0.00439732735408989,
        0.00399187824682882,
        0.00359518340543656,
        0.00320665965252516,
        0.00282575757575761,
        0.00245195851711411,
        0.00208477183941872,
        0.00172373243835626,
        0.0013683984720992,
        0.00101834928401819,
        0.000673183496828989,
        0.000444696368861723,
        0.000331146351765677,
        0.00033103673016018,
        0.000330927181108202,
        0.0003308177045378,
        0.000330708300376958,
        0.000330598968554104,
        0.000330489708997221,
        0.00033038052163481,
        0.000330271406395299,
        0.000330162363207339,
        0.00033005339199943,
        0.000329944492700444,
        0.000329835665239179,
        0.000329726909544581,
        0.000329618225545669,
        0.000329509613171686,
        0.000329401072351724,
        0.000329292603015174,
        0.000329184205091426,
        0.000329075878509943,
        0.000328967623200338,
        0.000328859439092296,
        0.000328751326115577,
        0.000328643284200088,
        0.000328535313275739,
        0.000328427413272658,
        0.000328319584120903,
        0.00032821182575075,
        0.000328104138092478,
        0.000327996521076586,
        0.00032788897463365,
        0.000327781498694095,
        0.000327674093188716,
        0.000327566758048311,
        0.000327459493203749,
        0.000327352298585974,
        0.000327245174126004,
        0.000327138119754933,
        0.000327031135404147,
        0.000326924221004961,
        0.000326817376488764,
        0.000326710601787017,
        0.000326603896831331,
        0.00032649726155339,
        0.000326390695884952,
        0.000326284199757998,
        0.000326177773104286,
        0.000326071415856018,
        0.000325965127945175,
        0.000325858909304033
      ],
      average: [
        null,
        null,
        null,
        null,
        0.961564769877203,
        0.475907775292725,
        0.314003564825578,
        0.233037630802254,
        0.184447078746825,
        0.15204428330369,
        0.128891696234264,
        0.111520548423109,
        0.0980037505529912,
        0.0871850533807828,
        0.0783286660501714,
        0.0709440652531708,
        0.0646916617065871,
        0.0593288952860741,
        0.0546778921110072,
        0.0506052502267917,
        0.0470089597563413,
        0.0438096830050232,
        0.0409447882205025,
        0.0383641732283465,
        0.0360272823182016,
        0.0339009367099707,
        0.0319577309887425,
        0.030174830434307,
        0.0285330569948187,
        0.0270161861927652,
        0.0256104002771234,
        0.0243038585601875,
        0.0230863566531414,
        0.0219490538573508,
        0.0208842533213949,
        0.0198852234213343,
        0.018946051619899,
        0.0180615241177543,
        0.0172270261384184,
        0.0164384588347186,
        0.0156921696721405,
        0.0149848938064607,
        0.0143137044822842,
        0.0136759708737864,
        0.0130693220969794,
        0.0124916163648558,
        0.0119409144481218,
        0.0114154567564579,
        0.0109136434770238,
        0.0104340173048701,
        0.00997524837911956,
        0.00953612110311752,
        0.00911552257927917,
        0.00871243243243244,
        0.00832591383091678,
        0.00795510554403619,
        0.00759921489880178,
        0.00725751151919589,
        0.00692932174815201,
        0.00661402366668858,
        0.00631104263662248,
        0.00412896004249704,
        0.00206051232948753,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333365,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333365,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333365
      ]
    },
    SaaS: { median: null, average: null },
    Transactional: {
      median: [
        null,
        null,
        null,
        null,
        0.963670391368966,
        0.477177616472854,
        0.314981280407657,
        0.233858646446356,
        0.185165159447882,
        0.152685961001358,
        0.129471820154472,
        0.112048116089613,
        0.0984844990103882,
        0.087622754491018,
        0.078725832721117,
        0.0713023631233381,
        0.0650121622198304,
        0.0596122381229029,
        0.0549243932250743,
        0.0508149758760792,
        0.047181778522604,
        0.0439453031306067,
        0.0410427848911873,
        0.0384240088105727,
        0.0360483215559873,
        0.0338824582485224,
        0.0318989361754654,
        0.0300748500703751,
        0.0283909565217392,
        0.0268309696676929,
        0.0253810133987263,
        0.0240291909411668,
        0.0227652434879292,
        0.0215802770986145,
        0.0204665424530671,
        0.0194172558964621,
        0.0184264530168696,
        0.017488868057183,
        0.0165998339942821,
        0.0157551992665321,
        0.0149512579996616,
        0.0141846912441877,
        0.0134525172476605,
        0.0127520491803279,
        0.0120808590413645,
        0.0114367467152391,
        0.0108177133394781,
        0.0102219382975624,
        0.00964775927266877,
        0.00909365489608331,
        0.00855822960343307,
        0.00804020037633113,
        0.00753838509964037,
        0.00705169230769228,
        0.00657911212832533,
        0.006119708262971,
        0.0056726108654032,
        0.00523701020207682,
        0.00481215099396914,
        0.00439732735408989,
        0.00399187824682882,
        0.00359518340543656,
        0.00320665965252516,
        0.00282575757575761,
        0.00245195851711411,
        0.00208477183941872,
        0.00172373243835626,
        0.0013683984720992,
        0.00101834928401819,
        0.000673183496828989,
        0.000444696368861723,
        0.000331146351765677,
        0.00033103673016018,
        0.000330927181108202,
        0.0003308177045378,
        0.000330708300376958,
        0.000330598968554104,
        0.000330489708997221,
        0.00033038052163481,
        0.000330271406395299,
        0.000330162363207339,
        0.00033005339199943,
        0.000329944492700444,
        0.000329835665239179,
        0.000329726909544581,
        0.000329618225545669,
        0.000329509613171686,
        0.000329401072351724,
        0.000329292603015174,
        0.000329184205091426,
        0.000329075878509943,
        0.000328967623200338,
        0.000328859439092296,
        0.000328751326115577,
        0.000328643284200088,
        0.000328535313275739,
        0.000328427413272658,
        0.000328319584120903,
        0.00032821182575075,
        0.000328104138092478,
        0.000327996521076586,
        0.00032788897463365,
        0.000327781498694095,
        0.000327674093188716,
        0.000327566758048311,
        0.000327459493203749,
        0.000327352298585974,
        0.000327245174126004,
        0.000327138119754933,
        0.000327031135404147,
        0.000326924221004961,
        0.000326817376488764,
        0.000326710601787017,
        0.000326603896831331,
        0.00032649726155339,
        0.000326390695884952,
        0.000326284199757998,
        0.000326177773104286,
        0.000326071415856018,
        0.000325965127945175,
        0.000325858909304033
      ],
      average: [
        null,
        null,
        null,
        null,
        0.961564769877203,
        0.475907775292725,
        0.314003564825578,
        0.233037630802254,
        0.184447078746825,
        0.15204428330369,
        0.128891696234264,
        0.111520548423109,
        0.0980037505529912,
        0.0871850533807828,
        0.0783286660501714,
        0.0709440652531708,
        0.0646916617065871,
        0.0593288952860741,
        0.0546778921110072,
        0.0506052502267917,
        0.0470089597563413,
        0.0438096830050232,
        0.0409447882205025,
        0.0383641732283465,
        0.0360272823182016,
        0.0339009367099707,
        0.0319577309887425,
        0.030174830434307,
        0.0285330569948187,
        0.0270161861927652,
        0.0256104002771234,
        0.0243038585601875,
        0.0230863566531414,
        0.0219490538573508,
        0.0208842533213949,
        0.0198852234213343,
        0.018946051619899,
        0.0180615241177543,
        0.0172270261384184,
        0.0164384588347186,
        0.0156921696721405,
        0.0149848938064607,
        0.0143137044822842,
        0.0136759708737864,
        0.0130693220969794,
        0.0124916163648558,
        0.0119409144481218,
        0.0114154567564579,
        0.0109136434770238,
        0.0104340173048701,
        0.00997524837911956,
        0.00953612110311752,
        0.00911552257927917,
        0.00871243243243244,
        0.00832591383091678,
        0.00795510554403619,
        0.00759921489880178,
        0.00725751151919589,
        0.00692932174815201,
        0.00661402366668858,
        0.00631104263662248,
        0.00412896004249704,
        0.00206051232948753,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333365,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333365,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333365
      ]
    },
    [CustomerFocus.B2B]: {
      median: [
        null,
        null,
        null,
        0.0,
        0.237598087783644,
        0.421893482493699,
        0.572473784167665,
        0.269845326602433,
        0.171329716813848,
        0.123232893594352,
        0.0950452290233832,
        0.0766752259164019,
        0.0638353023900982,
        0.0544018185315708,
        0.0472066871853829,
        0.0415562415555405,
        0.0370136571018965,
        0.0332907670102204,
        0.0301901536793522,
        0.0275722632914482,
        0.025335801098863,
        0.0234055589663207,
        0.0217245916540192,
        0.0202490361730689,
        0.0189445911532694,
        0.017784069117966,
        0.01674565994389,
        0.0158116764318084,
        0.0149676333241968,
        0.0142015611516776,
        0.0135034881823473,
        0.0128650445106144,
        0.0122791561033349,
        0.0117398059313687,
        0.0112418457071886,
        0.0107808462040605,
        0.0103529772798043,
        0.00995491098033585,
        0.00958374272889606,
        0.00923692680059614,
        0.00891222316477928,
        0.00860765343693104,
        0.00832146417858115,
        0.00805209616105596,
        0.00779815849800736,
        0.00755840677471831,
        0.0073317244755405,
        0.00711710714646396,
        0.00691364883661557,
        0.00672053044708336,
        0.00653700968287474,
        0.00636241235779729,
        0.00619612484554658,
        0.00603758750545517,
        0.00588628893997667,
        0.00574176096433895,
        0.00560357418797186,
        0.00547133412310548,
        0.00534467774899154,
        0.00522327047104154,
        0.00510680342321172,
        0.00499499106951344,
        0.00488756906686897,
        0.00478429235686558,
        0.00468493345846421,
        0.00458928093753845,
        0.00449713803235456,
        0.0044083214168702,
        0.00432266008608292,
        0.00423999434968247,
        0.00416017492199372,
        0.00408306209768846,
        0.00400852500403618,
        0.00393644092157327,
        0.00386669466603817,
        0.00379917802525724,
        0.00373378924539747,
        0.00367043256163694,
        0.00360901776886176,
        0.00354945982848472,
        0.00349167850790977,
        0.00343559804953878,
        0.00338114686655196,
        0.00332825726298299,
        0.00327686517586649,
        0.00322690993746651,
        0.00317833405579389,
        0.00313108301180254,
        0.00308510507181037,
        0.00304035111383919,
        0.00299677446668567,
        0.00295433076065758,
        0.0029129777890032,
        0.00287267537915718,
        0.00283338527300566,
        0.00279507101544504,
        0.00275769785057737,
        0.00272123262493958,
        0.00268564369722231,
        0.00265090085397753,
        0.00261697523086046,
        0.00258383923898601,
        0.00255146649602297,
        0.0025198317616717,
        0.00248891087720785,
        0.00245868070879762,
        0.00242911909431272,
        0.00240020479340005,
        0.00237191744057423,
        0.00234423750112363,
        0.00231714622963867,
        0.00229062563097811,
        0.00226465842351472,
        0.00223922800450153,
        0.00221431841742281,
        0.00218991432119623,
        0.00216600096110671,
        0.00214256414136009,
        0.00211959019915131,
        0.00209706598015417,
        0.00207497881533943
      ],
      average: [
        null,
        null,
        null,
        0.0699320275018038,
        0.297242316920065,
        0.527801369950817,
        0.716181832814007,
        0.305653481110616,
        0.187977861431439,
        0.133058110832842,
        0.101624597474927,
        0.0814392056043984,
        0.0674728158236092,
        0.0572878349355388,
        0.0495638365932209,
        0.0435255979492097,
        0.0386892570793393,
        0.0347378851157975,
        0.0314556279282913,
        0.0286906453680116,
        0.0263331835056277,
        0.0243020461044878,
        0.022535946457396,
        0.0209878077100093,
        0.019620905426372,
        0.0184061954349106,
        0.0173204241915681,
        0.0163447677106279,
        0.0154638348994697,
        0.0146649267789341,
        0.0139374783978106,
        0.0132726331695798,
        0.0126629145251195,
        0.0121019699915514,
        0.0115843698037299,
        0.0111054470188455,
        0.0106611695334683,
        0.0102480368508372,
        0.00986299621560679,
        0.00950337402622041,
        0.0091668193897243,
        0.00885125739555896,
        0.00855485022031795,
        0.00827596458176871,
        0.008013144371204,
        0.00776508753272546,
        0.00753062644399013,
        0.00730871119826807,
        0.00709839530195292,
        0.00689882339211708,
        0.00670922065070097,
        0.0065288836495363,
        0.00635717240676925,
        0.00619350347272304,
        0.00603734389370196,
        0.00588820592708202,
        0.00574564240141721,
        0.00560924263205213,
        0.00547862881659963,
        0.00535345284613409,
        0.00523339347753267,
        0.00511815382039497,
        0.00500745909868172,
        0.00490105465286176,
        0.00479870415311405,
        0.00470018799817133,
        0.0046053018778122,
        0.00451385547992371,
        0.00442567132554964,
        0.00434058371746245,
        0.00425843778963723,
        0.00417908864656758,
        0.00410240058272896,
        0.00402824637366199,
        0.00395650663117116,
        0.0038870692160086,
        0.0038198287021897,
        0.00375468588774649,
        0.00369154734732106,
        0.00363032502250197,
        0.00357093584626581,
        0.00351330139827259,
        0.00345734758811508,
        0.00340300436392897,
        0.00335020544403797,
        0.00329888806955066,
        0.00324899277603263,
        0.00320046318257565,
        0.00315324579673744,
        0.00310728983399293,
        0.00306254705045269,
        0.00301897158773505,
        0.0029765198289801,
        0.00293515026508525,
        0.00289482337033542,
        0.00285550148666595,
        0.00281714871587494,
        0.00277973081915735,
        0.00274321512338993,
        0.00270757043364855,
        0.00267276695148033,
        0.00263877619849628,
        0.00260557094488934,
        0.00257312514250985,
        0.00254141386216896,
        0.00251041323485968,
        0.00248010039661639,
        0.00245045343675458,
        0.00242145134925131,
        0.00239307398704961,
        0.00236530201908214,
        0.00233811688983169,
        0.00231150078125301,
        0.00228543657690006,
        0.00225990782811025,
        0.00223489872211191,
        0.00221039405192765,
        0.00218637918795809,
        0.00216284005113791,
        0.00213976308756458,
        0.00211713524450715
      ]
    },
    [CustomerFocus.B2C]: {
      median: [
        null,
        null,
        null,
        3.0,
        0.433333333333333,
        0.285714285714286,
        0.133333333333333,
        0.0869565217391304,
        0.0404770219025536,
        0.0207871772223941,
        0.0146313271835659,
        0.0182032595109578,
        0.0161187433595809,
        0.0144443844589274,
        0.0130713367799705,
        0.0119259735551648,
        0.0109567320673537,
        0.0101264420177272,
        0.00940764396138623,
        0.00877962143120163,
        0.00822645731715806,
        0.00773572480060862,
        0.00729758381770673,
        0.00690414382734776,
        0.00654900570343497,
        0.00622692671089831,
        0.00593357169040187,
        0.0056653256737386,
        0.00541915096248065,
        0.0051924768497702,
        0.00498311362105253,
        0.00478918482960933,
        0.00460907347974045,
        0.00444137890228221,
        0.00428488192841751,
        0.00413851656052448,
        0.00400134677158404,
        0.00387254738399223,
        0.0037513882165805,
        0.00363722086762978,
        0.0035294676374534,
        0.00342761219800776,
        0.00333119169705324,
        0.00323979004657586,
        0.0031530321937819,
        0.00307057921124369,
        0.00299212407305475,
        0.00291738800799009,
        0.00284611733997986,
        0.00277808074176907,
        0.00271306684022502,
        0.00265088212198905,
        0.00259134909653702,
        0.00253430468057074,
        0.00247959877332105,
        0.00242709299701664,
        0.00237665958066165,
        0.00232818036850121,
        0.00228154593726456,
        0.00223665480854862,
        0.002193412744622,
        0.0021517321175469,
        0.00211153134288825,
        0.00207273437044571,
        0.00203527022543715,
        0.0019990725944139,
        0.00196407945091326,
        0.001930232716482,
        0.00189747795324265,
        0.00186576408463927,
        0.00183504314140293,
        0.00180527003012558,
        0.00177640232213679,
        0.00174840006063937,
        0.0017212255842934,
        0.00169484336563985,
        0.00166921986293082,
        0.00164432338408971,
        0.00162012396166289,
        0.00159659323774079,
        0.00157370435793921,
        0.00155143187361821,
        0.00152975165160611,
        0.00150864079076518,
        0.00148807754480429,
        0.00146804125080102,
        0.00144851226294581,
        0.00142947189107427,
        0.00141090234358332,
        0.00139278667437851,
        0.00137510873352002,
        0.00135785312127316,
        0.00134100514529312,
        0.00132455078069671,
        0.00130847663279976,
        0.00129276990231183,
        0.00127741835280504,
        0.00126241028028429,
        0.00124773448470246,
        0.0012333802432789,
        0.00121933728548784,
        0.00120559576959815,
        0.001192146260653,
        0.00117897970978675,
        0.00116608743478704,
        0.00115346110181484,
        0.00114109270820302,
        0.00112897456626045,
        0.0011170992880137,
        0.00110545977082384,
        0.00109404918382043,
        0.00108286095509946,
        0.00107188875963504,
        0.00106112650785994,
        0.00105056833487101,
        0.00104020859022184,
        0.00103004182826346,
        0.00102006279900202,
        0.00101026643943847,
        0.00100064786536362,
        0.000991202363579265
      ],
      average: [
        null,
        null,
        null,
        3.0,
        0.433333333333333,
        0.333333333333333,
        0.166666666666667,
        0.115942028985507,
        0.0425858060523712,
        0.0240680320742344,
        0.0182034996233358,
        0.0168082062046833,
        0.0149060588508632,
        0.0133751803453935,
        0.0121176697802144,
        0.0110671353802763,
        0.0101769794119717,
        0.00941354644441065,
        0.00875193158237897,
        0.00817331723200998,
        0.00766322557584243,
        0.00721033952312207,
        0.00680568762890569,
        0.00644206842327673,
        0.00611363601841915,
        0.00581559668964353,
        0.00554398328213201,
        0.00529548513995863,
        0.00506731826567153,
        0.00485712504551487,
        0.00466289598550129,
        0.00448290802981958,
        0.0043156755094292,
        0.00415991080851967,
        0.00401449257859676,
        0.00387843986605282,
        0.00375089091078447,
        0.00363108566266298,
        0.00351835127836445,
        0.0034120900234195,
        0.0033117691275996,
        0.00321691223611217,
        0.00312709217183923,
        0.00304192478040721,
        0.00296106367409488,
        0.0028841957254225,
        0.00281103718884427,
        0.00274133035096217,
        0.00267484062728644,
        0.00261135403776443,
        0.00255067500478649,
        0.0024926244267233,
        0.00243703798768843,
        0.0023837646704874,
        0.00233266544488675,
        0.00228361210760693,
        0.00223648625400474,
        0.00219117836436659,
        0.00214758699021944,
        0.00210561802814483,
        0.00206518407033881,
        0.00202620382264166,
        0.00198860158201889,
        0.00195230676654501,
        0.00191725349185027,
        0.00188338018877395,
        0.00185062925763161,
        0.00181894675508139,
        0.00178828211006706,
        0.00175858786574423,
        0.00172981944466518,
        0.00170193493481892,
        0.0016748948944029,
        0.00164866217344536,
        0.0016232017506097,
        0.00159848058369808,
        0.00157446747253539,
        0.00155113293305524,
        0.00152844908153956,
        0.00150638952806855,
        0.00148492927834328,
        0.00146404464312126,
        0.00144371315458991,
        0.00142391348906568,
        0.00140462539546915,
        0.00138582962908103,
        0.00136750789012881,
        0.00134964276680205,
        0.00133221768232517,
        0.00131521684576078,
        0.00129862520623517,
        0.00128242841031693,
        0.00126661276229667,
        0.00125116518713986,
        0.00123607319590868,
        0.00122132485346027,
        0.00120690874825069,
        0.00119281396408681,
        0.00117903005367988,
        0.00116554701387036,
        0.00115235526240072,
        0.00113944561612615,
        0.0011268092705603,
        0.00111443778066052,
        0.00110232304276877,
        0.00109045727762506,
        0.00107883301438272,
        0.00106744307555514,
        0.0010562805628335,
        0.00104533884371586,
        0.00103461153889447,
        0.0010240925103524,
        0.00101377585012289,
        0.00100365586966888,
        0.000993727089843096,
        0.000983984231393527,
        0.000974422205977469,
        0.000965036107655785,
        0.000955821204834765,
        0.00094677293263142,
        0.000937886885633565
      ]
    },
    [Industry.OTHER]: {
      median: [
        null,
        null,
        null,
        null,
        0.963670391368966,
        0.477177616472854,
        0.314981280407657,
        0.233858646446356,
        0.185165159447882,
        0.152685961001358,
        0.129471820154472,
        0.112048116089613,
        0.0984844990103882,
        0.087622754491018,
        0.078725832721117,
        0.0713023631233381,
        0.0650121622198304,
        0.0596122381229029,
        0.0549243932250743,
        0.0508149758760792,
        0.047181778522604,
        0.0439453031306067,
        0.0410427848911873,
        0.0384240088105727,
        0.0360483215559873,
        0.0338824582485224,
        0.0318989361754654,
        0.0300748500703751,
        0.0283909565217392,
        0.0268309696676929,
        0.0253810133987263,
        0.0240291909411668,
        0.0227652434879292,
        0.0215802770986145,
        0.0204665424530671,
        0.0194172558964621,
        0.0184264530168696,
        0.017488868057183,
        0.0165998339942821,
        0.0157551992665321,
        0.0149512579996616,
        0.0141846912441877,
        0.0134525172476605,
        0.0127520491803279,
        0.0120808590413645,
        0.0114367467152391,
        0.0108177133394781,
        0.0102219382975624,
        0.00964775927266877,
        0.00909365489608331,
        0.00855822960343307,
        0.00804020037633113,
        0.00753838509964037,
        0.00705169230769228,
        0.00657911212832533,
        0.006119708262971,
        0.0056726108654032,
        0.00523701020207682,
        0.00481215099396914,
        0.00439732735408989,
        0.00399187824682882,
        0.00359518340543656,
        0.00320665965252516,
        0.00282575757575761,
        0.00245195851711411,
        0.00208477183941872,
        0.00172373243835626,
        0.0013683984720992,
        0.00101834928401819,
        0.000673183496828989,
        0.000444696368861723,
        0.000331146351765677,
        0.00033103673016018,
        0.000330927181108202,
        0.0003308177045378,
        0.000330708300376958,
        0.000330598968554104,
        0.000330489708997221,
        0.00033038052163481,
        0.000330271406395299,
        0.000330162363207339,
        0.00033005339199943,
        0.000329944492700444,
        0.000329835665239179,
        0.000329726909544581,
        0.000329618225545669,
        0.000329509613171686,
        0.000329401072351724,
        0.000329292603015174,
        0.000329184205091426,
        0.000329075878509943,
        0.000328967623200338,
        0.000328859439092296,
        0.000328751326115577,
        0.000328643284200088,
        0.000328535313275739,
        0.000328427413272658,
        0.000328319584120903,
        0.00032821182575075,
        0.000328104138092478,
        0.000327996521076586,
        0.00032788897463365,
        0.000327781498694095,
        0.000327674093188716,
        0.000327566758048311,
        0.000327459493203749,
        0.000327352298585974,
        0.000327245174126004,
        0.000327138119754933,
        0.000327031135404147,
        0.000326924221004961,
        0.000326817376488764,
        0.000326710601787017,
        0.000326603896831331,
        0.00032649726155339,
        0.000326390695884952,
        0.000326284199757998,
        0.000326177773104286,
        0.000326071415856018,
        0.000325965127945175,
        0.000325858909304033
      ],
      average: [
        null,
        null,
        null,
        null,
        0.961564769877203,
        0.475907775292725,
        0.314003564825578,
        0.233037630802254,
        0.184447078746825,
        0.15204428330369,
        0.128891696234264,
        0.111520548423109,
        0.0980037505529912,
        0.0871850533807828,
        0.0783286660501714,
        0.0709440652531708,
        0.0646916617065871,
        0.0593288952860741,
        0.0546778921110072,
        0.0506052502267917,
        0.0470089597563413,
        0.0438096830050232,
        0.0409447882205025,
        0.0383641732283465,
        0.0360272823182016,
        0.0339009367099707,
        0.0319577309887425,
        0.030174830434307,
        0.0285330569948187,
        0.0270161861927652,
        0.0256104002771234,
        0.0243038585601875,
        0.0230863566531414,
        0.0219490538573508,
        0.0208842533213949,
        0.0198852234213343,
        0.018946051619899,
        0.0180615241177543,
        0.0172270261384184,
        0.0164384588347186,
        0.0156921696721405,
        0.0149848938064607,
        0.0143137044822842,
        0.0136759708737864,
        0.0130693220969794,
        0.0124916163648558,
        0.0119409144481218,
        0.0114154567564579,
        0.0109136434770238,
        0.0104340173048701,
        0.00997524837911956,
        0.00953612110311752,
        0.00911552257927917,
        0.00871243243243244,
        0.00832591383091678,
        0.00795510554403619,
        0.00759921489880178,
        0.00725751151919589,
        0.00692932174815201,
        0.00661402366668858,
        0.00631104263662248,
        0.00412896004249704,
        0.00206051232948753,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333365,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333365,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333291,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333365,
        0.000100010000333365
      ]
    },
    [Industry.IT]: { median: null, average: null },
    [Industry.FIPT]: { median: null, average: null },
    [Industry.DH]: { median: null, average: null }
  }
};
